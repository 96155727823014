<template>
  <v-tooltip bottom transition="slide-y-transition">
    <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="pointer">
            <profile-avatar size="30" :avatar="userDetail" />
          </span>
    </template>
    <div class="font-weight-medium">
      <div>
        {{ userDetail?.name || 'Anonymous' }}
      </div>
      <div v-if="userDetail?.email" class="text-caption">
        {{ userDetail.email }}
      </div>
    </div>
  </v-tooltip>
</template>

<script>
import ProfileAvatar from "@/view/components/Team/Members/AvatarGroup/ProfileAvatar";
export default {
  components: { ProfileAvatar },
  props: {
    userDetail: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>

</style>
