var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_vm.fromModal ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-expansion-panels', {
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, _vm._l(_vm.faqList, function (item, index) {
    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', {
      staticClass: "py-2 px-4",
      scopedSlots: _vm._u([{
        key: "actions",
        fn: function fn() {
          return [_c('v-icon', {
            staticClass: "ml-4",
            attrs: {
              "left": "",
              "large": ""
            }
          }, [_vm._v("expand_more")])];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "subTitle darkGrey--text"
    }, [_vm._v(" " + _vm._s(item.title) + " ")])]), _c('v-expansion-panel-content', [_c('div', {
      staticClass: "darkGrey--text bodyFont",
      domProps: {
        "innerHTML": _vm._s(item.description)
      }
    })])], 1);
  }), 1)], 1) : _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', _vm._l(_vm.faqList, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-avatar', [_c('v-icon', {
      attrs: {
        "color": "primary"
      },
      domProps: {
        "textContent": _vm._s('mdi-file-document-outline')
      }
    })], 1)], 1), _c('v-col', [_c('div', {
      staticClass: "font-size-h3 bold-text"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      domProps: {
        "innerHTML": _vm._s(item.description)
      }
    })])], 1)], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }