var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', {
    attrs: {
      "can-be-managed": true,
      "is-empty": _vm.releases.length === 0,
      "loading": _vm.dashboardLoader
    },
    scopedSlots: _vm._u([{
      key: "widget-loading",
      fn: function fn() {
        return [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('v-skeleton-loader', {
          attrs: {
            "type": "list-item-two-line"
          }
        })], 1), _c('v-col', {
          staticStyle: {
            "display": "flex",
            "justify-content": "flex-end"
          },
          attrs: {
            "cols": "6"
          }
        }, [_c('v-skeleton-loader', {
          staticStyle: {
            "margin-right": "16px"
          },
          attrs: {
            "type": "button"
          }
        })], 1)], 1), _c('v-row', [_c('v-col', _vm._l(5, function (i) {
          return _c('v-skeleton-loader', {
            key: i,
            staticClass: "transparent",
            attrs: {
              "loading": true,
              "type": "list-item-avatar-two-line"
            }
          });
        }), 1)], 1)];
      },
      proxy: true
    }, {
      key: "widget-content",
      fn: function fn() {
        return [_c('v-card-title', [_c('div', [_c('div', {
          staticClass: "card-label bold-text text-dark"
        }, [_vm._v("Recent Releases")])]), _c('v-spacer'), _vm.canManageRelease && !_vm.getInactiveUserMessage ? _c('div', {
          staticClass: "card-toolbar"
        }, [_c('NewReleasePanel')], 1) : _vm._e()], 1), _c('v-data-table', {
          attrs: {
            "headers": _vm.releaseHeader,
            "items": _vm.releases,
            "hide-default-footer": "",
            "mobile-breakpoint": "0"
          },
          scopedSlots: _vm._u([{
            key: "item",
            fn: function fn(props) {
              var _props$item3, _props$item4, _props$item5, _props$item6, _props$item7, _props$item8, _props$item8$stats, _props$item9, _props$item9$stats;
              return [_c('tr', {
                staticClass: "cursor-pointer",
                on: {
                  "click": function click($event) {
                    var _props$item, _props$item$app, _props$item2;
                    return _vm.$router.push({
                      name: 'release-detail-page',
                      params: {
                        app_id: props === null || props === void 0 ? void 0 : (_props$item = props.item) === null || _props$item === void 0 ? void 0 : (_props$item$app = _props$item.app) === null || _props$item$app === void 0 ? void 0 : _props$item$app.id,
                        release_id: props === null || props === void 0 ? void 0 : (_props$item2 = props.item) === null || _props$item2 === void 0 ? void 0 : _props$item2.id
                      }
                    });
                  }
                }
              }, [_c('td', {
                staticClass: "py-2"
              }, [_c('v-row', {
                attrs: {
                  "align": "center"
                }
              }, [_c('v-col', {
                attrs: {
                  "cols": "auto"
                }
              }, [_c('AppImage', {
                attrs: {
                  "alt": props === null || props === void 0 ? void 0 : (_props$item3 = props.item) === null || _props$item3 === void 0 ? void 0 : _props$item3.name,
                  "appId": props === null || props === void 0 ? void 0 : (_props$item4 = props.item) === null || _props$item4 === void 0 ? void 0 : _props$item4.id,
                  "image": props === null || props === void 0 ? void 0 : (_props$item5 = props.item) === null || _props$item5 === void 0 ? void 0 : _props$item5.image
                }
              })], 1), _c('v-col', [_c('a', {
                staticClass: "darkGrey--text bodyFont mb-1"
              }, [_vm._v(" " + _vm._s(_vm.get(props.item, "app.name", "")))]), _c('div', [_c('vue-hoverable-date', {
                attrs: {
                  "date": props.item.created_at
                }
              })], 1)])], 1)], 1), _c('td', [props.item.platform == 'android' ? _c('PlatformIcon', {
                staticClass: "platforms",
                attrs: {
                  "android": "",
                  "color": "primary",
                  "size": "20"
                }
              }) : _vm._e(), props.item.platform == 'ios' ? _c('PlatformIcon', {
                staticClass: "platforms",
                attrs: {
                  "apple": "",
                  "color": "primary",
                  "size": "20"
                }
              }) : _vm._e()], 1), _c('td', [_c('user-avatar', {
                attrs: {
                  "user-detail": props === null || props === void 0 ? void 0 : (_props$item6 = props.item) === null || _props$item6 === void 0 ? void 0 : _props$item6.user
                }
              })], 1), _c('td', [_c('MaxText', {
                attrs: {
                  "text": props.item.version ? "v".concat(props.item.version, " (").concat(props === null || props === void 0 ? void 0 : (_props$item7 = props.item) === null || _props$item7 === void 0 ? void 0 : _props$item7.version_code, ")") : '-',
                  "max": "16"
                }
              })], 1), _c('td', [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$item8 = props.item) === null || _props$item8 === void 0 ? void 0 : (_props$item8$stats = _props$item8.stats) === null || _props$item8$stats === void 0 ? void 0 : _props$item8$stats.comments) + " ")]), _c('td', [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$item9 = props.item) === null || _props$item9 === void 0 ? void 0 : (_props$item9$stats = _props$item9.stats) === null || _props$item9$stats === void 0 ? void 0 : _props$item9$stats.installs) + " ")]), _c('td', [_c('div', {
                staticClass: "flex-row jc-c"
              }, [_c('v-btn', {
                attrs: {
                  "icon": ""
                }
              }, [_c('i', {
                staticClass: "arrow-right"
              })])], 1)])])];
            }
          }])
        })];
      },
      proxy: true
    }, {
      key: "widget-no-data",
      fn: function fn() {
        return [_c('v-card-title', [_vm._v(" Releases ")]), _c('no-data', {
          attrs: {
            "align-class": "text-left",
            "btn-icon": "",
            "btn-text": "New Releases",
            "first-text": "No Releases, Just Yet...",
            "second-text": "<ul><li><strong>1. Upload</strong>: Add your APK or IPA to notify team members and guests.</li><li><strong>2. Installation</strong>: The release can be installed via our app or the public install page.</li><li><strong>3. Automate</strong>: Streamline your process with <a href='https://help.testapp.io/topic/integrations/' target='_blank'>our integrations</a>. Automate release uploads, notifications, and more to save time and ensure consistency.</li></ul>"
          }
        })];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }