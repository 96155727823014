var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": _vm.outlined || _vm.$vuetify.theme.dark
    }
  }, [_c('v-row', [_vm._l(_vm.stats, function (stat, index) {
    return [_c('v-col', {
      key: index,
      staticClass: "pa-2 px-6",
      attrs: {
        "cols": "6",
        "md": "auto",
        "sm": "6"
      }
    }, [_c('div', {
      staticClass: "stats-item ma-4"
    }, [_vm.dotType == 'square' ? _c('div', [_c('v-badge', {
      attrs: {
        "color": _vm.statColor(stat),
        "dot": "",
        "inline": "",
        "tile": ""
      }
    }), _c('span', {
      staticClass: "darkGrey--text"
    }, [_vm._v(" " + _vm._s(stat.title))])], 1) : _vm._e(), _vm.dotType == 'circle' ? _c('div', {
      staticClass: "statRow"
    }, [_c('div', {
      staticClass: "greenCircleContainer"
    }, [_c('div', {
      staticClass: "greenCircle"
    })]), _c('span', {
      staticClass: "darkGrey--text"
    }, [_vm._v(_vm._s(stat.title))])]) : _vm._e(), _vm.dotType == 'icon' ? _c('div', {
      staticClass: "statRow"
    }, [stat.icon ? _c('div', [_c('v-icon', {
      attrs: {
        "size": "20",
        "left": "",
        "color": stat.color
      },
      domProps: {
        "textContent": _vm._s(stat.icon)
      }
    }), _c('span', {
      staticClass: "darkGrey--text smallFont"
    }, [_vm._v(_vm._s(stat.title))])], 1) : _vm._e(), !stat.icon && stat.storage ? _c('div', [_c('img', {
      staticClass: "mx-auto",
      attrs: {
        "contain": "",
        "height": "20",
        "src": stat.storage.name ? _vm.get(stat, 'storage.icon', null) == 'testappio' ? _vm.getStorageImg('testapp.io.svg') : _vm.getStorageImg(_vm.get(stat, 'storage.icon', null)) : _vm.getStorageImg('testapp.io.svg')
      }
    }), _c('span', {
      staticClass: "ml-2 darkGrey--text smallFont"
    }, [_vm._v(_vm._s(stat.title))])]) : _vm._e()]) : _vm._e(), stat.tooltip ? _c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('span', _vm._g({
            staticClass: "stats-count darkGrey--text",
            class: _vm.dotType == 'circle' ? 'green-text' : '',
            on: {
              "click": function click($event) {
                return _vm.navigateToLink(stat.link);
              }
            }
          }, on), [_vm._v(_vm._s(stat.count))])];
        }
      }], null, true)
    }, [_c('div', {
      domProps: {
        "innerHTML": _vm._s(stat.tooltip)
      }
    })]) : (stat === null || stat === void 0 ? void 0 : stat.type) === 'icon' ? _c('div', {
      staticClass: "text-left"
    }, [stat.count == 'mdi-check-circle-outline' ? _c('v-icon', {
      attrs: {
        "color": "success"
      },
      domProps: {
        "textContent": _vm._s(stat.count)
      }
    }) : (stat === null || stat === void 0 ? void 0 : stat.status) !== 1 && stat.count === 'mdi-close-circle-outline' ? _c('v-icon', {
      attrs: {
        "color": "error"
      },
      domProps: {
        "textContent": _vm._s(stat.count)
      }
    }) : _c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on;
          return [_c('v-btn', _vm._g({
            staticClass: "text-transform-none",
            attrs: {
              "small": "",
              "outlined": _vm.$vuetify.theme.dark,
              "loading": _vm.notifyLoading,
              "color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.$emit('update-notify');
              }
            }
          }, on), [_vm._v(" Notify ")])];
        }
      }], null, true)
    }, [_c('span', [_c('span', [_vm._v(" Choose this to send notifications manually from the release page at your convenience. All app "), _c('br'), _vm._v(" members with access will receive a push notification in our app for the relevant platform, "), _c('br'), _vm._v(" allowing them to install the release and share feedback. ")])])])], 1) : !stat.time && stat.type !== 'icon' ? _c('span', {
      staticClass: "stats-count darkGrey--text",
      class: {
        'green-text': _vm.dotType == 'circle',
        'cursor-pointer': stat.link
      },
      on: {
        "click": function click($event) {
          return _vm.navigateToLink(stat.link);
        }
      }
    }, [_vm._v(_vm._s(stat.count))]) : _c('span', {
      staticClass: "stats-count darkGrey--text"
    }, [stat.count && stat.from === 'session' ? _c('vue-hoverable-date', {
      attrs: {
        "date": _vm._f("unix")(stat.count)
      }
    }) : stat.from !== 'session' ? _c('vue-hoverable-date', {
      attrs: {
        "date": stat.count
      }
    }) : _c('span', [_vm._v("-")])], 1)], 1)]), !_vm.$vuetify.breakpoint.smAndDown && index < _vm.stats.length - 1 ? _c('v-col', {
      key: index,
      staticClass: "pa-1",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-divider', {
      staticClass: "stats-devider",
      attrs: {
        "inset": "",
        "vertical": ""
      }
    })], 1) : _vm._e()];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }