var render = function render(){
  var _vm$user;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.fromMenu && _vm.message ? _c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('vue-inline-alert', {
    attrs: {
      "message": _vm.message,
      "message-type": "error",
      "outlined": "",
      "no-close-btn": ""
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "mt-7 mb-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_vm.isEmpty(_vm.getTeamTrial) && !_vm.showContactUsMessage ? _c('div', {
    staticClass: "bold-text font11 primary--text"
  }, [_vm._v(" " + _vm._s(_vm.trialDay) + "-days free trial is ready to be activated 🎉 ")]) : _vm._e(), !_vm.showContactUsMessage ? _c('div', {
    staticClass: "font22 my-5 bold-text"
  }, [_vm._v(" Upgrade team plan ")]) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "font14 mb-6",
    domProps: {
      "innerHTML": _vm._s(_vm.featureInfo[_vm.selectedFeature].description)
    }
  })]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm.selectedFeature !== 'feedbackSessions' ? _c('img', {
    attrs: {
      "height": "auto",
      "width": "100%",
      "src": _vm.featureInfo[_vm.selectedFeature].src
    }
  }) : _c('video-modal', {
    attrs: {
      "from-start-trial": "",
      "video-class": "wistia_embed wistia_async_jbh2mr3oel email=".concat((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.id)
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-11 mb-0",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "px-0 pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "text-left",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "depressed": "",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.subscribeNow
    }
  }, [_c('div', {
    staticClass: "font14"
  }, [_vm._v("Upgrade now")])]), _vm.isEmpty(_vm.getTeamTrial) ? _c('v-btn', {
    staticClass: "text-transform-none mx-2",
    attrs: {
      "color": "primary",
      "id": "startTrialConfirmBtn",
      "outlined": ""
    },
    on: {
      "click": _vm.startTrial
    }
  }, [_c('div', {
    staticClass: "font14"
  }, [_vm._v("Start trial")])]) : _vm._e()], 1)], 1)], 1), _c('v-col', {
    class: _vm.$vuetify.breakpoint.smAndDown ? '' : 'borderLeft',
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "subListFont mb-3"
  }, [_vm._v("Features include:")]), _c('v-list', {
    staticClass: "subListFont"
  }, _vm._l(_vm.featureList, function (item, findex) {
    return _c('v-list-item', {
      key: findex,
      staticClass: "mb-2",
      class: _vm.selectedFeature === item.value ? 'primary--text' : '',
      on: {
        "click": function click($event) {
          _vm.selectedFeature = item.value;
        }
      }
    }, [_c('v-icon', {
      staticClass: "pt-1",
      attrs: {
        "color": _vm.selectedFeature === item.value ? 'primary' : '',
        "size": "18",
        "left": ""
      },
      domProps: {
        "textContent": _vm._s('mdi-flash')
      }
    }), _vm._v(" " + _vm._s(item.title) + " ")], 1);
  }), 1), _c('v-divider'), _c('div', [_c('div', {
    staticClass: "font12 pt-4"
  }, [_vm._v("CURRENT PLAN")]), _c('div', {
    staticClass: "subListFont"
  }, [_vm._v(" " + _vm._s(_vm.getCurrentPlan.name) + " ")]), _c('div', {
    staticClass: "mt-1"
  }, [_c('a', {
    staticClass: "font14",
    on: {
      "click": _vm.redirectToPricing
    }
  }, [_vm._v("Learn more and compare plans")])])])], 1), _vm.showContactUsMessage ? _c('v-col', {
    staticClass: "pt-0 bodyFont"
  }, [_vm._v(" Ops! This apps size is higher than our allowed size in our plans. "), _c('br'), _c('br'), _vm._v(" We can still create a custom exception for your app, please "), _c('span', {
    staticClass: "primary--text cursor-pointer",
    on: {
      "click": _vm.onContactus
    }
  }, [_vm._v("contact us")]), _vm._v(" for more info. ")]) : _vm._e(), _c('Modal', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 1100 : '',
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.openSubscriptionModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('plan-pricing', {
          attrs: {
            "from-modal": "",
            "from-upgrade-component": "",
            "from-plan-page": "",
            "subscribe": _vm.subscribe,
            "upgrade-plan-text": _vm.getUpgradePlanText()
          },
          on: {
            "success": function success($event) {
              _vm.$emit('close'), _vm.openSubscriptionModal = false;
            },
            "close": function close($event) {
              _vm.openSubscriptionModal = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openSubscriptionModal,
      callback: function callback($$v) {
        _vm.openSubscriptionModal = $$v;
      },
      expression: "openSubscriptionModal"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }