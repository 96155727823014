var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.fromWelcome || _vm.fromApiPage ? 'div' : 'v-card', {
    tag: "component",
    staticClass: "pa-4",
    attrs: {
      "id": "switchTeamCard",
      "flat": ""
    }
  }, [!_vm.fromMenu && !_vm.fromApiPage ? _c('v-row', [_c('v-col', {
    staticClass: "font-size-h3 darkGrey--text bold-text",
    class: _vm.fromWelcome ? 'mt-1 mb-3 px-0' : '',
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Switch teams "), _vm.fromTeamPage ? _c('div', {
    staticClass: "float-right top"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1) : _vm._e()])], 1) : _vm._e(), _c('v-row', [_c('v-card', {
    staticClass: "pb-2",
    attrs: {
      "flat": !_vm.fromWelcome,
      "outlined": _vm.fromWelcome,
      "width": "100%"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('div', [_c('div', {}, [_vm._v("Active teams")])])]), !_vm.fromWelcome ? _c('v-col', {
    staticClass: "text-right"
  }, [!_vm.fromApiPage ? _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "text": !_vm.$vuetify.theme.dark,
      "color": "primary",
      "id": "createNewTeamBtn",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": function click($event) {
        _vm.createTeamModal = true;
      }
    }
  }, [_c('span', {
    staticClass: "font14 semi-bold"
  }, [_vm._v("Create new team ")])]) : _vm._e(), _vm.fromApiPage ? _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "id": "createNewTeamBtn",
      "color": "primary",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-close')
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_vm.loading ? _c('v-row', _vm._l(2, function (i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "article"
      }
    })], 1);
  }), 1) : [_vm._l(_vm.getTeamList, function (team, index) {
    return _c('v-row', {
      key: index,
      staticClass: "lightBackground my-3",
      attrs: {
        "id": "switchAbleTeamRow".concat(team.id),
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "font-size-h4"
    }, [_c('div', [_vm._v(" " + _vm._s(team.name) + " "), _c('div', {
      staticClass: "font11"
    }, [_c('span', [_vm._v("Role:")]), _vm._v(" " + _vm._s(team.role) + " | "), _c('span', [_vm._v(" Plan: " + _vm._s(team.plan))])])]), team.status == 2 ? _c('div', {
      staticClass: "caption error--text"
    }, [_vm._v(" Inactive ")]) : _vm._e()]), _c('v-col', {
      staticClass: "text-right"
    }, [_vm.fromApiPage ? _c('v-chip', {
      staticClass: "text-transform-none",
      attrs: {
        "id": "switch".concat(index),
        "outlined": _vm.$vuetify.theme.dark,
        "color": "primary",
        "label": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('selected', team);
        }
      }
    }, [_c('div', {
      staticClass: "px-1 bold-text"
    }, [_vm._v("Select")])]) : [_vm.getTeamId !== team.id ? _c('v-chip', {
      staticClass: "text-transform-none",
      attrs: {
        "id": "switch".concat(index),
        "outlined": _vm.$vuetify.theme.dark,
        "color": "primary",
        "label": ""
      },
      on: {
        "click": function click($event) {
          return _vm.switchTeam(team);
        }
      }
    }, [_c('div', {
      staticClass: "px-1 bold-text"
    }, [_vm._v("Switch")])]) : _c('v-chip', {
      attrs: {
        "id": "currentChip",
        "color": "success",
        "label": ""
      }
    }, [_c('div', {
      staticClass: "bold-text"
    }, [_vm._v("Current")])])]], 2)], 1);
  }), _c('v-row', [_c('v-col', {
    staticClass: "pt-0 text-right",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_vm.getTotalTeamCount > 4 ? _c('v-pagination', {
    staticClass: "ml-auto",
    attrs: {
      "id": "paginationComponent",
      "disabled": _vm.getTotalTeamCount < 4,
      "length": _vm.totalLength || 0,
      "total-visible": 0,
      "flat": ""
    },
    model: {
      value: _vm.page,
      callback: function callback($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }) : _vm._e()], 1)])], 1)]], 2)], 1), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.showSuccessTeamSwitch = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', {
          staticClass: "my-4 font-size-h3 bold-text",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Switching teams ")]), _c('v-col', {
          staticClass: "text-center",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-progress-circular', {
          attrs: {
            "indeterminate": ""
          }
        })], 1), _c('v-col', {
          staticClass: "text-center",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v("Please wait...")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showSuccessTeamSwitch,
      callback: function callback($$v) {
        _vm.showSuccessTeamSwitch = $$v;
      },
      expression: "showSuccessTeamSwitch"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.createTeamModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.createTeamModal ? _c('v-card', [_c('v-card-title', [_c('div', {
          staticClass: "darkGrey--text body-font semi-bold",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Create new team ")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.createTeamModal = false;
            }
          }
        }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)], 1), _c('v-card-text', [_c('create-team', {
          attrs: {
            "from-switch-team": ""
          },
          on: {
            "teamCreated": _vm.afterSuccess
          }
        })], 1)], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.createTeamModal,
      callback: function callback($$v) {
        _vm.createTeamModal = $$v;
      },
      expression: "createTeamModal"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }