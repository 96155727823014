var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('feedbacks-widget', {
    attrs: {
      "can-manage": _vm.canManageApp,
      "feedbacks": _vm.feedbacks,
      "feedbacks-loading": _vm.feedbackLoading,
      "on-view-all": _vm.handleViewAll,
      "total-feedback-count": _vm.totalFeedbackCount
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }