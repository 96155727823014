var render = function render(){
  var _vm$appInfo, _vm$appInfo$info;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": "",
      "flat": ""
    }
  }, [_vm.fromCreateRelease ? _c('v-card-title', [_c('div', [_c('div', [_vm._v("App Information")]), _c('div', {
    staticClass: "smallFont"
  }, [_vm._v(" Please provide the following information about your app. "), _vm.showAllInfo ? _c('span', [_c('br'), _vm._v(" This information is important to be correct for future updates. "), _c('br'), _c('br'), _vm._v(" You can edit this information at any time from the app settings page. ")]) : _vm._e(), _c('span', {
    staticClass: "cursor-pointer primary--text",
    on: {
      "click": function click($event) {
        _vm.showAllInfo = !_vm.showAllInfo;
      }
    }
  }, [_vm._v(_vm._s(!_vm.showAllInfo ? "Read more..." : ""))])])]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-close')
    }
  })], 1)], 1) : _vm._e(), _vm.fromCreateRelease ? _c('v-divider', {
    staticClass: "mb-3"
  }) : _vm._e(), _c('v-card-text', [_c('v-row', [!_vm.fromCreateApp && ['started-releases', 'app-settings-information'].includes(_vm.$route.name) ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|min:3|max:27',
      expression: "'required|min:3|max:27'"
    }],
    attrs: {
      "id": "editAppName",
      "counter": "27",
      "filled": "",
      "label": "Name",
      "placeholder": "App name"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', _vm.veeValidate('Name', 'Name'), false))], 1) : _vm._e(), !_vm.fromCreateRelease ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [[_c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:600',
      expression: "'max:600'"
    }],
    attrs: {
      "id": "team-desc",
      "auto-grow": "",
      "counter": "600",
      "filled": "",
      "label": "Description",
      "placeholder": "Describe your awesome app... (Optional)"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  }, 'v-textarea', _vm.veeValidate('Description', 'Description'), false))]], 2) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-autocomplete', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'categories_validation',
      expression: "'categories_validation'"
    }],
    attrs: {
      "hint": _vm.appCategoriesHint,
      "items": _vm.getCategoriesList,
      "label": _vm.notRequired ? 'App categories' : 'App categories*',
      "chips": "",
      "counter": "3",
      "filled": "",
      "flat": "",
      "item-text": "name",
      "item-value": "id",
      "multiple": "",
      "persistent-hint": "",
      "placeholder": "Search..."
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn(_ref) {
        var message = _ref.message,
          key = _ref.key;
        return [_c('span', {
          domProps: {
            "innerHTML": _vm._s(message)
          }
        })];
      }
    }, {
      key: "selection",
      fn: function fn(data) {
        return [_c('v-chip', _vm._b({
          attrs: {
            "input-value": data.selected,
            "close": ""
          },
          on: {
            "click": data.select,
            "click:close": function clickClose($event) {
              return _vm.remove(data.item);
            }
          }
        }, 'v-chip', data.attrs, false), [_vm._v(" " + _vm._s(data.item.name) + " ")])];
      }
    }]),
    model: {
      value: _vm.form.categories,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "categories", $$v);
      },
      expression: "form.categories"
    }
  }, 'v-autocomplete', _vm.veeValidate('category', ''), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Android"
    },
    model: {
      value: _vm.showAndroid,
      callback: function callback($$v) {
        _vm.showAndroid = $$v;
      },
      expression: "showAndroid"
    }
  }), _c('v-autocomplete', _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showAndroid,
      expression: "showAndroid"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: '',
      expression: "''"
    }],
    attrs: {
      "items": _vm.getAndroidLanguage,
      "search-input": _vm.androidSearch,
      "chips": "",
      "filled": "",
      "flat": "",
      "item-text": "text",
      "item-value": "value",
      "label": "Android language",
      "placeholder": "Search..."
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.androidSearch = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.androidSearch = $event;
      }
    },
    model: {
      value: _vm.form.androidLanguage,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "androidLanguage", $$v);
      },
      expression: "form.androidLanguage"
    }
  }, 'v-autocomplete', _vm.veeValidate('android', ''), false), [_c('template', {
    slot: "append-item"
  }, [_c('div', [_c('v-divider'), _c('v-list-item', {
    staticStyle: {
      "border": "none"
    }
  }, [_c('span', {
    staticClass: "font-weight-regular mr-1"
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:60',
      expression: "'max:60'"
    }],
    staticClass: "my-3",
    attrs: {
      "value": _vm.newAndroidLanguage,
      "counter": "60",
      "dense": "",
      "filled": "",
      "hide-details": "",
      "placeholder": "Type here..."
    },
    on: {
      "change": function change($event) {
        _vm.newAndroidLanguage = $event;
      },
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.addAndroidLanguage.apply(null, arguments);
      }
    }
  }, 'v-text-field', _vm.veeValidate('android option', ''), false))], 1), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.addAndroidLanguage
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-plus')
    }
  }), _c('span', [_vm._v(" Add ")])], 1)], 1)], 1)]), _c('template', {
    slot: "no-data"
  }, [_c('div', [_c('v-list-item', {
    staticStyle: {
      "border": "none"
    }
  }, [_c('span', {
    staticClass: "font-weight-regular mr-1"
  }, [_c('b', [_vm._v(_vm._s(_vm.androidSearch) + " ")]), _vm._v(" is not available ")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.addAndroidLanguage
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-plus')
    }
  }), _c('span', [_vm._v(" Add now ")])], 1)], 1)], 1)])], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "iOS"
    },
    model: {
      value: _vm.showIOS,
      callback: function callback($$v) {
        _vm.showIOS = $$v;
      },
      expression: "showIOS"
    }
  }), _c('v-autocomplete', _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showIOS,
      expression: "showIOS"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: '',
      expression: "''"
    }],
    attrs: {
      "items": _vm.getiOSLanguage,
      "search-input": _vm.iOSSearch,
      "chips": "",
      "filled": "",
      "flat": "",
      "item-text": "text",
      "item-value": "value",
      "label": "iOS Language",
      "placeholder": "Search..."
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.iOSSearch = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.iOSSearch = $event;
      }
    },
    model: {
      value: _vm.form.iosLanguage,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "iosLanguage", $$v);
      },
      expression: "form.iosLanguage"
    }
  }, 'v-autocomplete', _vm.veeValidate('iOS', ''), false), [_c('template', {
    slot: "append-item"
  }, [_c('div', [_c('v-divider'), _c('v-list-item', {
    staticStyle: {
      "border": "none"
    }
  }, [_c('span', {
    staticClass: "font-weight-regular mr-1"
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:60',
      expression: "'max:60'"
    }],
    staticClass: "my-3",
    attrs: {
      "value": _vm.newIosLanguage,
      "counter": "60",
      "dense": "",
      "filled": "",
      "hide-details": "",
      "placeholder": "Type here..."
    },
    on: {
      "change": function change($event) {
        _vm.newIosLanguage = $event;
      },
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.addIosLanguage.apply(null, arguments);
      }
    }
  }, 'v-text-field', _vm.veeValidate('iOS option', ''), false))], 1), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.addIosLanguage
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-plus')
    }
  }), _c('span', [_vm._v(" Add ")])], 1)], 1)], 1)]), _c('template', {
    slot: "no-data"
  }, [_c('div', [_c('v-list-item', {
    staticStyle: {
      "border": "none"
    }
  }, [_c('span', {
    staticClass: "font-weight-regular mr-1"
  }, [_c('b', [_vm._v(_vm._s(_vm.iOSSearch) + " ")]), _vm._v(" is not available ")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.addIosLanguage
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-plus')
    }
  }), _c('span', [_vm._v(" Add now ")])], 1)], 1)], 1)])], 2)], 1), _vm.errorMessage ? _c('v-col', {
    staticClass: "error--text py-0",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [!((_vm$appInfo = _vm.appInfo) !== null && _vm$appInfo !== void 0 && (_vm$appInfo$info = _vm$appInfo.info) !== null && _vm$appInfo$info !== void 0 && _vm$appInfo$info.ownership) ? _c('ownership-autocomplete', {
    attrs: {
      "default-value": _vm.form.ownership,
      "not-required": _vm.notRequired
    },
    on: {
      "selected": function selected($event) {
        _vm.form.ownership = $event;
      }
    }
  }) : _vm._e()], 1)], 1)], 1), !_vm.fromCreateApp ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "disabled": !_vm.form.ownership || !_vm.form.categories.length || _vm.busy,
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark,
      "large": ""
    },
    on: {
      "click": _vm.updateInformation
    }
  }, [_vm._v(" Update ")]), _c('v-spacer')], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }