<template>
  <div>
    <div v-if="loading">
      <ContentLoader></ContentLoader>
    </div>
    <div v-else>
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-badge v-if="hover" :value="!!app?.image" light color="grey" avatar>
            <template v-slot:badge>
              <v-icon
                style="cursor: pointer"
                @click="openDeleteImagePopup"
                size="20"
                color="darkGrey"
                >close</v-icon
              >
            </template>
            <!-- <v-avatar style="border: none" size="100" class="mb-7"> -->
            <img
              :src="
                fromCreate
                  ? currentImage || getAppImage(app)
                  : app.image || getAppImage(app)
              "
              :width="width"
              :height="height"
              @error="replaceDefaultAppImage"
            />
            <v-fade-transition>
              <v-overlay
                z-index="1"
                v-if="hover && !loading"
                absolute
                color="black"
              >
                <label>
                  <v-icon
                    @click="appImageUpload = true"
                    style="cursor: pointer"
                    v-if="!loading && canManageApp"
                    >edit</v-icon
                  >
                  <v-btn v-if="loading" icon :loading="loading"> </v-btn>
                </label>
              </v-overlay>
            </v-fade-transition>
            <!-- </v-avatar> -->
          </v-badge>
          <div v-else>
            <img
              :src="
                fromCreate
                  ? currentImage || getAppImage(app)
                  : app.image || getAppImage(app)
              "
              :width="width"
              :height="height"
              @error="replaceDefaultAppImage"
            />
          </div>
        </template>
      </v-hover>
    </div>
    <div>
      <Modal v-model="showDeletePopup" @click="showDeletePopup = false">
        <template #card>
          <v-card>
            <v-card-title class="font-size-h3 bold-text" cols="12">
              Delete Image
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="message my-2" cols="12">
              Are you sure you want to delete this Image?
              <div>You can upload it later</div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="text-right pb-3" cols="12">
              <v-spacer></v-spacer>
              <v-btn
                text
                class="text-transform-none"
                color="primary"
                :disabled="busy"
                @click="showDeletePopup = false"
              >
                Cancel
              </v-btn>
              <v-btn
                depressed
                class="text-transform-none"
                color="primary"
                :loading="busy"
                @click="onDeleteImage"
              >
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </Modal>
      <Modal
        :width="$vuetify.breakpoint.mdAndUp ? '650' : ''"
        v-model="appImageUpload"
        @close="appImageUpload = false"
      >
        <template #card>
          <image-upload
            v-if="appImageUpload"
            v-model="appImage"
            hide-overlay
            from-create
            sub-title="100x100 pixels for the public install page"
            :view-port="{ width: 100, height: 100 }"
            @close="appImageUpload = false"
            @cropped-image="uploadImage"
            @base64-data="setCurrentImage"
          ></image-upload>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import ImageUpload from "@/view/components/Common/ImageUpload";
import { DELETE_IMAGE, UPDATE_IMAGE } from "@/store/apps/app.module";

export default {
  components: { ImageUpload },
  props: {
    app: {
      type: Object,
      default() {
        return {};
      },
    },
    fromCreate: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 80,
    },
    width: {
      type: Number,
      default: 80,
    },
  },
  data() {
    return {
      loading: false,
      busy: false,
      currentImage: "",
      image: "",
      appImage: "",
      showDeletePopup: false,
      appImageUpload: false,
    };
  },
  methods: {
    uploadImage(e) {
      if (!this.fromCreate) {
        this.image = e;
        this.onFileUpload(e);
      } else {
        this.appImageUpload = false;
        this.$emit("selectedFile", e);
      }
    },
    // setUploadedImage(data) {
    //   this.$store.commit(SET_IMAGE, data.image);
    //   this.appImageUpload = false
    // },
    setCurrentImage(e) {
      this.currentImage = e;
    },
    async onFileUpload() {
      if (this.loading) return;
      this.loading = true;
      const form = new FormData();
      form.append("image", this.image);
      try {
        const response = await this.$store.dispatch(UPDATE_IMAGE, {
          id: this.appId || this.app.id,
          image: form,
        });
        this.$emit("close");
        this.appImageUpload = false;
        this.notifyUserMessage({
          message: response.message || "App image successfully updated",
        });
        this.loading = false;
      } catch (err) {
        this.notifyErrorMessage(
          err.message || "Failed to update the app image"
        );
        this.loading = false;
      }
      this.loading = false;
    },
    openDeleteImagePopup() {
      this.showDeletePopup = true;
    },
    onDeleteImage() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(DELETE_IMAGE, this.app.id)
        .then((response) => {
          this.busy = false;
          this.showDeletePopup = false;
          this.notifyUserMessage({
            message: response.message || "App image successfully removed",
          });
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(
            err.message || `Something went wrong, please try again`
          );
        });
    },
  },
};
</script>
