<template>
  <widget
    :can-be-managed="true"
    :is-empty="comments.length === 0"
    :loading="dashboardLoader"
  >
    <template v-slot:widget-loading>
      <v-row align="center">
        <v-col cols="6">
          <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        </v-col>
        <v-col cols="6" style="display: flex; justify-content: flex-end">
          <v-skeleton-loader
            style="margin-right: 16px"
            type="button"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-skeleton-loader
            v-for="i in 5"
            :key="i"
            :loading="true"
            class="transparent"
            type="list-item-avatar-two-line"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:widget-content>
      <v-card-title>
        <v-row align="center">
          <v-col cols="6">
            <div class="card-label bold-text text-dark">Recent Comments</div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="commentHeader"
        :items="comments"
        hide-default-footer
        disable-sort
        mobile-breakpoint="0"
        @click:row="
          $router.push({
            name: 'release-comment',
            params: {
              app_id: $event?.app?.id,
              release_id: $event?.release_id,
              comment_id: $event?.id,
            },
          })
        "
      >
        <template #item.app="{ item }">
          <v-row align="center">
            <v-col cols="auto">
              <AppImage :alt="item?.app?.name" :image="item?.app?.image" />
            </v-col>
            <v-col>
              <a class="darkGrey--text bodyFont mb-1"> {{ item?.app?.name }}</a>
            </v-col>
          </v-row>
        </template>
        <template #item.release="{ item }">
          <release-chip
            :release="{ ...item.release, platform: 'android' }"
          ></release-chip>
        </template>
        <template #item.user="{ item }">
          <user-avatar :user-detail="item.user"></user-avatar>
        </template>
        <template #item.message="{ item }">
          <MaxText v-if="item.message" :text="item.message" max="150" />
          <div v-else>-</div>
        </template>
        <template #item.attachment="{ item }">
          {{ item?.stats?.attachments }}
        </template>
        <template #item.status="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <span v-on="on">
                <v-icon :color="$options.STATUS[item?.status].color">{{
                  $options.STATUS[item?.status].icon
                }}</v-icon>
              </span>
            </template>
            <span>
              {{ $options.STATUS[item?.status].title }}
            </span>
          </v-tooltip>
        </template>
        <template #item.created="{ item }">
          <vue-hoverable-date :date="item.created_at"></vue-hoverable-date>
        </template>
        <template #item.navigate>
          <div class="flex-row jc-c">
            <v-btn icon>
              <i class="arrow-right"></i>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </template>
    <template v-slot:widget-no-data>
      <v-card-title> Recent Comments </v-card-title>
      <no-data
        align-class="text-left"
        first-text="No Comments for This App... Yet!"
        second-text="<ul> <li><strong>Team</strong>: Team feedback about the app will appear here.</li> <li><strong>Guests</strong>: Shared the app with external testers? Their feedback shows up here. Remember, you can invite unlimited Guests for feedback in our app.</li> </ul>"
      ></no-data>
    </template>
  </widget>
</template>

<script>
import AppImage from "@/view/components/App/Image";
import UserAvatar from "@/view/components/Common/UserAvatar";
import Widget from "@/view/components/Common/Widget.vue";
export default {
  props: {
    comments: {
      type: Array,
      default() {
        return [];
      },
    },
    dashboardLoader: {
      type: Boolean,
      default: false,
    },
    getInactiveUserMessage: {
      type: Boolean,
      default: false,
    },
    totalComments: {
      type: Number,
      default: 0,
    },
  },
  components: { UserAvatar, AppImage, Widget },
  STATUS: {
    1: {
      title: "Not Resolved",
      color: "orange",
      icon: "mdi-dots-horizontal-circle-outline",
      value: 1,
    },
    3: {
      title: "Flagged for review ",
      color: "red",
      icon: "mdi-flag-outline",
      value: 3,
    },
    2: {
      title: "Resolved",
      color: "green",
      icon: "mdi-check-circle-outline",
      value: 2,
    },
  },
  data() {
    return {
      commentHeader: [
        {
          text: "App",
          align: "start",
          width: "200",
          value: "app",
        },
        {
          text: "Release",
          align: "start",
          width: "100",
          value: "release",
        },
        {
          text: "User",
          align: "start",
          width: "50",
          value: "user",
        },
        {
          text: "Message",
          align: "start",
          width: "200",
          value: "message",
        },
        {
          text: "Attachments",
          align: "start",
          width: "50",
          value: "attachment",
        },
        {
          text: "Status",
          align: "start",
          width: "50",
          value: "status",
        },
        {
          text: "Created",
          align: "start",
          width: "200",
          value: "created",
        },
        {
          text: "",
          align: "start",
          width: "50",
          value: "navigate",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
