<template>
    <v-card :flat="!fromStartTrial">
        <v-card-title v-if="!fromStartTrial && !showNoTitle">
            <div>
                <span class="bold-text titleFont">{{ title }}</span>
                <v-btn v-if="$vuetify.breakpoint.smAndDown" class="float-right" @click="$emit('close')" icon>
                    <v-icon v-text="'mdi-close'"></v-icon>
                </v-btn>
                <v-divider class="my-2" />
                <div class="font14">
                    {{ firstMessage }}
                </div>
                <div class="font14">
                    {{ secondMessage }}
                </div>
            </div>
        </v-card-title>
        <v-card-text :class="{ 'pa-0': showNoTitle }">
            <div class="wistia_responsive_padding" style="padding: 56.25% 0 0 0; position: relative">
                <div class="wistia_responsive_wrapper" style="height: 100%; left: 0; position: absolute; top: 0; width: 100%">
                    <span :class="videoClass" class="popover=true popoverAnimateThumbnail=true videoFoam=true" style="
                  display: inline-block;
                  height: 100%;
                  position: relative;
                  width: 100%;
                ">&nbsp;</span>
                </div>
            </div>
        </v-card-text>
        <v-card-actions v-if="$vuetify.breakpoint.mdAndUp && !fromOnboarding && !fromStartTrial">
            <v-spacer></v-spacer>
            <v-btn id="videoModalCloseButton" class="text-transform-none" color="primary" :outlined="$vuetify.theme.dark" @click="$emit('close')">
                {{ buttonText }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        fromStartTrial: {
            type: Boolean,
            default: false,
        },
        showNoTitle: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        buttonText: {
            type: String,
            default: "Ok, got it",
        },
        fromOnboarding: {
            type: Boolean,
            default: false,
        },
        firstMessage: {
            type: String,
            default: "",
        },
        secondMessage: {
            type: String,
            default: "",
        },
        videoClass: {
            type: String,
            default: "",
        },
    },
};
</script>
