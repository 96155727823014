var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('div', [_c('div', {
    staticClass: "font-size-h3 bold-text"
  }, [_vm._v("Timeline")]), _c('div', {
    staticClass: "bold-text text-body-1"
  }, [_vm._v("Timeline of this app")])])]), _c('v-card-text', [_vm.canViewTimeline ? _c('div', [_vm.infoError ? _c('div', [_c('noData', {
    attrs: {
      "firstText": _vm.infoError,
      "permission-issues": ""
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "p-10"
  }, [_vm.timelineLoading ? _c('v-timeline', {
    attrs: {
      "align-top": "",
      "dense": ""
    }
  }, _vm._l(4, function (i) {
    return _c('v-timeline-item', {
      key: i,
      attrs: {
        "color": "grey lighten-1",
        "small": ""
      }
    }, [_c('v-skeleton-loader', {
      staticClass: "transparent mx-n4 mt-n3",
      staticStyle: {
        "max-width": "300px"
      },
      attrs: {
        "type": "article"
      }
    })], 1);
  }), 1) : _vm.errorMessage ? _c('noData', {
    attrs: {
      "first-text": _vm.errorMessage,
      "permission-issues": ""
    }
  }) : !_vm.timeline ? _c('noData') : _c('v-timeline', {
    attrs: {
      "align-top": "",
      "dense": ""
    }
  }, _vm._l(_vm.timeline, function (item) {
    return _c('v-timeline-item', {
      key: item.id,
      attrs: {
        "color": "grey",
        "small": ""
      }
    }, [_c('v-row', {
      staticClass: "pt-1",
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      staticClass: "mb-2",
      attrs: {
        "cols": "12"
      }
    }, [_c('b', [_c('vue-hoverable-date', {
      attrs: {
        "date": item.created_at
      }
    })], 1)]), _c('v-col', {
      staticClass: "mb-2",
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "bodyFont"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "bodyFont mt-2"
    }, [item.action === 'member_invite' ? _c('div', [_vm._v(" " + _vm._s(item.user.name) + " accepted the invite ")]) : _vm._e(), _c('div', [_vm._v(" " + _vm._s(item.content) + " ")])])])], 1)], 1);
  }), 1)], 1), _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('v-pagination', {
    staticClass: "ml-auto",
    attrs: {
      "flat": "",
      "length": _vm.totalLength || 0,
      "disabled": _vm.getTotalTimeLineCount < 10,
      "total-visible": 0
    },
    model: {
      value: _vm.page,
      callback: function callback($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)])]) : _c('div', [_c('no-data', {
    attrs: {
      "first-text": "No timeline",
      "second-text": "You don't have the permission to view the app timeline",
      "permission-issues": ""
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }