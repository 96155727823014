var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', {
    attrs: {
      "can-be-managed": _vm.canManage,
      "is-empty": _vm.feedbacks.length === 0,
      "loading": _vm.feedbacksLoading
    },
    scopedSlots: _vm._u([{
      key: "widget-loading",
      fn: function fn() {
        return [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('v-skeleton-loader', {
          attrs: {
            "type": "list-item-two-line"
          }
        })], 1), _c('v-col', {
          staticStyle: {
            "display": "flex",
            "justify-content": "flex-end"
          },
          attrs: {
            "cols": "6"
          }
        }, [_c('v-skeleton-loader', {
          staticStyle: {
            "margin-right": "16px"
          },
          attrs: {
            "type": "button"
          }
        })], 1)], 1), _c('v-row', [_c('v-col', _vm._l(5, function (i) {
          return _c('v-skeleton-loader', {
            key: i,
            staticClass: "transparent",
            attrs: {
              "loading": true,
              "type": "list-item-avatar-two-line"
            }
          });
        }), 1)], 1)];
      },
      proxy: true
    }, {
      key: "widget-content",
      fn: function fn() {
        return [_c('v-card-title', [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('div', {
          staticClass: "card-label bold-text text-dark"
        }, [_vm._v(" " + _vm._s(_vm.text.widgetName) + " ")])]), _vm.feedbacks.length > 0 ? _c('v-col', {
          staticStyle: {
            "text-align": "end"
          },
          attrs: {
            "cols": "6"
          }
        }, [_c('v-btn', {
          attrs: {
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary"
          },
          on: {
            "click": _vm.onViewAll
          }
        }, [_vm._v(_vm._s(_vm.text.viewAll) + " ")])], 1) : _vm._e()], 1)], 1), _c('v-data-table', {
          attrs: {
            "headers": _vm.feedbackHeader,
            "items": _vm.feedbacks,
            "disable-sort": "",
            "hide-default-footer": "",
            "mobile-breakpoint": "0"
          },
          on: {
            "click:row": _vm.handleRowClick
          },
          scopedSlots: _vm._u([{
            key: "item.device.model",
            fn: function fn(_ref) {
              var item = _ref.item;
              return [_c('v-icon', {
                staticClass: "mr-1",
                attrs: {
                  "size": "24"
                },
                domProps: {
                  "textContent": _vm._s(item.device.brand === 'Android' ? 'mdi-android' : 'mdi-apple')
                }
              }), _c('span', [_vm._v(" " + _vm._s(item.device.model))])];
            }
          }, {
            key: "item.application",
            fn: function fn(_ref2) {
              var _item$application, _item$application2, _item$application3;
              var item = _ref2.item;
              return [_c('div', {
                staticClass: "text-left py-2"
              }, [_c('div', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$application = item.application) === null || _item$application === void 0 ? void 0 : _item$application.name))]), _c('MaxText', {
                attrs: {
                  "max": "25",
                  "text": "".concat(item === null || item === void 0 ? void 0 : (_item$application2 = item.application) === null || _item$application2 === void 0 ? void 0 : _item$application2.version, " (").concat(item === null || item === void 0 ? void 0 : (_item$application3 = item.application) === null || _item$application3 === void 0 ? void 0 : _item$application3.version_code, ")")
                }
              })], 1)];
            }
          }, {
            key: "item.user",
            fn: function fn(_ref3) {
              var item = _ref3.item;
              return [_c('user-avatar', {
                attrs: {
                  "user-detail": item.user
                }
              })];
            }
          }, {
            key: "item.attachments",
            fn: function fn(_ref4) {
              var item = _ref4.item;
              return [_vm._v(" " + _vm._s(item.stats.attachments) + " ")];
            }
          }, {
            key: "item.sessions",
            fn: function fn(_ref5) {
              var item = _ref5.item;
              return [_vm._v(" " + _vm._s(item.stats.sessions) + " ")];
            }
          }, {
            key: "item.status",
            fn: function fn(_ref6) {
              var item = _ref6.item;
              return [_c('feedback-status', {
                attrs: {
                  "status": item.status
                }
              })];
            }
          }, {
            key: "item.navigate",
            fn: function fn() {
              return [_c('div', {
                staticClass: "flex-row jc-c"
              }, [_c('v-btn', {
                attrs: {
                  "icon": ""
                }
              }, [_c('i', {
                staticClass: "arrow-right"
              })])], 1)];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }, {
      key: "widget-no-permission",
      fn: function fn() {
        return [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.text.widgetName) + " ")]), _c('no-data', {
          attrs: {
            "first-text": _vm.text.noData,
            "second-text": _vm.text.noPermission
          }
        })];
      },
      proxy: true
    }, {
      key: "widget-no-data",
      fn: function fn() {
        return [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.text.widgetName) + " ")]), _c('no-data', {
          attrs: {
            "first-text": _vm.text.noData,
            "second-text": _vm.text.noDataSecond
          }
        })];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }