var render = function render(){
  var _vm$avatar, _vm$avatar2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ProfileImage', {
    staticClass: "my-1",
    attrs: {
      "size": _vm.size,
      "image": (_vm$avatar = _vm.avatar) === null || _vm$avatar === void 0 ? void 0 : _vm$avatar.image,
      "alt": (_vm$avatar2 = _vm.avatar) === null || _vm$avatar2 === void 0 ? void 0 : _vm$avatar2.name
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }