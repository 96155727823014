<template>
  <div id="app" class="fileuploadzone">
    <div class="container">
      <!--      <v-overlay-->
      <!--        absolute-->
      <!--        :value="uploadProgress"-->
      <!--        v-if="uploadProgress"-->
      <!--        class=""-->
      <!--      >-->
      <!--        <v-progress-circular-->
      <!--          :rotate="360"-->
      <!--          :size="60"-->
      <!--          :width="8"-->
      <!--          :value="uploadProgress"-->
      <!--          color="primary"-->
      <!--        >-->
      <!--          {{ uploadProgress + " %" }}-->
      <!--        </v-progress-circular>-->
      <!--      </v-overlay>-->
      <!--UPLOAD-->
      <form enctype="multipart/form-data" novalidate>
        <div
          :id="`dropzone-upload${accept}`"
          :class="`dropbox overflow-hidden ${
            error ? 'errorbdr drop-zone-overlay' : ''
          } ${!isExpress ? 'disabled' : ''}`"
        >
          <div class="dropzone-msg ml-n1">
            <input
              v-if="!isFile && !disabled"
              :id="`file-upload${accept}`"
              :accept="accept"
              :disabled="disabled"
              :name="accept"
              class="input-file"
              type="file"
              @change="onFileSelect($event)"
            />

            <div
              v-if="!isFile"
              class="dz-message d-flex flex-column justify-center align-center px-5"
              @click.stop="$emit('try-select')"
            >
              <div v-if="loading">
                <content-loader></content-loader>
              </div>
              <div v-else-if="preErrorMessage" @click.stop>
                <div @click.stop>
                  <p class="red-cl" v-html="preErrorMessage"></p>
                </div>
                <div class="close-icon">
                  <v-icon small @click.stop="removeFile()">close</v-icon>
                </div>
              </div>
              <div
                v-else
                :id="`dropzone${accept}`"
                :class="fromStarted ? '' : 'mt-7'"
              >
                <h3 class="darkGrey--text dropzone-msg-title">
                  Click here or drag &amp; drop
                </h3>
                <div class="text-center">
                  <img
                    :max-height="70"
                    :max-width="70"
                    :src="
                      accept == '.apk'
                        ? '/media/placeholders/apk.svg'
                        : '/media/placeholders/ipa.svg'
                    "
                    class="mb-8 mx-auto svg-icon"
                    contain
                  />
                </div>
              </div>
              <div
                v-if="fromStarted"
                class="pa-1 support-panel text-center primary--text font-size-h5"
                style="
                  position: absolute;
                  bottom: 0px;
                  width: 100%;
                  border-radius: 5px;
                "
                @click.stop="downloadSampleApp"
              >
                Download our sample app
                <v-icon
                  color="primary"
                  right
                  small
                  v-text="'mdi-download'"
                ></v-icon>
              </div>
            </div>
            <div v-else class="file-info">
              <div v-if="!uploading" class="close-icon">
                <v-btn icon small style="z-index: 5" @click="removeFile">
                  <v-icon small>close</v-icon>
                </v-btn>
              </div>
              <div class="container-info">
                <div
                  v-if="releaseLoader"
                  class="progress d-flex align-center justify-center"
                >
                  <v-btn icon loading></v-btn>
                </div>
                <div
                  v-if="
                    (uploading ||
                      uploadStatus.status === 'uploaded' ||
                      error) &&
                    !releaseLoader
                  "
                  class="progress d-flex align-center justify-center fill-height fill-width"
                >
                  <div
                    v-if="uploading || uploadStatus.status === 'uploaded'"
                    class="text-center"
                  >
                    <div>
                      <v-progress-circular
                        :rotate="360"
                        :size="50"
                        :value="uploadProgress"
                        :width="5"
                        color="primary"
                      >
                        {{ uploadProgress + " %" }}
                      </v-progress-circular>
                      <!-- <div
                        @click="handleUpload"
                        v-if="uploadProgress < 100"
                        class="mt-4 primary--text cursor-pointer"
                      >
                        {{ isPaused ? "Resume upload" : "Pause Upload" }}
                      </div> -->
                    </div>
                    <div
                      v-if="
                        uploadStatus.status !== 'uploaded' &&
                        uploadProgress === 100
                      "
                      class="mt-2 bold-text primary--text"
                    >
                      Processing, please wait...
                    </div>
                    <div
                      v-if="uploadStatus.status === 'uploaded'"
                      class="mt-2 bold-text primary--text"
                    >
                      Successfully uploaded the release
                    </div>
                  </div>
                  <div
                    v-if="error"
                    class="red--text text-center"
                    style="white-space: pre-wrap"
                    v-html="error"
                  ></div>
                </div>
                <div class="d-flex darkGrey--text flex-column px-4 py-2">
                  <div>
                    Version:
                    <MaxText :text="get(file, 'info.version', '')" max="20" />
                  </div>
                  <div>
                    {{ accept == ".apk" ? "Version Code" : "Build Number" }}:
                    <MaxText
                      :text="get(file, 'info.version_code', '')"
                      max="20"
                    />
                  </div>
                  <div>
                    {{ accept == ".apk" ? "Package" : "Build ID" }}:
                    <MaxText :text="get(file, 'info.package', '')" max="20" />
                  </div>
                  <div v-if="accept == '.apk'">
                    Target SDK:
                    <MaxText
                      :text="get(file, 'info.meta.targetSDKVersion', '')"
                      max="20"
                    />
                  </div>
                  <div v-if="accept == '.apk'">
                    Min SDK:
                    <MaxText
                      :text="get(file, 'info.meta.minSDKVersion', '')"
                      max="20"
                    />
                  </div>
                  <div v-if="accept == '.ipa'">
                    Platform Version:
                    <MaxText
                      :text="get(file, 'info.meta.platformVersion', '')"
                      max="20"
                    />
                  </div>
                  <div v-if="accept == '.ipa'">
                    Min OS:
                    <MaxText
                      :text="get(file, 'info.meta.minimumOSVersion', '')"
                      max="20"
                    />
                  </div>
                  <div>
                    File:
                    <MaxText :text="get(file, 'info.file.name', '')" max="20" />
                  </div>
                  <div>
                    Size:
                    {{ formatBytes(get(file, "info.file.size", 0) / 1000) }}
                  </div>
                </div>
                <div
                  class="ml-1 pl-3 d-flex justify-space-between support-panel pa-2 primary--text mt-n1 rounded"
                  @click="() => onReleaseClick(file)"
                >
                  <div>
                    {{
                      `${get(file, "meta.members.eligible", "")} / ${get(
                        file,
                        "meta.members.total",
                        ""
                      )}`
                    }}
                    Members
                  </div>
                  <div>
                    {{
                      `${get(file, "meta.devices.total_eligible", "")} / ${get(
                        file,
                        "meta.devices.total",
                        ""
                      )}`
                    }}
                    Devices
                  </div>
                  <v-tooltip
                    v-if="totalWarnings"
                    :bottom="true"
                    color="primary"
                  >
                    <template #activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon color="orange" small>mdi-alert</v-icon>
                      </span>
                    </template>
                    <span
                      >Warnings: {{ totalWarnings }} <br />
                      You can still release it but it's better to fix them 👍
                    </span>
                  </v-tooltip>

                  <v-icon color="primary" small>mdi-arrow-right</v-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal
        v-model="openSampleInstruction"
        @close="openSampleInstruction = false"
      >
        <template #message>
          <v-row class="py-4">
            <v-col
              v-if="accept === '.apk'"
              class="text-center text-h6"
              cols="12"
            >
              <b>APK Downloaded 🎉</b>
              <br /><br />

              Sometimes you wouldn't have your APK with you so we provided a
              sample one!

              <br /><br />
              Now, close this dialog and upload the sample app to try our app
              distribution 💪
            </v-col>
            <v-col
              v-if="accept === '.ipa'"
              class="text-center text-h6"
              cols="12"
            >
              <b>IPA Downloaded 🎉</b>
              <br /><br />

              Sometimes you wouldn't have your IPA with you so we provided a
              sample one!
              <br /><br />

              <vue-inline-alert
                message="This sample app is on an ad-hoc provisioning profile. You will be able to install it from our mobile app but not opening it. So... don't worry!"
                message-type="info"
                no-close-btn
              >
              </vue-inline-alert>

              Now, close this dialog and upload the sample app to try our app
              distribution 💪
            </v-col>
            <v-col class="text-center pb-0" cols="12">
              <v-btn
                class="text-transform-none"
                color="primary"
                depressed
                @click="openSampleInstruction = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import { formatBytes, get } from "@/core/services/helper.service";
import contentLoader from "@/view/components/Common/ContentLoader.vue";
// import tusUpload from "@/mixins/upload.js";
import { mapGetters, mapMutations } from "vuex";
import { segmentEvents } from "@/consts/segmentEventConst";
import { isEmpty } from "../../../core/services/helper.service";

export default {
  components: { contentLoader },
  name: "app",
  props: [
    "app_id",
    "accept",
    "change",
    "fileInfo",
    "uploadStatus",
    "onReleaseClick",
    "disabled",
    "loading",
    "releaseLoader",
    "preError",
    "isExpress",
    "fromStarted",
  ],
  data() {
    return {
      get,
      error: "",
      file: null,
      isFile: false,
      preErrorMessage: "",
      formatBytes,
      isPaused: false,
      uploading: false,
      openSampleInstruction: false,
      uploadProgress: 0,
    };
  },
  computed: {
    deviceWarnings() {
      return this.file && this.file.info && this.file.info.warnings
        ? this.file.info.warnings.length
        : 0;
    },
    membersWarnings() {
      return this.file && this.file.meta && this.file.meta.devices
        ? this.file.meta.devices.total_not_eligible
        : 0;
    },
    ...mapGetters({
      getStorageUsage: "getTeamPlanUsage",
    }),
    maxUploadSize() {
      return this.getStorageUsage.max_upload_size || 100000000;
    },
    totalWarnings() {
      return this.deviceWarnings + this.membersWarnings;
    },
  },
  watch: {
    preError(val) {
      this.preErrorMessage = val;
    },
    releaseLoader(val) {
      if (val) {
        this.uploading = true;
      }
    },
    fileInfo: function (newVal) {
      this.isFile = !!newVal;
      this.file = newVal;
    },
    uploadStatus: function (upload) {
      if (isEmpty(upload)) {
        this.uploading = false;
        this.uploadProgress = 0;
        return;
      }
      if (upload.status == "uploading") {
        this.uploading = true;
        this.uploadProgress = upload.progress;
      } else if (upload.status == "failed") {
        this.uploading = false;
        this.uploadProgress = 0;
        this.error =
          upload.error && upload.error.message
            ? upload.error.message
            : "Something went wrong, please try again";
      } else if (upload.status == "uploaded") {
        this.uploading = false;
        this.uploadProgress = 100;
        this.$nextTick(() => {
          this.uploadProgress = 100;
          this.uploading = false;
        });
      }
    },
  },
  methods: {
    ...mapMutations({
      resetUpload: "resetUpload",
    }),
    // handleUpload() {
    //   if (this.isPaused) {
    //     tusUpload.startOrResumeUpload();
    //   } else {
    //     tusUpload.abortUpload();
    //   }
    //   this.isPaused = !this.isPaused;
    // },
    downloadSampleApp() {
      var platform = this.accept === ".ipa" ? "ios" : "android";

      if (platform === "ios") {
        window.open("/media/file/sample-app.ipa", "_self");
      } else {
        window.open("/media/file/sample-app-debug.apk", "_self");
      }
      this.openSampleInstruction = true;

      this.logEvent(segmentEvents.DOWNLOAD_APP, {
        platform: platform,
      });
    },
    reset() {
      this.error = "";
      this.isFile = false;
      this.file = null;
      this.preErrorMessage = "";
      this.uploading = false;
      this.uploadProgress = 0;
      this.resetUpload();
    },
    onFileSelect(event) {
      event.preventDefault();
      const fileList = event.target.files;
      if (fileList.length) {
        let file = fileList[0];
        this.$nextTick(() => {
          this.change(file);
        });
      }
      event.target.value = "";
    },
    removeFile() {
      this.preErrorMessage = "";
      this.error = "";
      this.isFile = false;
      this.file = null;
      this.uploading = false;
      this.uploadProgress = 0;
      this.resetUpload();
      this.change(null);
    },
  },
  mounted() {
    this.isFile = !!this.fileInfo;
    this.file = this.fileInfo;
    this.preErrorMessage = this.preError;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.fileuploadzone {
  .container {
    padding: 0;
  }
}

.dropbox {
  background: transparent;
  border: 2px dashed rgba($blue, 0.5);
  border-radius: 0.42rem;
  cursor: pointer;
  height: 160px;
  line-height: 1.5;
  position: relative;
  width: 250px;
}

.dropbox.disabled {
  border: 2px dashed #979797;
  cursor: not-allowed;

  .dropzone-msg-title {
    color: #b5b5c3 !important;
  }

  .dropzone-msg-desc {
    color: #b5b5c3 !important;
  }
}

.input-file {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0; /* invisible but it's there! */
  position: absolute;
  top: 0;
  width: 100%;
}

.dropbox:hover {
  background: transparent; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 12px;
  padding: 0;
  text-align: center;
}

.dropzonemain {
  .dropzone-msg {
    cursor: pointer;
    height: 160px;
    width: 253px;

    .dz-message {
      height: 160px;
      width: 253px;
    }

    .dropzone-msg-title {
      color: #464e5f;
      font-size: 12px;
      font-weight: 500;
      margin: 0 0 5px;
      padding: 0;
    }

    .dropzone-msg-desc {
      color: #b5b5c3;
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.red-cl {
  color: #f64e60;
  font-size: 0.9rem;
  margin-top: 0.25rem;
  pointer-events: auto;
  width: 100%;
}

.drop-zone-overlay {
  background: white;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.errorbdr {
  border: 2px dashed #f64e60;
  @extend .drop-zone-overlay;
}

.text-vr {
  display: flex;
  padding: 5px 0 0;

  .content-vr {
    margin: 0;
    width: 70%;
  }

  .remove-vr {
    color: #f64e60;
    vertical-align: baseline;
    width: 30%;
  }
}

.file-info {
  color: rgba(70, 78, 95, 0.6);
  font-size: 11px;
}

.support-panel {
  background-color: rgba(181, 181, 195, 0.2);
}

.close-icon {
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
}

.platform-icon {
  position: absolute;
  right: 1rem;
  top: 6rem;

  i {
    font-size: 36px;
  }
}

.release-icon {
  display: flex;
  justify-content: center;
  padding: 10px;

  i {
    font-size: 36px;
  }
}

.progress {
  opacity: 0.8;
  padding: 70px 50px;
  position: absolute;
  width: 98%;
  z-index: 3;
  @extend .drop-zone-overlay;
}

.container-info {
  position: relative;
}
</style>
