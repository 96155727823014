<template>
  <div>
    <div>
      <!-- <v-tooltip
        v-if="isExpress"
        bottom
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            absolute
            class="primary font-weight-bold"
            dark
            icon
            right
            small
            top
            v-bind="attrs"
            @click="startTour"
            v-on="on"
          >
            ?
          </v-btn>
        </template>
        <span>How to use</span>
      </v-tooltip> -->
      <v-card-title v-if="!noHeader" class="mb-4 display-1 justify-center">
        New release
      </v-card-title>
      <!--  -->
      <!--  -->
      <!--  -->
      <div class="_releasetabnew">
        <div class="side-release">
          <v-form
            ref="android"
            class="formflex"
            @submit.prevent="() => submitRelease('android')"
          >
            <v-card
              class="transparent mt-lg-5 flex-column justify-center align-center"
              flat
              tile
            >
              <v-overlay
                :opacity="$vuetify.theme.dark ? 0.9 : 0.5"
                :color="$vuetify.theme.dark ? '#22272e' : '#22272e'"
                v-if="releaseProgress"
                :value="true"
                absolute
              >
                <div class="mx-2">
                  <v-alert
                    :outlined="$vuetify.theme.dark"
                    color="primary"
                    dense
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        Release successfully created. You can check the progress
                        in upload queue.
                      </v-col>
                      <v-col class="shrink">
                        <v-btn icon @click="closeForm">
                          <v-icon
                            color="white"
                            v-text="'mdi-close-circle'"
                          ></v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </div>
              </v-overlay>

              <div v-show="!$route.params.app_id && initialList.length">
                <AppAutoComplete
                  ref="app-autocomplete"
                  v-model="app"
                  :focus.sync="focus"
                  :is-get-started="fromStarted"
                  :select-fist-item="fromStarted"
                  class="mb-4"
                  @blur="focus = false"
                  @input="app = $event"
                  @openApp="$emit('openApp')"
                />
              </div>
              <h1 class="text-center mb-4">
                {{ appDetail?.name }}
              </h1>

              <v-row>
                <v-col
                  :class="{ 'pr-5': $vuetify.breakpoint.smAndUp }"
                  class="d-flex flex-column align-center"
                  cols="12"
                  md="6"
                  sm="6"
                >
                  <div
                    :class="`platform-logo mb-5  ${
                      icon == 'android' ? 'selected-icon-container' : ''
                    }`"
                    class="mx-auto"
                  >
                    <img
                      v-if="get(apk, 'info.icon', '')"
                      :src="get(apk, 'info.icon', '')"
                      @error="replaceDefaultAndroidImage"
                    />

                    <!-- <inline-svg
                      class="mb-8 svg-icon"
                      :max-height="100"
                      :max-width="100"
                      src="/media/placeholders/apk.svg"
                      v-if="!get(apk, 'info.icon', '')"
                    /> -->

                    <PlatformIcon
                      v-if="!get(apk, 'info.icon', '')"
                      android
                      color="primary"
                    />
                  </div>
                  <FileUploadDropzone
                    id="android-box"
                    ref="apk-upload"
                    :app_id="app?.id ?? ''"
                    :change="(file) => onFileChange(file, 'android')"
                    :fileInfo="apk"
                    :loading="preLoadingAndroid"
                    :onReleaseClick="onInfoClick"
                    :preError="preReleaseErrorAndroid"
                    :releaseLoader="androidReleaseLoader"
                    :uploadStatus="{ ...getAndroidProgress }"
                    accept=".apk"
                    class="dropzonemain mx-auto"
                  />
                </v-col>
                <v-col
                  :class="{ 'pr-5': $vuetify.breakpoint.smAndUp }"
                  class="d-flex flex-column align-center"
                  cols="12"
                  md="6"
                  sm="6"
                >
                  <div
                    :class="`platform-logo mb-5  ${
                      icon == 'ios' ? 'selected-icon-container' : ''
                    }`"
                    class="mx-auto"
                  >
                    <img
                      v-if="get(ipa, 'info.icon', '')"
                      :src="get(ipa, 'info.icon', '')"
                      @error="replaceDefaultAndroidImage"
                    />

                    <PlatformIcon
                      v-if="!get(ipa, 'info.icon', '')"
                      apple
                      color="primary"
                    />
                  </div>
                  <FileUploadDropzone
                    id="ios-box"
                    ref="ipa-upload"
                    :app_id="app?.id ?? ''"
                    :change="(file) => onFileChange(file, 'ios')"
                    :fileInfo="ipa"
                    :loading="preLoadingIOS"
                    :onReleaseClick="onInfoClick"
                    :preError="preReleaseErrorIOS"
                    :releaseLoader="iosReleaseLoader"
                    :uploadStatus="{ ...getIOSProgress }"
                    accept=".ipa"
                    class="dropzonemain mx-auto"
                  />
                </v-col>
              </v-row>

              <v-checkbox
                id="release-note-check-box"
                v-model="oneReleaseNote"
                :disabled="busy || preLoadingAndroid || preLoadingIOS"
                hide-details
                label="One release note"
              ></v-checkbox>
              <div v-show="oneReleaseNote" class="my-3">
                <!-- :disabled="!apk && !ipa" -->
                <v-textarea
                  id="one-note"
                  v-model="form.note"
                  v-validate="'max:1200'"
                  :counter="1200"
                  :disabled="busy || preLoadingAndroid || preLoadingIOS"
                  auto-grow
                  filled
                  label="Note"
                  placeholder="What to expect or test for both releases?"
                  v-bind="veeValidate('Description', 'Description')"
                />
              </div>
              <v-row class="my-2" v-show="!oneReleaseNote">
                <v-col cols="12" sm="6">
                  <!-- :disabled="!apk" -->
                  <v-textarea
                    id="android-note"
                    v-model="form.androidNote"
                    v-validate="'max:1200'"
                    :counter="1200"
                    :disabled="busy || preLoadingAndroid || preLoadingIOS"
                    append-icon="android"
                    auto-grow
                    filled
                    label="Android note"
                    placeholder="What to expect or test in this release?"
                    v-bind="
                      veeValidate('Description-android', 'Android Description')
                    "
                  >
                    <template #append>
                      <PlatformIcon android color="darkGrey" />
                    </template>
                  </v-textarea>
                </v-col>
                <v-col cols="12" sm="6">
                  <!-- :disabled="!ipa" -->
                  <v-textarea
                    id="ios-note"
                    v-model="form.iosNote"
                    v-validate="'max:1200'"
                    :counter="1200"
                    :disabled="busy || preLoadingAndroid || preLoadingIOS"
                    auto-grow
                    filled
                    label="iOS note"
                    placeholder="What to expect or test in this release?"
                    v-bind="veeValidate('Description-ios', 'iOS Description')"
                  >
                    <template #append>
                      <PlatformIcon apple color="darkGrey" />
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>
              <div v-if="!fromStarted">
                <div
                  :class="!$vuetify.theme.dark ? 'tipFont' : 'darkGrey--text'"
                  class="font14"
                >
                  <v-icon
                    color="darkGrey"
                    small
                    v-text="'mdi-bell-outline'"
                  ></v-icon>
                  How would you like to notify your team?
                </div>
                <div class="ml-4">
                  <v-radio-group
                    :disabled="busy || preLoadingAndroid || preLoadingIOS"
                    v-model="notify"
                  >
                    <v-radio :value="true">
                      <template #label>
                        <div>
                          Notify immediately after upload
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn small v-on="on" icon>
                                <v-icon
                                  small
                                  v-text="'mdi-information-outline'"
                                ></v-icon>
                              </v-btn>
                            </template>
                            <span>
                              Select this to automatically send a push
                              notification to all app members with access <br />
                              immediately after the upload is complete. They can
                              <br />
                              install the release and share feedback promptly.
                            </span>
                          </v-tooltip>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio :value="false">
                      <template #label>
                        <div>
                          I will notify later from the release page
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn small v-on="on" icon>
                                <v-icon
                                  small
                                  v-text="'mdi-information-outline'"
                                ></v-icon>
                              </v-btn>
                            </template>
                            <span>
                              Choose this to send notifications manually from
                              the release page at your convenience. All app
                              <br />
                              members with access will receive a push
                              notification in our app for the relevant platform,
                              <br />
                              allowing them to install the release and share
                              feedback.
                            </span>
                          </v-tooltip>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>
              </div>
              <div v-if="!fromStarted" class="my-8">
                <div
                  :class="!$vuetify.theme.dark ? 'tipFont' : 'darkGrey--text'"
                  class="font14"
                >
                  <v-icon color="darkGrey" small>archive</v-icon>
                  Archive current latest release
                  <VueTooltip
                    :icon-color="$vuetify.theme.dark ? 'darkGrey' : 'grey'"
                    :max-width="200"
                    :v-html="'Upon successful upload, the current latest release will be automatically archived.<br /><br />This saves you the step of manual archiving.'"
                    color="primary"
                    text-class="white--text"
                  />
                </div>
                <div class="ml-4">
                  <v-checkbox
                    v-model="archiveAndroid"
                    :disabled="
                      !apk || busy || preLoadingAndroid || preLoadingIOS
                    "
                    hide-details
                    label="Android"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="archiveIos"
                    :disabled="
                      !ipa || busy || preLoadingAndroid || preLoadingIOS
                    "
                    hide-details
                    label="iOS"
                  ></v-checkbox>
                </div>
              </div>
              <v-checkbox
                v-if="!termsAndCondition"
                id="terms-check-box"
                v-model="termsAndCondition"
                :disabled="busy || preLoadingAndroid || preLoadingIOS"
                @click="setTermsAndCondition"
              >
                <template #label>
                  <div>
                    By creating this release, I agree to
                    <a
                      href="https://testapp.io/terms-and-conditions"
                      target="_blank"
                      @click.stop
                    >
                      Terms & Conditions
                    </a>
                    and
                    <a
                      href="https://testapp.io/privacy-policy#upload"
                      target="_blank"
                      @click.stop
                    >
                      Upload Policy
                    </a>
                  </div>
                </template>
              </v-checkbox>

              <div class="mb-8">
                <v-icon
                  color="darkGrey"
                  left
                  v-text="'mdi-lightbulb-on-outline'"
                ></v-icon>
                <span
                  :class="!$vuetify.theme.dark ? 'tipFont' : 'darkGrey--text'"
                  class="font14"
                >
                  Use
                  <a
                    class="text-decoration-underline"
                    @click="redirectToIntegration('ta-cli')"
                    >TA-CLI</a
                  >
                  or
                  <a
                    class="text-decoration-underline"
                    @click="redirectToIntegration('fastlane-plugin')"
                    >Fastlane</a
                  >
                  or other
                  <a
                    class="text-decoration-underline"
                    @click="redirectToIntegration('integrations')"
                    >integrations</a
                  >
                  to automate your releases.
                </span>
              </div>
              <!-- button should be disabled in loading process... -->
              <div class="text-center">
                <v-btn
                  ref="submit_btn_release"
                  :disabled="
                    !app ||
                    (!apk && !ipa) ||
                    !agree ||
                    busy ||
                    preLoadingAndroid ||
                    preLoadingIOS
                  "
                  :loading="busy"
                  :outlined="$vuetify.theme.dark"
                  class="text-transform-none px-15 py-7"
                  color="primary"
                  type="submit"
                  x-large
                >
                  <div class="font14">Create</div>
                </v-btn>
                <v-btn
                  v-if="!noHeader && fromStarted"
                  class="main large wide primary mx-auto my-3"
                  @click="$router.push({ name: 'team' }).catch(() => {})"
                >
                  Skip
                </v-btn>
              </div>
            </v-card>
          </v-form>
        </div>
      </div>
      <Modal
        v-model="openSubscriptionModal"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        :width="$vuetify.breakpoint.mdAndUp ? 1100 : ''"
        @close="openSubscriptionModal = false"
      >
        <template #message>
          <plan-pricing
            :allowed-size="allowedSize"
            :upgrade-plan-text="upgradePlanMessage"
            :uploaded-size="uploadedSize"
            from-modal
            @close="resetModal()"
          ></plan-pricing>
        </template>
      </Modal>
      <Modal v-model="expressLimitModal" @close="expressLimitModal = false">
        <template #message>
          <v-row class="bodyFont">
            <v-col class="bold-text mt-3" cols="12">
              Max app size limit reached
            </v-col>
            <v-col cols="12">
              <div class="mb-2">
                This release exceeded the limit which is
                <b>{{ allowedSize }}</b>
              </div>
              <div>
                You have uploaded <b>{{ uploadedSize }}</b>
              </div>
              <div class="bold-text text-center mt-4">
                Signup to increase the limit for free 💪
              </div>
            </v-col>
            <v-col class="text-center">
              <v-btn
                class="text-transform-none"
                color="primary"
                @click="$router.push({ name: 'signup' })"
              >
                Let's do it
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </Modal>
      <Modal
        v-model="showStartTrialModal"
        :fullscreen="$vuetify.breakpoint.mdAndDown"
        :width="$vuetify.breakpoint.mdAndUp ? 880 : ''"
        no-py
        @close="showStartTrialModal = false"
      >
        <template #message>
          <start-trial-modal
            v-if="showStartTrialModal"
            :allowed-size="allowedSize"
            :file-size="fileSize"
            :message="message"
            :tracker-event="trackerEvent"
            :trigger-from="'appUploadSize'"
            :uploaded-size="uploadedSize"
            from-create-release
            @close="(showStartTrialModal = false), resetForm()"
            @open-subscribe="openSubscriptionModal = true"
          ></start-trial-modal>
        </template>
      </Modal>
      <Modal
        v-model="showAppInformationModal"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        width="600"
      >
        <template #card>
          <app-information
            v-if="showAppInformationModal"
            :app-detail="app"
            from-create-release
            @close="showAppInformationModal = false"
            @success="afterInformationUpdated"
          ></app-information>
        </template>
      </Modal>
      <side-panel
        v-model="showPreReleaseInfo"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        :width="$vuetify.breakpoint.mdAndUp ? 960 : ''"
      >
        <pre-release-info-panel :app="app" :info="releaseInfo" />
      </side-panel>
    </div>
  </div>
</template>

<script>
import { formatBytes, get, isEmpty, UID } from "@/core/services/helper.service";
import { mapGetters, mapMutations, mapState } from "vuex";
import { CREATE } from "@/store/apps/createApp.module";
import AppAutoComplete from "@/view/components/Common/AppSearch.vue";
import FileUploadDropzone from "@/view/components/Common/FileUploadDropzone.vue";
import PreReleaseInfoPanel from "@/view/components/Releases/PreReleaseInfoPanel.vue";
import AppInfoParser from "app-info-parser";
import {
  PRE_RELEASE,
  RELEASE,
  RELEASE_UPLOAD,
} from "@/store/releases/createRelease.module.js";
import { RELEASES } from "@/store/releases/releases.module.js";
import { CANCEL_UPLOAD } from "@/store/common/uploads.module";
import veeValidate from "@/mixins/veeValidate";
import StorageService from "@/core/services/localstorage.service";
import PlanPricing from "@/view/components/Team/PlanPricing";
import dayjs from "dayjs";
import StartTrialModal from "@/view/components/Common/StartTrialModal";
import { segmentEvents } from "@/consts/segmentEventConst";
import AppInformation from "@/view/components/App/AppInformation";
// import SingleFileUpload from "@/view/components/Releases/SingleFileUpload";

export default {
  name: "create-release",
  props: {
    appInfo: {
      type: Object,
      default() {
        return null;
      },
    },
    singleRelease: {
      type: Boolean,
    },
    click: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    fromStarted: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [veeValidate],
  components: {
    StartTrialModal,
    AppInformation,
    PreReleaseInfoPanel,
    FileUploadDropzone,
    AppAutoComplete,
    PlanPricing,
  },
  data() {
    return {
      showAppInformationModal: false,
      trackerEvent: {},
      get,
      formatBytes,
      isEmpty,
      checkbox: null,
      showAlertInformation: false,
      drawer: false,
      selected: null,
      upgradePlanMessage: null,
      app: {},
      form: {
        note: "",
        androidNote: "",
        iosNote: "",
      },
      apk: null,
      ipa: null,
      subscribe: false,
      apkUDID: null,
      showPreReleaseInfo: false,
      ipaUDID: null,
      showStartTrialModal: false,
      iosReleaseLoader: false,
      message: "",
      androidReleaseLoader: false,
      busy: false,
      focus: false,
      notify: true,
      archiveAndroid: false,
      archiveIos: false,
      agree: null,
      ids: {},
      openSubscriptionModal: false,
      expressLimitModal: false,
      apkFileToken: "",
      ipaFileToken: "",
      oneReleaseNote: false,
      icon: "",
      releaseInfo: null,
      preReleaseErrorIOS: "",
      preReleaseErrorAndroid: "",
      preLoadingIOS: false,
      preLoadingAndroid: false,
      forExpressAppId: "",
      allowedSize: "",
      uploadedSize: "",
      fileSize: "",
    };
  },
  watch: {
    // this is for navigation drawer to remove the scroll when it open
    archiveIos(val) {
      localStorage.setItem(
        "userChoices",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("userChoices")),
          archiveIos: val?.toString(),
        })
      );
    },
    archiveAndroid(val) {
      localStorage.setItem(
        "userChoices",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("userChoices")),
          archiveAndroid: val?.toString(),
        })
      );
    },
    apk(val) {
      if (val) {
        this.archiveAndroid = this.getLatestAndroidArchiveChoice || false;
      } else {
        this.archiveAndroid = false;
      }
    },
    ipa(val) {
      if (val) {
        this.archiveIos = this.getLatestIosArchiveChoice || false;
      } else {
        this.archiveIos = false;
      }
    },
    drawer: function (v) {
      this.app = {};
      if (this.appDetail) {
        this.app = { ...this.appDetail };
      }
      this.resetUpload();
      this.apk = null;
      this.ipa = null;
    },
    getNotifyChoice(val) {
      this.notify = val;
    },
    notify(val) {
      localStorage.setItem(
        "userChoices",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("userChoices")),
          notify: val?.toString(),
        })
      );
    },
    oneReleaseNote: function (val) {
      if (val) {
        this.form.androidNote = "";
        this.form.iosNote = "";
      } else {
        this.form.note = "";
      }
    },
    appDetail: function (val) {
      if (val) {
        this.app = this.appDetail;
      }
    },
    bothProgress(val) {
      if (val.android && !val.ios && val.android.status === "uploaded") {
        this.makeAfterReleaseProgress();
      } else if (val.ios && !val.android && val.ios.status === "uploaded") {
        this.makeAfterReleaseProgress();
      } else if (
        val.ios &&
        val.android &&
        val.android.status === "uploaded" &&
        val.ios.status === "uploaded"
      ) {
        if ([val.android.status, val.ios.status].includes("uploaded")) {
          this.makeAfterReleaseProgress();
        }
      } else if (
        (val.ios && val.ios.status === "failed") ||
        (val.android && val.android.status === "failed")
      ) {
        this.busy = false;
        window.localStorage.setItem("is_uploading_releases", "false");
        this.setIsUploadingReleases(false);
      }
    },
    click: function (v) {
      this.drawer = v;
      this.resetForm();
    },
  },
  computed: {
    ...mapState({
      releases: (state) => state.releases.releases,
    }),
    getTourInfo() {
      let info = JSON.parse(localStorage.getItem("tour"));
      if (info && info.upload_queue === "true") {
        return false;
      } else {
        return true;
      }
    },
    releaseProgress() {
      const releaseUniq = [
        ...new Map(this.getReleaseProgressList.map((v) => [v.id, v])).values(),
      ];
      const releaseCount =
        releaseUniq.filter((x) => x.status === "uploading").length || 0;
      return releaseCount;
    },
    termsAndCondition: {
      get: function () {
        return StorageService.getItem("release_terms_condition");
      },
      set: function (v) {
        StorageService.setItem("release_terms_condition", v);
      },
    },
    getNotifyChoice() {
      return localStorage.getItem("userChoices") &&
        JSON.parse(localStorage.getItem("userChoices")).notify === "false"
        ? false
        : true;
    },
    getLatestAndroidArchiveChoice() {
      return localStorage.getItem("userChoices") &&
        JSON.parse(localStorage.getItem("userChoices")).archiveAndroid ===
          "false"
        ? false
        : true;
    },
    getLatestIosArchiveChoice() {
      return localStorage.getItem("userChoices") &&
        JSON.parse(localStorage.getItem("userChoices")).archiveIos === "false"
        ? false
        : true;
    },
    new_release_cli: {
      get: function () {
        return localStorage.getItem("info_message") &&
          JSON.parse(localStorage.getItem("info_message")).new_release_cli ===
            "false"
          ? false
          : true;
      },
      set: function (v) {
        localStorage.setItem(
          "info_message",
          JSON.stringify({
            ...JSON.parse(localStorage.getItem("info_message")),
            new_release_cli: v.toString(),
          })
        );
      },
    },
    ...mapGetters({
      getApkFile: "getApkFile",
      getIpaFile: "getIpaFile",
      getStatus: "getStatus",
      getReleaseProgressList: "getReleaseProgressList",
      getIOSProgress: "getIOSProgress",
      appDetail: "getAppInfo",
      initialList: "getSearchAppsInitial",
      currentUser: "currentUser",
      getTeamName: "getTeamName",
      getCurrentPlan: "getCurrentPlan",
      getAndroidProgress: "getAndroidProgress",
      expressReleaseToken: "expressReleaseToken",
      getTeamTrial: "getTeamTrial",
      getTeamId: "getTeamId",
      getStorageUsage: "getTeamPlanUsage",
    }),
    maxUploadSize() {
      return this.getStorageUsage.max_upload_size || 100000000;
    },
    bothProgress() {
      return { android: this.getAndroidProgress, ios: this.getIOSProgress };
    },
  },
  created() {
    this.showAlertInformation = false;
    if (this.termsAndCondition) {
      this.agree = true;
    }
    this.notify = this.getNotifyChoice;
    this.app = this.appDetail;
  },
  methods: {
    ...mapMutations({
      setReleaseMessage: "setReleaseMessage",
      resetUpload: "resetUpload",
      setUploadQueueTour: "setUploadQueueTour",
      appendAppStats: "appendAppStats",
      updateReleaseInfo: "updateReleaseInfo",
      setApkFileInfo: "setApkFileInfo",
      setIpaFileInfo: "setIpaFileInfo",
      setIsUploadingReleases: "setIsUploadingReleases",
      setReleaseImage: "setReleaseImage",
      setReleaseRefresh: "setReleaseRefresh",
      setLatestRelease: "setLatestRelease",
    }),
    onFileUploadClick() {
      if (!this.app || !this.app?.id) {
        this.$refs["app-autocomplete"].openList();
      }
    },
    afterInformationUpdated(detail) {
      this.app.info = detail.info;
      this.showAppInformationModal = false;
      this.submitRelease();
    },

    getReleaseName() {
      // get android name if empty get ios name and if empty get app name
      if (this.apk?.info?.name) {
        return this.apk?.info?.name;
      } else if (this.ipa?.info?.name) {
        return this.ipa?.info?.name;
      } else {
        return this.app?.name;
      }
    },
    // make a function like getReleaseName to get package instead of name
    getPackageName() {
      if (this.apk?.info?.package) {
        return this.apk?.info?.package;
      } else if (this.ipa?.info?.package) {
        return this.ipa?.info?.package;
      }
    },
    resetModal() {
      this.upgradePlanMessage = null;
      this.openSubscriptionModal = false;
    },
    redirectToIntegration(type) {
      this.$emit("close");
      if (this.app && this.app.id) {
        this.$router.push({
          name: "app-integrations",
          params: { app_id: this.app.id },
        });
      } else {
        if (type == "integrations") {
          window.open(`https://help.testapp.io/topic/integrations/`, "_blank");
        } else {
          window.open(`https://help.testapp.io/${type}/`, "_blank");
        }
      }
    },
    setTermsAndCondition() {
      if (StorageService.getItem("release_terms_condition")) {
        this.agree = true;
      } else {
        this.agree = false;
      }
    },
    startTour() {
      this.$tours["expressTour"]?.start();
    },
    focusAppField(val) {
      if (!(this.app && this.appInfo && !val)) {
        this.focus = true;
        if (this.$refs["app-autocomplete"]) {
          this.$refs["app-autocomplete"].$refs.menu.isActive = true;
        }
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    closeForm() {
      if (this.getTourInfo) {
        this.setUploadQueueTour(true);
      }
      this.$emit("close");
      this.resetUpload();
      this.busy = false;
      this.drawer = false;
      if (document.querySelector("body, html")) {
        document.querySelector("body, html").style.overflow = "auto";
      }
    },
    resetForm() {
      if (this.appDetail && !this.appDetail.name) {
        this.$refs["app-autocomplete"] &&
          this.$refs["app-autocomplete"].reset();
      }
      this.$refs["apk-upload"].reset();
      this.$refs["ipa-upload"].reset();
      this.$store.dispatch(CANCEL_UPLOAD, this.apkUDID);
      this.$store.dispatch(CANCEL_UPLOAD, this.ipaUDID);
      this.form = {
        note: "",
        androidNote: "",
        iosNote: "",
      };
      this.apk = null;
      this.ipa = null;
      this.notify = this.getNotifyChoice;
      this.archiveAndroid = false;
      this.archiveIos = false;
      this.agree = null;
      this.apkUDID = null;
      this.ipaUDID = null;
      this.oneReleaseNote = false;
      this.icon = "";
    },
    async extractFileInfo(file, platform) {
      return new Promise((resolve, reject) => {
        // for Express max upload is always 50 MB
        const accept = platform == "android" ? ".apk" : ".ipa";
        let error = "";
        const parser = new AppInfoParser(file);
        const info = {};
        parser
          .parse()
          .then((res) => {
            this.resetUpload();
            let extension = file.name.split(".").pop();
            if (accept.split(",").indexOf(`.${extension}`) == -1) {
              this.logEvent(segmentEvents.INVALID_FILE, {
                uploaded: extension,
                allowed: accept,
              });
              error = `Please select a valid ${accept} file`;
            }
            if (platform === "android") {
              info.warnings = [];

              if (
                res &&
                res.application &&
                !res.application.label &&
                !Array.isArray(res.application.label)
              ) {
                info.name = this.appDetail
                  ? this.appDetail.name
                  : this.app.name;
                info.warnings.push(
                  "Your base manifest is missing android:label"
                );
              } else {
                info.name =
                  res &&
                  res.application &&
                  (Array.isArray(res.application.label)
                    ? res.application.label[0]
                    : res.application.label);
              }

              info.package = res.package;
              info.version =
                res && res.versionName ? res.versionName.toString() : "";
              info.version_code =
                res && res.versionCode ? res.versionCode.toString() : "";

              info.icon = res.icon;
              if (!info.icon) {
                info.warnings.push(
                  "Your base manifest is missing android:icon"
                );
              }

              info.meta = {};
              info.meta.usesPermissions = res.usesPermissions;

              info.meta.minSDKVersion =
                res.usesSdk && res.usesSdk.minSdkVersion
                  ? res.usesSdk.minSdkVersion.toString()
                  : "";
              info.meta.targetSDKVersion =
                res && res.usesSdk && res.usesSdk.targetSdkVersion
                  ? res.usesSdk.targetSdkVersion.toString()
                  : "";
              info.APKDebug =
                res && res.application && res.application.debuggable;
              if (info.APKDebug) {
                info.meta.signingType = "debug";
              } else {
                info.meta.signingType = "release";
              }
            } else if (platform === "ios") {
              info.warnings = [];
              info.name = res.CFBundleDisplayName
                ? res.CFBundleDisplayName
                : res.CFBundleExecutable;
              info.package = res.CFBundleIdentifier;
              info.version = res.CFBundleShortVersionString;
              info.version_code = res.CFBundleVersion;
              info.icon = res.icon;
              if (!info.icon) {
                info.warnings.push("Your base manifest is missing iOS:icon");
              }
              info.customURL =
                res?.CFBundleURLTypes?.length && res?.CFBundleURLTypes[0]
                  ? res?.CFBundleURLTypes[0]?.CFBundleURLSchemes[0]
                  : "";
              info.meta = {};

              info.meta.minimumOSVersion =
                res && res.MinimumOSVersion
                  ? res.MinimumOSVersion.toString()
                  : "";
              info.meta.platformVersion =
                res && res.DTPlatformVersion
                  ? res.DTPlatformVersion.toString()
                  : "";
              info.meta.supportedPlatforms = res
                ? res.CFBundleSupportedPlatforms
                : "";

              info.meta.provisionedDevices = [];

              if (res.mobileProvision) {
                info.meta.UUID = res.mobileProvision.UUID;

                info.meta.provisionedDevices = res.mobileProvision
                  .ProvisionedDevices
                  ? res.mobileProvision.ProvisionedDevices
                  : [];

                info.meta.platforms = res.mobileProvision.Platform || [];
                info.meta.creationDate = res.mobileProvision.CreationDate
                  ? new Date(res.mobileProvision.CreationDate).getTime() / 1000
                  : 0;
                info.meta.expirationDate =
                  res &&
                  res.mobileProvision &&
                  res.mobileProvision.ExpirationDate
                    ? new Date(res.mobileProvision.ExpirationDate).getTime() /
                      1000
                    : 0;

                info.meta.provisioningProfileName = res.mobileProvision.Name;
                info.meta.teamName = res.mobileProvision.TeamName;
                info.meta.teamIdentifier =
                  res.mobileProvision.TeamIdentifier || [];
              }

              info.warnings = [];
              let signing = "";
              let provisionedDevices =
                res.mobileProvision && res.mobileProvision.ProvisionedDevices;
              let ProvisionsAllDevices =
                res.mobileProvision && res.mobileProvision.ProvisionsAllDevices;
              let getTaskAllow =
                res.mobileProvision &&
                res.mobileProvision.Entitlements &&
                res.mobileProvision.Entitlements["get-task-allow"];
              if (provisionedDevices) {
                if (getTaskAllow) {
                  signing = "development";
                  info.meta.signingType = "development";
                } else {
                  signing = "ad-hoc";
                  info.meta.signingType = "ad-hoc";
                }
              } else {
                if (ProvisionsAllDevices) {
                  signing = "enterprise";
                  info.meta.signingType = "enterprise";
                } else {
                  signing = "app-store";
                  info.meta.signingType = "app-store";
                }
              }
              if (signing === "app-store") {
                info.error =
                  "It appears that the application has been signed using the App Store distribution profile. <br /><br /> Please upload a signed IPA using either Development or Ad-Hoc or Enterprise provisioning profile.";
              }
              if (
                signing === "ad-hoc" &&
                (!res.mobileProvision.ProvisionedDevices ||
                  isEmpty(res.mobileProvision.ProvisionedDevices))
              ) {
                info.error =
                  "No provisioned devices (UDIDs) were found in this IPA. <br /><br /> As a result, your testers will not be able to install this version. <br /><br /> Please upload a new IPA that includes UDIDs.";
              }
              if (
                this.getDifference(
                  get(res, "mobileProvision.ExpirationDate", 0)
                )
              ) {
                info.error =
                  "The provisioning profile used to sign this IPA has expired. <br /><br /> As a result, your testers will not be able to install this version. <br /><br /> Please upload a new IPA with a valid provisioning profile.";
              }
            }

            if (error || info.error) {
              this.logEvent(segmentEvents.PRE_RELEASE_ERROR, {
                type: accept,
                reason: info.error || error,
              });
            }

            info.file = {};
            info.file.name = file.name;
            info.file.extension = file.name.split(".").pop();
            info.file.size = file.size;

            if (error || info?.error) {
              reject({ message: info?.error || error });
              this.notifyErrorMessage({ message: info?.error || error });
            } else {
              resolve(info);
            }
          })
          .catch((err) => {
            this.logError(err);
            reject(err);
          });
      });
    },
    getDifference(date) {
      const currentDate = dayjs().format();
      return new Date(currentDate) > new Date(date);
    },
    /**
     * Submits a pre-release for a given platform, file, and app ID.
     *
     * @param {Object} extractRes - The extract result.
     * @param {string} platform - The platform (android or ios).
     * @param {File} file - The file to submit.
     * @param {string} appID - The ID of the app (optional).
     */
    async submitPreRelease(extractRes, platform, file, appID) {
      const preLoading =
        platform === "android" ? "preLoadingAndroid" : "preLoadingIOS";
      try {
        const res = await this.$store.dispatch(PRE_RELEASE, {
          id: appID || this.app.id,
          body: {
            platform,
            info: extractRes,
          },
        });

        const udid = platform === "android" ? "apkUDID" : "ipaUDID";
        const fileToken =
          platform === "android" ? "apkFileToken" : "ipaFileToken";
        const fileType = platform === "android" ? "apk" : "ipa";
        this[preLoading] = false;

        this[udid] = res.id;
        this[fileToken] = res.id;
        this[fileType] = {
          ...this[fileType],
          id: res.id,
          info: extractRes,
          meta: res.meta,
          url: res.url,
          file,
        };
      } catch (err) {
        this[preLoading] = false;

        const maxUploadSize = this.maxUploadSize;

        if (err && err.status === 402) {
          if (file.size > maxUploadSize) {
            this.error =
              err.message ||
              `Max upload file size of your team's plan is ${formatBytes(
                maxUploadSize / 1000
              )} and you selected ${formatBytes(
                file.size / 1000
              )} Check Team Plans page for more info.`;
            this.message = `Max file size for your team is ${formatBytes(
              maxUploadSize / 1000
            )} and you selected ${formatBytes(file.size / 1000)}`;
            this.allowedSize = this.formatBytes(maxUploadSize / 1000);
            this.uploadedSize = this.formatBytes(file.size / 1000);
            this.fileSize = file.size / 1000;
            this.trackerEvent = {
              event: "Max upload size",
              current: this.allowedSize,
              requested: this.uploadedSize,
            };

            if (isEmpty(this.getTeamTrial)) {
              this.showStartTrialModal = true;
            } else if (!isEmpty(this.getTeamTrial)) {
              const popup = StorageService.getItem("popup");

              if (!popup || !popup.subscription) {
                this.openSubscriptionModal = true;
              }
            }
          } else {
            this.upgradePlanMessage = err.message;
            this.openSubscriptionModal = true;
          }
        } else {
          this.logEvent(segmentEvents.PRE_RELEASE, {
            platform,
            message: err.message,
            step: "api",
            status: "failed",
          });
        }

        const preReleaseError =
          platform === "android"
            ? "preReleaseErrorAndroid"
            : "preReleaseErrorIOS";
        this[preReleaseError] =
          err.message || "Something went wrong, please try again";
      }
    },
    async submitRelease() {
      if (!this.app?.info?.android && !this.app?.info?.ios) {
        if (this.fromStarted) {
          this.app.name = this.getReleaseName();
        }
        this.showAppInformationModal = true;
        return;
      }

      this.resetUpload();

      if (this.busy) return;

      this.busy = true;
      this.setIsUploadingReleases(true);
      window.localStorage.setItem("is_uploading_releases", "true");

      const body = {
        notify: this.notify,
      };

      if (this.apk) {
        body.android = {
          id: this.apk.id,
          note: this.oneReleaseNote ? this.form.note : this.form.androidNote,
          icon: this.icon === "android",
          archive_latest_release: !!this.archiveAndroid,
        };
        this.androidReleaseLoader = true;
      }

      if (this.ipa) {
        body.ios = {
          id: this.ipa.id,
          note: this.oneReleaseNote ? this.form.note : this.form.iosNote,
          archive_latest_release: !!this.archiveIos,
        };
        this.iosReleaseLoader = true;
      }

      try {
        const response = await this.$store.dispatch(RELEASE, {
          isExpress: false,
          app_id: this.app.id,
          body,
        });

        this.forExpressAppId = response.id;
        this.showAlertInformation = true;
        this.uploadRelease(response);
      } catch (err) {
        this.resetUpload();
        this.setIsUploadingReleases(false);
        window.localStorage.setItem("is_uploading_releases", "false");
        this.androidReleaseLoader = false;
        this.iosReleaseLoader = false;
        this.notifyErrorMessage(err.message || "Unable to create release!");
        this.busy = false;
      }
    },
    uploadRelease(details) {
      this.ids = details;
      this.$store
        .dispatch(RELEASE_UPLOAD, {
          ipa: {
            id: this.ipaUDID,
            type: "ios",
            uploadDetail: {
              appId: this.app?.id || this.appInfo?.id,
              name: this.ipa?.info?.name,
              icon: this.ipa?.info?.icon,
              user: this.currentUser?.name,
              version: this.ipa?.info?.version,
              version_code: this.ipa?.info?.version_code,
              releaseId: details?.ios?.id,
            },
            token: this.ipaFileToken,
            action: "release",
            url: this.ipa?.url,
          },
          apk: {
            id: this.apkUDID,
            type: "android",
            token: this.apkFileToken,
            uploadDetail: {
              appId: this.app?.id || this.appInfo?.id,
              name: this.apk?.info?.name,
              icon: this.apk?.info?.icon,
              user: this.currentUser?.name,
              version: this.apk?.info?.version,
              version_code: this.apk?.info?.version_code,
              releaseId: details?.android?.id,
            },
            appId: this.app?.id || this.appInfo?.id,
            action: "release",
            url: this.apk?.url,
          },
        })
        .then(() => {
          this.resetUpload();
          if (this.appInfo) {
            this.setReleaseMessage(details.message);
          } else {
            this.setReleaseMessage(details.message);
          }
          this.$nextTick(() => {
            this.androidReleaseLoader = false;
            this.iosReleaseLoader = false;
          });
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message || "Unable to create release!");
          this.busy = false;
          this.androidReleaseLoader = false;
          this.iosReleaseLoader = false;
        });
    },
    makeAfterReleaseProgress() {
      if (this.getTourInfo) {
        this.setUploadQueueTour(true);
      }
      this.setIsUploadingReleases(false);
      this.resetUpload();
      this.$emit("socketReconnect");
      window.localStorage.setItem("is_uploading_releases", "false");
      const imageDetails = {
        iosId: this.ids && this.ids.ios ? this.ids.ios.id : null,
        androidId: this.ids && this.ids.android ? this.ids.android.id : null,
        iosIcon: this.ipa && this.ipa.info ? this.ipa.info.icon : null,
        androidIcon: this.apk && this.apk.info ? this.apk.info.icon : null,
      };

      const popup = JSON.parse(localStorage.getItem("popup"));
      let box = popup ? popup.install_instruction2 : null;

      if (this.appInfo) {
        this.setReleaseRefresh(true);
        this.$nextTick(() => {
          const latestReleases = this.releases.map((release) => {
            if (release.id === imageDetails.androidId) {
              release.image = imageDetails.androidIcon;
            }
            if (release.id === imageDetails.iosId) {
              release.image = imageDetails.iosIcon;
            }
          });
          this.setLatestRelease(latestReleases);
        });
      } else {
        this.setReleaseImage(imageDetails);
        if (!box) {
          localStorage.setItem(
            "popup",
            JSON.stringify({
              ...JSON.parse(localStorage.getItem("popup")),
              install_instruction2: "false",
            })
          );
          // box = JSON.parse(localStorage.getItem("popup"));
        }
        if (!this.singleRelease) {
          this.notifyUserMessage({ message: "Release successfully uploaded" });
        }
        if (box !== "true") {
          this.$router
            .push({
              name: "app-release-index",
              params: {
                app_id: this.app.id,
              },
              query: {
                install: true,
              },
            })
            .catch(() => {});
        } else {
          this.$router
            .push({
              name: "app-release-index",
              params: {
                app_id: this.app.id,
              },
            })
            .catch(() => {});
        }
      }
      const apkSize = this.apk && this.apk.file ? this.apk.file.size / 1000 : 0;
      const ipaSize = this.ipa && this.ipa.file ? this.ipa.file.size / 1000 : 0;
      this.updateReleaseInfo({
        count: 1,
        size: apkSize + ipaSize,
        android: !!apkSize,
        ios: !!ipaSize,
      });
      if (box !== "true") {
        if (this.apk && this.ipa) {
          this.$emit("releaseSuccess");
        } else {
          this.$emit("releaseSuccess", this.apk ? this.apk : this.ipa);
        }
      }
      this.appendAppStats({
        releaseCount: this.apk && this.ipa ? 2 : 1,
        storage:
          this.app && this.app.storage && this.app.storage.name === "TestApp.io"
            ? apkSize + ipaSize
            : null,
        externalStorage:
          this.app && this.app.storage && this.app.storage.name !== "TestApp.io"
            ? apkSize + ipaSize
            : null,
      });
      this.closeForm();
      this.busy = false;
      this.$emit("released");
      if (!this.singleRelease) {
        this.notifyUserMessage({
          message: "Release successfully created",
          timeout: true,
        });
      }
    },
    async onFileChange(file, platform) {
      if (!this.app || !this.app?.id) {
        this.onFileUploadClick();
        return;
      }
      if (!file && !platform) {
        this.apk = null;
        this.ipa = null;
      }
      this.androidUploadStatus = {};
      this.iosUploadStatus = {};
      if (!file) {
        platform === "android" && ((this.apk = null), (this.apkUDID = null));
        platform === "ios" && ((this.ipa = null), (this.ipaUDID = null));
      } else {
        const udid = UID();
        platform === "android" && (this.apkUDID = udid);
        platform === "ios" && (this.ipaUDID = udid);
        if (platform === "android") {
          this.preLoadingAndroid = true;
          this.setApkFileInfo(file);
          this.preReleaseErrorAndroid = "";
        }
        if (platform === "ios") {
          this.setIpaFileInfo(file);
          this.preLoadingIOS = true;
          this.preReleaseErrorIOS = "";
        }
        await this.extractFileInfo(file, platform)
          .then(async (extractRes) => {
            this.submitPreRelease(extractRes, platform, file);
          })
          .catch((err) => {
            this.logEvent(segmentEvents.PRE_RELEASE, {
              platform: platform,
              message: err,
              name: file?.name,
              size: file?.size,
              step: "extract",
              status: "failed",
            });

            if (platform === "android") {
              this.preReleaseErrorAndroid =
                err.message || "Please select a valid APK file";
              this.notifyErrorMessage(this.preReleaseErrorIOS);
            }
            if (platform === "ios") {
              this.preReleaseErrorIOS =
                err.message || "Please select a valid IPA file";
              this.notifyErrorMessage(this.preReleaseErrorIOS);
            }
          })
          .finally(() => {
            if (platform === "android") {
              this.preLoadingAndroid = false;
            }
            if (platform === "ios") {
              this.preLoadingIOS = false;
            }
          });
      }
    },

    replaceDefaultAndroidImage(e) {
      e.target.src = "/media/defaults/icon-120.png";
    },
    replaceDefaultIOSImage(e) {
      e.target.src = "/media/defaults/icon-120.png";
    },
    onInfoClick(info) {
      this.releaseInfo = info;
      delete this.releaseInfo.file;
      this.showPreReleaseInfo = true;
    },
    onConfirm() {
      this.resetForm();
    },
  },
};
</script>
