var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: '',
      expression: "''"
    }],
    attrs: {
      "items": _vm.getAppOwnership,
      "label": _vm.notRequired ? 'App Ownership' : 'App Ownership*',
      "search-input": _vm.ownershipSearch,
      "chips": "",
      "filled": "",
      "flat": "",
      "item-text": "text",
      "item-value": "value",
      "placeholder": "This project is for..."
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.ownershipSearch = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.ownershipSearch = $event;
      }
    },
    model: {
      value: _vm.ownership,
      callback: function callback($$v) {
        _vm.ownership = $$v;
      },
      expression: "ownership"
    }
  }, 'v-autocomplete', _vm.veeValidate('Ownership', ''), false), [_c('template', {
    slot: "no-data"
  }, [_c('div', [_c('v-list-item', {
    staticStyle: {
      "border": "none"
    }
  }, [_c('span', {
    staticClass: "font-weight-regular mr-1"
  }, [_c('b', [_vm._v(_vm._s(_vm.ownershipSearch) + " ")]), _vm._v(" is not available ")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.addAppOwnerShip
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-plus')
    }
  }), _c('span', [_vm._v(" Add now ")])], 1)], 1)], 1)]), _c('template', {
    slot: "append-item"
  }, [_c('div', [_c('v-divider'), _c('v-list-item', {
    staticStyle: {
      "border": "none"
    }
  }, [_c('span', {
    staticClass: "font-weight-regular mr-1"
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:120',
      expression: "'max:120'"
    }],
    staticClass: "my-3",
    attrs: {
      "value": _vm.newOwnership,
      "counter": "120",
      "dense": "",
      "filled": "",
      "hide-details": "",
      "placeholder": "Type here..."
    },
    on: {
      "change": function change($event) {
        _vm.newOwnership = $event;
      },
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.addAppOwnerShip.apply(null, arguments);
      }
    }
  }, 'v-text-field', _vm.veeValidate('ownership option', ''), false))], 1), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.addAppOwnerShip
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-plus')
    }
  }), _c('span', [_vm._v(" Add ")])], 1)], 1)], 1)])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }