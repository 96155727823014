<template>
  <img
    :src="
      image && appId
        ? getAppImage(image, appId)
        : defaultImg || $vuetify.theme.dark
        ? 'app_default_light'
        : 'app_default'
    "
    :height="size"
    :class="className"
    :alt="alt || 'app'"
    @error="replaceDefaultAppImage"
  />
</template>

<script>
export default {
  name: "app-image",
  props: {
    defaultImg: {
      type: [String],
      default: null,
    },
    image: [String, Boolean, Object],
    appId: [String, Number],
    className: [String],
    alt: [String],
    size: {
      type: [String, Number],
      default: 50,
    },
  },
};
</script>
