<template>
  <v-card :outlined="outlined || $vuetify.theme.dark">
    <v-row>
      <template v-for="(stat, index) in stats">
        <v-col class="pa-2 px-6" cols="6" md="auto" sm="6" :key="index">
          <div class="stats-item ma-4">
            <div v-if="dotType == 'square'">
              <v-badge :color="statColor(stat)" dot inline tile></v-badge>
              <span class="darkGrey--text"> {{ stat.title }}</span>
            </div>

            <div v-if="dotType == 'circle'" class="statRow">
              <div class="greenCircleContainer">
                <div class="greenCircle"></div>
              </div>
              <span class="darkGrey--text">{{ stat.title }}</span>
            </div>
            <div v-if="dotType == 'icon'" class="statRow">
              <div v-if="stat.icon">
                <v-icon
                  size="20"
                  left
                  :color="stat.color"
                  v-text="stat.icon"
                ></v-icon>
                <span class="darkGrey--text smallFont">{{ stat.title }}</span>
              </div>
              <div v-if="!stat.icon && stat.storage">
                <img
                  class="mx-auto"
                  contain
                  height="20"
                  :src="
                    stat.storage.name
                      ? get(stat, 'storage.icon', null) == 'testappio'
                        ? getStorageImg('testapp.io.svg')
                        : getStorageImg(get(stat, 'storage.icon', null))
                      : getStorageImg('testapp.io.svg')
                  "
                />
                <span class="ml-2 darkGrey--text smallFont">{{
                  stat.title
                }}</span>
              </div>
            </div>

            <v-tooltip v-if="stat.tooltip" bottom>
              <template #activator="{ on }">
                <span
                  :class="dotType == 'circle' ? 'green-text' : ''"
                  class="stats-count darkGrey--text"
                  v-on="on"
                  @click="navigateToLink(stat.link)"
                  >{{ stat.count }}</span
                >
              </template>
              <div v-html="stat.tooltip"></div>
            </v-tooltip>
            <div v-else-if="stat?.type === 'icon'" class="text-left">
              <v-icon
                color="success"
                v-if="stat.count == 'mdi-check-circle-outline'"
                v-text="stat.count"
              ></v-icon>
              <v-icon
                color="error"
                v-else-if="
                  stat?.status !== 1 &&
                  stat.count === 'mdi-close-circle-outline'
                "
                v-text="stat.count"
              ></v-icon>
              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    v-on="on"
                    class="text-transform-none"
                    :outlined="$vuetify.theme.dark"
                    :loading="notifyLoading"
                    @click="$emit('update-notify')"
                    color="primary"
                  >
                    Notify
                  </v-btn>
                </template>
                <span>
                  <span>
                    Choose this to send notifications manually from the release
                    page at your convenience. All app
                    <br />
                    members with access will receive a push notification in our
                    app for the relevant platform,
                    <br />
                    allowing them to install the release and share feedback.
                  </span>
                </span>
              </v-tooltip>
            </div>
            <span
              v-else-if="!stat.time && stat.type !== 'icon'"
              :class="{
                'green-text': dotType == 'circle',
                'cursor-pointer': stat.link,
              }"
              class="stats-count darkGrey--text"
              @click="navigateToLink(stat.link)"
              >{{ stat.count }}</span
            >
            <span class="stats-count darkGrey--text" v-else>
              <vue-hoverable-date
                v-if="stat.count && stat.from === 'session'"
                :date="stat.count | unix"
              ></vue-hoverable-date>
              <vue-hoverable-date
                v-else-if="stat.from !== 'session'"
                :date="stat.count"
              ></vue-hoverable-date>
              <span v-else>-</span>
            </span>
          </div>
        </v-col>
        <v-col
          v-if="!$vuetify.breakpoint.smAndDown && index < stats.length - 1"
          :key="index"
          class="pa-1"
          cols="auto"
        >
          <v-divider class="stats-devider" inset vertical />
        </v-col>
      </template>
    </v-row>
  </v-card>
</template>

<script>
import { get } from "@/core/services/helper.service";
export default {
  props: {
    stats: {
      type: Array,
      required: true,
    },
    notifyLoading: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dotType: {
      type: String,
      default: "square",
      enum: ["square", "circle", "icon"],
    },
  },
  data() {
    return {
      get,
    };
  },
  methods: {
    navigateToLink(link) {
      if (link) {
        this.$router.push(link);
      }
    },
    statColor(stat) {
      if (stat.title === "Errors") {
        return "#D50000";
      } else if (stat.title === "Activities") {
        return "#00C4B4";
      } else if (stat.title === "Feedback") {
        return "#9747FF";
      } else {
        return "#2962FF";
      }
    },
  },
};
</script>

<style scoped>
.stats-item {
  max-width: 150px;
  width: 100%;
  color: #000000;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 14px;
  line-height: 34px;
}

.stats-count {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.stats-devider {
  height: 85px;
}

.statRow {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.greenCircleContainer {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: rgba(0, 200, 83, 0.15);
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.greenCircle {
  width: 8px;
  height: 8px;
  background: #00c853;
  border-radius: 50%;
}

.green-text {
  color: #00c853;
}
</style>
