var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-left"
  }, [_c('v-btn', {
    staticClass: "main",
    attrs: {
      "id": "createReleaseBtn",
      "disabled": _vm.disabled,
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.drawer = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("layers")]), _vm._v(" New release ")], 1), _c('SidePanel', {
    attrs: {
      "width": "740"
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    },
    model: {
      value: _vm.drawer,
      callback: function callback($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_vm.drawer ? _c('create-release-step', {
    attrs: {
      "app-info": _vm.appInfo
    },
    on: {
      "close": function close($event) {
        _vm.drawer = false, _vm.$emit('close');
      },
      "releaseSuccess": function releaseSuccess($event) {
        return _vm.$emit('releaseSuccess', $event);
      },
      "socketReconnect": function socketReconnect($event) {
        return _vm.$emit('socketReconnect');
      }
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }