var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "disabled": _vm.loading,
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "display-1 justify-center mb-8 mt-n3"
  }, [_vm._v(" Add storage ")]), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-select', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    staticClass: "mb-4",
    attrs: {
      "item-disabled": _vm.disableItem,
      "items": _vm.providerList,
      "menu-props": {
        offsetY: true
      },
      "append-icon": "mdi-chevron-down",
      "attach": "",
      "filled": "",
      "item-text": "name",
      "item-value": "id",
      "placeholder": "Select Providers"
    },
    on: {
      "focusin": function focusin($event) {
        $event.preventDefault();
        return _vm.getProviderList.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex align-center fill-width"
        }, [_c('v-card', {
          staticClass: "transparent d-flex justify-center align-center my-2 mr-4",
          staticStyle: {
            "width": "32px"
          },
          attrs: {
            "disabled": item.status !== 1,
            "flat": "",
            "tile": ""
          }
        }, [item.icon ? _c('img', {
          attrs: {
            "src": item.icon ? _vm.getStorageImg(item.icon) : null,
            "height": "32"
          }
        }) : _vm._e()]), _vm._v(" " + _vm._s(item.name) + " "), _c('v-spacer'), item.status === 2 ? _c('v-chip', {
          staticClass: "radius-10 bold-text px-3",
          staticStyle: {
            "height": "26px",
            "padding-bottom": "2px"
          }
        }, [_vm._v(" In beta ")]) : _vm._e(), item.status === 3 ? _c('v-chip', {
          staticClass: "radius-10 bold-text px-3",
          staticStyle: {
            "height": "26px",
            "padding-bottom": "2px"
          }
        }, [_vm._v(" Soon ")]) : _vm._e(), item.status === 4 ? _c('v-chip', {
          staticClass: "radius-10 bold-text px-3",
          staticStyle: {
            "height": "26px",
            "padding-bottom": "2px"
          }
        }, [_vm._v(" Future ")]) : _vm._e()], 1)];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('div', {
          staticClass: "pa-4"
        }, [_vm.storageLoading ? _c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('ContentLoader', {
          attrs: {
            "size": "25"
          }
        })], 1) : _c('div', [_vm._v("No data available")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formValues.s3_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "s3_id", $$v);
      },
      expression: "formValues.s3_id"
    }
  }, 'v-select', _vm.veeValidate('Providers', 'Providers'), false)), _c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    staticClass: "mb-4",
    attrs: {
      "disabled": !_vm.formValues.s3_id,
      "filled": "",
      "placeholder": "Enter Key"
    },
    model: {
      value: _vm.formValues.key,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "key", $$v);
      },
      expression: "formValues.key"
    }
  }, 'v-text-field', _vm.veeValidate('Key', 'Key'), false)), _c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    staticClass: "mb-4",
    attrs: {
      "disabled": !_vm.formValues.s3_id,
      "filled": "",
      "placeholder": "Enter Secret"
    },
    model: {
      value: _vm.formValues.secret,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "secret", $$v);
      },
      expression: "formValues.secret"
    }
  }, 'v-text-field', _vm.veeValidate('Secret', 'Secret'), false))], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "py-7 px-10 text-transform-none",
    attrs: {
      "disabled": _vm.loading || _vm.errors.any() || !_vm.formValues.s3_id || !_vm.formValues.key || !_vm.formValues.secret || !(_vm.getTeamPlanUsage && _vm.getTeamPlanUsage.connect_storage),
      "loading": _vm.loading,
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "x-large": ""
    },
    on: {
      "click": _vm.createStorage
    }
  }, [_c('span', {
    staticClass: "font14"
  }, [_vm._v(" Add ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }