var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fileuploadzone",
    attrs: {
      "id": "app"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('form', {
    attrs: {
      "enctype": "multipart/form-data",
      "novalidate": ""
    }
  }, [_c('div', {
    class: "dropbox overflow-hidden ".concat(_vm.error ? 'errorbdr drop-zone-overlay' : '', " ").concat(!_vm.isExpress ? 'disabled' : ''),
    attrs: {
      "id": "dropzone-upload".concat(_vm.accept)
    }
  }, [_c('div', {
    staticClass: "dropzone-msg ml-n1"
  }, [!_vm.isFile && !_vm.disabled ? _c('input', {
    staticClass: "input-file",
    attrs: {
      "id": "file-upload".concat(_vm.accept),
      "accept": _vm.accept,
      "disabled": _vm.disabled,
      "name": _vm.accept,
      "type": "file"
    },
    on: {
      "change": function change($event) {
        return _vm.onFileSelect($event);
      }
    }
  }) : _vm._e(), !_vm.isFile ? _c('div', {
    staticClass: "dz-message d-flex flex-column justify-center align-center px-5",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$emit('try-select');
      }
    }
  }, [_vm.loading ? _c('div', [_c('content-loader')], 1) : _vm.preErrorMessage ? _c('div', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('p', {
    staticClass: "red-cl",
    domProps: {
      "innerHTML": _vm._s(_vm.preErrorMessage)
    }
  })]), _c('div', {
    staticClass: "close-icon"
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.removeFile();
      }
    }
  }, [_vm._v("close")])], 1)]) : _c('div', {
    class: _vm.fromStarted ? '' : 'mt-7',
    attrs: {
      "id": "dropzone".concat(_vm.accept)
    }
  }, [_c('h3', {
    staticClass: "darkGrey--text dropzone-msg-title"
  }, [_vm._v(" Click here or drag & drop ")]), _c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "mb-8 mx-auto svg-icon",
    attrs: {
      "max-height": 70,
      "max-width": 70,
      "src": _vm.accept == '.apk' ? '/media/placeholders/apk.svg' : '/media/placeholders/ipa.svg',
      "contain": ""
    }
  })])]), _vm.fromStarted ? _c('div', {
    staticClass: "pa-1 support-panel text-center primary--text font-size-h5",
    staticStyle: {
      "position": "absolute",
      "bottom": "0px",
      "width": "100%",
      "border-radius": "5px"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.downloadSampleApp.apply(null, arguments);
      }
    }
  }, [_vm._v(" Download our sample app "), _c('v-icon', {
    attrs: {
      "color": "primary",
      "right": "",
      "small": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-download')
    }
  })], 1) : _vm._e()]) : _c('div', {
    staticClass: "file-info"
  }, [!_vm.uploading ? _c('div', {
    staticClass: "close-icon"
  }, [_c('v-btn', {
    staticStyle: {
      "z-index": "5"
    },
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": _vm.removeFile
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("close")])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "container-info"
  }, [_vm.releaseLoader ? _c('div', {
    staticClass: "progress d-flex align-center justify-center"
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "loading": ""
    }
  })], 1) : _vm._e(), (_vm.uploading || _vm.uploadStatus.status === 'uploaded' || _vm.error) && !_vm.releaseLoader ? _c('div', {
    staticClass: "progress d-flex align-center justify-center fill-height fill-width"
  }, [_vm.uploading || _vm.uploadStatus.status === 'uploaded' ? _c('div', {
    staticClass: "text-center"
  }, [_c('div', [_c('v-progress-circular', {
    attrs: {
      "rotate": 360,
      "size": 50,
      "value": _vm.uploadProgress,
      "width": 5,
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.uploadProgress + " %") + " ")])], 1), _vm.uploadStatus.status !== 'uploaded' && _vm.uploadProgress === 100 ? _c('div', {
    staticClass: "mt-2 bold-text primary--text"
  }, [_vm._v(" Processing, please wait... ")]) : _vm._e(), _vm.uploadStatus.status === 'uploaded' ? _c('div', {
    staticClass: "mt-2 bold-text primary--text"
  }, [_vm._v(" Successfully uploaded the release ")]) : _vm._e()]) : _vm._e(), _vm.error ? _c('div', {
    staticClass: "red--text text-center",
    staticStyle: {
      "white-space": "pre-wrap"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.error)
    }
  }) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "d-flex darkGrey--text flex-column px-4 py-2"
  }, [_c('div', [_vm._v(" Version: "), _c('MaxText', {
    attrs: {
      "text": _vm.get(_vm.file, 'info.version', ''),
      "max": "20"
    }
  })], 1), _c('div', [_vm._v(" " + _vm._s(_vm.accept == ".apk" ? "Version Code" : "Build Number") + ": "), _c('MaxText', {
    attrs: {
      "text": _vm.get(_vm.file, 'info.version_code', ''),
      "max": "20"
    }
  })], 1), _c('div', [_vm._v(" " + _vm._s(_vm.accept == ".apk" ? "Package" : "Build ID") + ": "), _c('MaxText', {
    attrs: {
      "text": _vm.get(_vm.file, 'info.package', ''),
      "max": "20"
    }
  })], 1), _vm.accept == '.apk' ? _c('div', [_vm._v(" Target SDK: "), _c('MaxText', {
    attrs: {
      "text": _vm.get(_vm.file, 'info.meta.targetSDKVersion', ''),
      "max": "20"
    }
  })], 1) : _vm._e(), _vm.accept == '.apk' ? _c('div', [_vm._v(" Min SDK: "), _c('MaxText', {
    attrs: {
      "text": _vm.get(_vm.file, 'info.meta.minSDKVersion', ''),
      "max": "20"
    }
  })], 1) : _vm._e(), _vm.accept == '.ipa' ? _c('div', [_vm._v(" Platform Version: "), _c('MaxText', {
    attrs: {
      "text": _vm.get(_vm.file, 'info.meta.platformVersion', ''),
      "max": "20"
    }
  })], 1) : _vm._e(), _vm.accept == '.ipa' ? _c('div', [_vm._v(" Min OS: "), _c('MaxText', {
    attrs: {
      "text": _vm.get(_vm.file, 'info.meta.minimumOSVersion', ''),
      "max": "20"
    }
  })], 1) : _vm._e(), _c('div', [_vm._v(" File: "), _c('MaxText', {
    attrs: {
      "text": _vm.get(_vm.file, 'info.file.name', ''),
      "max": "20"
    }
  })], 1), _c('div', [_vm._v(" Size: " + _vm._s(_vm.formatBytes(_vm.get(_vm.file, "info.file.size", 0) / 1000)) + " ")])]), _c('div', {
    staticClass: "ml-1 pl-3 d-flex justify-space-between support-panel pa-2 primary--text mt-n1 rounded",
    on: {
      "click": function click() {
        return _vm.onReleaseClick(_vm.file);
      }
    }
  }, [_c('div', [_vm._v(" " + _vm._s("".concat(_vm.get(_vm.file, "meta.members.eligible", ""), " / ").concat(_vm.get(_vm.file, "meta.members.total", ""))) + " Members ")]), _c('div', [_vm._v(" " + _vm._s("".concat(_vm.get(_vm.file, "meta.devices.total_eligible", ""), " / ").concat(_vm.get(_vm.file, "meta.devices.total", ""))) + " Devices ")]), _vm.totalWarnings ? _c('v-tooltip', {
    attrs: {
      "bottom": true,
      "color": "primary"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_c('v-icon', {
          attrs: {
            "color": "orange",
            "small": ""
          }
        }, [_vm._v("mdi-alert")])], 1)];
      }
    }], null, false, 434782382)
  }, [_c('span', [_vm._v("Warnings: " + _vm._s(_vm.totalWarnings) + " "), _c('br'), _vm._v(" You can still release it but it's better to fix them 👍 ")])]) : _vm._e(), _c('v-icon', {
    attrs: {
      "color": "primary",
      "small": ""
    }
  }, [_vm._v("mdi-arrow-right")])], 1)])])])])]), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.openSampleInstruction = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "py-4"
        }, [_vm.accept === '.apk' ? _c('v-col', {
          staticClass: "text-center text-h6",
          attrs: {
            "cols": "12"
          }
        }, [_c('b', [_vm._v("APK Downloaded 🎉")]), _c('br'), _c('br'), _vm._v(" Sometimes you wouldn't have your APK with you so we provided a sample one! "), _c('br'), _c('br'), _vm._v(" Now, close this dialog and upload the sample app to try our app distribution 💪 ")]) : _vm._e(), _vm.accept === '.ipa' ? _c('v-col', {
          staticClass: "text-center text-h6",
          attrs: {
            "cols": "12"
          }
        }, [_c('b', [_vm._v("IPA Downloaded 🎉")]), _c('br'), _c('br'), _vm._v(" Sometimes you wouldn't have your IPA with you so we provided a sample one! "), _c('br'), _c('br'), _c('vue-inline-alert', {
          attrs: {
            "message": "This sample app is on an ad-hoc provisioning profile. You will be able to install it from our mobile app but not opening it. So... don't worry!",
            "message-type": "info",
            "no-close-btn": ""
          }
        }), _vm._v(" Now, close this dialog and upload the sample app to try our app distribution 💪 ")], 1) : _vm._e(), _c('v-col', {
          staticClass: "text-center pb-0",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": function click($event) {
              _vm.openSampleInstruction = false;
            }
          }
        }, [_vm._v(" Close ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openSampleInstruction,
      callback: function callback($$v) {
        _vm.openSampleInstruction = $$v;
      },
      expression: "openSampleInstruction"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }