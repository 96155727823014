var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sessions-widget', {
    attrs: {
      "can-manage": _vm.canManageApp,
      "on-view-all": _vm.handleViewAll,
      "sessions": _vm.sessions,
      "sessions-loading": _vm.sessionsLoading,
      "total-sessions-count": _vm.totalSessionsCount
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }