export const sessionStatus = {
  0: "Ended",
  1: "Active",
  2: "Expired",
};

export const feedbackStatus = {
  1: {
    title: "Not Resolved",
    color: "orange",
    icon: "mdi-dots-horizontal-circle-outline",
    value: 1,
  },
  3: {
    title: "Flagged for review ",
    color: "red",
    icon: "mdi-flag-outline",
    value: 3,
  },
  2: {
    title: "Resolved",
    color: "green",
    icon: "mdi-check-circle-outline",
    value: 2,
  },
};

export const releaseStatus = {
  1: { title: "Active", color: "green", icon: 'mdi-check-circle-outline' },
  2: { title: "Failed", color: "red", icon: 'mdi-alert-circle' },
  3: { title: "Archived", color: "grey", icon: 'mdi-download-box-outline' },
  4: { title: "Uploading", color: "orange", icon: 'mdi-file-clock-outline' },
  5: { title: "Processing", color: "info", icon: 'mdi-circle' },
}

