<template>
  <component
    :is="fromWelcome || fromApiPage ? 'div' : 'v-card'"
    id="switchTeamCard"
    class="pa-4"
    flat
  >
    <v-row v-if="!fromMenu && !fromApiPage">
      <v-col
        :class="fromWelcome ? 'mt-1 mb-3 px-0' : ''"
        class="font-size-h3 darkGrey--text bold-text"
        cols="12"
      >
        Switch teams
        <div class="float-right top" v-if="fromTeamPage">
          <v-btn @click="$emit('close')" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-card
        :flat="!fromWelcome"
        :outlined="fromWelcome"
        class="pb-2"
        width="100%"
      >
        <v-col cols="12">
          <v-row align="center">
            <v-col>
              <div>
                <div class="">Active teams</div>
              </div>
            </v-col>
            <v-col v-if="!fromWelcome" class="text-right">
              <v-btn
                v-if="!fromApiPage"
                :text="!$vuetify.theme.dark"
                color="primary"
                id="createNewTeamBtn"
                :outlined="$vuetify.theme.dark"
                class="text-transform-none"
                @click="createTeamModal = true"
              >
                <span class="font14 semi-bold">Create new team </span>
              </v-btn>
              <v-btn
                v-if="fromApiPage"
                id="createNewTeamBtn"
                class="text-transform-none"
                color="primary"
                icon
                @click="$emit('close')"
              >
                <v-icon v-text="'mdi-close'"></v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="py-0" cols="12">
          <v-row v-if="loading">
            <v-col v-for="i in 2" :key="i" cols="12">
              <v-skeleton-loader type="article"></v-skeleton-loader>
            </v-col>
          </v-row>
          <template v-else>
            <v-row
              v-for="(team, index) in getTeamList"
              :id="`switchAbleTeamRow${team.id}`"
              :key="index"
              align="center"
              class="lightBackground my-3"
            >
              <v-col class="font-size-h4">
                <div>
                  {{ team.name }}
                  <div class="font11">
                    <span>Role:</span> {{ team.role }} |
                    <span> Plan: {{ team.plan }}</span>
                  </div>
                </div>
                <div v-if="team.status == 2" class="caption error--text">
                  Inactive
                </div>
              </v-col>
              <v-col class="text-right">
                <v-chip
                  v-if="fromApiPage"
                  :id="`switch${index}`"
                  :outlined="$vuetify.theme.dark"
                  class="text-transform-none"
                  color="primary"
                  label
                  @click="$emit('selected', team)"
                >
                  <div class="px-1 bold-text">Select</div>
                </v-chip>
                <template v-else>
                  <v-chip
                    v-if="getTeamId !== team.id"
                    :id="`switch${index}`"
                    :outlined="$vuetify.theme.dark"
                    class="text-transform-none"
                    color="primary"
                    label
                    @click="switchTeam(team)"
                  >
                    <div class="px-1 bold-text">Switch</div>
                  </v-chip>
                  <v-chip v-else id="currentChip" color="success" label>
                    <div class="bold-text">Current</div>
                  </v-chip>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 text-right" cols="12">
                <div style="display: flex; justify-content: flex-end">
                  <v-pagination
                    v-if="getTotalTeamCount > 4"
                    id="paginationComponent"
                    v-model="page"
                    :disabled="getTotalTeamCount < 4"
                    :length="totalLength || 0"
                    :total-visible="0"
                    class="ml-auto"
                    flat
                  ></v-pagination>
                </div>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-card>
      <Modal
        v-model="showSuccessTeamSwitch"
        @close="showSuccessTeamSwitch = false"
      >
        <template #message>
          <v-row>
            <v-col class="my-4 font-size-h3 bold-text" cols="12">
              Switching teams
            </v-col>
            <v-col class="text-center" cols="12">
              <v-progress-circular indeterminate></v-progress-circular>
            </v-col>
            <v-col class="text-center" cols="12">Please wait...</v-col>
          </v-row>
        </template>
      </Modal>
      <Modal
        v-model="createTeamModal"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        @close="createTeamModal = false"
      >
        <template #card>
          <v-card v-if="createTeamModal">
            <v-card-title>
              <div class="darkGrey--text body-font semi-bold" cols="12">
                Create new team
              </div>
              <v-spacer></v-spacer>
              <v-btn @click="createTeamModal = false" icon>
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <create-team
                from-switch-team
                @teamCreated="afterSuccess"
              ></create-team>
            </v-card-text>
          </v-card>
        </template>
      </Modal>
    </v-row>
  </component>
</template>

<script>
import { GET_TEAMS, SWITCH_TEAM } from "@/store/team/manageTeam.module.js";
import { UPDATE_USER_TOKEN } from "@/store/users/auth.module";
import CreateTeam from "@/view/components/Team/Create.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "team-list",
  props: {
    fromTeamPage: {
      type: Boolean,
      default: false,
    },
    fromWelcome: {
      type: Boolean,
      default: false,
    },
    fromApiPage: {
      type: Boolean,
      default: false,
    },
    fromMenu: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CreateTeam,
  },
  data() {
    return {
      loading: false,
      modal: false,
      createTeamModal: false,
      successModalMessage: "",
      showSuccessTeamSwitch: false,
      page: 1,
      selectedTeam: {},
    };
  },
  computed: {
    ...mapGetters({
      getTeamList: "getTeamList",
      getTeamId: "getTeamId",
      getTotalTeamList: "getTotalTeamList",
      getTotalTeamCount: "getTotalTeamCount",
    }),
    totalLength() {
      return Math.ceil(this.getTotalTeamCount / 4);
    },
  },
  watch: {
    page(val, oldVal) {
      let offset = (val - 1) * 4;
      let limit = val * 4;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (
          this.getTotalTeamList &&
          this.getTotalTeamList.length > oldVal * 4
        ) {
          this.setPreviousTeamData(this.getTotalTeamList.slice(offset, limit));
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.getTeamList[this.getTeamList.length - 1].id;
          this.getTeamsList(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousTeamData(this.getTotalTeamList.slice(offset, limit));
      }
    },
  },
  created() {
    if (!this.fromWelcome) {
      this.getTeamsList();
    }
  },
  methods: {
    ...mapMutations({
      setPreviousTeamData: "setPreviousTeamData",
    }),
    getTeamsList(lastId) {
      this.createTeamModal = false;
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_TEAMS, lastId)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    async afterSuccess(plan) {
      this.createTeamModal = false;
      if (["Pro", "Starter"].includes(plan)) {
        await this.$router.push({ name: "team-plan" });
      } else if (this.$route.params && this.$route.params.app_id) {
        await this.$router.push({ name: "apps" });
      } else if(this.$route.name === 'welcome') {
        await this.$router.push({name: "dashboard"})
      }
      this.showSuccessTeamSwitch = true;
      this.$nextTick(() => {
        window.location.reload();
      });
    },
    switchTeam(details) {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(SWITCH_TEAM, details.id)
        .then((response) => {
          this.successModalMessage = response.data.message;
          this.afterSuccess();
          // this.$store.dispatch(CLEAR_STORE);
          this.$store.dispatch(UPDATE_USER_TOKEN, response.data.token);
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
