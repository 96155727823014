var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', {
    attrs: {
      "can-be-managed": true,
      "is-empty": _vm.comments.length === 0,
      "loading": _vm.dashboardLoader
    },
    scopedSlots: _vm._u([{
      key: "widget-loading",
      fn: function fn() {
        return [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('v-skeleton-loader', {
          attrs: {
            "type": "list-item-two-line"
          }
        })], 1), _c('v-col', {
          staticStyle: {
            "display": "flex",
            "justify-content": "flex-end"
          },
          attrs: {
            "cols": "6"
          }
        }, [_c('v-skeleton-loader', {
          staticStyle: {
            "margin-right": "16px"
          },
          attrs: {
            "type": "button"
          }
        })], 1)], 1), _c('v-row', [_c('v-col', _vm._l(5, function (i) {
          return _c('v-skeleton-loader', {
            key: i,
            staticClass: "transparent",
            attrs: {
              "loading": true,
              "type": "list-item-avatar-two-line"
            }
          });
        }), 1)], 1)];
      },
      proxy: true
    }, {
      key: "widget-content",
      fn: function fn() {
        return [_c('v-card-title', [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('div', {
          staticClass: "card-label bold-text text-dark"
        }, [_vm._v("Recent Comments")])])], 1)], 1), _c('v-data-table', {
          attrs: {
            "headers": _vm.commentHeader,
            "items": _vm.comments,
            "hide-default-footer": "",
            "disable-sort": "",
            "mobile-breakpoint": "0"
          },
          on: {
            "click:row": function clickRow($event) {
              var _$event$app;
              return _vm.$router.push({
                name: 'release-comment',
                params: {
                  app_id: $event === null || $event === void 0 ? void 0 : (_$event$app = $event.app) === null || _$event$app === void 0 ? void 0 : _$event$app.id,
                  release_id: $event === null || $event === void 0 ? void 0 : $event.release_id,
                  comment_id: $event === null || $event === void 0 ? void 0 : $event.id
                }
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "item.app",
            fn: function fn(_ref) {
              var _item$app, _item$app2, _item$app3;
              var item = _ref.item;
              return [_c('v-row', {
                attrs: {
                  "align": "center"
                }
              }, [_c('v-col', {
                attrs: {
                  "cols": "auto"
                }
              }, [_c('AppImage', {
                attrs: {
                  "alt": item === null || item === void 0 ? void 0 : (_item$app = item.app) === null || _item$app === void 0 ? void 0 : _item$app.name,
                  "image": item === null || item === void 0 ? void 0 : (_item$app2 = item.app) === null || _item$app2 === void 0 ? void 0 : _item$app2.image
                }
              })], 1), _c('v-col', [_c('a', {
                staticClass: "darkGrey--text bodyFont mb-1"
              }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$app3 = item.app) === null || _item$app3 === void 0 ? void 0 : _item$app3.name))])])], 1)];
            }
          }, {
            key: "item.release",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [_c('release-chip', {
                attrs: {
                  "release": Object.assign({}, item.release, {
                    platform: 'android'
                  })
                }
              })];
            }
          }, {
            key: "item.user",
            fn: function fn(_ref3) {
              var item = _ref3.item;
              return [_c('user-avatar', {
                attrs: {
                  "user-detail": item.user
                }
              })];
            }
          }, {
            key: "item.message",
            fn: function fn(_ref4) {
              var item = _ref4.item;
              return [item.message ? _c('MaxText', {
                attrs: {
                  "text": item.message,
                  "max": "150"
                }
              }) : _c('div', [_vm._v("-")])];
            }
          }, {
            key: "item.attachment",
            fn: function fn(_ref5) {
              var _item$stats;
              var item = _ref5.item;
              return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$stats = item.stats) === null || _item$stats === void 0 ? void 0 : _item$stats.attachments) + " ")];
            }
          }, {
            key: "item.status",
            fn: function fn(_ref6) {
              var item = _ref6.item;
              return [_c('v-tooltip', {
                attrs: {
                  "bottom": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref7) {
                    var on = _ref7.on;
                    return [_c('span', _vm._g({}, on), [_c('v-icon', {
                      attrs: {
                        "color": _vm.$options.STATUS[item === null || item === void 0 ? void 0 : item.status].color
                      }
                    }, [_vm._v(_vm._s(_vm.$options.STATUS[item === null || item === void 0 ? void 0 : item.status].icon))])], 1)];
                  }
                }], null, true)
              }, [_c('span', [_vm._v(" " + _vm._s(_vm.$options.STATUS[item === null || item === void 0 ? void 0 : item.status].title) + " ")])])];
            }
          }, {
            key: "item.created",
            fn: function fn(_ref8) {
              var item = _ref8.item;
              return [_c('vue-hoverable-date', {
                attrs: {
                  "date": item.created_at
                }
              })];
            }
          }, {
            key: "item.navigate",
            fn: function fn() {
              return [_c('div', {
                staticClass: "flex-row jc-c"
              }, [_c('v-btn', {
                attrs: {
                  "icon": ""
                }
              }, [_c('i', {
                staticClass: "arrow-right"
              })])], 1)];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }, {
      key: "widget-no-data",
      fn: function fn() {
        return [_c('v-card-title', [_vm._v(" Recent Comments ")]), _c('no-data', {
          attrs: {
            "align-class": "text-left",
            "first-text": "No Comments for This App... Yet!",
            "second-text": "<ul> <li><strong>Team</strong>: Team feedback about the app will appear here.</li> <li><strong>Guests</strong>: Shared the app with external testers? Their feedback shows up here. Remember, you can invite unlimited Guests for feedback in our app.</li> </ul>"
          }
        })];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }