<template>
  <v-autocomplete
    v-model="ownership"
    v-validate="''"
    :items="getAppOwnership"
    :label="notRequired ? 'App Ownership' : 'App Ownership*'"
    :search-input.sync="ownershipSearch"
    chips
    filled
    flat
    item-text="text"
    item-value="value"
    placeholder="This project is for..."
    v-bind="veeValidate('Ownership', '')"
  >
    <template slot="no-data">
      <div>
        <v-list-item style="border: none">
          <span class="font-weight-regular mr-1">
            <b>{{ ownershipSearch }} </b> is not available
          </span>
          <v-btn
            class="text-transform-none"
            color="primary"
            text
            @click="addAppOwnerShip"
          >
            <v-icon left v-text="'mdi-plus'"></v-icon>
            <span> Add now </span>
          </v-btn>
        </v-list-item>
      </div>
    </template>
    <template slot="append-item">
      <div>
        <v-divider></v-divider>
        <v-list-item style="border: none">
          <span class="font-weight-regular mr-1">
            <v-text-field
              v-validate="'max:120'"
              :value="newOwnership"
              class="my-3"
              counter="120"
              dense
              filled
              hide-details
              placeholder="Type here..."
              v-bind="veeValidate('ownership option', '')"
              @change="newOwnership = $event"
              @keypress.enter="addAppOwnerShip"
            ></v-text-field>
          </span>
          <v-btn
            class="text-transform-none"
            color="primary"
            text
            @click="addAppOwnerShip"
          >
            <v-icon left v-text="'mdi-plus'"></v-icon>
            <span> Add </span>
          </v-btn>
        </v-list-item>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";

export default {
  mixins: [veeValidate],
  props: {
    defaultValue: {
      type: [Object, String],
      default: "",
    },
    notRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ownershipSearch: "",
      newOwnership: "",
      ownership: "",
      getAppOwnership: [
        {
          text: "Our apps",
          value: "our_apps",
        },
        {
          text: "Client apps",
          value: "client_apps",
        },
        {
          text: "Both",
          value: "both",
        },
      ],
    };
  },
  watch: {
    defaultValue(val) {
      if (val) {
        if (!this.getAppOwnership.find((x) => x.value === val)) {
          this.getAppOwnership.push({
            text: val,
            value: val,
          });
        }
        this.ownership = val;
      }
    },
    ownership(val) {
      this.$emit("selected", val);
    },
  },
  created() {
    if (this.defaultValue) {
      if (!this.getAppOwnership.find((x) => x.value === this.defaultValue)) {
        this.getAppOwnership.push({
          text: this.defaultValue,
          value: this.defaultValue,
        });
      }
      this.ownership = this.defaultValue;
    }
  },
  methods: {
    async addAppOwnerShip() {
      if (await this.validateAllFields()) {
        if (!this.newOwnership) {
          return;
        }
        this.getAppOwnership.push({
          text: this.newOwnership,
          value: this.newOwnership,
        });
        this.ownership = this.newOwnership;
        this.newOwnership = "";
      }
    },
  },
};
</script>