<template>
  <div>
    <group-status-card
      outlined
      dot-type="icon"
      :stats="appStats.filter((stat) => !!stat.permissions)"
    ></group-status-card>
  </div>
</template>

<script>
import GroupStatusCard from "@/view/components/Sessions/GroupStatusCard";
import { formatBytes } from "@/core/services/helper.service";
import { mapGetters } from "vuex";
export default {
  props: {
    fromReleasesPage: {
      type: Boolean,
      default: false,
    },
  },
  components: { GroupStatusCard },
  computed: {
    ...mapGetters({
      appInfo: "getAppInfo",
    }),
    getInstallTotalCount() {
      const totalInstallCount =
        (this.appInfo?.stats?.installs?.installs?.android || 0) +
        (this.appInfo?.stats?.installs?.installs?.ios || 0);
      const totalDownloadCount =
        (this.appInfo?.stats?.installs?.downloads?.android || 0) +
        (this.appInfo?.stats?.installs?.downloads?.ios || 0);
      return this.$options?.filters?.formatNumber(
        totalInstallCount + totalDownloadCount
      );
    },
    appStats() {
      return [
        {
          title: "Android",
          icon: "mdi-android",
          color: "success",
          permissions: true,
          view: "",
          count: this.$options?.filters?.formatNumber(
            this.appInfo?.stats?.releases?.android?.active || 0
          ),
          tooltip: `<ul>
                        <li>
                        Active: ${
                          this.appInfo?.stats?.releases?.android?.active || 0
                        }
                        </li>
                        <li>
                        Created: ${
                          this.appInfo?.stats?.releases?.android?.create || 0
                        }
                        </li>
                       </ul>`,
        },
        {
          title: "iOS",
          icon: "mdi-apple",
          color: "grey",
          permissions: true,
          count: this.$options?.filters?.formatNumber(
            this.appInfo?.stats?.releases?.ios?.active || 0
          ),
          tooltip: `<ul>
                      <li>
                      Active: ${this.appInfo?.stats?.releases?.ios?.active || 0}
                      </li>
                      <li>
                      Created: ${
                        this.appInfo?.stats?.releases?.ios?.create || 0
                      }
                      </li>
                    </ul>`,
        },
        {
          title: "Feedback",
          icon: "mdi-comment-alert",
          color: "info",
          permissions: this.canManageApp,
          count: this.$options?.filters?.formatNumber(
            this.appInfo?.stats?.feedback || 0
          ),
        },
        {
          title: "Comments",
          icon: "mdi-comment-alert",
          permissions: this.canManageApp,
          color: "info",
          count: this.$options?.filters?.formatNumber(
            this.appInfo?.stats?.comments || 0
          ),
        },
        //         {
        //           title: 'Members',
        //           icon: 'mdi-account-group',
        //           color: 'info',
        //           count: this.appInfo?.stats?.members?.active,
        //           tooltip: `<ul>
        // <li>Active: ${this.appInfo?.stats?.members?.active}</li>
        // <li>Inactive: ${this.appInfo?.stats?.members?.inactive}</li>
        // </ul>`
        //         },
        {
          title: "Installs",
          icon: "mdi-cellphone-arrow-down-variant",
          color: "info",
          permissions: this.canManageApp,
          count: this.getInstallTotalCount,
          tooltip: `<ul>
                      <li>
                        Installs:
                      <ul>
                        <li>
                          Android: ${
                            this.appInfo?.stats?.installs?.installs?.android ||
                            0
                          }
                        </li>
                        <li>
                        iOS: ${
                          this.appInfo?.stats?.installs?.installs?.ios || 0
                        }
                        </li>
                      </ul>
                      </li>
                      <li>
                      Downloads:
                      <ul>
                      <li>
                        Android: ${
                          this.appInfo?.stats?.installs?.downloads?.android || 0
                        }
                      </li>
                      <li>
                       iOS: ${
                         this.appInfo?.stats?.installs?.downloads?.ios || 0
                       }
                      </li>
                      </ul>
                      </li>
                    </ul>`,
        },
        {
          title: "Storage",
          storage: this.appInfo.storage,
          permissions: this.canManageApp || this.fromReleasesPage,
          tooltip: `<ul>
                      <li>
                      Releases: ${formatBytes(
                        this.appInfo?.storage?.usage?.releases ?? 0
                      )}
                      </li>
                      <li>
                      Attachments
                      <ul>
                      <li>
                      Comments: ${formatBytes(
                        this.appInfo?.storage?.usage?.attachments?.comments || 0
                      )}
                      </li>
                      <li>
                      Feedback: ${formatBytes(
                        this.appInfo?.storage?.usage?.attachments?.feedback || 0
                      )}
                      </li>
                      </ul>
                      </li>
                    </ul>`,
          count: formatBytes(
            this.calculateTotalUsage(this.appInfo?.storage?.usage)
          ),
        },
      ];
    },
  },
  methods: {
    calculateTotalUsage(usage) {
      const { releases = 0, attachments: { comments = 0, feedback = 0 } = {} } =
        usage || {};
      return releases + comments + feedback;
    },
  },
};
</script>

<style scoped></style>
