<template>
  <div
    :disabled="busy"
    class="radius-10 pb-14"
    flat
    @keypress.enter.prevent="createTeam"
  >
    <v-form>
      <v-text-field
        id="team-name"
        v-model="formValues.name"
        v-validate="'required|min:3|max:27'"
        counter="27"
        filled
        label="Team name"
        messages="Can be renamed later"
        placeholder="Your team name"
        v-bind="veeValidate('Name', '')"
      ></v-text-field>
      <template>
        <v-textarea
          id="team-desc"
          v-model="formValues.description"
          v-validate="'max:600'"
          auto-grow
          class="mt-5"
          counter="600"
          filled
          label="Description"
          placeholder="Describe your awesome team... (Optional)"
          v-bind="veeValidate('Description', 'Description')"
        ></v-textarea>
      </template>
      <template>
        <ownership-autocomplete
          :default-value="formValues.info.ownership"
          @selected="formValues.info.ownership = $event"
        ></ownership-autocomplete>
      </template>
      <template v-if="fromSwitchTeam">
        <v-select
          v-model="formValues.plan"
          v-validate="''"
          :items="teamPlanOptions"
          class="mt-5"
          filled
          item-text="name"
          item-value="value"
          v-bind="veeValidate('plan', 'Select plan')"
        ></v-select>
      </template>
      <div class="text-center mt-7">
        <v-btn
          id="stepOneNext"
          :disabled="
            errors.any() || !formValues.name || !formValues.info?.ownership
          "
          :loading="busy"
          :outlined="$vuetify.theme.dark"
          class="text-transform-none py-7 px-10"
          color="primary"
          x-large
          @click="createTeam"
        >
          <span class="font14 semi-bold">
            {{ fromSwitchTeam ? "Create" : "Next" }}
          </span>
          <img
            v-if="!fromSwitchTeam"
            alt
            class="ml-3"
            height="20"
            src="/media/svg/icon-arrow-right-light.svg"
          />
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import veeValidate from "@/mixins/veeValidate.js";
import {
  CREATE_TEAM_INFO,
  GET_TEAM_INFO,
} from "@/store/team/manageTeam.module";
import { GET_USER_ROLES, UPDATE_USER_TOKEN } from "@/store/users/auth.module";
import { mapGetters, mapMutations } from "vuex";
import OwnershipAutocomplete from "@/view/components/Common/OwnershipAutocomplete";

export default {
  name: "create-team",
  components: { OwnershipAutocomplete },
  mixins: [veeValidate],
  props: {
    inTeam: {
      type: Boolean,
      default: false,
    },
    fromSwitchTeam: {
      type: Boolean,
      default: false,
    },
    team: {
      type: Object,
      default() {
        return {
          name: "",
          description: "",
        };
      },
    },
  },
  data() {
    return {
      formValues: {
        info: {
          ownership: "",
        },
      },
      newOwnership: "",
      ownershipSearch: "",
      busy: false,
      teamOwnershipOptions: [
        {
          text: "Our apps",
          value: "our_apps",
        },
        {
          text: "Client apps",
          value: "client_apps",
        },
        {
          text: "Both",
          value: "both",
        },
      ],
      teamPlanOptions: [
        {
          name: "Pro",
          value: "Pro",
        },
        {
          name: "Starter",
          value: "Starter",
        },
        {
          name: "Freelancer",
          value: "Freelancer",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getTeamName: "getTeamName",
      getRedirectedFromPlan: "getRedirectedFromPlan",
    }),
  },
  created() {
    this.formValues = { ...this.team, info: { ownership: "" } };
  },
  methods: {
    ...mapMutations({
      updateUserPermission: "updateUserPermission",
    }),
    addAppOwnerShip() {
      if (!this.newOwnership) {
        return;
      }
      this.teamOwnershipOptions.push({
        name: this.newOwnership,
        value: this.newOwnership,
      });
      this.formValues.ownership = this.newOwnership;
      this.newOwnership = "";
    },
    async createTeam() {
      if (await this.validateAllFields()) {
        if (this.busy) return;
        this.busy = true;
        if (this.fromSwitchTeam) {
          this.formValues.fromSwitch = true;
        }
        if (
          this.inTeam ||
          (this.getTeamName && this.$route.name === "get-started'")
        ) {
          if (this.canManageTeam) {
            this.$emit("update", this.formValues);
          } else {
            this.notifyErrorMessage(
              "You don't have permission to perform this action"
            );
            this.busy = false;
          }
        } else {
          this.$store
            .dispatch(CREATE_TEAM_INFO, this.formValues)
            .then((response) => {
              this.$store.dispatch(UPDATE_USER_TOKEN, response.token);
              this.busy = false;
              if (this.fromSwitchTeam) {
                this.$nextTick(() => {
                  this.$emit("teamCreated", this.formValues.plan);
                });
              } else {
                this.busy = false;

                this.updateUserPermission(response.permissions);
                this.$nextTick(() => {
                  this.$store
                    .dispatch(GET_USER_ROLES)
                    .then(() => {
                      this.connectToSocket();
                      if (["Pro", "Starter"].includes(this.formValues.plan)) {
                        this.$router.push({ name: "team-plan" });
                      } else {
                        const redirectFromLanding =
                          window.sessionStorage.getItem("plan_redirection");
                        if (redirectFromLanding) {
                          this.$emit("openSubscriptionModal");
                        }
                        if (response.upgraded) {
                          this.$emit("skip-plan");
                        } else {
                          this.$emit("next-step");
                        }
                      }
                    })
                    .catch((err) => {
                      this.notifyErrorMessage({ message: err.message });
                      this.busy = false;
                    });

                  this.$store.dispatch(GET_TEAM_INFO).catch((err) => {
                    this.notifyErrorMessage(err.message);
                    this.busy = false;
                  });
                });
              }
              this.notifyUserMessage({
                message: response.message || "Successfully created",
              });
            })
            .catch((err) => {
              this.notifyErrorMessage(err.message || "Something went wrong");
              this.busy = false;
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
