var render = function render(){
  var _vm$userDetail, _vm$userDetail2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', {
    attrs: {
      "bottom": "",
      "transition": "slide-y-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('span', _vm._g(_vm._b({
          staticClass: "pointer"
        }, 'span', attrs, false), on), [_c('profile-avatar', {
          attrs: {
            "size": "30",
            "avatar": _vm.userDetail
          }
        })], 1)];
      }
    }])
  }, [_c('div', {
    staticClass: "font-weight-medium"
  }, [_c('div', [_vm._v(" " + _vm._s(((_vm$userDetail = _vm.userDetail) === null || _vm$userDetail === void 0 ? void 0 : _vm$userDetail.name) || 'Anonymous') + " ")]), (_vm$userDetail2 = _vm.userDetail) !== null && _vm$userDetail2 !== void 0 && _vm$userDetail2.email ? _c('div', {
    staticClass: "text-caption"
  }, [_vm._v(" " + _vm._s(_vm.userDetail.email) + " ")]) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }