<template>
  <div>
    <div v-if="!fromTransferOwnership" class="mt-4">
      <v-checkbox
        v-if="!inviteImportant"
        v-model="invite"
        :readonly="inviteImportant"
        :messages="inviteImportant ? '' : 'It can be done later'"
        hide-details
      >
        <template slot="label">
          <div>Add members to this app</div>
        </template>
      </v-checkbox>
      <div v-else class="bold-text py-2">Add members to this app</div>
      <v-radio-group
        :disabled="!app && addMember"
        v-model="radioGroup"
        v-if="invite"
      >
        <v-radio value="Current" label="All team members"></v-radio
        ><v-radio value="Specific" label="Specific team members"></v-radio>
      </v-radio-group>
    </div>
    <v-row
      class="mt-4"
      v-if="fromTransferOwnership || (invite && radioGroup == 'Specific')"
    >
      <v-col cols="12">
        <v-autocomplete
          v-model="selectedData"
          :multiple="!single"
          attach
          :items="showItems"
          label="Team members"
          item-value="id"
          item-text="name"
          placeholder="Select team members"
          data-cy="add-member"
          append-icon="mdi-chevron-down"
          chips
          v-validate="'max_length'"
          v-bind="veeValidate('Members', '')"
          counter="20"
          :loading="loading"
          deletable-chips
          class="tag-input"
          small-chips
          return-object
          @focusin="fetchData"
          :search-input.sync="search"
          filled
          @change="$emit('change')"
          @blur="$emit('blur')"
        >
          <template slot="item" slot-scope="data">
            <v-row v-if="data && data.item" align="center" no-gutters>
              <v-col class="d-flex align-center justify-center">
                <v-list-item-title id="data-item">
                  {{ data.item.name }}
                </v-list-item-title>
              </v-col>
              <v-col>
                <div class="caption text-right">
                  {{ data.item.role.name }}
                </div>
              </v-col>
            </v-row>
          </template>
          <template slot="no-data">
            <div class="d-flex align-center justify-center pa-4">
              <ContentLoader v-if="loading" size="25" />
              <div v-else>All team members are already added to this app
              </div>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import veeValidate from "@/mixins/veeValidate.js";
import { mapGetters } from "vuex";
import VeeValidate from "vee-validate";
VeeValidate.Validator.extend("max_length", {
  getMessage: (field) => `You can only add 20 ${field} at a time`,
  validate: (value) => {
    return value.length <= 20;
  },
});
export default {
  props: {
    value: {
      type: [Number, String, Array, Object],
      default: undefined,
    },
    default: {
      type: [Object, Array, String],
      required: false,
      default: null,
    },
    app: {
      type: [String, Object, Array],
      default() {
        return "";
      },
    },
    addMember: {
      type: Boolean,
      default: false,
    },
    inviteImportant: {
      type: Boolean,
      default: false,
    },
    single: {
      type: Boolean,
      default: false,
    },
    fromTransferOwnership: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [veeValidate],
  data() {
    return {
      selectedData: "",
      search: "",
      loading: false,
      radioGroup: "Current",
      members: null,
      searchItems: [],
      invite: false,
    };
  },
  computed: {
    ...mapGetters({
      items: "getMemberAutocomplete",
      initialList: "getMemberInitialList",
    }),
    showItems() {
      return [...this.searchItems, ...this.selectedData];
    },
  },
  watch: {
    invite(val) {
      this.$emit("isInvite", val);
    },
    search(val) {
      if (val && val.length > 0) {
        clearTimeout(this._timerId);
        this.loading = true;
        this._timerId = setTimeout(() => {
          this.getSearchValues(val);
        }, 500);
      } else {
        this.searchItems = [...this.initialList];
      }
    },
    radioGroup(val) {
      this.$emit("selected", val);
    },
    selectedData(val) {
      this.$emit("input", val);
      this.search = "";
    },
    items(val) {
      this.searchItems = [...val];
    },
    initialList(val) {
      this.searchItems = [...val];
    },
  },
  created() {
    this.invite = this.inviteImportant;
  },
  methods: {
    populateInitialData(val) {
      if (this.selectedData) return;
      if (val) {
        this.getSearchValues(val);
      } else {
        this.items = [...this.initialList];
      }
      this.$emit("input", this.selectedData);
    },
    remove(item) {
      if (this.selectedData instanceof Object) {
        const index = this.selectedData.indexOf(item.id);
        if (index >= 0) this.selectedData.splice(index, 1);
      } else {
        this.selectedData = null;
      }
    },
    getSearchValues(val) {
      this.loading = true;
      this.$store
        .dispatch("getMembersAutocomplete", {
          search: val,
          appID: this.app && this.addMember ? this.app.id : "",
        })
        .then(() => {
          this.loading = false;
          this.searchItems = [...this.items];
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
    fetchData() {
      if (this.loading) return;
      this.loading = true;
      if (this.initialList.length) {
        this.loading = false;
        return;
      }
      this.$store
        .dispatch(
          "getMembersInitialList",
          this.app && this.addMember ? this.app.id : ""
        )
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
  },
};
</script>
