var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": "",
      "box": ""
    }
  }, [_c('v-card-title', [_c('v-spacer'), _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "pt-5"
  }, [_vm._v(" " + _vm._s(_vm.title || "Upload Image") + " ")]), _c('div', {
    staticClass: "text-caption text-center"
  }, [_vm._v(" " + _vm._s(_vm.subTitle || "") + " ")])]), _c('v-spacer')], 1), _c('v-card-text', {
    class: _vm.normalUpload ? '' : 'pa-4'
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_vm.showImg && !_vm.showCroppie ? _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    class: _vm.profile ? 'my-4' : '',
    attrs: {
      "src": _vm.cropped,
      "width": _vm.getViewPort.width,
      "height": _vm.getViewPort.height,
      "contain": ""
    }
  }), !_vm.normalUpload ? _c('v-textarea', {
    staticClass: "mt-2",
    attrs: {
      "label": "Notes",
      "filled": "",
      "row": "2",
      "placeholder": "Enter note for this attachment"
    },
    model: {
      value: _vm.attachmentNote,
      callback: function callback($$v) {
        _vm.attachmentNote = $$v;
      },
      expression: "attachmentNote"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.showCroppie ? _c('v-col', {
    staticClass: "my-10 pa-0",
    attrs: {
      "cols": "10"
    }
  }, [_c('vue-croppie', {
    ref: "croppieRef",
    attrs: {
      "enableResize": _vm.enableResize,
      "viewport": _vm.getViewPort,
      "enforceBoundary": "",
      "boundary": _vm.getViewPort
    },
    on: {
      "result": _vm.result
    }
  })], 1) : _vm._e(), !_vm.image && !_vm.showCroppie ? _c('v-col', {
    staticClass: "dottedBoarder my-10 text-center cursor-pointer",
    attrs: {
      "cols": "8"
    },
    on: {
      "click": _vm.pickFile
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "rounded": "",
      "color": "accent"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-plus')
    }
  }), _vm._v(" " + _vm._s(_vm.normalUpload ? "Select Image" : "Select attachments") + " ")], 1)], 1) : _vm._e()], 1)], 1), _c('v-card-actions', {
    staticClass: "primaryLight"
  }, [_c('v-spacer'), _c('input', {
    ref: "image",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": _vm.acceptedType
    },
    on: {
      "change": _vm.upload
    }
  }), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" Cancel ")]), _vm.image ? _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": _vm.reset
    }
  }, [_vm._v(" Reset ")]) : _vm._e(), !_vm.cropStatus && _vm.showCroppie ? _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "data-cy": "btn-crop-image",
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark,
      "depressed": ""
    },
    on: {
      "click": _vm.crop
    }
  }, [_vm._v(" Crop ")]) : _vm._e(), _vm.cropped ? _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "data-cy": "btn-update-image",
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark,
      "depressed": ""
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v(" " + _vm._s(_vm.fromCreate ? "Select" : "Upload") + " ")]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }