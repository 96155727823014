var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "wrap": ""
    }
  }, [_c('v-col', {
    attrs: {
      "id": "storage-select-box",
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    ref: "drop",
    staticClass: "tag-input",
    attrs: {
      "clearable": _vm.multiple,
      "deletable-chips": _vm.multiple,
      "hide-selected": _vm.searchItems.length > 1,
      "items": _vm.searchItems.filter(function (x) {
        return x.id !== _vm.hideAppId;
      }),
      "label": _vm.multiple ? _vm.label + '(s) ' : _vm.label + '',
      "loading": _vm.loading,
      "multiple": _vm.multiple,
      "placeholder": _vm.label,
      "search-input": _vm.search,
      "append-icon": "mdi-chevron-down",
      "dense": "",
      "filled": "",
      "hide-details": "",
      "item-text": "name",
      "item-value": "id",
      "return-object": "",
      "small-chips": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "blur": function blur($event) {
        return _vm.$emit('blur');
      },
      "change": function change($event) {
        return _vm.$emit('change');
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-row', {
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-list-item-title', {
          attrs: {
            "id": "data-item"
          },
          domProps: {
            "textContent": _vm._s(data && data.item ? data.item.name : '')
          }
        })], 1), _c('v-col', {
          staticClass: "text-right"
        }, [_c('v-tooltip', {
          attrs: {
            "bottom": "",
            "transition": "slide-y-transition"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-icon', _vm._g({
                attrs: {
                  "color": data && data.item.public ? 'primary' : 'success',
                  "small": ""
                },
                domProps: {
                  "textContent": _vm._s(data && data.item.public ? 'mdi-lock-open-outline' : 'mdi-lock-outline')
                }
              }, on))];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(data && data.item.public ? "Can be accessed publicly" : "Accessed by your team members only") + " ")])]), data && data.item && data.item.storage ? _c('span', {
          staticClass: "ml-2"
        }, [_c('v-tooltip', {
          attrs: {
            "bottom": "",
            "transition": "slide-y-transition"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "color": "primary",
                  "icon": "",
                  "large": ""
                }
              }, 'v-btn', attrs, false), on), [_c('img', {
                attrs: {
                  "src": data.item.storage.icon ? _vm.getStorageImg(data.item.storage.icon) : 'testapp.io',
                  "height": "24"
                }
              })])];
            }
          }], null, true)
        }, [data.item.storage ? _c('span', [_c('div', [_vm._v("Storage")]), _vm._v(" " + _vm._s(data.item.storage.name || "testapp.io") + " ")]) : _vm._e()])], 1) : _vm._e()], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.selectedData,
      callback: function callback($$v) {
        _vm.selectedData = $$v;
      },
      expression: "selectedData"
    }
  }, [_c('template', {
    slot: "no-data"
  }, [_c('div', [_vm.loading ? _c('div', {
    staticClass: "d-flex align-center justify-center pa-4"
  }, [_c('ContentLoader', {
    attrs: {
      "size": "25"
    }
  })], 1) : _vm._e()])])], 2)], 1)], 1), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    model: {
      value: _vm.openAppForm,
      callback: function callback($$v) {
        _vm.openAppForm = $$v;
      },
      expression: "openAppForm"
    }
  }, [_c('template', {
    slot: "message"
  }, [_c('v-card-title', {
    staticClass: "display-1 justify-center mb-4"
  }, [_vm._v(" Add App ")]), _c('v-card-text', {
    staticClass: "px-0"
  }, [_vm.openAppForm ? _c('create-app-step', {
    attrs: {
      "new-app-name": _vm.newAppName,
      "is-auto-complete": ""
    },
    on: {
      "close": function close($event) {
        _vm.openAppForm = false;
      },
      "goNext": _vm.afterCreateApp
    }
  }) : _vm._e()], 1)], 1)], 2), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.mdAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 900 : '',
      "no-py": ""
    },
    on: {
      "close": function close($event) {
        _vm.openStartTrialPopup = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm.openStartTrialPopup ? _c('start-trial-modal', {
          attrs: {
            "message": 'Your team has reached the maximum number of apps',
            "tracker-event": _vm.trackerEvent,
            "from-apps-page": "",
            "trigger-from": "appProject"
          },
          on: {
            "close": function close($event) {
              _vm.openStartTrialPopup = false;
            },
            "open-subscribe": function openSubscribe($event) {
              _vm.openSubscriptionModal = true;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openStartTrialPopup,
      callback: function callback($$v) {
        _vm.openStartTrialPopup = $$v;
      },
      expression: "openStartTrialPopup"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }