<template>
  <div class="text-left">
    <v-btn
      id="createReleaseBtn"
      :disabled="disabled"
      :outlined="$vuetify.theme.dark"
      class="main"
      color="primary"
      @click="drawer = true"
    >
      <v-icon left>layers</v-icon>
      New release
    </v-btn>
    <SidePanel v-model="drawer" width="740" @close="$emit('close')">
      <create-release-step
        v-if="drawer"
        :app-info="appInfo"
        @close="(drawer = false), $emit('close')"
        @releaseSuccess="$emit('releaseSuccess', $event)"
        @socketReconnect="$emit('socketReconnect')"
      />
    </SidePanel>
  </div>
</template>

<script>
import CreateReleaseStep from "@/view/components/Releases/CreateRelease";
import { mapMutations } from "vuex";

export default {
  name: "create-release",
  props: {
    appInfo: {
      type: Object,
      default() {
        return null;
      },
    },
    click: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  components: {
    CreateReleaseStep,
  },
  data() {
    return {
      checkbox: null,
      drawer: false,
      selected: null,
      app: {},
      form: {
        note: "",
        androidNote: "",
        iosNote: "",
      },
      apk: null,
      ipa: null,
      apkUDID: null,
      ipaUDID: null,
      iosReleaseLoader: false,
      androidReleaseLoader: false,
      busy: false,
      notify: true,
      agree: null,
      oneReleaseNote: false,
      icon: "",
      releaseInfo: null,
      preLoadingIOS: false,
      preLoadingAndroid: false,
    };
  },
  watch: {
    // this is for navigation drawer to remove the scroll when it open
    drawer: function (v) {
      if (v) {
        // document.querySelector("body, html").style.overflow = "hidden";
      } else {
        // document.querySelector("body, html").style.overflow = "auto";
        // this.$emit("onClose");
      }
      this.app = {};
      // this.resetUpload();
      if (this.appInfo) {
        this.app = { ...this.appInfo };
      }

      this.apk = null;
      this.ipa = null;
    },
    //
    appInfo: function (val) {
      if (val) {
        this.app = this.appInfo;
      }
    },
    click: function (v) {
      this.drawer = v;
    },
  },
  mounted() {
    if (this.appInfo) {
      this.app = this.appInfo;
    }
  },
  methods: {
    ...mapMutations({
      resetUpload: "resetUpload",
    }),
    closeForm() {
      this.$emit("close");
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss"></style>