<template>
  <v-card :class="this.$style.widget" outlined>
    <slot v-if="!canBeManaged" name="widget-no-permission"></slot>

    <template v-else>
      <slot v-if="loading" name="widget-loading"></slot>

      <template v-else>
        <slot v-if="!isEmpty" name="widget-content"></slot>

        <slot v-else name="widget-no-data"></slot>
      </template>
    </template>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    canBeManaged: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module scoped>
.widget {
  padding-left: 0;
  padding-right: 0;
}
</style>
