<template>
  <v-row>
    <v-col v-if="fromModal" cols="12">
      <v-expansion-panels flat outlined>
        <v-expansion-panel v-for="(item, index) in faqList" :key="index">
          <v-expansion-panel-header class="py-2 px-4">
            <div class="subTitle darkGrey--text">
              {{ item.title }}
            </div>
            <template #actions>
              <v-icon left large class="ml-4">expand_more</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              class="darkGrey--text bodyFont"
              v-html="item.description"
            ></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col v-else cols="12">
      <v-row>
        <v-col v-for="(item, index) in faqList" :key="index" cols="12" md="6">
          <v-row align="center">
            <v-col cols="auto">
              <v-avatar>
                <v-icon
                  color="primary"
                  v-text="'mdi-file-document-outline'"
                ></v-icon>
              </v-avatar>
            </v-col>
            <v-col>
              <div class="font-size-h3 bold-text">
                {{ item.title }}
              </div>
              <div v-html="item.description"></div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    fromModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      faqList: [
        {
          title: "How do you ensure payment security?",
          description: `We prioritize your security. Our payment gateway, Paddle.com, employs bank-grade encryption to safeguard your transactions. Additionally, we never retain your payment details on our servers.`,
        },
        {
          title: "What does the TestApp.io Happiness Guarantee entail?",
          description:
            "Your satisfaction is paramount. If our service doesn't meet your expectations, we offer a 100% money-back guarantee. Simply reach out, and we'll process your refund.",
        },
        {
          title: "Do you offer an affiliate program?",
          description: `Yes, we do! By joining our affiliate program, you can earn money by referring new customers to TestApp.io. Contact us to learn more about the benefits and how to get started.`,
        },
        {
          title: "Are there any concessions for students or non-profits?",
          description:
            "Yes, we're committed to accessibility. We provide special discounts for students, non-profits, and those with financial constraints. Please contact us for more details.",
        },
        {
          title: "What's the maximum allowable app size?",
          description: `The upload limit is set by the size of the APK (Android) and IPA (iOS) files. If your app exceeds this, consider upgrading or reach out for a custom size add-on.`,
        },
        {
          title: "How can I transfer team ownership?",
          description: `If you're the team owner, transferring ownership is straightforward. Simply assign another team member as the new owner.`,
        },
        {
          title: "What if I run out of storage space?",
          description: `No worries! You can either archive older releases to free up space or contact us for additional storage add-ons.`,
        },
        {
          title: "Can I pause my subscription?",
          description: `Absolutely! If you need a break or wish to temporarily halt your subscription, just let us know. You can resume whenever you're ready.`,
        },
        {
          title: "How do I terminate my subscription?",
          description: `You can cancel your subscription anytime. The changes will take effect from the next billing cycle.`,
        },
        {
          title: "Which payment methods do you accept?",
          description: `We support a variety of payment options including Credit/Debit Cards, PayPal, Google Pay and Apple Pay.`,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
