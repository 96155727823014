var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.fromTransferOwnership ? _c('div', {
    staticClass: "mt-4"
  }, [!_vm.inviteImportant ? _c('v-checkbox', {
    attrs: {
      "readonly": _vm.inviteImportant,
      "messages": _vm.inviteImportant ? '' : 'It can be done later',
      "hide-details": ""
    },
    model: {
      value: _vm.invite,
      callback: function callback($$v) {
        _vm.invite = $$v;
      },
      expression: "invite"
    }
  }, [_c('template', {
    slot: "label"
  }, [_c('div', [_vm._v("Add members to this app")])])], 2) : _c('div', {
    staticClass: "bold-text py-2"
  }, [_vm._v("Add members to this app")]), _vm.invite ? _c('v-radio-group', {
    attrs: {
      "disabled": !_vm.app && _vm.addMember
    },
    model: {
      value: _vm.radioGroup,
      callback: function callback($$v) {
        _vm.radioGroup = $$v;
      },
      expression: "radioGroup"
    }
  }, [_c('v-radio', {
    attrs: {
      "value": "Current",
      "label": "All team members"
    }
  }), _c('v-radio', {
    attrs: {
      "value": "Specific",
      "label": "Specific team members"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.fromTransferOwnership || _vm.invite && _vm.radioGroup == 'Specific' ? _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max_length',
      expression: "'max_length'"
    }],
    staticClass: "tag-input",
    attrs: {
      "multiple": !_vm.single,
      "attach": "",
      "items": _vm.showItems,
      "label": "Team members",
      "item-value": "id",
      "item-text": "name",
      "placeholder": "Select team members",
      "data-cy": "add-member",
      "append-icon": "mdi-chevron-down",
      "chips": "",
      "counter": "20",
      "loading": _vm.loading,
      "deletable-chips": "",
      "small-chips": "",
      "return-object": "",
      "search-input": _vm.search,
      "filled": ""
    },
    on: {
      "focusin": _vm.fetchData,
      "update:searchInput": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "change": function change($event) {
        return _vm.$emit('change');
      },
      "blur": function blur($event) {
        return _vm.$emit('blur');
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [data && data.item ? _c('v-row', {
          attrs: {
            "align": "center",
            "no-gutters": ""
          }
        }, [_c('v-col', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-list-item-title', {
          attrs: {
            "id": "data-item"
          }
        }, [_vm._v(" " + _vm._s(data.item.name) + " ")])], 1), _c('v-col', [_c('div', {
          staticClass: "caption text-right"
        }, [_vm._v(" " + _vm._s(data.item.role.name) + " ")])])], 1) : _vm._e()];
      }
    }], null, false, 3379046163),
    model: {
      value: _vm.selectedData,
      callback: function callback($$v) {
        _vm.selectedData = $$v;
      },
      expression: "selectedData"
    }
  }, 'v-autocomplete', _vm.veeValidate('Members', ''), false), [_c('template', {
    slot: "no-data"
  }, [_c('div', {
    staticClass: "d-flex align-center justify-center pa-4"
  }, [_vm.loading ? _c('ContentLoader', {
    attrs: {
      "size": "25"
    }
  }) : _c('div', [_vm._v("All team members are already added to this app ")])], 1)])], 2)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }