var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    style: _vm.height ? "height: ".concat(_vm.height, "px") : 'height:100%',
    attrs: {
      "id": "loader"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": _vm.size,
      "color": _vm.color,
      "width": _vm.width
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }