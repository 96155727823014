var render = function render(){
  var _vm$permissions;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [!_vm.fromReleasePage ? _c('v-col', {
    class: _vm.$vuetify.theme.dark ? '' : 'lightBackground',
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" App include the following permissions ")]) : _vm._e(), (_vm$permissions = _vm.permissions) !== null && _vm$permissions !== void 0 && _vm$permissions.length ? [_vm._l(_vm.permissionLists, function (category, index) {
    return [category.permissions && category.permissions.length ? _c('v-col', {
      key: index,
      class: _vm.$vuetify.theme.dark || _vm.fromReleasePage ? '' : 'lightBackground',
      attrs: {
        "cols": "12"
      }
    }, [_c('div', [_c('v-icon', {
      attrs: {
        "color": "darkGrey",
        "left": "",
        "size": "20"
      },
      domProps: {
        "textContent": _vm._s(category.icon)
      }
    }), _c('span', [_vm._v(" " + _vm._s(category.title) + " ")])], 1), _vm._l(category.permissions, function (item, pindex) {
      return _c('ul', {
        key: pindex,
        staticClass: "ml-4"
      }, [_c('li', [_vm._v(" " + _vm._s(item) + " ")])]);
    })], 2) : _vm._e()];
  })] : _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('no-data', {
    attrs: {
      "first-text": "No permissions for this release"
    }
  })], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }