<template>
  <widget
    :can-be-managed="canManage"
    :is-empty="sessions.length === 0"
    :loading="sessionsLoading"
  >
    <template v-slot:widget-loading>
      <v-row align="center">
        <v-col cols="6">
          <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        </v-col>
        <v-col cols="6" style="display: flex; justify-content: flex-end">
          <v-skeleton-loader
            style="margin-right: 16px"
            type="button"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-skeleton-loader
            v-for="i in 5"
            :key="i"
            :loading="true"
            class="transparent"
            type="list-item-avatar-two-line"
          />
        </v-col>
      </v-row>
    </template>

    <template v-slot:widget-content>
      <v-card-title>
        <v-row align="center">
          <v-col cols="6">
            <div class="card-label bold-text text-dark">
              {{ text.widgetName }}
            </div>
          </v-col>
          <v-col v-if="sessions.length > 0" cols="6" style="text-align: end">
            <v-btn
              :outlined="$vuetify.theme.dark"
              color="primary"
              @click="onViewAll"
              >{{ text.viewAll }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="sessions"
        disable-sort
        hide-default-footer
        mobile-breakpoint="0"
        @click:row="handleNavigation"
      >
        <template #item.device.model="{ item }">
          <v-icon
            class="mr-1"
            size="24"
            v-text="
              item.device.name === 'Android' ? 'mdi-android' : 'mdi-apple'
            "
          />
          <span> {{ item.device.model }}</span>
        </template>
        <template #item.application="{ item }">
          <div class="text-left py-2">
            <div>{{ item?.application?.name }}</div>
            <MaxText
              max="25"
              :text="`${item?.application?.version} (${item?.application?.version_code})`"
            ></MaxText>
          </div>
        </template>
        <template #item.stats.errors="{ item }">
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <v-icon
              :class="item.stats.errors > 0 ? 'error--text' : ''"
              class="mr-1"
              size="24"
              >mdi mdi-alert-circle
            </v-icon>
            <span :class="item.stats.errors > 0 ? 'error--text' : ''">
              {{ item?.stats?.errors ?? 0 | formatNumber }}
            </span>
          </div>
        </template>
        <template #item.feedback="{ item }">
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            {{ item?.stats?.feedback ?? 0 }}
          </div>
        </template>
        <template #item.stats="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                class="primary--text"
                icon
                v-bind="attrs"
                v-on="on"
                @click.stop="handleNavigation"
              >
                {{
                  [item?.stats?.screens, item?.stats.events]
                    | sum
                    | formatNumber
                }}
              </v-btn>
            </template>
            <ul>
              <li>Screens: {{ item?.stats?.screens ?? 0 | formatNumber }}</li>
              <li>Events: {{ item?.stats.events ?? 0 | formatNumber }}</li>
            </ul>
          </v-tooltip>
        </template>
        <template #item.start_at="{ item }">
          <span :class="item.status == 1 ? 'success--text' : null">{{
            getDuration(item.start_at, item.end_at)
          }}</span>
        </template>
        <template #item.status="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                :class="item.status == 1 ? 'success--text' : null"
                class="mr-1"
                size="24"
                >mdi mdi-timer
              </v-icon>
            </template>
            <span>
              {{ item.status == 1 ? "Active" : "Expired" }}
            </span>
          </v-tooltip>
        </template>

        <template #item.navigate="{ item }">
          <div class="flex-row jc-c">
            <v-btn icon @click.stop="handleNavigation(item)">
              <i class="arrow-right"></i>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </template>

    <template v-slot:widget-no-permission>
      <v-card-title>
        {{ text.widgetName }}
      </v-card-title>
      <no-data
        :first-text="text.noData"
        :second-text="text.noPermission"
      ></no-data>
    </template>

    <template v-slot:widget-no-data>
      <v-card-title>
        {{ text.widgetName }}
      </v-card-title>
      <no-data
        :first-text="text.noData"
        :second-text="text.noDataSecond"
      ></no-data>
    </template>
  </widget>
</template>

<script>
import Widget from "@/view/components/Common/Widget.vue";
import { getDuration } from "@/core/services/helper.service";

const headers = [
  {
    text: "Application",
    value: "application",
    align: "center",
    width: 140,
  },
  {
    text: "Device",
    align: "start",
    value: "device.model",
    width: 0,
    cellClass: "no-wrap",
  },
  {
    text: "Errors",
    value: "stats.errors",
    align: "center",
    width: 140,
  },
  {
    text: "Feedback",
    value: "feedback",
    align: "center",
    width: 140,
  },
  {
    text: "Duration",
    align: "start",
    value: "start_at",
    width: 0,
    cellClass: "no-wrap",
  },
  {
    text: "Status",
    align: "start",
    value: "status",
    width: 0,
    cellClass: "no-wrap",
  },
  {
    text: "",
    align: "center",
    sortable: false,
    width: "100",
    value: "navigate",
  },
];

export default {
  components: { Widget },
  props: {
    sessions: {
      type: Array,
      default: () => [],
    },
    totalSessionsCount: {
      type: Number,
      default: 0,
    },
    sessionsLoading: {
      type: Boolean,
      default: false,
    },
    canManage: {
      type: Boolean,
      default: false,
    },
    onViewAll: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      headers,
      text: {
        viewAll: "View all",
        widgetName: "Recent Sessions",
        noData: "Capture 🐞 Faster",
        noPermission: `You don't have the permission to view or manage sessions.`,
        noDataSecond: "Collect feedback from your testers with ease",
        noDataSecond:
          "Looks like there are no sessions yet. Start capturing user interactions to gain insights and understand their journey within your app",
      },
    };
  },
  methods: {
    getDuration,
    handleNavigation(item) {
      this.$router.push({
        name: "sessions-detail",
        params: {
          app_id: item.app_id,
          session_id: item.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
