<template>
  <v-card flat box>
    <v-card-title>
      <v-spacer></v-spacer>
      <div class="text-center">
        <div class="pt-5">
          {{ title || "Upload Image" }}
        </div>
        <div class="text-caption text-center">
          {{ subTitle || "" }}
        </div>
      </div>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text :class="normalUpload ? '' : 'pa-4'">
      <v-row justify="center">
        <v-col cols="10" v-if="showImg && !showCroppie">
          <v-img
            :class="profile ? 'my-4' : ''"
            :src="cropped"
            :width="getViewPort.width"
            :height="getViewPort.height"
            class="mx-auto"
            contain
          ></v-img>
          <v-textarea
            v-if="!normalUpload"
            label="Notes"
            filled
            class="mt-2"
            row="2"
            placeholder="Enter note for this attachment"
            v-model="attachmentNote"
          >
          </v-textarea>
        </v-col>
        <v-col v-if="showCroppie" cols="10" class="my-10 pa-0">
          <vue-croppie
            ref="croppieRef"
            :enableResize="enableResize"
            :viewport="getViewPort"
            enforceBoundary
            :boundary="getViewPort"
            @result="result"
          ></vue-croppie>
        </v-col>
        <v-col
          v-if="!image && !showCroppie"
          @click="pickFile"
          cols="8"
          class="dottedBoarder my-10 text-center cursor-pointer"
        >
          <v-btn rounded color="accent" class="text-transform-none">
            <v-icon v-text="'mdi-plus'" left></v-icon>
            {{ normalUpload ? "Select Image" : "Select attachments" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="primaryLight">
      <v-spacer></v-spacer>
      <input
        ref="image"
        type="file"
        class="d-none"
        :accept="acceptedType"
        @change="upload"
      />
      <v-btn class="text-transform-none" color="primary" text @click="close">
        Cancel
      </v-btn>
      <v-btn
        v-if="image"
        text
        color="error"
        class="text-transform-none"
        @click="reset"
      >
        Reset
      </v-btn>
      <v-btn
        v-if="!cropStatus && showCroppie"
        data-cy="btn-crop-image"
        color="primary"
        :outlined="$vuetify.theme.dark"
        class="text-transform-none"
        @click="crop"
        depressed
      >
        Crop
      </v-btn>
      <v-btn
        v-if="cropped"
        data-cy="btn-update-image"
        color="primary"
        :outlined="$vuetify.theme.dark"
        class="text-transform-none"
        depressed
        @click="update"
      >
        {{ fromCreate ? "Select" : "Upload" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import Vue from "vue";
import VueCroppie from "vue-croppie";
Vue.use(VueCroppie);
import "croppie/croppie.css";
export default {
  name: "image-upload",
  props: {
    cover: {
      type: Boolean,
      default: false,
    },
    fromCreate: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: Boolean,
      default: false,
    },
    // Temporary fix carried out for overlay issue when v-dialog is present inside another v-dialog
    hideOverlay: {
      type: Boolean,
      default: false,
    },
    viewPort: {
      type: Object,
      default() {
        return {
          width: 200,
          height: 200,
        };
      },
    },
    optionsSize: {
      type: String,
      default: "original",
    },
    title: {
      type: String,
      default: "",
    },
    acceptedType: {
      type: String,
      default: ".jpg,.png",
    },
    subTitle: {
      type: String,
      default: "",
    },
    normalUpload: {
      type: Boolean,
      default: true,
    },
    enableResize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: true,
      image: "",
      showCroppie: false,
      cropped: "",
      cropStatus: false,
      showImg: false,
      attachmentNote: "",
      fileType: "",
      fileName: "",
      fileSize: "",
      options: {
        format: "png",
        circle: false,
        size: this.optionsSize,
      },
      checkbox: true,
    };
  },
  computed: {
    getViewPort() {
      if (this.cover) {
        return { width: 100, height: 100, type: "square" };
      } else if (this.profile) {
        return { width: 120, height: 120, type: "circle" };
      } else {
        return {
          width: this.viewPort.width,
          height: this.viewPort.height,
          type: "square",
        };
      }
    },
  },
  methods: {
    pickFile() {
      this.$refs.image.click();
    },
    upload(file) {
      if (file.size > 150000000) {
        this.notifyErrorMessage("File size is over 150 MB.");
        return;
      }
      let videoSrc = "";
      if (["mp4"].includes(file.target.files[0]?.name.split(".").pop())) {
        let reader = new FileReader();
        let self = this;
        reader.readAsDataURL(file.target.files[0]);
        reader.addEventListener("load", function() {
          videoSrc = reader.result;
          self.$emit("cropped-image", {});
          self.$emit("base64-data", {
            id: "new-id" + file.target.files[0].size,
            type: file.target.files[0]?.name?.split(".").pop(),
            file: file.target.files[0],
            src: videoSrc,
            name: file.target.files[0]?.name,
            size: file.target.files[0].size,
            ext: file.target.files[0].name.split(".").pop(),
            note: this.attachmentNote,
          });
        });
        this.dialog = false;
        this.reset();
      } else {
        if (file.target.files[0]?.name.split(".").pop() !== "svg") {
          const reader = new FileReader();
          this.fileName = file.target.files[0].name;
          this.fileType = file.target.files[0].type;
          (this.fileSize = file.target.files[0].size),
            reader.readAsDataURL(file.target.files[0]);
          this.showCroppie = true;
          reader.onload = () => {
            this.$refs.croppieRef.bind({
              url: reader.result,
            });
            this.image = reader.result;
            file.target.value = null;
          };
        } else {
          this.notifyErrorMessage("Svg images are not allowed");
        }
      }
    },
    crop() {
      this.$refs.croppieRef.result(this.options, (output) => {
        this.cropped = output;
      });
      this.showCroppie = false;
      this.cropStatus = false;
      this.showImg = true;
    },
    close() {
      this.dialog = false;
      this.$emit("close");
      this.reset();
    },
    update() {
      if (this.normalUpload) {
        this.$emit("cropped-image", this.dataURLtoBlob(this.cropped));
        this.$emit("base64-data", this.dataURLtoBlob(this.cropped));
      } else {
        this.$emit("cropped-image", {
          id: "new-id" + this.fileSize,
          type: this.fileType,
          file: this.dataURLtoBlob(this.cropped),
          note: this.attachmentNote,
          ext: this.fileType,
          name: this.fileName,
          size: this.fileSize,
        });
        this.$emit("base64-data", {
          id: "new-id" + this.fileSize,
          file: this,
          note: this.attachmentNote,
          ext: this.fileType,
          size: this.fileSize,
          src: this.cropped,
          type: this.fileType,
        });
      }
      this.$emit("input", this.dataURLtoBlob(this.cropped));
      this.$emit("close");
      this.dialog = false;
      this.reset();
    },
    dataURLtoBlob(dataurl) {
      const arr = dataurl.split(",");
      const mime = arr[0] ? arr[0].match(/:(.*?);/)[1] : "";
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const blob = new Blob([u8arr], { type: mime });
      blob.name = this.fileName;
      return blob;
    },
    result(output) {
      this.cropped = output;
    },
    reset() {
      this.cropped = null;
      this.cropStatus = false;
      this.showImg = false;
      this.showCroppie = false;
      this.image = "";
      this.fileName = "";
    },
  },
};
</script>
<style scoped>
.profile-picture {
  border-radius: 100%;
}
.dottedBoarder {
  border: 1px dashed #878787;
  box-sizing: border-box;
  border-radius: 16px;
}
</style>
