var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "color": "background"
    }
  }, [_c('v-app-bar', {
    attrs: {
      "flat": !_vm.$vuetify.theme.dark,
      "color": "secondary",
      "dark": "",
      "height": "80",
      "max-height": "80"
    }
  }, [_c('v-img', {
    staticClass: "text-left",
    attrs: {
      "src": '/media/logos/logo.svg',
      "contain": "",
      "height": "50"
    }
  }), _c('v-spacer'), _c('v-spacer')], 1), _c('v-card-text', {
    staticClass: "mt-2"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-row', {
    staticClass: "darkGrey--text",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "pt-5"
  }, [_c('v-col', {
    staticClass: "subTitle darkGrey--text",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.getTeamName) + " "), _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "nudge-bottom": "20"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({
          staticClass: "mr-4 pointer font12 primary--text"
        }, on), [_c('v-icon', {
          attrs: {
            "color": "primary",
            "small": ""
          }
        }, [_vm._v(" mdi-swap-horizontal ")]), _vm._v(" Switch teams ")], 1)];
      }
    }])
  }, [_c('team-list', {
    attrs: {
      "from-menu": ""
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "text-right"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-close')
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "background"
  }, [_c('div', {
    staticClass: "titleFont darkGrey--text"
  }, [_vm._v(" Upgrade your team to " + _vm._s(_vm.selectedPlan.name) + " plan ")])]), _c('v-col', {
    staticClass: "background",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _vm.loading ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "card"
    }
  })], 1) : _vm._e(), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading,
      expression: "!loading"
    }],
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "background"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-timeline', {
    attrs: {
      "align-top": "",
      "clipped": "",
      "dense": ""
    }
  }, [_c('v-timeline-item', {
    attrs: {
      "color": "background",
      "icon": "mdi-clock",
      "icon-color": "darkGrey",
      "small": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "bodyFont darkGrey--text mb-3"
  }, [_vm._v(" How often you want to be billed? ")]), _c('div', {
    staticClass: "text-muted"
  }, [_vm.billingType === 'annually' ? _c('span', [_vm._v(" Thank you for choosing yearly! We love you too ❤️")]) : _c('span', [_vm._v(" Show us some love and go yearly! ")])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-transform-none mr-1",
    attrs: {
      "color": _vm.billingType === 'annually' ? 'primary' : 'darkGrey',
      "disabled": _vm.loading,
      "outlined": "",
      "small": "",
      "value": "annually"
    },
    on: {
      "click": function click($event) {
        _vm.billingType = 'annually';
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.billingType === 'annually' ? 'primary' : 'grey',
      "left": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.billingType === 'annually' ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank')
    }
  }), _c('span', {
    staticClass: "smallFont darkGrey--text",
    class: _vm.billingType === 'annually' ? 'semi-bold' : ''
  }, [_vm._v(" Pay yearly (save 30% or nearly "), _c('b', [_vm._v("4 months")]), _vm._v(" free) ")])], 1), _c('span', {
    staticClass: "text-transform-none",
    attrs: {
      "color": _vm.billingType === 'monthly' ? 'primary' : 'darkGrey',
      "disabled": _vm.loading,
      "outlined": "",
      "small": "",
      "value": "monthly"
    },
    on: {
      "click": function click($event) {
        _vm.billingType = 'monthly';
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.billingType === 'monthly' ? 'primary' : 'grey',
      "left": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.billingType === 'monthly' ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank')
    }
  }), _c('span', {
    staticClass: "smallFont darkGrey--text",
    class: _vm.billingType === 'monthly' ? 'semi-bold' : ''
  }, [_vm._v(" Pay monthly ")])], 1)])], 1)], 1), _c('v-timeline-item', {
    attrs: {
      "color": "background",
      "icon": "mdi-credit-card",
      "icon-color": "darkGrey",
      "small": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "bodyFont darkGrey--text mb-3"
  }, [_vm._v(" Proceed with your payment ")]), _c('div', {
    staticClass: "text-muted"
  }, [_c('v-icon', {
    attrs: {
      "color": "green",
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-lock-outline ")]), _vm._v(" We never store your card information. We use Paddle.com to process your payment and they use bank-grade technology trusted by thousands of companies. ")], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.paddleLoading ? _c('div', {
    staticClass: "bodyFont"
  }, [_vm._v(" Loading payment information, please wait... "), _c('v-skeleton-loader', {
    attrs: {
      "type": "article"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "paddleContainer"
  })])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "bodyFont"
  }, [_vm._v(" " + _vm._s(_vm.selectedPlan.name) + " plan ")]), _c('v-col', {
    staticClass: "bodyFont"
  }, [_vm._v(" $" + _vm._s(_vm.getCurrentPrice) + " / " + _vm._s(_vm.billingType === "annually" ? "yearly" : "monthly") + " ")])], 1), !_vm.isEmpty(_vm.couponAppliedPrice) ? _c('v-row', [_c('v-col', {
    staticClass: "semi-bold"
  }, [_vm._v(" Coupon (Discount) ")]), _c('v-col', {
    staticClass: "bodyFont"
  }, [_vm._v(" $" + _vm._s(_vm.couponDiscount) + " ")])], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    staticClass: "bodyFont"
  }, [_vm._v(" Tax")]), _c('v-col', {
    staticClass: "bodyFont"
  }, [_vm.paddleLoading ? _c('div', [_vm._v("-")]) : _c('div', [_vm._v("$" + _vm._s(_vm.totalTax.toFixed(2)))])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "darkGrey--text"
  }, [_c('v-col', {
    staticClass: "titleFont bold-text"
  }, [_vm._v(" Due amount")]), _c('v-col', {
    staticClass: "bold-text titleFont"
  }, [_vm.paddleLoading ? _c('div', [_vm._v("-")]) : _c('div', [_vm._v(" $" + _vm._s(_vm.paddleLoading ? "-" : (_vm.getCurrentPrice + _vm.totalTax - _vm.couponDiscount).toFixed(2)) + " ")])])], 1)], 1)], 1), _c('v-row')], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1)], 1)], 1)], 1), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "py-4"
        }, [_c('v-col', {
          staticClass: "text-center text-h5",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Congratulations 🎉 ")]), _c('v-col', {
          staticClass: "text-center text-h6",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" We are processing the payment "), _c('br'), _vm._v(" and upgrading your plan. ")]), _c('v-col', {
          staticClass: "text-center",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-progress-circular', {
          attrs: {
            "color": "success",
            "indeterminate": ""
          }
        })], 1), _c('v-col', {
          staticClass: "text-center bodyFont",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" It should be done within few moments ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.successModal,
      callback: function callback($$v) {
        _vm.successModal = $$v;
      },
      expression: "successModal"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }