<template>
  <div>
    <v-row class="mt-4" wrap>
      <v-col id="storage-select-box" cols="12">
        <v-autocomplete
          ref="drop"
          v-model="selectedData"
          :clearable="multiple"
          :deletable-chips="multiple"
          :hide-selected="searchItems.length > 1"
          :items="searchItems.filter((x) => x.id !== hideAppId)"
          :label="multiple ? label + '(s) ' : label + ''"
          :loading="loading"
          :multiple="multiple"
          :placeholder="label"
          :search-input.sync="search"
          append-icon="mdi-chevron-down"
          dense
          class="tag-input"
          filled
          hide-details
          item-text="name"
          item-value="id"
          return-object
          small-chips
          @blur="$emit('blur')"
          @change="$emit('change')"
        >
          <template slot="item" slot-scope="data">
            <v-row no-gutters>
              <v-col class="d-flex align-center justify-center">
                <v-list-item-title
                  id="data-item"
                  v-text="data && data.item ? data.item.name : ''"
                />
              </v-col>
              <v-col class="text-right">
                <v-tooltip bottom transition="slide-y-transition">
                  <template #activator="{ on }">
                    <v-icon
                      :color="data && data.item.public ? 'primary' : 'success'"
                      small
                      v-on="on"
                      v-text="
                        data && data.item.public
                          ? 'mdi-lock-open-outline'
                          : 'mdi-lock-outline'
                      "
                    ></v-icon>
                  </template>
                  <span>
                    {{
                      data && data.item.public
                        ? "Can be accessed publicly"
                        : "Accessed by your team members only"
                    }}
                  </span>
                </v-tooltip>
                <span
                  v-if="data && data.item && data.item.storage"
                  class="ml-2"
                >
                  <v-tooltip bottom transition="slide-y-transition">
                    <template #activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        icon
                        large
                        v-bind="attrs"
                        v-on="on"
                      >
                        <img
                          :src="
                            data.item.storage.icon
                              ? getStorageImg(data.item.storage.icon)
                              : 'testapp.io'
                          "
                          height="24"
                        />
                      </v-btn>
                    </template>
                    <span v-if="data.item.storage">
                      <div>Storage</div>
                      {{ data.item.storage.name || "testapp.io" }}
                    </span>
                  </v-tooltip>
                </span>
              </v-col>
            </v-row>
          </template>
          <template slot="no-data">
            <div>
              <div
                v-if="loading"
                class="d-flex align-center justify-center pa-4"
              >
                <ContentLoader size="25" />
              </div>
              <!-- <v-list-item v-else @click="openApp" style="border:none">
                <span class="font-weight-regular mr-1">
                  oops no apps available,
                </span>
                <span class="primary--text">
                  let's create one now
                </span>
              </v-list-item> -->
            </div>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <Modal v-model="openAppForm" :fullscreen="$vuetify.breakpoint.smAndDown">
      <template slot="message">
        <v-card-title class="display-1 justify-center mb-4">
          Add App
        </v-card-title>
        <v-card-text class="px-0">
          <create-app-step
            v-if="openAppForm"
            :new-app-name="newAppName"
            is-auto-complete
            @close="openAppForm = false"
            @goNext="afterCreateApp"
          ></create-app-step>
        </v-card-text>
      </template>
    </Modal>
    <Modal
      v-model="openStartTrialPopup"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      :width="$vuetify.breakpoint.mdAndUp ? 900 : ''"
      no-py
      @close="openStartTrialPopup = false"
    >
      <template #message>
        <start-trial-modal
          v-if="openStartTrialPopup"
          :message="'Your team has reached the maximum number of apps'"
          :tracker-event="trackerEvent"
          from-apps-page
          trigger-from="appProject"
          @close="openStartTrialPopup = false"
          @open-subscribe="openSubscriptionModal = true"
        ></start-trial-modal>
      </template>
    </Modal>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
// import { isEmpty } from "@/core/services/helper.service";
import CreateAppStep from "@/view/components/App/CreateAppStep.vue";
import { CHECK_APP } from "@/store/apps/apps.module";
import StartTrialModal from "@/view/components/Common/StartTrialModal";

export default {
  components: { StartTrialModal, CreateAppStep },
  props: {
    noChips: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String, Array, Object],
      default: undefined,
    },
    fromAppSearch: {
      type: Boolean,
      default: false,
    },
    default: {
      type: [Object, Array, String],
      required: false,
      default: null,
    },
    integrationSubId: {
      type: String,
      default: "",
    },
    focus: {
      type: Boolean,
      default: false,
    },
    initialData: {
      type: [Array, Object, String],
      default() {
        return "";
      },
    },
    label: {
      type: String,
      default: "Select App",
    },
    memberId: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    addApp: {
      type: Boolean,
      default: false,
    },
    isGetStarted: {
      type: Boolean,
    },
    hideAppId: {
      type: String,
      default: "",
    },
    selectFistItem: {
      type: Boolean,
      default: false,
    },
    currentApp: {
      type: [Object, Boolean],
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      selectedData: "",
      search: "",
      searchItems: [],
      loading: false,
      openStartTrialPopup: false,
      newAppName: "",
      members: null,
      busy: false,
      setMessage: false,
      openAppForm: false,
      invite: null,
      current: null,
    };
  },
  computed: {
    ...mapGetters({
      items: "getSearchedApps",
      initialList: "getSearchAppsInitial",
      appDetail: "getAppInfo",
    }),
    showItems() {
      if (this.selectedData && this.selectedData.length) {
        return [...this.searchItems, ...this.selectedData];
      } else if (
        typeof this.selectedData === "object" &&
        Object.keys(this.selectedData).length
      ) {
        return [...this.searchItems, this.selectedData];
      } else {
        return this.searchItems;
      }
    },
  },
  watch: {
    focus(val, oldVal) {
      if (!val && oldVal) {
        this.setMessage = false;
      } else {
        this.setMessage = val;
      }
    },
    search(val) {
      if (val && val.length > 0) {
        this.newAppName = val;
        clearTimeout(this._timerId);
        this._timerId = setTimeout(() => {
          this.fetchData(val);
        }, 500);
      } else {
        this.searchItems = [...this.initialList];
      }
    },
    selectedData(val) {
      if (Array.isArray(val)) {
        this.setSearchAppsInitial([...this.initialList, ...val]);
      }
      if (val && typeof val === "object" && val.id) {
        this.initialList.push(val);
      }
      this.$emit("input", val ? val : {});
      this.search = "";
      this.$nextTick(() => {
        this.$emit("focus", false);
        this.setMessage = false;
      });
    },
    items(val) {
      this.searchItems = [...val];
    },
    initialList(val) {
      this.searchItems = [...val];
    },
  },
  created() {
    this.fetchData().then(() => {
      this.setMessage = this.focus;
      if (this.selectFistItem && this.initialList.length) {
        this.selectedData = this.initialList[0];
      }
      if (this.$route.query.appId) {
        this.selectedData = [{ name: "", id: this.$route.query.appId }];
      }
      if (this.$route.params.app_id) {
        this.selectedData = [{ name: "", id: this.$route.params.app_id }];
      }
      if (this.initialData) {
        this.selectedData = [...this.initialData];
      }
      if (this.$route.params && this.$route.params.app_id) {
        this.selectedData = {
          ...this.appDetail,
        };
      }
    });
  },
  methods: {
    ...mapMutations({
      setSearchAppsInitial: "setSearchAppsInitial",
    }),
    afterCreateApp($event) {
      this.searchItems = [];
      this.fetchData().then(() => {
        if (Array.isArray(this.selectedData)) {
          this.selectedData.push({ ...$event });
        } else {
          this.selectedData = $event;
        }
        this.openAppForm = false;
      });
    },
    openList() {
      const input = this.$refs.drop.$el.querySelector("input");
      input.click();
      this.notifyInfoMessage({
        message: "Select app for this release and try uploading file",
      });
      this.fetchData();
      if (this.initialList && !this.initialList.length) {
        this.openAppForm = true;
      }
    },
    clearFocus() {
      this.setMessage = false;
      this.$emit("focus", false);
    },
    checkApp() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(CHECK_APP)
        .then(() => {
          this.openAppForm = true;
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          if (err.status === 402) {
            this.openStartTrialPopup = true;
          } else {
            this.notifyErrorMessage(err.message);
          }
        });
    },
    remove(item) {
      if (this.selectedData instanceof Object) {
        const index = this.selectedData.indexOf(item.id);
        if (index >= 0) this.selectedData.splice(index, 1);
      } else {
        this.selectedData = null;
      }
    },
    fetchData(val) {
      this.clearFocus();
      if (this.loading) return;
      this.loading = true;
      let params = {
        member_id: this.addApp && this.memberId ? this.memberId : "",
        active: this.addApp ? false : true,
        query: val || "",
        integrationSubId: this.integrationSubId || "",
      };
      if (val) {
        delete params.page;
      }
      return this.$store
        .dispatch("searchApps", params)
        .then((data) => {
          this.loading = false;
          if (this.fromAppSearch) {
            this.selectedData = null;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
