<template>
  <widget
    :can-be-managed="true"
    :is-empty="releases.length === 0"
    :loading="dashboardLoader"
  >
    <template v-slot:widget-loading>
      <v-row align="center">
        <v-col cols="6">
          <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        </v-col>
        <v-col cols="6" style="display: flex; justify-content: flex-end">
          <v-skeleton-loader
            style="margin-right: 16px"
            type="button"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-skeleton-loader
            v-for="i in 5"
            :key="i"
            :loading="true"
            class="transparent"
            type="list-item-avatar-two-line"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:widget-content>
      <v-card-title>
        <div>
          <div class="card-label bold-text text-dark">Recent Releases</div>
        </div>
        <v-spacer />
        <div
          v-if="canManageRelease && !getInactiveUserMessage"
          class="card-toolbar"
        >
          <NewReleasePanel />
        </div>
      </v-card-title>
      <v-data-table
        :headers="releaseHeader"
        :items="releases"
        hide-default-footer
        mobile-breakpoint="0"
      >
        <template slot="item" slot-scope="props">
          <tr
            class="cursor-pointer"
            @click="
              $router.push({
                name: 'release-detail-page',
                params: {
                  app_id: props?.item?.app?.id,
                  release_id: props?.item?.id,
                },
              })
            "
          >
            <td class="py-2">
              <v-row align="center">
                <v-col cols="auto">
                  <AppImage
                    :alt="props?.item?.name"
                    :appId="props?.item?.id"
                    :image="props?.item?.image"
                  />
                </v-col>
                <v-col>
                  <a class="darkGrey--text bodyFont mb-1">
                    {{ get(props.item, "app.name", "") }}</a
                  >
                  <div>
                    <vue-hoverable-date
                      :date="props.item.created_at"
                    ></vue-hoverable-date>
                  </div>
                </v-col>
              </v-row>
            </td>
            <td>
              <PlatformIcon
                v-if="props.item.platform == 'android'"
                android
                class="platforms"
                color="primary"
                size="20"
              />
              <PlatformIcon
                v-if="props.item.platform == 'ios'"
                apple
                class="platforms"
                color="primary"
                size="20"
              />
            </td>
            <td>
              <user-avatar :user-detail="props?.item?.user"></user-avatar>
            </td>
            <td>
              <MaxText
                :text="
                  props.item.version
                    ? `v${props.item.version} (${props?.item?.version_code})`
                    : '-'
                "
                max="16"
              />
            </td>
            <td>
              {{ props?.item?.stats?.comments }}
            </td>
            <td>
              {{ props?.item?.stats?.installs }}
            </td>
            <td>
              <div class="flex-row jc-c">
                <v-btn icon>
                  <i class="arrow-right"></i>
                </v-btn>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>

    <template v-slot:widget-no-data>
      <v-card-title> Releases </v-card-title>
      <no-data
        align-class="text-left"
        btn-icon=""
        btn-text="New Releases"
        first-text="No Releases, Just Yet..."
        second-text="<ul><li><strong>1. Upload</strong>: Add your APK or IPA to notify team members and guests.</li><li><strong>2. Installation</strong>: The release can be installed via our app or the public install page.</li><li><strong>3. Automate</strong>: Streamline your process with <a href='https://help.testapp.io/topic/integrations/' target='_blank'>our integrations</a>. Automate release uploads, notifications, and more to save time and ensure consistency.</li></ul>"
      ></no-data>
    </template>
  </widget>
</template>

<script>
import NewReleasePanel from "@/view/components/Releases/NewReleasePanel.vue";
import { get, formatBytes } from "@/core/services/helper.service";
import AppImage from "@/view/components/App/Image";
import UserAvatar from "@/view/components/Common/UserAvatar";
import Widget from "../../../../components/Common/Widget.vue";

export default {
  props: {
    releases: {
      type: Array,
      default() {
        return [];
      },
    },
    dashboardLoader: {
      type: Boolean,
      default: false,
    },
    getInactiveUserMessage: {
      type: Boolean,
      default: false,
    },
    totalReleases: {
      type: Number,
      default: 0,
    },
  },
  components: { UserAvatar, AppImage, NewReleasePanel, Widget },
  data() {
    return {
      get,
      formatBytes,
      releaseHeader: [
        {
          text: "App",
          align: "start",
          sortable: false,
          width: "200",
          value: "app_name",
        },
        {
          text: "Platform",
          align: "start",
          sortable: false,
          width: "100",
          value: "platform",
        },
        {
          text: "Developer",
          align: "start",
          sortable: false,
          width: "100",
          value: "developer",
        },
        {
          text: "Version",
          align: "start",
          sortable: false,
          width: "100",
          value: "version",
        },
        {
          text: "Comments",
          align: "start",
          sortable: false,
          width: "100",
          value: "comments",
        },
        {
          text: "Installs",
          align: "start",
          sortable: false,
          width: "150",
          value: "installs",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          width: "50",
          value: "action",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
