<template>
  <feedbacks-widget
    :can-manage="canManageApp"
    :feedbacks="feedbacks"
    :feedbacks-loading="feedbackLoading"
    :on-view-all="handleViewAll"
    :total-feedback-count="totalFeedbackCount"
  />
</template>

<script>
import FeedbacksWidget from "@/view/components/Common/widgets/FeedbacksWidget.vue";

export default {
  props: {
    feedbacks: {
      type: Array,
      required: true,
    },
    feedbackLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FeedbacksWidget,
  },
  data() {
    return {
      totalFeedbackCount: 0,
    };
  },
  computed: {
    appId() {
      return this.$route.params.app_id;
    },
  },
  methods: {
    handleViewAll() {
      this.$router.push({
        name: "app-sessions-feedback",
        params: {
          app_id: this.appId,
        },
      });
    },
  },
  created() {},
};
</script>

<style lang="scss" module scoped>
.widget {
  padding-left: 0;
  padding-right: 0;
}
</style>
