var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mx-auto"
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "disabled": _vm.busy
    }
  }, [_c('v-card-text', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "mb-3 text-center",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('app-image-edit', {
    attrs: {
      "from-create": "",
      "app-id": _vm.appId
    },
    on: {
      "selectedFile": function selectedFile($event) {
        _vm.selectedImage = $event;
      }
    }
  })], 1)], 1), _vm.fromCreatePanel ? _c('storage-list', {
    attrs: {
      "id": "storageList"
    },
    on: {
      "fetch": _vm.storageLoad
    },
    model: {
      value: _vm.formValues.storage_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "storage_id", $$v);
      },
      expression: "formValues.storage_id"
    }
  }) : _vm._e(), _c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|max:27|min:3|name_field_validation',
      expression: "'required|max:27|min:3|name_field_validation'"
    }],
    attrs: {
      "id": "appName",
      "label": "App name",
      "counter": "27",
      "filled": "",
      "placeholder": "Your app name",
      "messages": "Can be renamed later"
    },
    model: {
      value: _vm.formValues.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "name", $$v);
      },
      expression: "formValues.name"
    }
  }, 'v-text-field', _vm.veeValidate('Name', ''), false)), _vm.fromCreatePanel ? _c('member-select', {
    attrs: {
      "app": null
    },
    on: {
      "isInvite": function isInvite($event) {
        _vm.inviteMember = $event;
      },
      "selected": function selected($event) {
        _vm.selectedOption = $event;
      }
    },
    model: {
      value: _vm.selectedMembers,
      callback: function callback($$v) {
        _vm.selectedMembers = $$v;
      },
      expression: "selectedMembers"
    }
  }) : _vm._e(), _vm.inviteMember ? _c('v-checkbox', {
    attrs: {
      "label": "Notify members by email",
      "value": false
    },
    model: {
      value: _vm.notify,
      callback: function callback($$v) {
        _vm.notify = $$v;
      },
      expression: "notify"
    }
  }) : _vm._e(), _c('v-divider', {
    staticClass: "mt-6 mb-3"
  }), _c('div', {
    staticClass: "bodyFont cursor-pointer py-3",
    on: {
      "click": function click($event) {
        _vm.showAppInformation = !_vm.showAppInformation;
      }
    }
  }, [_vm._v(" Additional Information "), _c('v-icon', {
    attrs: {
      "color": "primary",
      "right": ""
    }
  }, [_vm._v(_vm._s(_vm.showAppInformation ? "mdi-minus-circle" : "mdi-plus-circle"))])], 1), _vm.showAppInformation ? _c('app-information', {
    attrs: {
      "not-required": "",
      "from-create-app": ""
    },
    on: {
      "inputValue": function inputValue($event) {
        _vm.additionalInformation = $event;
      }
    }
  }) : _vm._e()], 1), _c('v-card-actions', [_vm.fromGetStarted ? _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('skip');
      }
    }
  }, [_vm._v(" Skip to the end ")]) : _vm._e(), _c('v-spacer'), !_vm.fromCreatePanel ? _c('v-btn', {
    staticClass: "text-transform-none py-7 px-15",
    attrs: {
      "loading": _vm.busy,
      "disabled": _vm.errors.any() || _vm.busy || !_vm.formValues.name || _vm.busyStorage,
      "id": "appSubmitButton",
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark,
      "depressed": "",
      "x-large": ""
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" " + _vm._s("Create") + " ")]) : _c('v-btn', {
    staticClass: "py-7 px-15 text-transform-none",
    attrs: {
      "disabled": _vm.errors.any() || _vm.busy || !_vm.formValues.name || _vm.busyStorage,
      "loading": _vm.busy,
      "id": "appSubmitButton",
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "x-large": ""
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_c('span', {
    staticClass: "font14"
  }, [_vm._v(" Create ")])]), !_vm.fromGetStarted ? _c('v-spacer') : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }