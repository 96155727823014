var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "storage-select-box"
    }
  }, [_c('v-select', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: '',
      expression: "''"
    }],
    staticClass: "mb-4",
    attrs: {
      "menu-props": {
        offsetY: true
      },
      "attach": "",
      "append-icon": "mdi-chevron-down",
      "filled": "",
      "items": _vm.storageList,
      "item-value": "id",
      "item-text": "name",
      "placeholder": "Select Storage",
      "loading": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [!item.action ? [_c('div', {
          staticClass: "d-flex justify-center align-center mr-4",
          staticStyle: {
            "width": "32px"
          }
        }, [item.icon ? _c('img', {
          attrs: {
            "height": "24",
            "src": item.icon ? _vm.getStorageImg(item.icon) : null
          }
        }) : _vm._e()]), _vm._v(" " + _vm._s(item.name) + " ")] : [_c('div', {
          staticClass: "d-flex justify-center primary--text align-center mr-4",
          class: item.disabled ? 'text-muted' : '',
          on: {
            "click": function click($event) {
              item.disabled ? '' : _vm.openCreateForm;
            }
          }
        }, [_c('v-icon', {
          staticClass: "sticky-list-icon",
          attrs: {
            "left": "",
            "color": item.disabled ? '' : 'primary'
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" " + _vm._s(item.name) + " "), item.disabled ? _c('v-chip', {
          staticClass: "text-right",
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("Starter & Pro")]) : _vm._e()], 1)]];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('div', {
          staticClass: "pa-4"
        }, [_vm.loading ? _c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('ContentLoader', {
          attrs: {
            "size": "25"
          }
        })], 1) : _c('div', [_vm._v("No data available")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.storageId,
      callback: function callback($$v) {
        _vm.storageId = $$v;
      },
      expression: "storageId"
    }
  }, 'v-select', _vm.veeValidate('Storage', 'Storage'), false)), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.xsOnly
    },
    on: {
      "close": function close($event) {
        _vm.storageId = '', _vm.openCreateStorage = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('div', {
          staticClass: "px-7 py-4"
        }, [_vm.openCreateStorage ? _c('create-form', {
          on: {
            "refresh": _vm.afterSuccess,
            "close": function close($event) {
              _vm.storageId = '', _vm.openCreateStorage = false;
            }
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openCreateStorage,
      callback: function callback($$v) {
        _vm.openCreateStorage = $$v;
      },
      expression: "openCreateStorage"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }