<template>
  <div class="release-info">
    <div>
      <div class="text-center">
        <v-card-title class="display-1 justify-center mb-5">Info</v-card-title>
        <div class="font-weight-bold">
          <h2 class="mb-3">
            {{
              get(info, "info.file.extension", "") == "apk" ? "Android" : "iOS"
            }}
          </h2>
        </div>
      </div>
      <v-expansion-panels v-model="topPanel" flat multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="headline darkGrey--text">
              {{
                get(info, "info.file.extension", "") == "apk" ? "APK" : "IPA"
              }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="px-6 font14 info-height">
              <div>
                Name: {{ info && info.info.name ? info.info.name : app.name }}
              </div>
              <div>Signing: {{ get(info, "info.meta.signingType", "") }}</div>
              <div>
                {{
                  info?.info?.file?.extension === "apk"
                    ? "Package"
                    : "Bundle ID"
                }}:
                {{ get(info, "info.package", "") }}
              </div>
              <div>Version: {{ get(info, "info.version", "") }}</div>
              <div>
                {{
                  info?.info?.file?.extension === "apk"
                    ? " Version Code"
                    : "Bundle Number"
                }}
                :
                {{ get(info, "info.version_code", "") }}
              </div>

              <div v-if="get(info, 'info.file.extension', '') == 'apk'">
                Target SDK: {{ get(info, "info.meta.targetSDKVersion", "") }}
              </div>
              <div v-if="get(info, 'info.file.extension', '') == 'apk'">
                Min SDK: {{ get(info, "info.meta.minSDKVersion", "") }}
              </div>
              <div v-if="get(info, 'info.file.extension', '') == 'ipa'">
                Platform Version:
                {{ get(info, "info.meta.platformVersion", "") }}
              </div>
              <div v-if="get(info, 'info.file.extension', '') == 'ipa'">
                Min OS: {{ get(info, "info.meta.minimumOSVersion", "") }}
              </div>
              <div v-if="get(info, 'info.file.extension', '') == 'ipa'">
                Supported Platforms:
                {{ info?.info?.meta?.supportedPlatforms?.join(", ") }}
              </div>
              <div v-if="get(info, 'info.file.extension', '') == 'ipa'">
                Platforms: {{ info?.info?.meta?.platforms?.join(", ") }}
              </div>
              <div>
                Size: {{ formatBytes(get(info, "info.file.size", 0) / 1000) }}
              </div>
              <div class="mb-4">
                File name: {{ get(info, "info.file.name", "") }}
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="info?.info?.file?.extension === 'apk'">
          <v-expansion-panel-header>
            <div class="headline darkGrey--text">Permissions</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              :class="
                get(info, 'info.meta.usesPermissions', [])?.length
                  ? 'scrollBar'
                  : ''
              "
              class="px-9 mb-9 mt-3 font14 info-height"
            >
              <permission-mapper
                v-if="get(info, 'info.meta.usesPermissions', [])?.length"
                :permissions="get(info, 'info.meta.usesPermissions', [])"
              ></permission-mapper>
              <div class="font14 info-height ml-n3" v-else>
                No permissions found
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="info?.info?.file?.extension === 'ipa'">
          <v-expansion-panel-header>
            <div class="headline darkGrey--text">Certificate</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="ml-5 font14 info-height">
              <div class="smallFont px-2 pb-0">
                <span>Created:</span>
                <span class="ml-3">
                  <date-tooltip :date="info?.info?.meta?.creationDate" />
                  {{
                }}</span>
              </div>
              <div class="smallFont px-2 pb-0">
                <span>Expire:</span>
                <span class="ml-3">
                  <date-tooltip :date="info?.info?.meta?.expirationDate" />
                </span>
              </div>
              <div class="smallFont px-2 pb-0">
                <span>Team Identifier:</span>
                <span class="ml-3">
                  {{ info?.info?.meta?.teamIdentifier?.join(", ") || "-" }}
                </span>
              </div>
              <div class="smallFont px-2 pb-0">
                <span>Provisioning profile name:</span>
                <span class="ml-3">
                  {{ info?.info?.meta?.provisioningProfileName }}
                </span>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="info?.info?.file?.extension === 'ipa'">
          <v-expansion-panel-header>
            <div class="headline darkGrey--text">
              Provisioned Devices ({{
                info?.info?.meta?.provisionedDevices?.length || "0"
              }}
              / 100)
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              class="ml-5 font14 info-height"
              :class="
                info?.info?.meta?.provisionedDevices?.length ? 'scrollBar' : ''
              "
              v-if="info?.info?.meta?.provisionedDevices?.length"
            >
              <div
                v-for="(udid, uindex) in info?.info?.meta?.provisionedDevices"
                :key="uindex"
                class="mb-1"
              >
                <span class="mr-2"> {{ uindex + 1 }}. </span>
                <span>
                  {{ udid }}
                </span>
              </div>
            </div>

            <div class="font14 info-height ml-6" v-else>No devices found</div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row v-if="!isExpress" class="ml-3 my-6">
        <v-col cols="12" md="6">
          <div class="headline darkGrey--text">Members</div>
          <div class="font14 info-height">
            <div>
              Total eligible: {{ get(info, "meta.members.eligible", 0) }}
            </div>
            <div v-if="get(info, 'info.file.extension', '') == 'apk'">
              Total with Android:
              {{ get(info, "meta.members.eligible_per_platform", 0) }}
            </div>
            <div v-if="get(info, 'info.file.extension', '') == 'ipa'">
              Total with iOS:
              {{ get(info, "meta.members.eligible_per_platform", 0) }}
            </div>
            <div>
              Total with Android & iOS:
              {{ get(info, "meta.members.total", 0) }}
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="headline darkGrey--text">Devices</div>
          <div class="font14 info-height">
            <div>Total: {{ get(info, "meta.devices.total", 0) }}</div>
            <div>
              Total eligible:
              {{ get(info, "meta.devices.total_eligible", 0) }}
            </div>
            <div>
              Total not eligible:
              {{ get(info, "meta.devices.total_not_eligible", 0) }}
            </div>
          </div>
        </v-col>
      </v-row>
      <v-expansion-panels v-model="warning" class="mb-5" flat>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="headline darkGrey--text">Warnings</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <template
              v-if="
                !get(info, 'meta.devices.members', [])?.length &&
                !(info && info.info.warnings && info.info.warnings?.length)
              "
            >
              <div class="d-flex flex-center text-center min-h-200px">
                <div class="no-data-found mx-auto">
                  <inline-svg
                    class="mt-6 svg-icon empty_icon"
                    src="/media/placeholders/check.svg"
                  />
                  <p
                    class="mt-2 empty-title bold-text darkGrey--text text-center"
                  >
                    All looks good
                  </p>
                </div>
              </div>
            </template>
            <template v-else>
              <v-expansion-panels
                v-if="info && info.info.warnings && info.info.warnings?.length"
              >
                <v-expansion-panel class="mb-2">
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col cols="auto">
                        <span class="title">
                          {{
                            get(info, "info.file.extension", "") == "apk"
                              ? "APK"
                              : "IPA"
                          }}
                          warnings
                        </span>
                        <v-chip
                          class="font-weight-bold"
                          color="orange"
                          small
                          v-text="info.info.warnings?.length"
                        >
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pa-0">
                    <v-card class="pa-4 mb-2" flat tile>
                      <ul>
                        <li v-for="(warning, j) in info.info.warnings" :key="j">
                          {{ warning }}
                        </li>
                      </ul>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div
                v-if="
                  get(info, 'meta.devices.members', [])?.length && !isExpress
                "
                class="darkGrey--text font17 py-4 ml-6"
              >
                Devices
              </div>
              <v-expansion-panels
                v-if="info && info.meta && info.meta.devices && !isExpress"
              >
                <v-expansion-panel
                  v-for="(member, i) in get(info, 'meta.devices.members', [])"
                  v-bind:key="i"
                  outline
                >
                  <v-expansion-panel-header>
                    <v-row align="center">
                      <v-col cols="auto">
                        <img
                          v-if="info?.info?.icon"
                          :src="info?.info?.icon"
                          height="50"
                        />
                        <img
                          v-else
                          height="50"
                          src="/media/defaults/icon-120.png"
                        />
                      </v-col>
                      <v-col class="text-left">
                        <div>{{ member.name }}</div>
                        <div class="mr-4 mt-2">
                          {{ member?.devices?.length }} devices are not eligible
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card
                      v-for="(device, j) of member.devices"
                      :key="j"
                      class="pa-4 mb-2"
                      flat
                      tile
                    >
                      <div class="font14 info-height">
                        <v-row>
                          <v-col
                            class="py-0 d-flex justify-space-between"
                            cols="12"
                            md="4"
                            sm="6"
                          >
                            <span class="bold-text mr-2">OS:</span>
                            {{ device?.os || "---" }}
                          </v-col>
                          <v-col
                            class="py-0 d-flex justify-space-between"
                            cols="12"
                            md="4"
                            sm="6"
                          >
                            <span class="bold-text mr-2">Name:</span>
                            {{ device?.name || "---" }}
                          </v-col>
                          <v-col
                            class="py-0 d-flex justify-space-between"
                            cols="12"
                            md="4"
                            sm="6"
                          >
                            <span class="bold-text mr-2">Type:</span>
                            {{ device?.type || "---" }}
                          </v-col>
                          <v-col
                            class="py-0 d-flex justify-space-between"
                            cols="12"
                            md="4"
                            sm="6"
                          >
                            <span class="bold-text mr-2">Model:</span>
                            {{ device?.model || "---" }}
                          </v-col>

                          <v-col
                            class="py-0 d-flex justify-space-between"
                            cols="12"
                            md="4"
                            sm="6"
                          >
                            <span class="bold-text mr-2">Language:</span>
                            {{ device?.locale || device?.language || "---" }}
                          </v-col>
                          <v-col
                            class="py-0 d-flex justify-space-between"
                            cols="12"
                            md="4"
                            sm="6"
                          >
                            <span class="bold-text mr-2">Manufacturer:</span>
                            {{ device?.manufacturer || "---" }}
                          </v-col>

                          <v-col
                            class="py-0 d-flex justify-space-between"
                            cols="12"
                            md="4"
                            sm="6"
                          >
                            <span class="bold-text mr-2"
                              >Notifications Enabled:</span
                            >
                            {{ device?.push_enabled ? "Yes" : "No" || "---" }}
                          </v-col>
                          <v-col
                            class="py-0 d-flex justify-space-between"
                            cols="12"
                            md="4"
                            sm="6"
                          >
                            <span class="bold-text mr-2">SDK version:</span>
                            {{ device?.sdk_version || "---" }}
                          </v-col>
                          <v-col
                            class="py-0 d-flex justify-space-between"
                            cols="12"
                            md="4"
                            sm="6"
                          >
                            <span class="bold-text mr-2">OS version:</span>
                            {{ device?.os_version || "---" }}
                          </v-col>
                        </v-row>
                        <div class="mr-2 mt-3">
                          <span class="bold-text">UUID:</span>
                          {{ device?.uuid || "---" }}
                          <v-btn
                            v-if="device.uuid"
                            @click="copy(device.uuid)"
                            icon
                          >
                            <v-icon
                              size="16"
                              v-text="'mdi-content-copy'"
                            ></v-icon>
                          </v-btn>
                        </div>
                        <div
                          v-if="device.os.toLowerCase() == 'ios'"
                          class="mr-2 mt-3"
                        >
                          <span class="bold-text">UDID:</span>
                          {{ device?.udid || "---" }}
                          <v-btn
                            v-if="device.udid"
                            @click="copy(device.udid)"
                            icon
                          >
                            <v-icon
                              size="16"
                              v-text="'mdi-content-copy'"
                            ></v-icon>
                          </v-btn>
                        </div>

                        <div class="bold-text mr-2 mt-3">Messages</div>
                        <div
                          v-for="(message, k) of device?.messages"
                          v-bind:key="k"
                        >
                          <div class="mr-2 mt-3">
                            <b>{{ k + 1 }}</b
                            >. <span v-html="message"></span>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import { formatBytes, get, isEmpty } from "@/core/services/helper.service";
import PermissionMapper from "@/view/components/Common/PermissionMapper";

export default {
  name: "pre-release-info",
  components: { PermissionMapper },
  props: ["info", "app", "data", "isExpress"],
  data() {
    return {
      get,
      formatBytes,
      warning: 0,
      topPanel: [0, 0],
    };
  },
  computed: {
    noData() {
      return isEmpty(this.data) ? true : false;
    },
  },
  methods: {
    replaceDefaultImage(e) {
      e.target.src = "/media/defaults/icon-120.png";
    },
  },
};
</script>

<style lang="scss">
.scrollBar {
  max-height: 350px;
  overflow-y: scroll;
}

.info-height {
  letter-spacing: 0.0125em;
  line-height: 1.5;
  word-break: normal;
}

.scrollBar::-webkit-scrollbar {
  width: 3px;
}

.release-info {
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
}
</style>
