<template>
  <div>
    <v-row justify="center">
      <v-col
        v-if="fromModal && !fromGetStarted && !fromPlanPage"
        class="text-center"
        cols="12"
      >
        <vue-inline-alert
          :message="infoMessage()"
          message-type="error"
          no-close-btn
          class="mt-10 mb-3"
        >
        </vue-inline-alert>
      </v-col>
    </v-row>
    <v-row class="my-8" align="center" justify="space-around">
      <v-col cols="12" class="text-center darkGrey--text font35 extra-bold">
        Make <b>better</b> apps
      </v-col>
      <div class="font17 text-muted mt-2 mb-5">
        Trusted by 50,000+ teams, agencies and freelancers
      </div>
    </v-row>

    <v-container fluid v-if="checkIfMaxPlan()">
      <v-row justify="center" align="center" class="my-5">
        <v-col cols="12" md="8">
          <v-card class="pa-5" elevation="2">
            <v-card-title>
              <h3>Thank You for Being on the Pro Plan!</h3>
            </v-card-title>
            <v-card-text class="text-muted bodyFont">
              We understand that you are running into some limitations. We're
              excited to announce that we'll be introducing self-serve add-ons
              soon. In the meantime, if you need any additional features or
              resources, please chat with us and we'll be happy to enable them
              for you.
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                :outlined="$vuetify.theme.dark"
                color="primary"
                @click="openIntercomChat(contactMessage)"
                >Chat with Us</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-row v-if="!checkIfMaxPlan()">
      <v-col cols="12" class="text-center">
        <v-chip
          @click="billingType = 'monthly'"
          :color="
            billingType === 'monthly' ? 'switch-view-selected' : 'switch-view'
          "
          :class="
            !$vuetify.theme.dark && billingType === 'monthly'
              ? 'white--text'
              : ''
          "
          :outlined="$vuetify.theme.dark"
          label
        >
          <v-icon left>mdi-view-list</v-icon>
          Monthly</v-chip
        >
        <v-chip
          @click="billingType = 'annually'"
          :color="
            billingType === 'annually' ? 'switch-view-selected' : 'switch-view'
          "
          :class="
            !$vuetify.theme.dark && billingType === 'annually'
              ? 'white--text'
              : ''
          "
          :outlined="$vuetify.theme.dark"
          label
        >
          <v-icon left>mdi-card-text</v-icon>
          Yearly</v-chip
        >
      </v-col>
      <v-col class="text-center text-muted bodyFont" cols="12">
        <div>
          Pick the annual billing and save <b>30%</b>! That's like getting
          nearly <b>4 months</b> free 🎉
        </div>
      </v-col>
    </v-row>

    <v-row justify="center" align="center" v-if="!checkIfMaxPlan()">
      <template v-if="!planLoading">
        <v-col v-if="!fromModal" cols="12" md="4">
          <v-card outlined class="pa-10 planBoxCard">
            <v-row>
              <v-col class="darkGrey--text" cols="12">
                <div class="titleFont darkGrey--text bold-text">
                  <v-icon v-text="'mdi-water-circle'"></v-icon>
                  Freelancer
                </div>
                <div>The essentials to provide your best work for clients.</div>
              </v-col>
            </v-row>
            <v-row class="darkGrey--text">
              <v-col>
                <div>
                  <span
                    class="font65 extra-bold titleFont"
                    id="currentPlanPrice"
                  >
                    ${{ "0" }}</span
                  >
                  <div class="darkGrey--text pt-2">
                    {{ hobbyDetails.display_price_message }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  v-if="!subscription.status && !activeTrail"
                  large
                  color="primary"
                  outlined
                >
                  Current Plan
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div
                  class="mb-3 bodyFont"
                  v-for="(feat, index) in hobbyFeatures"
                  :key="index + feat.title"
                >
                  <v-tooltip
                    v-if="feat.info"
                    bottom
                    :color="$vuetify.theme.dark ? '' : 'primary'"
                  >
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-icon
                          color="primary"
                          size="24"
                          v-text="'mdi-check-circle-outline'"
                          left
                        ></v-icon>
                        <span class="mr-1" v-html="feat.title"></span>
                      </span>
                    </template>
                    <span v-html="feat.info"> </span>
                  </v-tooltip>
                  <div class="darkGrey--text" v-else>
                    <v-icon
                      color="primary"
                      size="24"
                      v-text="'mdi-check-circle-outline'"
                      left
                    ></v-icon>
                    <span class="mr-1" v-html="feat.title"></span>
                  </div>
                  <div class="ml-8 mt-2" v-if="feat.items && feat.items.length">
                    <div v-for="(item, sdindex) in feat.items" :key="sdindex">
                      <v-tooltip v-if="item.info" bottom color="primary">
                        <template v-slot:activator="{ on }">
                          <div class="mb-1 darkGrey--text" v-on="on">
                            <span class="mr-1" v-html="item.title"></span>
                          </div>
                        </template>
                        <span v-html="item.info"> </span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <template v-for="(plan, sindex) in getStarterPlans">
          <v-col
            v-if="
              plan.recurring === billingType &&
              (subscription.name !== plan.name || activeTrail)
            "
            :key="sindex + plan.id"
            cols="12"
            :md="fromModal ? 5 : 4"
          >
            <v-card
              :outlined="$vuetify.theme.dark"
              color=""
              class="pa-8 planBoxCard"
            >
              <v-row class="darkGrey--text">
                <v-col cols="12">
                  <div class="titleFont bold-text ml-n3">
                    <v-icon> mdi-lightning-bolt-outline </v-icon>
                    {{ plan.name }}
                  </div>
                  <div :class="fromModal ? 'my-3' : ''">
                    {{ plan.description }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="darkGrey--text">
                  <div>
                    <span
                      v-if="plan.meta.previous_price"
                      class="mr-2 body-1 text-decoration-line-through"
                    >
                      <span v-if="billingType === 'annually'">
                        {{ "$ " + plan.meta.previous_price / 12 }}
                      </span>
                      <span v-else>
                        {{ "$ " + plan.meta.previous_price }}
                      </span>
                    </span>
                    <span
                      v-if="isFloat(get(plan, 'meta.display_price', ''))"
                      class="font65 extra-bold"
                      >${{
                        get(plan, "meta.display_price", "")
                          .toString()
                          .split(".")[0]
                      }}
                      <span class="ml-n4 bodyFont"
                        >.{{
                          get(plan, "meta.display_price", "")
                            .toString()
                            .split(".")[1]
                        }}</span
                      >
                    </span>
                    <span class="font65 extra-bold" v-else>
                      ${{ get(plan, "meta.display_price", "") }}
                    </span>
                    <div
                      :class="fromModal ? 'mt-2' : ''"
                      class="darkGrey--text"
                    >
                      {{ get(plan, "meta.display_price_message", "") }}
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row class="darkGrey--text">
                <v-col class="darkGrey--text" cols="10">
                  <div>
                    <template
                      v-if="
                        !getIsUserDisabled &&
                        canManageTeam &&
                        isEmpty(getTeamTrial) &&
                        !getPromoInfo.source_name &&
                        subscription.status !== 'active'
                      "
                    >
                      <v-btn
                        v-if="!subscribe"
                        @click="openFreeTrialModal(plan)"
                        class="text-transform-none pa-7"
                        id="startTrialProPlan"
                        :outlined="$vuetify.theme.dark"
                        style="border-width: 3px"
                        large
                        color="primary"
                      >
                        <span class="font14 bold-text">
                          Start 14-day trial
                        </span>
                      </v-btn>
                      <div v-if="!subscribe" class="my-5">
                        <v-btn
                          outlined
                          color="darkGrey"
                          class="text-transform-none"
                          @click="upgradePlanConfirmation(plan)"
                        >
                          <div class="smallFont">Or upgrade now</div></v-btn
                        >
                      </div>
                    </template>
                    <v-btn
                      v-if="
                        !getIsUserDisabled &&
                        canManageTeam &&
                        !isEmpty(getTeamTrial) &&
                        !getPromoInfo.source_name &&
                        (!subscription.status ||
                          (['active', 'cancel', 'cancelled', 'paused'].includes(
                            subscription.status
                          ) &&
                            !['pro', 'starter'].includes(
                              subscription.category
                            )))
                      "
                      @click="
                        subscription.status == 'paused'
                          ? initiatePauseAlert({ action: 'Upgrade' }, plan)
                          : upgradePlanConfirmation(plan)
                      "
                      class="text-transform-none my-5 pa-7"
                      id="proSubscribeBtn"
                      :outlined="$vuetify.theme.dark"
                      color="primary"
                    >
                      <span class="font14 bold-text"> Upgrade now </span>
                    </v-btn>
                    <v-btn
                      v-if="
                        !getIsUserDisabled &&
                        canManageTeam &&
                        !isEmpty(getTeamTrial) &&
                        !getPromoInfo.source_name &&
                        ['active', 'paused'].includes(subscription.status) &&
                        subscription.category !== 'starter'
                      "
                      color="primary"
                      class="text-transform-none cursor-pointer my-5 pa-7"
                      outlined
                      block
                      @click="initiatePauseAlert({ action: 'Downgrade' })"
                    >
                      <span class="font14 bold-text"> Downgrade </span>
                    </v-btn>
                    <v-btn
                      v-if="
                        !getIsUserDisabled &&
                        canManageTeam &&
                        !getPromoInfo.source_name &&
                        ['past_due', 'suspended'].includes(subscription.status)
                      "
                      color="primary"
                      class="text-transform-none cursor-pointer my-5 pa-7"
                      outlined
                      @click="openUpdateUrl"
                    >
                      <span class="font14 bold-text"> Update billing </span>
                    </v-btn>
                    <template
                      v-if="
                        !getIsUserDisabled &&
                        canManageTeam &&
                        !isEmpty(getTeamTrial) &&
                        !getPromoInfo.source_name &&
                        ['active', 'paused'].includes(subscription.status) &&
                        subscription.category === 'starter'
                      "
                    >
                      <v-btn
                        color="primary"
                        class="text-transform-none my-5 pa-7"
                        :class="
                          subscription.recurring !== 'annually' ? '' : 'mb-4'
                        "
                        depressed
                        id="switchPlanFromStarter"
                        :loading="busy"
                        :outlined="$vuetify.theme.dark"
                        @click="
                          subscription.status == 'paused'
                            ? initiatePauseAlert({
                                action: 'Switch',
                                plan: 'Starter',
                                type:
                                  subscription.recurring !== 'annually'
                                    ? 'annually'
                                    : 'monthly',
                                planId: plan.id,
                              })
                            : openSwitchPlanDialog(
                                'Starter',
                                subscription.recurring !== 'annually'
                                  ? 'annually'
                                  : 'monthly',
                                plan.id
                              )
                        "
                      >
                        <span class="font14 bold-text">
                          Switch to
                          {{
                            subscription.recurring !== "annually"
                              ? "Annually"
                              : "Monthly"
                          }}
                        </span>
                      </v-btn>
                    </template>
                    <v-btn
                      v-if="
                        !getIsUserDisabled &&
                        canManageTeam &&
                        !isEmpty(getTeamTrial) &&
                        !getPromoInfo.source_name &&
                        subscription.category === 'starter' &&
                        subscription.status &&
                        ['cancel', 'cancelled'].includes(subscription.status)
                      "
                      @click="upgradePlanConfirmation(plan)"
                      class="text-transform-none my-5 pa-7"
                      block
                      id="starterUpgradePlanBtn"
                      depressed
                      :outlined="$vuetify.theme.dark"
                      :color="$vuetify.theme.dark ? 'background' : 'primary'"
                    >
                      <span class="font14 bold-text"> Resubscribe Now</span>
                    </v-btn>
                    <!-- <v-btn
                      v-if="
                        !getIsUserDisabled &&
                        canManageTeam &&
                        !isEmpty(getTeamTrial) &&
                        !getPromoInfo.source_name &&
                        ['cancel', 'cancelled'].includes(subscription.status) &&
                        subscription.category !== 'starter'
                      "
                      @click="
                        subscription.status == 'paused'
                          ? initiatePauseAlert({ action: 'Upgrade' }, plan)
                          : upgradePlanConfirmation(plan)
                      "
                      class="text-transform-none my-5 pa-7"
                      :outlined="$vuetify.theme.dark"
                      id="starterUpgradePlanBtn"
                      depressed
                      color="primary"
                    >
                      <span class="font14 bold-text"> Upgrade now </span>
                    </v-btn> -->
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="darkGrey--text" cols="11">
                  <div
                    class="mb-3 bodyFont"
                    v-for="(feat, starter_feat_index) in plan.features"
                    :key="starter_feat_index + feat.title"
                  >
                    <v-tooltip
                      v-if="feat.info"
                      bottom
                      :color="$vuetify.theme.dark ? '' : 'primary'"
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-icon
                            color="primary"
                            size="24"
                            left
                            v-text="'mdi-check-circle-outline'"
                          ></v-icon>
                          <span class="mr-1" v-html="feat.title"></span>
                        </div>
                      </template>
                      <div v-html="feat.info"></div>
                    </v-tooltip>
                    <div v-else>
                      <v-icon
                        color="primary"
                        size="24"
                        left
                        v-text="'mdi-check-circle-outline'"
                      ></v-icon>
                      <span class="mr-1" v-html="feat.title"></span>
                    </div>
                    <div
                      class="ml-8 mt-2"
                      v-if="feat.items && feat.items.length"
                    >
                      <div v-for="(item, sdindex) in feat.items" :key="sdindex">
                        <v-tooltip v-if="item.info" bottom color="primary">
                          <template v-slot:activator="{ on }">
                            <div class="mb-1" v-on="on">
                              <span class="mr-1" v-html="item.title"></span>
                            </div>
                          </template>
                          <span v-html="item.info"> </span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </template>
        <template v-for="(plan, pro_index) in getProPlans">
          <v-col
            v-if="plan.recurring === billingType"
            :key="pro_index + plan.id"
            cols="12"
            :md="fromModal ? 5 : 4"
          >
            <v-card :outlined="$vuetify.theme.dark" class="pa-8 planBoxCard">
              <v-row>
                <v-col class="darkGrey--text" cols="12">
                  <div class="titleFont bold-text">
                    <v-icon>mdi-seal</v-icon>
                    Pro
                  </div>
                  <div :class="fromModal ? 'my-3' : ''">
                    {{ plan.description }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="darkGrey--text">
                  <div>
                    <span
                      v-if="plan.meta.previous_price"
                      class="mr-2 body-1 text-decoration-line-through"
                    >
                      <span v-if="plan.recurring === 'annually'">
                        {{ "$ " + plan.meta.previous_price / 12 }}
                      </span>
                      <span v-else>
                        {{ "$ " + plan.meta.previous_price }}
                      </span>
                    </span>
                    <span
                      v-if="isFloat(get(plan, 'meta.display_price', ''))"
                      class="font65 extra-bold"
                      >${{
                        get(plan, "meta.display_price", "")
                          .toString()
                          .split(".")[0]
                      }}
                      <span class="ml-n4 bodyFont"
                        >.{{
                          get(plan, "meta.display_price", "")
                            .toString()
                            .split(".")[1]
                        }}</span
                      >
                    </span>
                    <span class="font65 extra-bold" v-else>
                      ${{ get(plan, "meta.display_price", "") }}
                    </span>
                    <div :class="fromModal ? 'mt-2' : ''">
                      {{ get(plan, "meta.display_price_message", "") }}
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <div>
                    <template
                      v-if="
                        !getIsUserDisabled &&
                        canManageTeam &&
                        isEmpty(getTeamTrial) &&
                        !getPromoInfo.source_name &&
                        subscription.status !== 'active'
                      "
                    >
                      <v-btn
                        v-if="!subscribe"
                        @click="openFreeTrialModal(plan)"
                        class="text-transform-none pa-7"
                        id="startTrialProPlan"
                        :outlined="$vuetify.theme.dark"
                        style="border-width: 3px"
                        large
                        color="primary"
                      >
                        <span class="font14 bold-text">
                          Start 14-day trial
                        </span>
                      </v-btn>
                      <div v-if="!subscribe" class="my-5">
                        <v-btn
                          outlined
                          color="darkGrey"
                          class="text-transform-none"
                          @click="upgradePlanConfirmation(plan)"
                        >
                          <div class="smallFont">Or upgrade now</div></v-btn
                        >
                      </div>
                      <v-btn
                        v-else
                        @click="upgradePlanConfirmation(plan)"
                        class="text-transform-none my-5 pa-7"
                        id="startTrialStarterPlan"
                        depressed
                        :outlined="$vuetify.theme.dark"
                        color="primary"
                      >
                        <span class="font14 bold-text"> Upgrade now </span>
                      </v-btn>
                    </template>
                    <v-btn
                      v-if="
                        !getIsUserDisabled &&
                        canManageTeam &&
                        !isEmpty(getTeamTrial) &&
                        !getPromoInfo.source_name &&
                        (!subscription.status ||
                          (['active', 'cancel', 'cancelled', 'paused'].includes(
                            subscription.status
                          ) &&
                            subscription.category !== 'pro'))
                      "
                      @click="
                        subscription.status == 'paused'
                          ? initiatePauseAlert({ action: 'Upgrade' }, plan)
                          : upgradePlanConfirmation(plan)
                      "
                      class="text-transform-none my-5 pa-7"
                      id="proSubscribeBtn"
                      :outlined="$vuetify.theme.dark"
                      color="primary"
                    >
                      <span class="font14 bold-text"> Upgrade now </span>
                    </v-btn>
                    <template
                      v-if="
                        !getIsUserDisabled &&
                        canManageTeam &&
                        !isEmpty(getTeamTrial) &&
                        !getPromoInfo.source_name &&
                        ['active', 'paused'].includes(subscription.status) &&
                        subscription.category === plan.meta.category
                      "
                    >
                      <v-btn
                        :outlined="$vuetify.theme.dark"
                        color="primary"
                        class="text-transform-none my-5 pa-7"
                        depressed
                        :loading="busy"
                        :class="
                          subscription.recurring !== 'annually' ? '' : 'mb-4'
                        "
                        @click="
                          subscription.status == 'paused'
                            ? initiatePauseAlert({
                                action: 'Switch',
                                plan: 'Pro',
                                type:
                                  subscription.recurring !== 'annually'
                                    ? 'annually'
                                    : 'monthly',
                                planId: plan.id,
                              })
                            : openSwitchPlanDialog(
                                'Pro',
                                subscription.recurring !== 'annually'
                                  ? 'annually'
                                  : 'monthly',
                                plan.id
                              )
                        "
                      >
                        <span class="font14 bold-text">
                          Switch to
                          {{
                            subscription.recurring !== "annually"
                              ? "Annually"
                              : "Monthly"
                          }}
                        </span>
                      </v-btn>
                    </template>
                    <v-btn
                      v-if="
                        !getIsUserDisabled &&
                        canManageTeam &&
                        !getPromoInfo.source_name &&
                        ['past_due', 'suspended'].includes(subscription.status)
                      "
                      color="primary"
                      class="text-transform-none cursor-pointer my-5 pa-7"
                      outlined
                      @click="openUpdateUrl"
                    >
                      <span class="font14 bold-text"> Update billing </span>
                    </v-btn>
                    <v-btn
                      v-if="
                        !getIsUserDisabled &&
                        canManageTeam &&
                        !isEmpty(getTeamTrial) &&
                        !getPromoInfo.source_name &&
                        subscription.category === 'pro' &&
                        subscription.status &&
                        ['cancel', 'cancelled'].includes(subscription.status)
                      "
                      @click="upgradePlanConfirmation(plan)"
                      class="text-transform-none my-5 pa-7"
                      id="starterUpgradePlanBtn"
                      depressed
                      :outlined="$vuetify.theme.dark"
                      color="primary"
                    >
                      <span class="font14 bold-text"> Resubscribe Now</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div
                    class="mb-3 bodyFont darkGrey--text"
                    v-for="(feat, feat_pro_index) in plan.features"
                    :key="feat_pro_index + feat.title"
                  >
                    <v-tooltip
                      v-if="feat.info"
                      bottom
                      :color="$vuetify.theme.dark ? '' : 'primary'"
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-icon
                            color="primary"
                            size="24"
                            left
                            v-text="'mdi-check-circle-outline'"
                          ></v-icon>
                          <span class="mr-1" v-html="feat.title"></span>
                        </div>
                      </template>
                      <div v-html="feat.info"></div>
                    </v-tooltip>
                    <div v-else>
                      <v-icon
                        color="primary"
                        size="24"
                        left
                        v-text="'mdi-check-circle-outline'"
                      ></v-icon>
                      <span class="mr-1" v-html="feat.title"></span>
                    </div>
                    <div
                      class="ml-8 mt-2"
                      v-if="feat.items && feat.items.length"
                    >
                      <div v-for="(item, sdindex) in feat.items" :key="sdindex">
                        <v-tooltip v-if="item.info" bottom color="primary">
                          <template v-slot:activator="{ on }">
                            <div class="mb-1 darkGrey--text" v-on="on">
                              <span class="mr-1" v-html="item.title"></span>
                            </div>
                          </template>
                          <span v-html="item.info"> </span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <!--              <v-row>-->
              <!--                <v-col cols="12" class="text-center">-->
              <!--                  <v-btn-->
              <!--                    class="text-transform-none"-->
              <!--                    @click="openMakeDealModal(plan)"-->
              <!--                    outlined-->
              <!--                    color="offWhite"-->
              <!--                  >-->
              <!--                    <v-icon left v-text="'mdi-cash'" color="offWhite"></v-icon>-->
              <!--                    <span class="semi-bold font14 my-2">-->
              <!--                      Or let's make a deal-->
              <!--                    </span>-->
              <!--                  </v-btn>-->
              <!--                </v-col>-->
              <!--              </v-row>-->
            </v-card>
          </v-col>
        </template>
      </template>
      <template v-else>
        <v-col cols="12">
          <v-row>
            <v-col v-for="i in 3" :key="i" cols="12" md="4">
              <v-skeleton-loader
                type="card-avatar, article"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </v-row>

    <v-row justify="center" v-if="!checkIfMaxPlan()">
      <v-col cols="auto">
        <a
          href="https://testapp.io/pricing"
          class="text-decoration-underline bodyFont"
          target="_blank"
        >
          Compare all plans
        </a>
      </v-col>
    </v-row>

    <v-divider class="my-10"></v-divider>

    <v-row v-if="!checkIfMaxPlan()">
      <v-row>
        <v-col cols="6" class="darkGrey--text py-4">
          <div class="titleFont">Frequently Asked Questions</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <plans-questions from-modal></plans-questions>
        </v-col>
        <v-col cols="6">
          <v-card flat outlined class="pa-6">
            <v-row justify="center">
              <v-col class="text-center" cols="12">
                <div class="py-3">
                  <img src="/media/svg/referrals.svg" width="40%" />
                </div>
                <div class="titleFont semi-bold primary--text">
                  Turn referrals into revenue
                </div>
              </v-col>
              <v-col class="text-center bodyFont" cols="12">
                Become a TestApp.io affiliate partner and earn up to
                <b>25%</b> commission on customer referrals.
              </v-col>
              <v-col class="text-center" cols="12">
                <v-btn
                  color="primary"
                  class="text-transform-none"
                  :outlined="$vuetify.theme.dark"
                  href="https://testapp.io/affiliate"
                  target="_blank"
                >
                  Apply now
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-row>

    <Modal v-model="switchPlanDialog" @close="switchPlanDialog = false">
      <template #message>
        <v-row>
          <v-col cols="12" class="mt-4 bold-text font-size-h3">
            Switch to {{ changingPlan }}
            <span class="text-capitalize">({{ changingType }})</span>
          </v-col>
          <v-col cols="12" class="bodyFont">
            <div v-if="changingType == 'annually'">
              <div>
                Team: <b>{{ getTeamName }}</b>
              </div>

              <div class="my-4">
                Great choice! Reduce payment failures and lock the current
                discount for a year 💪
              </div>

              <div class="mt-6">
                <v-alert outlined color="success">
                  <span class="body-1">
                    One-click away from saving <b>30%</b> or nearly
                    <b>4 months</b> free 🎉
                  </span>
                </v-alert>
                <v-alert outlined color="info">
                  <span class="body-1">
                    We will apply the remaining balance to your new billing
                  </span>
                </v-alert>
              </div>
            </div>

            <div v-else>
              Are you sure you want to change the billing from Annually to
              Monthly?

              <div class="mt-6">
                <v-alert id="monthlyAlert" color="info" outlined>
                  <span class="body-1">
                    The remaining balance will be in your account for the
                    subsequent billing cycles
                  </span>
                </v-alert>
              </div>
            </div>
          </v-col>
          <v-col class="text-right" cols="12">
            <v-btn
              @click="switchPlanDialog = false"
              class="text-transform-none mx-2"
              text
              :disabled="busy"
              color="primary"
              >Cancel</v-btn
            >
            <v-btn
              @click="changePlan"
              class="text-transform-none"
              color="primary"
              :outlined="$vuetify.theme.dark"
              id="swichConfirmBtn"
              :loading="busy"
              depressed
              >Switch <span v-if="changingType == 'annually'">🎉</span></v-btn
            >
          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal v-model="changeModal" @close="changeModal = false">
      <template #message>
        <v-row class="py-4">
          <v-col class="text-h6" cols="12">
            <div class="text-center">
              Your current plan is
              <b>
                {{ subscription.name + " " }}
                <span class="text-capitalize"
                  >({{ subscription.recurring }})</span
                >
              </b>
            </div>
            <div class="text-left mt-4 bodyFont">
              <div>
                - You can change your billing cycle to either Monthly or
                Annually.
              </div>
              <div class="mb-4">
                - You can upgrade or downgrade your plans at any time.
              </div>
              <div>
                Note: When downgrading a plan, the remaining balance will be in
                your account for your next billing cycles.
              </div>
            </div>
          </v-col>
          <v-col class="pt-0 text-center" cols="12">
            <v-select
              v-model="selectedPlanId"
              item-value="id"
              filled
              id="plan"
              placeholder="Select a plan"
              :items="plansList"
            >
              <template v-slot:selection="{ item }">
                <div>
                  {{ item.name + " " }}
                  <span class="text-capitalize">({{ item.recurring }})</span>
                  <v-chip
                    small
                    class="ml-3"
                    v-if="item.recurring === 'annually'"
                    >Get 30% discount (~4 months)</v-chip
                  >
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div>
                  {{ item.name + " " }}
                  <span class="text-capitalize">({{ item.recurring }})</span>
                  <v-chip
                    small
                    class="ml-3"
                    v-if="item.recurring === 'annually'"
                    >Get 30% discount (~4 months)</v-chip
                  >
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col class="text-center" cols="12">
            <v-btn
              :disabled="subscription.id === selectedPlanId"
              color="primary"
              class="text-transform-none"
              :outlined="$vuetify.theme.dark"
              @click="changePlan"
              :loading="busy"
              depressed
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal v-model="successModal">
      <template v-if="successModal" #message>
        <v-row class="py-4">
          <v-col class="text-center text-h5" cols="12">
            Congratulations 🎉
          </v-col>
          <v-col class="text-center text-h6" cols="12">
            {{ upgradeMessage || "We are upgrading your plan" }}
          </v-col>
          <v-col class="text-center" cols="12">
            <v-progress-circular
              indeterminate
              color="success"
            ></v-progress-circular>
          </v-col>
          <v-col class="text-center bodyFont" cols="12">
            It should be done within few moments
          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal v-model="pausedAlert">
      <template #card>
        <v-card>
          <v-card-title>
            <div class="titleFont semi-bold">Attention</div>
          </v-card-title>
          <v-card-text class="message my-3">
            <div>
              Your team's subscription is currently on pause, to proceed with
              the {{ selectedAction.action }}, we will need to resume it and
              then {{ selectedAction.action }}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="pausedAlert = false"
              text
              class="text-transform-none"
              color="primary"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="resumeSubscription"
              depressed
              class="text-transform-none"
              color="primary"
            >
              Resume & {{ selectedAction.action }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </Modal>
    <Modal
      fullscreen
      v-model="upgradeSummaryModal"
      @close="upgradeSummaryModal = false"
    >
      <template #card>
        <upgrade-summary
          v-if="upgradeSummaryModal"
          @close="upgradeSummaryModal = false"
          :plans="selectedPlans"
          :selected-plan="selectedPlan"
          :type="billingType"
          @proceed="upgradePlan"
        ></upgrade-summary>
      </template>
    </Modal>
    <Modal v-model="confirmationModal" @close="confirmationModal = false">
      <template #message>
        <v-row v-if="confirmationModal" class="py-4">
          <v-col class="text-center text-h6" cols="12">
            You are about to
            {{
              startTrial
                ? `start the ${trialDay}-day free trial for the`
                : "change your plan to"
            }}
            <div>
              <b id="selectedPlanName"
                >{{ selectedPlan.name }}
                <span v-if="!startTrial" class="text-capitalize"
                  >({{ selectedPlan.recurring }})</span
                ></b
              >
              <span v-if="startTrial">plan</span>
            </div>
          </v-col>
          <v-col v-if="!startTrial" class="text-center text-h6" cols="12">
            <div v-if="selectedPlan.recurring === 'annually'">
              🎉 Congratulations you saved
              <b>${{ Math.floor((selectedPlan.price / 12) * 2) }}!</b>
              <br /><br />
              You will be billed
              <s>${{ selectedPlan.meta.previous_price }}</s>
              <b> ${{ selectedPlan.price }}</b> annually.
            </div>
            <div v-if="selectedPlan.recurring === 'monthly'">
              You will be billed <s>${{ selectedPlan.meta.previous_price }}</s>
              <b> ${{ selectedPlan.price }}</b> monthly. <br /><br />
              You could still save
              <b
                >${{
                  Math.floor((Math.ceil(selectedPlan.price * 10) / 12) * 2)
                }}</b
              >
              on annual team plan.
            </div>
          </v-col>
          <v-col class="text-center pb-0" cols="12">
            <v-btn
              id="upgradePlanBtn"
              @click="upgradePlan"
              class="text-transform-none"
              color="primary"
              :loading="loading"
              depressed
            >
              Let's do it 💪
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal v-model="paddleLoadingModal" hide-close>
      <template #message>
        <v-row>
          <v-col class="text-center" cols="12">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-col>
          <v-col class="text-center" cols="12">Please wait...</v-col>
        </v-row>
      </template>
    </Modal>
    <Modal width="600" v-model="makeDealModal" hide-close>
      <template #card>
        <make-deal-form
          v-if="makeDealModal"
          :price="selectedPlanPrice"
          :planId="selectedPlanId"
          :selected-plan="plansSelection"
          @close="makeDealModal = false"
        ></make-deal-form>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  CHANGE_PLANS,
  GET_PLANS,
  START_FREE_TRIAL,
} from "@/store/team/team.module";
import dayjs from "dayjs";
import { GET_USER_ROLES } from "@/store/users/auth.module";
import { get, isEmpty } from "@/core/services/helper.service";
import StorageService from "@/core/services/localstorage.service";
import MakeDealForm from "@/view/components/Team/MakeDealForm";
import PlansQuestions from "@/view/components/Team/PlansQuestions";
import UpgradeSummary from "@/view/components/Team/UpgradeSummary";
import { segmentEvents } from "@/consts/segmentEventConst";
// import Logos from "@/view/components/Others/Logos";
// import PlansTable from "@/view/components/Team/PlansTable.vue";

export default {
  props: {
    fromModal: {
      type: Boolean,
      default: false,
    },
    upgradePlanText: {
      type: String,
      default: "",
    },
    fromOverlay: {
      type: Boolean,
      default: false,
    },
    fromGetStarted: {
      type: Boolean,
      default: false,
    },
    uploadedSize: {
      type: [String, Number],
      default: "",
    },
    allowedSize: {
      type: [String, Number],
      default: "",
    },
    fromUpgradeComponent: {
      type: Boolean,
      default: false,
    },
    subscribe: {
      type: Boolean,
      default: false,
    },
    fromPlanPage: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: { UpgradeSummary, PlansQuestions, MakeDealForm },
  data() {
    return {
      isEmpty,
      get,
      billingType: "annually",
      startTrial: false,
      successModal: false,
      contactMessage: {
        message:
          "Hi, I am on the Pro plan, and I've encountered a limitation. I'd like to learn more about the upcoming add-ons. Can you help?",
        subject: "Support Needed for Pro Plan Limitation",
      },

      upgradeMessage: null,
      selectedAction: {},
      pausedAlert: false,
      switchPlanDialog: false,
      billing: true,
      changingPlan: "",
      makeDealModal: false,
      upgradeSummaryModal: false,
      selectedPlanPrice: 0,
      changingType: "",
      plansSelection: [],
      paddleLoadingModal: false,
      confirmationModal: false,
      planLoading: false,
      busy: false,
      changeModal: false,
      showAllFeature: false,
      selectedPlanId: "",
      selectedPlans: [],
      selectedPlan: {},
      showSubscriptionPopup: false,
      showMore: false,
    };
  },
  watch: {
    billing(val) {
      if (val) {
        this.billingType = "monthly";
      } else {
        this.billingType = "annually";
      }
    },
    billingType(val) {
      this.$emit("updateType", val);
      this.logEvent(`Selected ${val || ""} plan`);
    },
    loading(val) {
      this.planLoading = val;
    },
  },
  computed: {
    ...mapGetters({
      plans: "getPlansDetail",
      user: "currentUser",
      getCurrentPlan: "getCurrentPlan",
      getRedirectedFromPlan: "getRedirectedFromPlan",
      getDiscountMessage: "getDiscountMessage",
      getTeamId: "getTeamId",
      getTeamName: "getTeamName",
      getTeamTrial: "getTeamTrial",
    }),
    getStarterPlans() {
      return this.plans && this.plans.plans
        ? this.plans.plans.filter((x) => x.name === "Starter")
        : [];
    },
    activeTrail() {
      return (
        !isEmpty(this.getTeamTrial) && this.getTeamTrial.status === "active"
      );
    },
    getProPlans() {
      return this.plans && this.plans.plans
        ? this.plans.plans.filter((x) => x.name === "Pro")
        : [];
    },
    hobbyDetails() {
      return this.plans &&
        this.plans.plans &&
        this.plans.plans.find((plan) => plan.name === "Freelancer")
        ? this.plans.plans.find((plan) => plan.name === "Freelancer").meta
        : {};
    },
    hobbyFeatures() {
      return this.plans &&
        this.plans.plans &&
        this.plans.plans.find((plan) => plan.name === "Freelancer")
        ? this.plans.plans.find((plan) => plan.name === "Freelancer").features
        : [];
    },
    getPromoInfo() {
      return this.plans.info ? this.plans.info.promo : {};
    },
    plansList() {
      return this.plans && this.plans.plans
        ? this.plans.plans.filter(
            (plan) =>
              plan.meta.category !== this.subscription.category &&
              plan.name !== "Freelancer"
          )
        : [];
    },
    freelancerFeatures() {
      return this.plans &&
        this.plans.plans &&
        this.plans.plans.find((plan) => plan.name === "Freelancer")
        ? this.plans.plans.find((plan) => plan.name === "Freelancer").features
        : [];
    },
    freelancerLimit() {
      return this.plans &&
        this.plans.plans &&
        this.plans.plans.find((plan) => plan.name === "Freelancer")
        ? this.plans.plans.find((plan) => plan.name === "Freelancer").usage
        : [];
    },
    subscription() {
      return this.plans && this.plans.info ? this.plans.info.subscription : {};
    },
    checkbox: {
      get: function () {
        return (
          StorageService.getItem("popup") &&
          StorageService.getItem("popup").subscription
        );
      },
      set: function (v) {
        const popup = StorageService.getItem("popup");
        StorageService.setItem("popup", {
          ...popup,
          subscription: v,
        });
      },
    },
  },
  created() {
    if (this.fromModal) {
      this.getPlans();
      this.logEvent(
        this.fromGetStarted ? "Viewed select plan page" : "Viewed plans popup",
        {
          message: this.infoMessage(),
        }
      );
    }
    if (
      !isEmpty(this.subscription) &&
      this.subscription.recurring === "annually"
    ) {
      this.billing = false;
      this.billingType = "annually";
    }
    if (
      !isEmpty(this.subscription) &&
      this.subscription.recurring === "monthly"
    ) {
      this.billing = false;
      this.billingType = "annually";
    }
    let plan_billing = window.sessionStorage.getItem("plan_billing");
    if (this.fromGetStarted && plan_billing) {
      this.billingType = plan_billing;
    }
  },
  mounted() {
    this.planLoading = this.loading;
  },
  methods: {
    getPlans() {
      if (this.planLoading) return;
      this.planLoading = true;
      this.$store
        .dispatch(GET_PLANS)
        .then(() => {
          this.planLoading = false;
          if (this.subscription.recurring === "monthly") {
            this.billing = false;
            this.billingType = "annually";
          }
        })
        .catch((err) => {
          this.planLoading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    resumeSubscription() {
      this.pausedAlert = false;
      if (this.selectedAction.action === "Downgrade") {
        this.changeModal = true;
      } else if (this.selectedAction.action === "Switch") {
        this.openSwitchPlanDialog(
          this.selectedAction.plan,
          this.selectedAction.type,
          this.selectedAction.planId
        );
      } else if (this.selectedAction.action === "Upgrade") {
        this.upgradePlanConfirmation(this.selectedPlan);
      }
    },
    initiatePauseAlert(details, plan) {
      this.selectedAction = {};
      this.selectedPlan = {};
      if (this.subscription.status === "paused") {
        this.selectedAction = details;
        this.selectedPlan = plan;
        this.pausedAlert = true;
      } else {
        this.changeModal = true;
      }
    },
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },
    viewAllPlanFeatures(detail) {
      this.showAllFeature = !this.showAllFeature;
      if (this.showAllFeature) {
        this.logEvent("Viewed all plan features", {
          plan_id: detail ? detail.id : "",
        });
      }
    },
    openMakeDealModal(plan) {
      this.selectedPlanId = plan.id;
      this.selectedPlanPrice = plan.meta.display_price;
      this.plansSelection = this.plans.plans.filter(
        (x) => x.meta.category == plan.meta.category
      );
      this.makeDealModal = true;
    },
    getDiscount(currentPrice, previousPrice) {
      if (currentPrice && previousPrice) {
        return Math.floor(
          (parseInt(currentPrice) / parseInt(previousPrice)) * 100
        );
      }
    },
    startFreeTrial() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(START_FREE_TRIAL, { plan_id: "V3GoPO2xXy" })
        .then((response) => {
          this.notifyUserMessage({ message: response.message });
          this.$store.dispatch(GET_USER_ROLES).catch((err) => {
            this.notifyErrorMessage(err.message);
          });
          this.getPlans();
          if (this.fromGetStarted) {
            this.$router.push({ name: "started-invite" });
          }
          this.$emit("success");
          this.loading = false;
          this.confirmationModal = false;
          this.startTrial = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
    upgradePlanConfirmation(details) {
      if (this.subscription.status && this.subscription.status === "active") {
        this.confirmationModal = false;
        this.changeModal = true;
        this.selectedPlanId = details.id;
        return;
      }
      this.selectedPlan = details;
      this.selectedPlanId = details.id;
      // this.upgradePlan();
      this.selectedPlans = this.plans.plans.filter(
        (plan) => plan.name === details.name
      );
      this.$nextTick(() => {
        this.upgradeSummaryModal = true;
      });
      this.logEvent("initiated upgrade plan", {
        plan_id: details.id,
        plan_name: details.name,
      });
    },
    openFreeTrialModal(details) {
      this.selectedPlan = details;
      this.selectedPlanId = details.id;
      this.logEvent("Initiated start trial", {
        plan_id: details.id,
        plan_name: details.name,
      });
      this.startFreeTrial();
    },
    upgradePlan(url) {
      this.upgradeSummaryModal = false;
      if (this.startTrial) {
        this.startFreeTrial();
      } else {
        if (this.subscription.active && this.subscription.status === "active") {
          this.confirmationModal = false;
          this.changeModal = true;
          this.selectedPlanId = this.selectedPlan.id;
          return;
        }

        this.paddleLoadingModal = true;

        this.logEvent("Initiated the upgrade payment popup", {
          plan_id: this.selectedPlan.id,
          plan_name: this.selectedPlan.name,
        });

        this.confirmationModal = false;
        let self = this;
        window.Paddle.Checkout.open({
          override: url || "",
          product: this.selectedPlan.gateway_id || this.getSelectedGatewayId,
          email: this.user?.email,
          passthrough: JSON.stringify({
            user_id: this.user?.id,
            team_id: self.getTeamId,
          }),
          loadCallback: function () {
            self.paddleLoadingModal = false;
          },
          successCallback: function () {
            self.logEvent("Upgrade plan", {
              plan_id: self.selectedPlan.id,
              plan_name: self.selectedPlan.name,
            });

            self.notifyUserMessage({
              message: "Your plan has been successfully upgraded 🎉",
            });
            self.successModal = true;
            if (self.fromGetStarted) {
              self.$router.push({ name: "started-invite" });
            }
            setTimeout(() => {
              self.successModal = false;
              window.location.reload();
            }, 5000);
          },
          closeCallback: function () {
            self.paddleLoadingModal = false;
            self.logEvent("Closed the upgrade payment popup", {
              plan_id: self.selectedPlan.id,
              plan_name: self.selectedPlan.name,
            });
          },
        });
      }
    },
    changePlan() {
      let self = this;
      this.upgradeMessage = null;
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(CHANGE_PLANS, { plan_id: this.selectedPlanId })
        .then((response) => {
          this.upgradeMessage = response.message;
          this.$nextTick(() => {
            self.successModal = true;
            setTimeout(() => {
              self.successModal = false;
              window.location.reload();
            }, 5000);
          });
          this.changeModal = false;
          this.upgradePlanMessage = "";
          this.switchPlanDialog = false;
          this.busy = false;
          this.$emit("close");
          this.changeModal = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
        });
    },
    getFormattedDate(date) {
      return dayjs(date).format("MMM, DD YYYY");
    },
    openSwitchPlanDialog(plans, type, currentPlanId) {
      this.changingPlan = plans;
      this.changingType = type;
      if (this.plans && this.plans.plans) {
        this.selectedPlanId = this.plans.plans.find(
          (plan) => plan.name === plans && plan.recurring === type
        ).id;
      }
      this.logEvent(segmentEvents.OPEN_SWITCH_PLAN, {
        plan_id: this.selectedPlanId,
        plan_name: plans,
        plan_billing: type,
        current_plan_id: currentPlanId,
        current_plan_name: plans,
        current_plan_billing: type === "monthly" ? "annually" : "monthly",
      });
      this.switchPlanDialog = true;
    },
    openUpdateUrl() {
      let self = this;
      window.Paddle.Checkout.open({
        override:
          this.subscription && this.subscription.urls
            ? this.subscription.urls.update
            : "",
        successCallback: function () {
          self.logEvent(segmentEvents.UPDATE_BILLING);
          window.Paddle.Spinner.show();
          setTimeout(() => {
            self.notifyUserMessage(
              "Billing Information Processed! Your updates have been successfully applied. Note: We do not store your billing details"
            );
            self.getPlans();
            window.Paddle.Spinner.hide();
          }, 5000);
        },
        closeCallback: function () {
          self.logEvent(segmentEvents.CLOSED_UPDATE, {
            plan_id: self.subscription.id,
            plan_name: self.subscription.name,
          });
        },
      });
    },
    infoMessage() {
      if (this.upgradePlanText) {
        return this.upgradePlanText;
      } else if (this.fromGetStarted) {
        return "";
      } else {
        return `This release has exceeded your team's plan limit which is ${
          this.allowedSize
        }. You have uploaded ${this.uploadedSize}. <br> ${
          isEmpty(this.getTeamTrial)
            ? "Start your team's free trial"
            : "Upgrade now"
        } to increase the max upload limit.`;
      }
    },
    checkIfMaxPlan() {
      return (
        this.fromModal &&
        this.subscription.category === "pro" &&
        !this.startTrial
      );
    },
  },
};
</script>

<style scoped>
.fontMutedColor {
  color: #f1f5fc;
}
.planBoxCard {
  border-radius: 20px;
}

.switch-view-selected {
  background-color: rgb(115, 115, 115) !important;
}

.switch-view {
  background-color: rgb(227, 227, 227) !important;
  opacity: 0.6;
}
</style>
