var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('div', [_c('ContentLoader')], 1) : _c('div', [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$app;
        var hover = _ref.hover;
        return [hover ? _c('v-badge', {
          attrs: {
            "value": !!((_vm$app = _vm.app) !== null && _vm$app !== void 0 && _vm$app.image),
            "light": "",
            "color": "grey",
            "avatar": ""
          },
          scopedSlots: _vm._u([{
            key: "badge",
            fn: function fn() {
              return [_c('v-icon', {
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "size": "20",
                  "color": "darkGrey"
                },
                on: {
                  "click": _vm.openDeleteImagePopup
                }
              }, [_vm._v("close")])];
            },
            proxy: true
          }], null, true)
        }, [_c('img', {
          attrs: {
            "src": _vm.fromCreate ? _vm.currentImage || _vm.getAppImage(_vm.app) : _vm.app.image || _vm.getAppImage(_vm.app),
            "width": _vm.width,
            "height": _vm.height
          },
          on: {
            "error": _vm.replaceDefaultAppImage
          }
        }), _c('v-fade-transition', [hover && !_vm.loading ? _c('v-overlay', {
          attrs: {
            "z-index": "1",
            "absolute": "",
            "color": "black"
          }
        }, [_c('label', [!_vm.loading && _vm.canManageApp ? _c('v-icon', {
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              _vm.appImageUpload = true;
            }
          }
        }, [_vm._v("edit")]) : _vm._e(), _vm.loading ? _c('v-btn', {
          attrs: {
            "icon": "",
            "loading": _vm.loading
          }
        }) : _vm._e()], 1)]) : _vm._e()], 1)], 1) : _c('div', [_c('img', {
          attrs: {
            "src": _vm.fromCreate ? _vm.currentImage || _vm.getAppImage(_vm.app) : _vm.app.image || _vm.getAppImage(_vm.app),
            "width": _vm.width,
            "height": _vm.height
          },
          on: {
            "error": _vm.replaceDefaultAppImage
          }
        })])];
      }
    }])
  })], 1), _c('div', [_c('Modal', {
    on: {
      "click": function click($event) {
        _vm.showDeletePopup = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', {
          staticClass: "font-size-h3 bold-text",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Delete Image ")]), _c('v-divider'), _c('v-card-text', {
          staticClass: "message my-2",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Are you sure you want to delete this Image? "), _c('div', [_vm._v("You can upload it later")])]), _c('v-divider'), _c('v-card-actions', {
          staticClass: "text-right pb-3",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "text": "",
            "color": "primary",
            "disabled": _vm.busy
          },
          on: {
            "click": function click($event) {
              _vm.showDeletePopup = false;
            }
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "depressed": "",
            "color": "primary",
            "loading": _vm.busy
          },
          on: {
            "click": _vm.onDeleteImage
          }
        }, [_vm._v(" Yes ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showDeletePopup,
      callback: function callback($$v) {
        _vm.showDeletePopup = $$v;
      },
      expression: "showDeletePopup"
    }
  }), _c('Modal', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.mdAndUp ? '650' : ''
    },
    on: {
      "close": function close($event) {
        _vm.appImageUpload = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.appImageUpload ? _c('image-upload', {
          attrs: {
            "hide-overlay": "",
            "from-create": "",
            "sub-title": "100x100 pixels for the public install page",
            "view-port": {
              width: 100,
              height: 100
            }
          },
          on: {
            "close": function close($event) {
              _vm.appImageUpload = false;
            },
            "cropped-image": _vm.uploadImage,
            "base64-data": _vm.setCurrentImage
          },
          model: {
            value: _vm.appImage,
            callback: function callback($$v) {
              _vm.appImage = $$v;
            },
            expression: "appImage"
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.appImageUpload,
      callback: function callback($$v) {
        _vm.appImageUpload = $$v;
      },
      expression: "appImageUpload"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }