<template>
  <widget
    :can-be-managed="canManage"
    :is-empty="feedbacks.length === 0"
    :loading="feedbacksLoading"
  >
    <template v-slot:widget-loading>
      <v-row align="center">
        <v-col cols="6">
          <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        </v-col>
        <v-col cols="6" style="display: flex; justify-content: flex-end">
          <v-skeleton-loader
            style="margin-right: 16px"
            type="button"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-skeleton-loader
            v-for="i in 5"
            :key="i"
            :loading="true"
            class="transparent"
            type="list-item-avatar-two-line"
          />
        </v-col>
      </v-row>
    </template>

    <template v-slot:widget-content>
      <v-card-title>
        <v-row align="center">
          <v-col cols="6">
            <div class="card-label bold-text text-dark">
              {{ text.widgetName }}
            </div>
          </v-col>
          <v-col v-if="feedbacks.length > 0" cols="6" style="text-align: end">
            <v-btn
              :outlined="$vuetify.theme.dark"
              color="primary"
              @click="onViewAll"
              >{{ text.viewAll }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="feedbackHeader"
        :items="feedbacks"
        disable-sort
        hide-default-footer
        mobile-breakpoint="0"
        @click:row="handleRowClick"
      >
        <template #item.device.model="{ item }">
          <v-icon
            class="mr-1"
            size="24"
            v-text="
              item.device.brand === 'Android' ? 'mdi-android' : 'mdi-apple'
            "
          />
          <span> {{ item.device.model }}</span>
        </template>
        <template #item.application="{ item }">
          <div class="text-left py-2">
            <div>{{ item?.application?.name }}</div>
            <MaxText
              max="25"
              :text="`${item?.application?.version} (${item?.application?.version_code})`"
            ></MaxText>
          </div>
        </template>
        <template #item.user="{ item }">
          <user-avatar :user-detail="item.user"></user-avatar>
        </template>

        <template #item.attachments="{ item }">
          {{ item.stats.attachments }}
        </template>
        <template #item.sessions="{ item }">
          {{ item.stats.sessions }}
        </template>

        <template #item.status="{ item }">
          <feedback-status :status="item.status"></feedback-status>
        </template>

        <template #item.navigate>
          <div class="flex-row jc-c">
            <v-btn icon>
              <i class="arrow-right"></i>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </template>

    <template v-slot:widget-no-permission>
      <v-card-title>
        {{ text.widgetName }}
      </v-card-title>
      <no-data
        :first-text="text.noData"
        :second-text="text.noPermission"
      ></no-data>
    </template>

    <template v-slot:widget-no-data>
      <v-card-title>
        {{ text.widgetName }}
      </v-card-title>
      <no-data
        :first-text="text.noData"
        :second-text="text.noDataSecond"
      ></no-data>
    </template>
  </widget>
</template>

<script>
import FeedbackStatus from "@/view/components/Feedback/FeedbackStatus.vue";
import Widget from "@/view/components/Common/Widget.vue";
import UserAvatar from "@/view/components/Common/UserAvatar";

const feedbackHeader = [
  {
    text: "Application",
    value: "application",
    align: "center",
    width: 140,
  },
  {
    text: "Device",
    align: "start",
    value: "device.model",
    width: 100,
    cellClass: "no-wrap",
  },
  {
    text: "User",
    align: "start",
    sortable: false,
    width: "50",
    value: "user",
  },
  {
    text: "Message",
    align: "start",
    sortable: false,
    width: "200",
    value: "message",
  },
  {
    text: "Sessions",
    align: "center",
    sortable: false,
    value: "sessions",
  },
  {
    text: "Attachments",
    align: "center",
    sortable: false,
    value: "attachments",
  },
  {
    text: "Status",
    align: "center",
    sortable: false,
    value: "status",
  },
  {
    text: "",
    align: "center",
    sortable: false,
    width: "100",
    value: "navigate",
  },
];

export default {
  components: { UserAvatar, Widget, FeedbackStatus },
  props: {
    feedbacks: {
      type: Array,
      default: () => [],
    },
    totalFeedbackCount: {
      type: Number,
      default: 0,
    },
    feedbacksLoading: {
      type: Boolean,
      default: false,
    },
    canManage: {
      type: Boolean,
      default: false,
    },
    onViewAll: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      feedbackHeader,
      text: {
        viewAll: "View all",
        widgetName: "Recent Feedback",
        noData: "No feedback",
        noPermission: `You don't have the permission to view or manage the feedback`,
        noDataSecond:
          "Looks like there's no feedback yet. Use Sessions to capture user experiences with screenshots and recordings, and see exactly what they're experiencing",
      },
    };
  },
  computed: {
    appId() {
      return this.$route.params.app_id;
    },
  },
  methods: {
    handleRowClick(item) {
      this.$router.push({
        name: "session-feedback-detail",
        params: {
          app_id: item.app_id ?? this.appId,
          session_id: item.session_id,
          feedback_id: item.id,
        },
      });
    },
  },
};
</script>
