<template>
  <div id="storage-select-box">
    <v-select
      v-model="storageId"
      v-validate="''"
      :menu-props="{ offsetY: true }"
      attach
      append-icon="mdi-chevron-down"
      class="mb-4"
      filled
      :items="storageList"
      item-value="id"
      item-text="name"
      v-bind="veeValidate('Storage', 'Storage')"
      placeholder="Select Storage"
      :loading="loading"
    >
      <template #item="{ item }">
        <template v-if="!item.action">
          <div
            class="d-flex justify-center align-center mr-4"
            style="width: 32px"
          >
            <img
              v-if="item.icon"
              height="24"
              :src="item.icon ? getStorageImg(item.icon) : null"
            />
          </div>
          {{ item.name }}
        </template>
        <template v-else>
          <div
            @click="item.disabled ? '' : openCreateForm"
            class="d-flex justify-center primary--text align-center mr-4"
            :class="item.disabled ? 'text-muted' : ''"
          >
            <v-icon
              left
              class="sticky-list-icon"
              :color="item.disabled ? '' : 'primary'"
              >mdi-plus</v-icon
            >
            {{ item.name }}
            <v-chip v-if="item.disabled" label small class="text-right"
              >Starter & Pro</v-chip
            >
          </div>
        </template>
      </template>
      <template #no-data>
        <div class="pa-4">
          <div v-if="loading" class="d-flex align-center justify-center">
            <ContentLoader size="25" />
          </div>
          <div v-else>No data available</div>
        </div>
      </template>
    </v-select>
    <Modal
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="openCreateStorage"
      @close="(storageId = ''), (openCreateStorage = false)"
    >
      <template #message>
        <div class="px-7 py-4">
          <create-form
            v-if="openCreateStorage"
            @refresh="afterSuccess"
            @close="(storageId = ''), (openCreateStorage = false)"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import { GET_STORAGE } from "@/store/team/storage.module";
import CreateForm from "@/view/components/Team/Storage/CreateForm";
import { mapGetters } from "vuex";
export default {
  components: { CreateForm },
  DEFAULT_STORAGE: {
    id: "",
    name: "TestApp.io",
    description: "",
    icon: "testapp.io.svg",
  },
  ADD_NEW_STORAGE: {
    id: "create_new",
    name: "Add new storage",
    action: "create",
  },
  mixins: [veeValidate],
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      storageId: "",
      openCreateStorage: false,
      storageList: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getTeamUsageInformation: "getTeamUsageInformation"
    }),
  },
  watch: {
    storageId(newValue) {
      if (this.storageId === "create_new") {
        this.openCreateForm();
      } else {
        this.$emit("input", newValue);
      }
    },
  },
  created() {
    this.getStorageList();
  },
  methods: {
    getStorageList() {
      this.$emit("fetch", true);
      if (this.loading) return;
      this.loading = true;
      if (this.getTeamUsageInformation && this.getTeamUsageInformation?.connect_storage) {
        this.$store
          .dispatch(GET_STORAGE)
          .then((response) => {
            this.storageList = [
              this.$options.DEFAULT_STORAGE,
              ...response.external,
              this.$options.ADD_NEW_STORAGE,
            ];
            this.$emit("fetch", false);
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.notifyErrorMessage({ message: err.message });
          });
      } else {
        this.storageList = [
          this.$options.DEFAULT_STORAGE,
          { ...this.$options.ADD_NEW_STORAGE, disabled: !this.getTeamUsageInformation.connect_storage },
        ];
        this.loading = false;
        this.$emit("fetch", false);
      }
    },
    openCreateForm() {
      this.openCreateStorage = true;
    },
    afterSuccess(storage) {
      this.getStorageList();
      this.storageId = storage.id;
      this.openCreateStorage = false;
    },
  },
};
</script>
