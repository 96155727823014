<template>
  <v-card outlined class="pa-2">
    <v-card-title>
      <div>
        <div class="font-size-h3 bold-text">Timeline</div>
        <div class="bold-text text-body-1">Timeline of this app</div>
      </div>
    </v-card-title>
    <v-card-text>
      <div v-if="canViewTimeline">
        <div v-if="infoError">
          <noData :firstText="infoError" permission-issues />
        </div>
        <div v-else>
          <div class="p-10">
            <v-timeline align-top dense v-if="timelineLoading">
              <v-timeline-item
                color="grey lighten-1"
                small
                v-for="i in 4"
                :key="i"
              >
                <v-skeleton-loader
                  type="article"
                  class="transparent mx-n4 mt-n3"
                  style="max-width: 300px"
                />
              </v-timeline-item>
            </v-timeline>
            <noData
              v-else-if="errorMessage"
              :first-text="errorMessage"
              permission-issues
            />
            <noData v-else-if="!timeline" />

            <v-timeline align-top dense v-else>
              <v-timeline-item
                color="grey"
                small
                v-for="item in timeline"
                :key="item.id"
              >
                <!-- <v-row class="pt-1" no-gutters>
                  <v-col cols="12">
                    <v-skeleton-loader
                      cols="12"
                      type="article"
                      class="transparent mb-2 mx-n4"
                      style="width:300px"
                    />
                  </v-col>
                </v-row> -->
                <v-row class="pt-1" no-gutters>
                  <v-col cols="12" class="mb-2">
                    <b>
                      <vue-hoverable-date
                        :date="item.created_at"
                      ></vue-hoverable-date>
                    </b>
                  </v-col>
                  <v-col cols="12" class="mb-2">
                    <div class="bodyFont">
                      {{ item.title }}
                    </div>
                    <div class="bodyFont mt-2">
                      <div v-if="item.action === 'member_invite'">
                        {{ item.user.name }} accepted the invite
                      </div>
                      <div>
                        {{ item.content }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>

            <!--  -->
          </div>
          <div style="display: flex; justify-content: flex-end">
            <v-pagination
              flat
              class="ml-auto"
              v-model="page"
              :length="totalLength || 0"
              :disabled="getTotalTimeLineCount < 10"
              :total-visible="0"
            ></v-pagination>
          </div>
        </div>
      </div>
      <div v-else>
        <no-data
          first-text="No timeline"
          second-text="You don't have the permission to view the app timeline"
          permission-issues
        ></no-data>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { isEmpty } from "@/core/services/helper.service";
import { getTimelineTime } from "@/core/services/helper.service";
import { TIMELINE } from "@/store/apps/timeline.module.js";

export default {
  name: "app-timeline",
  props: ["app_id", "loader", "info", "infoError"],
  data() {
    return {
      isLoaded: false,
      timelineLoading: false,
      page: 1,
      errorMessage: null,
      getTimelineTime,
    };
  },
  watch: {
    page(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.totalTimelineList.length > oldVal * 10) {
          this.setPreviousTimelineData(
            this.totalTimelineList.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.timeline[this.timeline.length - 1].id;
          this.loadData(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousTimelineData(
          this.totalTimelineList.slice(offset, limit)
        );
      }
    },
  },
  computed: {
    ...mapGetters({
      timeline: "getTimeline",
      getTotalTimeLineCount: "getTotalTimeLineCount",
      totalTimelineList: "getTotalTimelineList",
      getInfoErrorMessage: "getInfoErrorMessage",
      getAppInfoLoading: "getAppInfoLoading",
    }),
    noData() {
      return isEmpty(this.timeline) ? true : false;
    },
    totalLength() {
      return Math.ceil(this.getTotalTimeLineCount / 10);
    },
    noInfo() {
      return isEmpty(this.info) ? true : false;
    },
  },
  methods: {
    ...mapMutations({
      setPreviousTimelineData: "setPreviousTimelineData",
    }),
    loadData(lastId) {
      this.errorMessage = null;
      if (!this.infoError) {
        if (this.timelineLoading) return;
        this.timelineLoading = true;
        this.$store
          .dispatch(TIMELINE, {
            app_id: this.$route.params.app_id,
            lastId: lastId || "",
          })
          .then(() => {
            this.timelineLoading = false;
          })
          .catch((err) => {
            this.errorMessage = err.message;
            this.timelineLoading = false;
          });
      }
    },
  },
  created() {
    if (
      this.noData &&
      this.canViewTimeline &&
      !this.getInfoErrorMessage &&
      !this.getAppInfoLoading
    ) {
      this.loadData();
    }
  },
};
</script>
