<template>
  <v-card>
    <v-card-title class="mb-2">
      <v-spacer></v-spacer>
      <div>
        <div class="headingTitle bold-text">Make a Deal</div>
        <div class="mt-2 text-center">for the entire team</div>
      </div>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')">
        <v-icon color="darkGrey" v-text="'mdi-close'"></v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="px-0">
      <v-row class="pt-2 px-4 mx-0">
        <v-col class="bodyFont" cols="12">
          Plan:
          <span class="semi-bold mx-2">{{ activePlanSelected.name }}</span>
        </v-col>
        <v-col cols="12">
          <v-card
            :color="$vuetify.theme.dark ? 'background' : 'blue-grey lighten-5'"
            class="pa-3"
            flat
          >
            <v-row align="center">
              <v-col cols="auto">
                <v-avatar class="radius-6" color="primary" size="40" tile>
                  <v-icon color="white">mdi-calendar-clock</v-icon>
                </v-avatar>
              </v-col>
              <v-col class="subListFont darkGrey--text" cols="6">
                How often do you want to be billed?
              </v-col>
              <v-col>
                <v-chip
                  :class="
                    !$vuetify.theme.dark && billingType === 'monthly'
                      ? 'white--text'
                      : ''
                  "
                  :color="
                    billingType === 'monthly'
                      ? 'switch-view-selected'
                      : 'switch-view'
                  "
                  :outlined="$vuetify.theme.dark"
                  label
                  small
                  @click="billingType = 'monthly'"
                >
                  <v-icon left>mdi-view-list</v-icon>
                  Monthly
                </v-chip>
                <v-chip
                  :class="
                    !$vuetify.theme.dark && billingType === 'annually'
                      ? 'white--text'
                      : ''
                  "
                  :color="
                    billingType === 'annually'
                      ? 'switch-view-selected'
                      : 'switch-view'
                  "
                  :outlined="$vuetify.theme.dark"
                  label
                  small
                  @click="billingType = 'annually'"
                >
                  <v-icon left>mdi-card-text</v-icon>
                  Yearly
                </v-chip>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card
            :color="$vuetify.theme.dark ? 'background' : 'blue-grey lighten-5'"
            class="pa-3"
            flat
          >
            <v-row align="center">
              <v-col cols="auto">
                <v-avatar color="primary" size="40" title>
                  <v-icon color="white">mdi-cash</v-icon>
                </v-avatar>
              </v-col>
              <v-col class="subListFont darkGrey--text" cols="7">
                How much are you willing to pay {{ billingType }}?
              </v-col>
              <v-col>
                <v-text-field
                  v-model="formValues.price"
                  v-validate="'required'"
                  autofocus
                  color="primary"
                  filled
                  hide-details
                  max="100000"
                  min="1"
                  outlined
                  prepend-inner-icon="mdi-currency-usd"
                  style="font-size: 38px; color: #6c92bf"
                  type="number"
                  v-bind="veeValidate('Price', '')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mx-0 px-8">
        <v-col cols="12">
          <div class="mb-3 semi-bold font14">Your message (Optional)</div>
          <v-textarea
            v-model="formValues.message"
            v-validate="'max:600'"
            counter="600"
            filled
            large
            placeholder="Type your message here..."
            prepend-inner-icon="mdi-message-outline"
            v-bind="veeValidate('message', '')"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="mb-3">
      <v-btn
        :loading="loading"
        :outlined="$vuetify.theme.dark"
        block
        class="pa-7 text-transform-none"
        color="primary"
        depressed
        x-large
        @click="submitForm"
      >
        <div class="font17">Let's make a deal 💪</div>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import { MAKE_A_DEAL } from "@/store/team/team.module";

export default {
  name: "make-deal-form",
  mixins: [veeValidate],
  props: {
    planId: {
      type: String,
      default: "",
    },
    price: {
      type: [Number, String],
      default: 0,
    },
    selectedPlan: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      billingType: "monthly",
      formValues: {
        price: 20,
        members: 10,
        message: "",
      },
    };
  },
  computed: {
    activePlanSelected() {
      return this.selectedPlan.find(
        (plan) => plan.recurring === this.billingType
      );
    },
  },
  watch: {
    activePlanSelected(val) {
      this.formValues.price = val.price;
    },
    "formValues.price"(val) {
      if (val > 100000) {
        this.$nextTick(() => {
          this.formValues.price = 100000;
        });
      }
      if (val < 1) {
        this.$nextTick(() => {
          this.formValues.price = 1;
        });
      }
    },
    "formValues.members"(val) {
      if (val > 100000) {
        this.$nextTick(() => {
          this.formValues.members = 100000;
        });
      }
      if (val < 1) {
        this.$nextTick(() => {
          this.formValues.members = 1;
        });
      }
    },
  },
  created() {
    if (this.activePlanSelected) {
      this.formValues.price = this.activePlanSelected.price;
    }
  },
  methods: {
    async submitForm() {
      if (await this.validateAllFields()) {
        if (this.loading) return;
        this.loading = true;
        const dataToPost = {
          message: this.formValues.message,
          members: parseInt(this.formValues.members),
          price: parseInt(this.formValues.price),
          plan_id: this.activePlanSelected.id,
        };
        this.$store
          .dispatch(MAKE_A_DEAL, dataToPost)
          .then((response) => {
            this.loading = false;
            this.$emit("close");
            this.notifyUserMessage({ message: response.message });
          })
          .catch((err) => {
            this.loading = false;
            this.notifyErrorMessage(err.message);
          });
      }
    },
  },
};
</script>

<style scoped>
.switch-view-selected {
  background-color: rgb(115, 115, 115) !important;
}

.switch-view {
  background-color: rgb(227, 227, 227) !important;
  opacity: 0.6;
}
</style>