<template>
  <div style="margin-top: 40px">
    <v-row v-if="errorMessages">
      <v-col v-if="errorMessages" cols="12">
        <no-data :firstText="errorMessages" with-button></no-data>
      </v-col>
    </v-row>
    <div v-else>
      <v-row v-if="loading">
        <v-col cols="12">
          <v-skeleton-loader type="card, list"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" v-for="col in 4" :key="col">
          <v-skeleton-loader type="table"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-if="!loading">
        <v-col cols="12">
          <app-release-stats></app-release-stats>
        </v-col>
      </v-row>
      <!-- <v-row v-if="canManageApp">
        <v-col cols="12">
          <app-charts></app-charts>
        </v-col>
      </v-row> -->
      <v-row v-if="!loading">
        <v-col cols="12">
          <recent-releases-widget
            :releases="dashboardList.releases"
            :dashboard-loader="loading"
          ></recent-releases-widget>
        </v-col>
        <v-col cols="12">
          <recent-comments-widget
            :comments="dashboardList.release_comments"
            :dashboard-loader="loading"
          ></recent-comments-widget>
        </v-col>
        <v-col v-if="canManageApp" cols="12" md="12">
          <recent-sessions-widget
            :sessions="dashboardList.sessions"
            :sessions-loading="loading"
          />
        </v-col>
        <v-col v-if="canManageFeedback" cols="12" md="12">
          <recent-feedback-widget
            :feedbacks="dashboardList.feedback"
            :feedback-loading="loading"
          />
        </v-col>
      </v-row>
      <v-row v-if="isNotGuestRole">
        <v-col v-if="canViewTimeline" cols="12" md="6">
          <app-timeline :app_id="app_id" :info="appInfo"></app-timeline>
        </v-col>
      </v-row>
    </div>

    <SidePanel
      v-model="releaseDrawer"
      width="720"
      @close="releaseDrawer = false"
    >
      <create-release-step
        v-if="releaseDrawer"
        :app-info="appInfo"
        @close="(releaseDrawer = false), $emit('close')"
        @released="
          $router.push({ name: 'releases', params: { app_id: appInfo.id } })
        "
      />
    </SidePanel>
  </div>
</template>

<script>
// import AppStats from "@/view/components/App/Dashboard/AppStats.vue";
import { mapGetters } from "vuex";
// import AppCharts from "@/view/components/App/Dashboard/AppCharts.vue";
import CreateReleaseStep from "@/view/components/Releases/CreateRelease.vue";
import AppTimeline from "@/view/components/App/Timeline.vue";
import RecentFeedbackWidget from "@/view/pages/app/dashboard/RecentFeedbackWidget.vue";
import RecentSessionsWidget from "@/view/pages/app/dashboard/RecentSessionsWidget.vue";
import RecentCommentsWidget from "../../user/dashboard/widgets/RecentCommentsWidget.vue";
import RecentReleasesWidget from "../../user/dashboard/widgets/RecentReleasesWidget.vue";
import AppReleaseStats from "@/view/components/App/AppReleaseStats";

export default {
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    app_id: {
      type: String,
      default: "",
    },
  },
  components: {
    AppReleaseStats,
    RecentCommentsWidget,
    RecentSessionsWidget,
    RecentFeedbackWidget,
    RecentReleasesWidget,
    // AppCharts,
    // AppStats,
    AppTimeline,
    CreateReleaseStep,
  },
  data() {
    return {
      releaseDrawer: false,
      dashboardList: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      appInfo: "getAppInfo",
      getInfoErrorMessage: "getInfoErrorMessage",
      getAppInfoLoading: "getAppInfoLoading",
    }),
    errorMessages() {
      return this.getInfoErrorMessage;
    },
  },
  created() {
    if (!this.getInfoErrorMessage && !this.getAppInfoLoading) {
      this.getAppDashboardData();
    }
  },
  methods: {
    getAppDashboardData() {
      if (this.loading) return;
      this.loading = true;
      this.$portalApi
        .get(
          `${this.apiRoutes?.portal?.app?.getDashboardData(
            this.$route.params.app_id
          )}`
        )
        .then(({ data }) => {
          this.dashboardList = data;
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
