<template>
  <sessions-widget
    :can-manage="canManageApp"
    :on-view-all="handleViewAll"
    :sessions="sessions"
    :sessions-loading="sessionsLoading"
    :total-sessions-count="totalSessionsCount"
  />
</template>

<script>
import SessionsWidget from "@/view/components/Common/widgets/SessionsWidget.vue";

export default {
  props: {
    sessions: {
      type: Array,
      required: true,
    },
    sessionsLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: { SessionsWidget },
  data() {
    return {
      totalSessionsCount: 0,
    };
  },
  computed: {
    appId() {
      return this.$route.params.app_id;
    },
  },
  methods: {
    handleViewAll() {
      this.$router.push({
        name: "app-sessions",
        params: {
          app_id: this.appId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
