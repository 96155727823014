var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_vm.fromModal && !_vm.fromGetStarted && !_vm.fromPlanPage ? _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('vue-inline-alert', {
    staticClass: "mt-10 mb-3",
    attrs: {
      "message": _vm.infoMessage(),
      "message-type": "error",
      "no-close-btn": ""
    }
  })], 1) : _vm._e()], 1), _c('v-row', {
    staticClass: "my-8",
    attrs: {
      "align": "center",
      "justify": "space-around"
    }
  }, [_c('v-col', {
    staticClass: "text-center darkGrey--text font35 extra-bold",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Make "), _c('b', [_vm._v("better")]), _vm._v(" apps ")]), _c('div', {
    staticClass: "font17 text-muted mt-2 mb-5"
  }, [_vm._v(" Trusted by 50,000+ teams, agencies and freelancers ")])], 1), _vm.checkIfMaxPlan() ? _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    staticClass: "my-5",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-card', {
    staticClass: "pa-5",
    attrs: {
      "elevation": "2"
    }
  }, [_c('v-card-title', [_c('h3', [_vm._v("Thank You for Being on the Pro Plan!")])]), _c('v-card-text', {
    staticClass: "text-muted bodyFont"
  }, [_vm._v(" We understand that you are running into some limitations. We're excited to announce that we'll be introducing self-serve add-ons soon. In the meantime, if you need any additional features or resources, please chat with us and we'll be happy to enable them for you. ")]), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.openIntercomChat(_vm.contactMessage);
      }
    }
  }, [_vm._v("Chat with Us")])], 1)], 1)], 1)], 1)], 1) : _vm._e(), !_vm.checkIfMaxPlan() ? _c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-chip', {
    class: !_vm.$vuetify.theme.dark && _vm.billingType === 'monthly' ? 'white--text' : '',
    attrs: {
      "color": _vm.billingType === 'monthly' ? 'switch-view-selected' : 'switch-view',
      "outlined": _vm.$vuetify.theme.dark,
      "label": ""
    },
    on: {
      "click": function click($event) {
        _vm.billingType = 'monthly';
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-view-list")]), _vm._v(" Monthly")], 1), _c('v-chip', {
    class: !_vm.$vuetify.theme.dark && _vm.billingType === 'annually' ? 'white--text' : '',
    attrs: {
      "color": _vm.billingType === 'annually' ? 'switch-view-selected' : 'switch-view',
      "outlined": _vm.$vuetify.theme.dark,
      "label": ""
    },
    on: {
      "click": function click($event) {
        _vm.billingType = 'annually';
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-card-text")]), _vm._v(" Yearly")], 1)], 1), _c('v-col', {
    staticClass: "text-center text-muted bodyFont",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v(" Pick the annual billing and save "), _c('b', [_vm._v("30%")]), _vm._v("! That's like getting nearly "), _c('b', [_vm._v("4 months")]), _vm._v(" free 🎉 ")])])], 1) : _vm._e(), !_vm.checkIfMaxPlan() ? _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [!_vm.planLoading ? [!_vm.fromModal ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "pa-10 planBoxCard",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "darkGrey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "titleFont darkGrey--text bold-text"
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-water-circle')
    }
  }), _vm._v(" Freelancer ")], 1), _c('div', [_vm._v("The essentials to provide your best work for clients.")])])], 1), _c('v-row', {
    staticClass: "darkGrey--text"
  }, [_c('v-col', [_c('div', [_c('span', {
    staticClass: "font65 extra-bold titleFont",
    attrs: {
      "id": "currentPlanPrice"
    }
  }, [_vm._v(" $" + _vm._s("0"))]), _c('div', {
    staticClass: "darkGrey--text pt-2"
  }, [_vm._v(" " + _vm._s(_vm.hobbyDetails.display_price_message) + " ")])])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [!_vm.subscription.status && !_vm.activeTrail ? _c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary",
      "outlined": ""
    }
  }, [_vm._v(" Current Plan ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, _vm._l(_vm.hobbyFeatures, function (feat, index) {
    return _c('div', {
      key: index + feat.title,
      staticClass: "mb-3 bodyFont"
    }, [feat.info ? _c('v-tooltip', {
      attrs: {
        "bottom": "",
        "color": _vm.$vuetify.theme.dark ? '' : 'primary'
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('span', _vm._g({}, on), [_c('v-icon', {
            attrs: {
              "color": "primary",
              "size": "24",
              "left": ""
            },
            domProps: {
              "textContent": _vm._s('mdi-check-circle-outline')
            }
          }), _c('span', {
            staticClass: "mr-1",
            domProps: {
              "innerHTML": _vm._s(feat.title)
            }
          })], 1)];
        }
      }], null, true)
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(feat.info)
      }
    })]) : _c('div', {
      staticClass: "darkGrey--text"
    }, [_c('v-icon', {
      attrs: {
        "color": "primary",
        "size": "24",
        "left": ""
      },
      domProps: {
        "textContent": _vm._s('mdi-check-circle-outline')
      }
    }), _c('span', {
      staticClass: "mr-1",
      domProps: {
        "innerHTML": _vm._s(feat.title)
      }
    })], 1), feat.items && feat.items.length ? _c('div', {
      staticClass: "ml-8 mt-2"
    }, _vm._l(feat.items, function (item, sdindex) {
      return _c('div', {
        key: sdindex
      }, [item.info ? _c('v-tooltip', {
        attrs: {
          "bottom": "",
          "color": "primary"
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function fn(_ref2) {
            var on = _ref2.on;
            return [_c('div', _vm._g({
              staticClass: "mb-1 darkGrey--text"
            }, on), [_c('span', {
              staticClass: "mr-1",
              domProps: {
                "innerHTML": _vm._s(item.title)
              }
            })])];
          }
        }], null, true)
      }, [_c('span', {
        domProps: {
          "innerHTML": _vm._s(item.info)
        }
      })]) : _vm._e()], 1);
    }), 0) : _vm._e()], 1);
  }), 0)], 1)], 1)], 1) : _vm._e(), _vm._l(_vm.getStarterPlans, function (plan, sindex) {
    return [plan.recurring === _vm.billingType && (_vm.subscription.name !== plan.name || _vm.activeTrail) ? _c('v-col', {
      key: sindex + plan.id,
      attrs: {
        "cols": "12",
        "md": _vm.fromModal ? 5 : 4
      }
    }, [_c('v-card', {
      staticClass: "pa-8 planBoxCard",
      attrs: {
        "outlined": _vm.$vuetify.theme.dark,
        "color": ""
      }
    }, [_c('v-row', {
      staticClass: "darkGrey--text"
    }, [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "titleFont bold-text ml-n3"
    }, [_c('v-icon', [_vm._v(" mdi-lightning-bolt-outline ")]), _vm._v(" " + _vm._s(plan.name) + " ")], 1), _c('div', {
      class: _vm.fromModal ? 'my-3' : ''
    }, [_vm._v(" " + _vm._s(plan.description) + " ")])])], 1), _c('v-row', [_c('v-col', {
      staticClass: "darkGrey--text"
    }, [_c('div', [plan.meta.previous_price ? _c('span', {
      staticClass: "mr-2 body-1 text-decoration-line-through"
    }, [_vm.billingType === 'annually' ? _c('span', [_vm._v(" " + _vm._s("$ " + plan.meta.previous_price / 12) + " ")]) : _c('span', [_vm._v(" " + _vm._s("$ " + plan.meta.previous_price) + " ")])]) : _vm._e(), _vm.isFloat(_vm.get(plan, 'meta.display_price', '')) ? _c('span', {
      staticClass: "font65 extra-bold"
    }, [_vm._v("$" + _vm._s(_vm.get(plan, "meta.display_price", "").toString().split(".")[0]) + " "), _c('span', {
      staticClass: "ml-n4 bodyFont"
    }, [_vm._v("." + _vm._s(_vm.get(plan, "meta.display_price", "").toString().split(".")[1]))])]) : _c('span', {
      staticClass: "font65 extra-bold"
    }, [_vm._v(" $" + _vm._s(_vm.get(plan, "meta.display_price", "")) + " ")]), _c('div', {
      staticClass: "darkGrey--text",
      class: _vm.fromModal ? 'mt-2' : ''
    }, [_vm._v(" " + _vm._s(_vm.get(plan, "meta.display_price_message", "")) + " ")])])])], 1), _c('v-row', {
      staticClass: "darkGrey--text"
    }, [_c('v-col', {
      staticClass: "darkGrey--text",
      attrs: {
        "cols": "10"
      }
    }, [_c('div', [!_vm.getIsUserDisabled && _vm.canManageTeam && _vm.isEmpty(_vm.getTeamTrial) && !_vm.getPromoInfo.source_name && _vm.subscription.status !== 'active' ? [!_vm.subscribe ? _c('v-btn', {
      staticClass: "text-transform-none pa-7",
      staticStyle: {
        "border-width": "3px"
      },
      attrs: {
        "id": "startTrialProPlan",
        "outlined": _vm.$vuetify.theme.dark,
        "large": "",
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.openFreeTrialModal(plan);
        }
      }
    }, [_c('span', {
      staticClass: "font14 bold-text"
    }, [_vm._v(" Start 14-day trial ")])]) : _vm._e(), !_vm.subscribe ? _c('div', {
      staticClass: "my-5"
    }, [_c('v-btn', {
      staticClass: "text-transform-none",
      attrs: {
        "outlined": "",
        "color": "darkGrey"
      },
      on: {
        "click": function click($event) {
          return _vm.upgradePlanConfirmation(plan);
        }
      }
    }, [_c('div', {
      staticClass: "smallFont"
    }, [_vm._v("Or upgrade now")])])], 1) : _vm._e()] : _vm._e(), !_vm.getIsUserDisabled && _vm.canManageTeam && !_vm.isEmpty(_vm.getTeamTrial) && !_vm.getPromoInfo.source_name && (!_vm.subscription.status || ['active', 'cancel', 'cancelled', 'paused'].includes(_vm.subscription.status) && !['pro', 'starter'].includes(_vm.subscription.category)) ? _c('v-btn', {
      staticClass: "text-transform-none my-5 pa-7",
      attrs: {
        "id": "proSubscribeBtn",
        "outlined": _vm.$vuetify.theme.dark,
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          _vm.subscription.status == 'paused' ? _vm.initiatePauseAlert({
            action: 'Upgrade'
          }, plan) : _vm.upgradePlanConfirmation(plan);
        }
      }
    }, [_c('span', {
      staticClass: "font14 bold-text"
    }, [_vm._v(" Upgrade now ")])]) : _vm._e(), !_vm.getIsUserDisabled && _vm.canManageTeam && !_vm.isEmpty(_vm.getTeamTrial) && !_vm.getPromoInfo.source_name && ['active', 'paused'].includes(_vm.subscription.status) && _vm.subscription.category !== 'starter' ? _c('v-btn', {
      staticClass: "text-transform-none cursor-pointer my-5 pa-7",
      attrs: {
        "color": "primary",
        "outlined": "",
        "block": ""
      },
      on: {
        "click": function click($event) {
          return _vm.initiatePauseAlert({
            action: 'Downgrade'
          });
        }
      }
    }, [_c('span', {
      staticClass: "font14 bold-text"
    }, [_vm._v(" Downgrade ")])]) : _vm._e(), !_vm.getIsUserDisabled && _vm.canManageTeam && !_vm.getPromoInfo.source_name && ['past_due', 'suspended'].includes(_vm.subscription.status) ? _c('v-btn', {
      staticClass: "text-transform-none cursor-pointer my-5 pa-7",
      attrs: {
        "color": "primary",
        "outlined": ""
      },
      on: {
        "click": _vm.openUpdateUrl
      }
    }, [_c('span', {
      staticClass: "font14 bold-text"
    }, [_vm._v(" Update billing ")])]) : _vm._e(), !_vm.getIsUserDisabled && _vm.canManageTeam && !_vm.isEmpty(_vm.getTeamTrial) && !_vm.getPromoInfo.source_name && ['active', 'paused'].includes(_vm.subscription.status) && _vm.subscription.category === 'starter' ? [_c('v-btn', {
      staticClass: "text-transform-none my-5 pa-7",
      class: _vm.subscription.recurring !== 'annually' ? '' : 'mb-4',
      attrs: {
        "color": "primary",
        "depressed": "",
        "id": "switchPlanFromStarter",
        "loading": _vm.busy,
        "outlined": _vm.$vuetify.theme.dark
      },
      on: {
        "click": function click($event) {
          _vm.subscription.status == 'paused' ? _vm.initiatePauseAlert({
            action: 'Switch',
            plan: 'Starter',
            type: _vm.subscription.recurring !== 'annually' ? 'annually' : 'monthly',
            planId: plan.id
          }) : _vm.openSwitchPlanDialog('Starter', _vm.subscription.recurring !== 'annually' ? 'annually' : 'monthly', plan.id);
        }
      }
    }, [_c('span', {
      staticClass: "font14 bold-text"
    }, [_vm._v(" Switch to " + _vm._s(_vm.subscription.recurring !== "annually" ? "Annually" : "Monthly") + " ")])])] : _vm._e(), !_vm.getIsUserDisabled && _vm.canManageTeam && !_vm.isEmpty(_vm.getTeamTrial) && !_vm.getPromoInfo.source_name && _vm.subscription.category === 'starter' && _vm.subscription.status && ['cancel', 'cancelled'].includes(_vm.subscription.status) ? _c('v-btn', {
      staticClass: "text-transform-none my-5 pa-7",
      attrs: {
        "block": "",
        "id": "starterUpgradePlanBtn",
        "depressed": "",
        "outlined": _vm.$vuetify.theme.dark,
        "color": _vm.$vuetify.theme.dark ? 'background' : 'primary'
      },
      on: {
        "click": function click($event) {
          return _vm.upgradePlanConfirmation(plan);
        }
      }
    }, [_c('span', {
      staticClass: "font14 bold-text"
    }, [_vm._v(" Resubscribe Now")])]) : _vm._e()], 2)])], 1), _c('v-row', [_c('v-col', {
      staticClass: "darkGrey--text",
      attrs: {
        "cols": "11"
      }
    }, _vm._l(plan.features, function (feat, starter_feat_index) {
      return _c('div', {
        key: starter_feat_index + feat.title,
        staticClass: "mb-3 bodyFont"
      }, [feat.info ? _c('v-tooltip', {
        attrs: {
          "bottom": "",
          "color": _vm.$vuetify.theme.dark ? '' : 'primary'
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function fn(_ref3) {
            var on = _ref3.on;
            return [_c('div', _vm._g({}, on), [_c('v-icon', {
              attrs: {
                "color": "primary",
                "size": "24",
                "left": ""
              },
              domProps: {
                "textContent": _vm._s('mdi-check-circle-outline')
              }
            }), _c('span', {
              staticClass: "mr-1",
              domProps: {
                "innerHTML": _vm._s(feat.title)
              }
            })], 1)];
          }
        }], null, true)
      }, [_c('div', {
        domProps: {
          "innerHTML": _vm._s(feat.info)
        }
      })]) : _c('div', [_c('v-icon', {
        attrs: {
          "color": "primary",
          "size": "24",
          "left": ""
        },
        domProps: {
          "textContent": _vm._s('mdi-check-circle-outline')
        }
      }), _c('span', {
        staticClass: "mr-1",
        domProps: {
          "innerHTML": _vm._s(feat.title)
        }
      })], 1), feat.items && feat.items.length ? _c('div', {
        staticClass: "ml-8 mt-2"
      }, _vm._l(feat.items, function (item, sdindex) {
        return _c('div', {
          key: sdindex
        }, [item.info ? _c('v-tooltip', {
          attrs: {
            "bottom": "",
            "color": "primary"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on;
              return [_c('div', _vm._g({
                staticClass: "mb-1"
              }, on), [_c('span', {
                staticClass: "mr-1",
                domProps: {
                  "innerHTML": _vm._s(item.title)
                }
              })])];
            }
          }], null, true)
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(item.info)
          }
        })]) : _vm._e()], 1);
      }), 0) : _vm._e()], 1);
    }), 0)], 1)], 1)], 1) : _vm._e()];
  }), _vm._l(_vm.getProPlans, function (plan, pro_index) {
    return [plan.recurring === _vm.billingType ? _c('v-col', {
      key: pro_index + plan.id,
      attrs: {
        "cols": "12",
        "md": _vm.fromModal ? 5 : 4
      }
    }, [_c('v-card', {
      staticClass: "pa-8 planBoxCard",
      attrs: {
        "outlined": _vm.$vuetify.theme.dark
      }
    }, [_c('v-row', [_c('v-col', {
      staticClass: "darkGrey--text",
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "titleFont bold-text"
    }, [_c('v-icon', [_vm._v("mdi-seal")]), _vm._v(" Pro ")], 1), _c('div', {
      class: _vm.fromModal ? 'my-3' : ''
    }, [_vm._v(" " + _vm._s(plan.description) + " ")])])], 1), _c('v-row', [_c('v-col', {
      staticClass: "darkGrey--text"
    }, [_c('div', [plan.meta.previous_price ? _c('span', {
      staticClass: "mr-2 body-1 text-decoration-line-through"
    }, [plan.recurring === 'annually' ? _c('span', [_vm._v(" " + _vm._s("$ " + plan.meta.previous_price / 12) + " ")]) : _c('span', [_vm._v(" " + _vm._s("$ " + plan.meta.previous_price) + " ")])]) : _vm._e(), _vm.isFloat(_vm.get(plan, 'meta.display_price', '')) ? _c('span', {
      staticClass: "font65 extra-bold"
    }, [_vm._v("$" + _vm._s(_vm.get(plan, "meta.display_price", "").toString().split(".")[0]) + " "), _c('span', {
      staticClass: "ml-n4 bodyFont"
    }, [_vm._v("." + _vm._s(_vm.get(plan, "meta.display_price", "").toString().split(".")[1]))])]) : _c('span', {
      staticClass: "font65 extra-bold"
    }, [_vm._v(" $" + _vm._s(_vm.get(plan, "meta.display_price", "")) + " ")]), _c('div', {
      class: _vm.fromModal ? 'mt-2' : ''
    }, [_vm._v(" " + _vm._s(_vm.get(plan, "meta.display_price_message", "")) + " ")])])])], 1), _c('v-row', [_c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('div', [!_vm.getIsUserDisabled && _vm.canManageTeam && _vm.isEmpty(_vm.getTeamTrial) && !_vm.getPromoInfo.source_name && _vm.subscription.status !== 'active' ? [!_vm.subscribe ? _c('v-btn', {
      staticClass: "text-transform-none pa-7",
      staticStyle: {
        "border-width": "3px"
      },
      attrs: {
        "id": "startTrialProPlan",
        "outlined": _vm.$vuetify.theme.dark,
        "large": "",
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.openFreeTrialModal(plan);
        }
      }
    }, [_c('span', {
      staticClass: "font14 bold-text"
    }, [_vm._v(" Start 14-day trial ")])]) : _vm._e(), !_vm.subscribe ? _c('div', {
      staticClass: "my-5"
    }, [_c('v-btn', {
      staticClass: "text-transform-none",
      attrs: {
        "outlined": "",
        "color": "darkGrey"
      },
      on: {
        "click": function click($event) {
          return _vm.upgradePlanConfirmation(plan);
        }
      }
    }, [_c('div', {
      staticClass: "smallFont"
    }, [_vm._v("Or upgrade now")])])], 1) : _c('v-btn', {
      staticClass: "text-transform-none my-5 pa-7",
      attrs: {
        "id": "startTrialStarterPlan",
        "depressed": "",
        "outlined": _vm.$vuetify.theme.dark,
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.upgradePlanConfirmation(plan);
        }
      }
    }, [_c('span', {
      staticClass: "font14 bold-text"
    }, [_vm._v(" Upgrade now ")])])] : _vm._e(), !_vm.getIsUserDisabled && _vm.canManageTeam && !_vm.isEmpty(_vm.getTeamTrial) && !_vm.getPromoInfo.source_name && (!_vm.subscription.status || ['active', 'cancel', 'cancelled', 'paused'].includes(_vm.subscription.status) && _vm.subscription.category !== 'pro') ? _c('v-btn', {
      staticClass: "text-transform-none my-5 pa-7",
      attrs: {
        "id": "proSubscribeBtn",
        "outlined": _vm.$vuetify.theme.dark,
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          _vm.subscription.status == 'paused' ? _vm.initiatePauseAlert({
            action: 'Upgrade'
          }, plan) : _vm.upgradePlanConfirmation(plan);
        }
      }
    }, [_c('span', {
      staticClass: "font14 bold-text"
    }, [_vm._v(" Upgrade now ")])]) : _vm._e(), !_vm.getIsUserDisabled && _vm.canManageTeam && !_vm.isEmpty(_vm.getTeamTrial) && !_vm.getPromoInfo.source_name && ['active', 'paused'].includes(_vm.subscription.status) && _vm.subscription.category === plan.meta.category ? [_c('v-btn', {
      staticClass: "text-transform-none my-5 pa-7",
      class: _vm.subscription.recurring !== 'annually' ? '' : 'mb-4',
      attrs: {
        "outlined": _vm.$vuetify.theme.dark,
        "color": "primary",
        "depressed": "",
        "loading": _vm.busy
      },
      on: {
        "click": function click($event) {
          _vm.subscription.status == 'paused' ? _vm.initiatePauseAlert({
            action: 'Switch',
            plan: 'Pro',
            type: _vm.subscription.recurring !== 'annually' ? 'annually' : 'monthly',
            planId: plan.id
          }) : _vm.openSwitchPlanDialog('Pro', _vm.subscription.recurring !== 'annually' ? 'annually' : 'monthly', plan.id);
        }
      }
    }, [_c('span', {
      staticClass: "font14 bold-text"
    }, [_vm._v(" Switch to " + _vm._s(_vm.subscription.recurring !== "annually" ? "Annually" : "Monthly") + " ")])])] : _vm._e(), !_vm.getIsUserDisabled && _vm.canManageTeam && !_vm.getPromoInfo.source_name && ['past_due', 'suspended'].includes(_vm.subscription.status) ? _c('v-btn', {
      staticClass: "text-transform-none cursor-pointer my-5 pa-7",
      attrs: {
        "color": "primary",
        "outlined": ""
      },
      on: {
        "click": _vm.openUpdateUrl
      }
    }, [_c('span', {
      staticClass: "font14 bold-text"
    }, [_vm._v(" Update billing ")])]) : _vm._e(), !_vm.getIsUserDisabled && _vm.canManageTeam && !_vm.isEmpty(_vm.getTeamTrial) && !_vm.getPromoInfo.source_name && _vm.subscription.category === 'pro' && _vm.subscription.status && ['cancel', 'cancelled'].includes(_vm.subscription.status) ? _c('v-btn', {
      staticClass: "text-transform-none my-5 pa-7",
      attrs: {
        "id": "starterUpgradePlanBtn",
        "depressed": "",
        "outlined": _vm.$vuetify.theme.dark,
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.upgradePlanConfirmation(plan);
        }
      }
    }, [_c('span', {
      staticClass: "font14 bold-text"
    }, [_vm._v(" Resubscribe Now")])]) : _vm._e()], 2)])], 1), _c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, _vm._l(plan.features, function (feat, feat_pro_index) {
      return _c('div', {
        key: feat_pro_index + feat.title,
        staticClass: "mb-3 bodyFont darkGrey--text"
      }, [feat.info ? _c('v-tooltip', {
        attrs: {
          "bottom": "",
          "color": _vm.$vuetify.theme.dark ? '' : 'primary'
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function fn(_ref5) {
            var on = _ref5.on;
            return [_c('div', _vm._g({}, on), [_c('v-icon', {
              attrs: {
                "color": "primary",
                "size": "24",
                "left": ""
              },
              domProps: {
                "textContent": _vm._s('mdi-check-circle-outline')
              }
            }), _c('span', {
              staticClass: "mr-1",
              domProps: {
                "innerHTML": _vm._s(feat.title)
              }
            })], 1)];
          }
        }], null, true)
      }, [_c('div', {
        domProps: {
          "innerHTML": _vm._s(feat.info)
        }
      })]) : _c('div', [_c('v-icon', {
        attrs: {
          "color": "primary",
          "size": "24",
          "left": ""
        },
        domProps: {
          "textContent": _vm._s('mdi-check-circle-outline')
        }
      }), _c('span', {
        staticClass: "mr-1",
        domProps: {
          "innerHTML": _vm._s(feat.title)
        }
      })], 1), feat.items && feat.items.length ? _c('div', {
        staticClass: "ml-8 mt-2"
      }, _vm._l(feat.items, function (item, sdindex) {
        return _c('div', {
          key: sdindex
        }, [item.info ? _c('v-tooltip', {
          attrs: {
            "bottom": "",
            "color": "primary"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on;
              return [_c('div', _vm._g({
                staticClass: "mb-1 darkGrey--text"
              }, on), [_c('span', {
                staticClass: "mr-1",
                domProps: {
                  "innerHTML": _vm._s(item.title)
                }
              })])];
            }
          }], null, true)
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(item.info)
          }
        })]) : _vm._e()], 1);
      }), 0) : _vm._e()], 1);
    }), 0)], 1)], 1)], 1) : _vm._e()];
  })] : [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', _vm._l(3, function (i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "card-avatar, article"
      }
    })], 1);
  }), 1)], 1)]], 2) : _vm._e(), !_vm.checkIfMaxPlan() ? _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('a', {
    staticClass: "text-decoration-underline bodyFont",
    attrs: {
      "href": "https://testapp.io/pricing",
      "target": "_blank"
    }
  }, [_vm._v(" Compare all plans ")])])], 1) : _vm._e(), _c('v-divider', {
    staticClass: "my-10"
  }), !_vm.checkIfMaxPlan() ? _c('v-row', [_c('v-row', [_c('v-col', {
    staticClass: "darkGrey--text py-4",
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "titleFont"
  }, [_vm._v("Frequently Asked Questions")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('plans-questions', {
    attrs: {
      "from-modal": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    staticClass: "pa-6",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "py-3"
  }, [_c('img', {
    attrs: {
      "src": "/media/svg/referrals.svg",
      "width": "40%"
    }
  })]), _c('div', {
    staticClass: "titleFont semi-bold primary--text"
  }, [_vm._v(" Turn referrals into revenue ")])]), _c('v-col', {
    staticClass: "text-center bodyFont",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Become a TestApp.io affiliate partner and earn up to "), _c('b', [_vm._v("25%")]), _vm._v(" commission on customer referrals. ")]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark,
      "href": "https://testapp.io/affiliate",
      "target": "_blank"
    }
  }, [_vm._v(" Apply now ")])], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.switchPlanDialog = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', {
          staticClass: "mt-4 bold-text font-size-h3",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Switch to " + _vm._s(_vm.changingPlan) + " "), _c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v("(" + _vm._s(_vm.changingType) + ")")])]), _c('v-col', {
          staticClass: "bodyFont",
          attrs: {
            "cols": "12"
          }
        }, [_vm.changingType == 'annually' ? _c('div', [_c('div', [_vm._v(" Team: "), _c('b', [_vm._v(_vm._s(_vm.getTeamName))])]), _c('div', {
          staticClass: "my-4"
        }, [_vm._v(" Great choice! Reduce payment failures and lock the current discount for a year 💪 ")]), _c('div', {
          staticClass: "mt-6"
        }, [_c('v-alert', {
          attrs: {
            "outlined": "",
            "color": "success"
          }
        }, [_c('span', {
          staticClass: "body-1"
        }, [_vm._v(" One-click away from saving "), _c('b', [_vm._v("30%")]), _vm._v(" or nearly "), _c('b', [_vm._v("4 months")]), _vm._v(" free 🎉 ")])]), _c('v-alert', {
          attrs: {
            "outlined": "",
            "color": "info"
          }
        }, [_c('span', {
          staticClass: "body-1"
        }, [_vm._v(" We will apply the remaining balance to your new billing ")])])], 1)]) : _c('div', [_vm._v(" Are you sure you want to change the billing from Annually to Monthly? "), _c('div', {
          staticClass: "mt-6"
        }, [_c('v-alert', {
          attrs: {
            "id": "monthlyAlert",
            "color": "info",
            "outlined": ""
          }
        }, [_c('span', {
          staticClass: "body-1"
        }, [_vm._v(" The remaining balance will be in your account for the subsequent billing cycles ")])])], 1)])]), _c('v-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', {
          staticClass: "text-transform-none mx-2",
          attrs: {
            "text": "",
            "disabled": _vm.busy,
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.switchPlanDialog = false;
            }
          }
        }, [_vm._v("Cancel")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary",
            "outlined": _vm.$vuetify.theme.dark,
            "id": "swichConfirmBtn",
            "loading": _vm.busy,
            "depressed": ""
          },
          on: {
            "click": _vm.changePlan
          }
        }, [_vm._v("Switch "), _vm.changingType == 'annually' ? _c('span', [_vm._v("🎉")]) : _vm._e()])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.switchPlanDialog,
      callback: function callback($$v) {
        _vm.switchPlanDialog = $$v;
      },
      expression: "switchPlanDialog"
    }
  }), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.changeModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "py-4"
        }, [_c('v-col', {
          staticClass: "text-h6",
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" Your current plan is "), _c('b', [_vm._v(" " + _vm._s(_vm.subscription.name + " ") + " "), _c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v("(" + _vm._s(_vm.subscription.recurring) + ")")])])]), _c('div', {
          staticClass: "text-left mt-4 bodyFont"
        }, [_c('div', [_vm._v(" - You can change your billing cycle to either Monthly or Annually. ")]), _c('div', {
          staticClass: "mb-4"
        }, [_vm._v(" - You can upgrade or downgrade your plans at any time. ")]), _c('div', [_vm._v(" Note: When downgrading a plan, the remaining balance will be in your account for your next billing cycles. ")])])]), _c('v-col', {
          staticClass: "pt-0 text-center",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-select', {
          attrs: {
            "item-value": "id",
            "filled": "",
            "id": "plan",
            "placeholder": "Select a plan",
            "items": _vm.plansList
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function fn(_ref7) {
              var item = _ref7.item;
              return [_c('div', [_vm._v(" " + _vm._s(item.name + " ") + " "), _c('span', {
                staticClass: "text-capitalize"
              }, [_vm._v("(" + _vm._s(item.recurring) + ")")]), item.recurring === 'annually' ? _c('v-chip', {
                staticClass: "ml-3",
                attrs: {
                  "small": ""
                }
              }, [_vm._v("Get 30% discount (~4 months)")]) : _vm._e()], 1)];
            }
          }, {
            key: "item",
            fn: function fn(_ref8) {
              var item = _ref8.item;
              return [_c('div', [_vm._v(" " + _vm._s(item.name + " ") + " "), _c('span', {
                staticClass: "text-capitalize"
              }, [_vm._v("(" + _vm._s(item.recurring) + ")")]), item.recurring === 'annually' ? _c('v-chip', {
                staticClass: "ml-3",
                attrs: {
                  "small": ""
                }
              }, [_vm._v("Get 30% discount (~4 months)")]) : _vm._e()], 1)];
            }
          }]),
          model: {
            value: _vm.selectedPlanId,
            callback: function callback($$v) {
              _vm.selectedPlanId = $$v;
            },
            expression: "selectedPlanId"
          }
        })], 1), _c('v-col', {
          staticClass: "text-center",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "disabled": _vm.subscription.id === _vm.selectedPlanId,
            "color": "primary",
            "outlined": _vm.$vuetify.theme.dark,
            "loading": _vm.busy,
            "depressed": ""
          },
          on: {
            "click": _vm.changePlan
          }
        }, [_vm._v(" Continue ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.changeModal,
      callback: function callback($$v) {
        _vm.changeModal = $$v;
      },
      expression: "changeModal"
    }
  }), _c('Modal', {
    scopedSlots: _vm._u([_vm.successModal ? {
      key: "message",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "py-4"
        }, [_c('v-col', {
          staticClass: "text-center text-h5",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Congratulations 🎉 ")]), _c('v-col', {
          staticClass: "text-center text-h6",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" " + _vm._s(_vm.upgradeMessage || "We are upgrading your plan") + " ")]), _c('v-col', {
          staticClass: "text-center",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-progress-circular', {
          attrs: {
            "indeterminate": "",
            "color": "success"
          }
        })], 1), _c('v-col', {
          staticClass: "text-center bodyFont",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" It should be done within few moments ")])], 1)];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.successModal,
      callback: function callback($$v) {
        _vm.successModal = $$v;
      },
      expression: "successModal"
    }
  }), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', [_c('div', {
          staticClass: "titleFont semi-bold"
        }, [_vm._v("Attention")])]), _c('v-card-text', {
          staticClass: "message my-3"
        }, [_c('div', [_vm._v(" Your team's subscription is currently on pause, to proceed with the " + _vm._s(_vm.selectedAction.action) + ", we will need to resume it and then " + _vm._s(_vm.selectedAction.action) + " ")])]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.pausedAlert = false;
            }
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "depressed": "",
            "color": "primary"
          },
          on: {
            "click": _vm.resumeSubscription
          }
        }, [_vm._v(" Resume & " + _vm._s(_vm.selectedAction.action) + " ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.pausedAlert,
      callback: function callback($$v) {
        _vm.pausedAlert = $$v;
      },
      expression: "pausedAlert"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": ""
    },
    on: {
      "close": function close($event) {
        _vm.upgradeSummaryModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.upgradeSummaryModal ? _c('upgrade-summary', {
          attrs: {
            "plans": _vm.selectedPlans,
            "selected-plan": _vm.selectedPlan,
            "type": _vm.billingType
          },
          on: {
            "close": function close($event) {
              _vm.upgradeSummaryModal = false;
            },
            "proceed": _vm.upgradePlan
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.upgradeSummaryModal,
      callback: function callback($$v) {
        _vm.upgradeSummaryModal = $$v;
      },
      expression: "upgradeSummaryModal"
    }
  }), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.confirmationModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm.confirmationModal ? _c('v-row', {
          staticClass: "py-4"
        }, [_c('v-col', {
          staticClass: "text-center text-h6",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" You are about to " + _vm._s(_vm.startTrial ? "start the ".concat(_vm.trialDay, "-day free trial for the") : "change your plan to") + " "), _c('div', [_c('b', {
          attrs: {
            "id": "selectedPlanName"
          }
        }, [_vm._v(_vm._s(_vm.selectedPlan.name) + " "), !_vm.startTrial ? _c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v("(" + _vm._s(_vm.selectedPlan.recurring) + ")")]) : _vm._e()]), _vm.startTrial ? _c('span', [_vm._v("plan")]) : _vm._e()])]), !_vm.startTrial ? _c('v-col', {
          staticClass: "text-center text-h6",
          attrs: {
            "cols": "12"
          }
        }, [_vm.selectedPlan.recurring === 'annually' ? _c('div', [_vm._v(" 🎉 Congratulations you saved "), _c('b', [_vm._v("$" + _vm._s(Math.floor(_vm.selectedPlan.price / 12 * 2)) + "!")]), _c('br'), _c('br'), _vm._v(" You will be billed "), _c('s', [_vm._v("$" + _vm._s(_vm.selectedPlan.meta.previous_price))]), _c('b', [_vm._v(" $" + _vm._s(_vm.selectedPlan.price))]), _vm._v(" annually. ")]) : _vm._e(), _vm.selectedPlan.recurring === 'monthly' ? _c('div', [_vm._v(" You will be billed "), _c('s', [_vm._v("$" + _vm._s(_vm.selectedPlan.meta.previous_price))]), _c('b', [_vm._v(" $" + _vm._s(_vm.selectedPlan.price))]), _vm._v(" monthly. "), _c('br'), _c('br'), _vm._v(" You could still save "), _c('b', [_vm._v("$" + _vm._s(Math.floor(Math.ceil(_vm.selectedPlan.price * 10) / 12 * 2)))]), _vm._v(" on annual team plan. ")]) : _vm._e()]) : _vm._e(), _c('v-col', {
          staticClass: "text-center pb-0",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "id": "upgradePlanBtn",
            "color": "primary",
            "loading": _vm.loading,
            "depressed": ""
          },
          on: {
            "click": _vm.upgradePlan
          }
        }, [_vm._v(" Let's do it 💪 ")])], 1)], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.confirmationModal,
      callback: function callback($$v) {
        _vm.confirmationModal = $$v;
      },
      expression: "confirmationModal"
    }
  }), _c('Modal', {
    attrs: {
      "hide-close": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', {
          staticClass: "text-center",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-progress-circular', {
          attrs: {
            "indeterminate": ""
          }
        })], 1), _c('v-col', {
          staticClass: "text-center",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v("Please wait...")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.paddleLoadingModal,
      callback: function callback($$v) {
        _vm.paddleLoadingModal = $$v;
      },
      expression: "paddleLoadingModal"
    }
  }), _c('Modal', {
    attrs: {
      "width": "600",
      "hide-close": ""
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.makeDealModal ? _c('make-deal-form', {
          attrs: {
            "price": _vm.selectedPlanPrice,
            "planId": _vm.selectedPlanId,
            "selected-plan": _vm.plansSelection
          },
          on: {
            "close": function close($event) {
              _vm.makeDealModal = false;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.makeDealModal,
      callback: function callback($$v) {
        _vm.makeDealModal = $$v;
      },
      expression: "makeDealModal"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }