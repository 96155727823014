var render = function render(){
  var _vm$appDetail, _vm$app$id, _vm$app, _vm$app$id2, _vm$app2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [!_vm.noHeader ? _c('v-card-title', {
    staticClass: "mb-4 display-1 justify-center"
  }, [_vm._v(" New release ")]) : _vm._e(), _c('div', {
    staticClass: "_releasetabnew"
  }, [_c('div', {
    staticClass: "side-release"
  }, [_c('v-form', {
    ref: "android",
    staticClass: "formflex",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return function () {
          return _vm.submitRelease('android');
        }.apply(null, arguments);
      }
    }
  }, [_c('v-card', {
    staticClass: "transparent mt-lg-5 flex-column justify-center align-center",
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_vm.releaseProgress ? _c('v-overlay', {
    attrs: {
      "opacity": _vm.$vuetify.theme.dark ? 0.9 : 0.5,
      "color": _vm.$vuetify.theme.dark ? '#22272e' : '#22272e',
      "value": true,
      "absolute": ""
    }
  }, [_c('div', {
    staticClass: "mx-2"
  }, [_c('v-alert', {
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "dense": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "grow"
  }, [_vm._v(" Release successfully created. You can check the progress in upload queue. ")]), _c('v-col', {
    staticClass: "shrink"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeForm
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    },
    domProps: {
      "textContent": _vm._s('mdi-close-circle')
    }
  })], 1)], 1)], 1)], 1)], 1)]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.$route.params.app_id && _vm.initialList.length,
      expression: "!$route.params.app_id && initialList.length"
    }]
  }, [_c('AppAutoComplete', {
    ref: "app-autocomplete",
    staticClass: "mb-4",
    attrs: {
      "focus": _vm.focus,
      "is-get-started": _vm.fromStarted,
      "select-fist-item": _vm.fromStarted
    },
    on: {
      "update:focus": function updateFocus($event) {
        _vm.focus = $event;
      },
      "blur": function blur($event) {
        _vm.focus = false;
      },
      "input": function input($event) {
        _vm.app = $event;
      },
      "openApp": function openApp($event) {
        return _vm.$emit('openApp');
      }
    },
    model: {
      value: _vm.app,
      callback: function callback($$v) {
        _vm.app = $$v;
      },
      expression: "app"
    }
  })], 1), _c('h1', {
    staticClass: "text-center mb-4"
  }, [_vm._v(" " + _vm._s((_vm$appDetail = _vm.appDetail) === null || _vm$appDetail === void 0 ? void 0 : _vm$appDetail.name) + " ")]), _c('v-row', [_c('v-col', {
    staticClass: "d-flex flex-column align-center",
    class: {
      'pr-5': _vm.$vuetify.breakpoint.smAndUp
    },
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "mx-auto",
    class: "platform-logo mb-5  ".concat(_vm.icon == 'android' ? 'selected-icon-container' : '')
  }, [_vm.get(_vm.apk, 'info.icon', '') ? _c('img', {
    attrs: {
      "src": _vm.get(_vm.apk, 'info.icon', '')
    },
    on: {
      "error": _vm.replaceDefaultAndroidImage
    }
  }) : _vm._e(), !_vm.get(_vm.apk, 'info.icon', '') ? _c('PlatformIcon', {
    attrs: {
      "android": "",
      "color": "primary"
    }
  }) : _vm._e()], 1), _c('FileUploadDropzone', {
    ref: "apk-upload",
    staticClass: "dropzonemain mx-auto",
    attrs: {
      "id": "android-box",
      "app_id": (_vm$app$id = (_vm$app = _vm.app) === null || _vm$app === void 0 ? void 0 : _vm$app.id) !== null && _vm$app$id !== void 0 ? _vm$app$id : '',
      "change": function change(file) {
        return _vm.onFileChange(file, 'android');
      },
      "fileInfo": _vm.apk,
      "loading": _vm.preLoadingAndroid,
      "onReleaseClick": _vm.onInfoClick,
      "preError": _vm.preReleaseErrorAndroid,
      "releaseLoader": _vm.androidReleaseLoader,
      "uploadStatus": Object.assign({}, _vm.getAndroidProgress),
      "accept": ".apk"
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex flex-column align-center",
    class: {
      'pr-5': _vm.$vuetify.breakpoint.smAndUp
    },
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "mx-auto",
    class: "platform-logo mb-5  ".concat(_vm.icon == 'ios' ? 'selected-icon-container' : '')
  }, [_vm.get(_vm.ipa, 'info.icon', '') ? _c('img', {
    attrs: {
      "src": _vm.get(_vm.ipa, 'info.icon', '')
    },
    on: {
      "error": _vm.replaceDefaultAndroidImage
    }
  }) : _vm._e(), !_vm.get(_vm.ipa, 'info.icon', '') ? _c('PlatformIcon', {
    attrs: {
      "apple": "",
      "color": "primary"
    }
  }) : _vm._e()], 1), _c('FileUploadDropzone', {
    ref: "ipa-upload",
    staticClass: "dropzonemain mx-auto",
    attrs: {
      "id": "ios-box",
      "app_id": (_vm$app$id2 = (_vm$app2 = _vm.app) === null || _vm$app2 === void 0 ? void 0 : _vm$app2.id) !== null && _vm$app$id2 !== void 0 ? _vm$app$id2 : '',
      "change": function change(file) {
        return _vm.onFileChange(file, 'ios');
      },
      "fileInfo": _vm.ipa,
      "loading": _vm.preLoadingIOS,
      "onReleaseClick": _vm.onInfoClick,
      "preError": _vm.preReleaseErrorIOS,
      "releaseLoader": _vm.iosReleaseLoader,
      "uploadStatus": Object.assign({}, _vm.getIOSProgress),
      "accept": ".ipa"
    }
  })], 1)], 1), _c('v-checkbox', {
    attrs: {
      "id": "release-note-check-box",
      "disabled": _vm.busy || _vm.preLoadingAndroid || _vm.preLoadingIOS,
      "hide-details": "",
      "label": "One release note"
    },
    model: {
      value: _vm.oneReleaseNote,
      callback: function callback($$v) {
        _vm.oneReleaseNote = $$v;
      },
      expression: "oneReleaseNote"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.oneReleaseNote,
      expression: "oneReleaseNote"
    }],
    staticClass: "my-3"
  }, [_c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:1200',
      expression: "'max:1200'"
    }],
    attrs: {
      "id": "one-note",
      "counter": 1200,
      "disabled": _vm.busy || _vm.preLoadingAndroid || _vm.preLoadingIOS,
      "auto-grow": "",
      "filled": "",
      "label": "Note",
      "placeholder": "What to expect or test for both releases?"
    },
    model: {
      value: _vm.form.note,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "note", $$v);
      },
      expression: "form.note"
    }
  }, 'v-textarea', _vm.veeValidate('Description', 'Description'), false))], 1), _c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.oneReleaseNote,
      expression: "!oneReleaseNote"
    }],
    staticClass: "my-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:1200',
      expression: "'max:1200'"
    }],
    attrs: {
      "id": "android-note",
      "counter": 1200,
      "disabled": _vm.busy || _vm.preLoadingAndroid || _vm.preLoadingIOS,
      "append-icon": "android",
      "auto-grow": "",
      "filled": "",
      "label": "Android note",
      "placeholder": "What to expect or test in this release?"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('PlatformIcon', {
          attrs: {
            "android": "",
            "color": "darkGrey"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.androidNote,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "androidNote", $$v);
      },
      expression: "form.androidNote"
    }
  }, 'v-textarea', _vm.veeValidate('Description-android', 'Android Description'), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:1200',
      expression: "'max:1200'"
    }],
    attrs: {
      "id": "ios-note",
      "counter": 1200,
      "disabled": _vm.busy || _vm.preLoadingAndroid || _vm.preLoadingIOS,
      "auto-grow": "",
      "filled": "",
      "label": "iOS note",
      "placeholder": "What to expect or test in this release?"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('PlatformIcon', {
          attrs: {
            "apple": "",
            "color": "darkGrey"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.iosNote,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "iosNote", $$v);
      },
      expression: "form.iosNote"
    }
  }, 'v-textarea', _vm.veeValidate('Description-ios', 'iOS Description'), false))], 1)], 1), !_vm.fromStarted ? _c('div', [_c('div', {
    staticClass: "font14",
    class: !_vm.$vuetify.theme.dark ? 'tipFont' : 'darkGrey--text'
  }, [_c('v-icon', {
    attrs: {
      "color": "darkGrey",
      "small": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-bell-outline')
    }
  }), _vm._v(" How would you like to notify your team? ")], 1), _c('div', {
    staticClass: "ml-4"
  }, [_c('v-radio-group', {
    attrs: {
      "disabled": _vm.busy || _vm.preLoadingAndroid || _vm.preLoadingIOS
    },
    model: {
      value: _vm.notify,
      callback: function callback($$v) {
        _vm.notify = $$v;
      },
      expression: "notify"
    }
  }, [_c('v-radio', {
    attrs: {
      "value": true
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', [_vm._v(" Notify immediately after upload "), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "small": "",
                  "icon": ""
                }
              }, on), [_c('v-icon', {
                attrs: {
                  "small": ""
                },
                domProps: {
                  "textContent": _vm._s('mdi-information-outline')
                }
              })], 1)];
            }
          }], null, false, 1887443717)
        }, [_c('span', [_vm._v(" Select this to automatically send a push notification to all app members with access "), _c('br'), _vm._v(" immediately after the upload is complete. They can "), _c('br'), _vm._v(" install the release and share feedback promptly. ")])])], 1)];
      },
      proxy: true
    }], null, false, 1536600649)
  }), _c('v-radio', {
    attrs: {
      "value": false
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', [_vm._v(" I will notify later from the release page "), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "small": "",
                  "icon": ""
                }
              }, on), [_c('v-icon', {
                attrs: {
                  "small": ""
                },
                domProps: {
                  "textContent": _vm._s('mdi-information-outline')
                }
              })], 1)];
            }
          }], null, false, 1887443717)
        }, [_c('span', [_vm._v(" Choose this to send notifications manually from the release page at your convenience. All app "), _c('br'), _vm._v(" members with access will receive a push notification in our app for the relevant platform, "), _c('br'), _vm._v(" allowing them to install the release and share feedback. ")])])], 1)];
      },
      proxy: true
    }], null, false, 2683009229)
  })], 1)], 1)]) : _vm._e(), !_vm.fromStarted ? _c('div', {
    staticClass: "my-8"
  }, [_c('div', {
    staticClass: "font14",
    class: !_vm.$vuetify.theme.dark ? 'tipFont' : 'darkGrey--text'
  }, [_c('v-icon', {
    attrs: {
      "color": "darkGrey",
      "small": ""
    }
  }, [_vm._v("archive")]), _vm._v(" Archive current latest release "), _c('VueTooltip', {
    attrs: {
      "icon-color": _vm.$vuetify.theme.dark ? 'darkGrey' : 'grey',
      "max-width": 200,
      "v-html": 'Upon successful upload, the current latest release will be automatically archived.<br /><br />This saves you the step of manual archiving.',
      "color": "primary",
      "text-class": "white--text"
    }
  })], 1), _c('div', {
    staticClass: "ml-4"
  }, [_c('v-checkbox', {
    attrs: {
      "disabled": !_vm.apk || _vm.busy || _vm.preLoadingAndroid || _vm.preLoadingIOS,
      "hide-details": "",
      "label": "Android"
    },
    model: {
      value: _vm.archiveAndroid,
      callback: function callback($$v) {
        _vm.archiveAndroid = $$v;
      },
      expression: "archiveAndroid"
    }
  }), _c('v-checkbox', {
    attrs: {
      "disabled": !_vm.ipa || _vm.busy || _vm.preLoadingAndroid || _vm.preLoadingIOS,
      "hide-details": "",
      "label": "iOS"
    },
    model: {
      value: _vm.archiveIos,
      callback: function callback($$v) {
        _vm.archiveIos = $$v;
      },
      expression: "archiveIos"
    }
  })], 1)]) : _vm._e(), !_vm.termsAndCondition ? _c('v-checkbox', {
    attrs: {
      "id": "terms-check-box",
      "disabled": _vm.busy || _vm.preLoadingAndroid || _vm.preLoadingIOS
    },
    on: {
      "click": _vm.setTermsAndCondition
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', [_vm._v(" By creating this release, I agree to "), _c('a', {
          attrs: {
            "href": "https://testapp.io/terms-and-conditions",
            "target": "_blank"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_vm._v(" Terms & Conditions ")]), _vm._v(" and "), _c('a', {
          attrs: {
            "href": "https://testapp.io/privacy-policy#upload",
            "target": "_blank"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_vm._v(" Upload Policy ")])])];
      },
      proxy: true
    }], null, false, 4173679842),
    model: {
      value: _vm.termsAndCondition,
      callback: function callback($$v) {
        _vm.termsAndCondition = $$v;
      },
      expression: "termsAndCondition"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "mb-8"
  }, [_c('v-icon', {
    attrs: {
      "color": "darkGrey",
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-lightbulb-on-outline')
    }
  }), _c('span', {
    staticClass: "font14",
    class: !_vm.$vuetify.theme.dark ? 'tipFont' : 'darkGrey--text'
  }, [_vm._v(" Use "), _c('a', {
    staticClass: "text-decoration-underline",
    on: {
      "click": function click($event) {
        return _vm.redirectToIntegration('ta-cli');
      }
    }
  }, [_vm._v("TA-CLI")]), _vm._v(" or "), _c('a', {
    staticClass: "text-decoration-underline",
    on: {
      "click": function click($event) {
        return _vm.redirectToIntegration('fastlane-plugin');
      }
    }
  }, [_vm._v("Fastlane")]), _vm._v(" or other "), _c('a', {
    staticClass: "text-decoration-underline",
    on: {
      "click": function click($event) {
        return _vm.redirectToIntegration('integrations');
      }
    }
  }, [_vm._v("integrations")]), _vm._v(" to automate your releases. ")])], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    ref: "submit_btn_release",
    staticClass: "text-transform-none px-15 py-7",
    attrs: {
      "disabled": !_vm.app || !_vm.apk && !_vm.ipa || !_vm.agree || _vm.busy || _vm.preLoadingAndroid || _vm.preLoadingIOS,
      "loading": _vm.busy,
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "type": "submit",
      "x-large": ""
    }
  }, [_c('div', {
    staticClass: "font14"
  }, [_vm._v("Create")])]), !_vm.noHeader && _vm.fromStarted ? _c('v-btn', {
    staticClass: "main large wide primary mx-auto my-3",
    on: {
      "click": function click($event) {
        _vm.$router.push({
          name: 'team'
        }).catch(function () {});
      }
    }
  }, [_vm._v(" Skip ")]) : _vm._e()], 1)], 1)], 1)], 1)]), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 1100 : ''
    },
    on: {
      "close": function close($event) {
        _vm.openSubscriptionModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('plan-pricing', {
          attrs: {
            "allowed-size": _vm.allowedSize,
            "upgrade-plan-text": _vm.upgradePlanMessage,
            "uploaded-size": _vm.uploadedSize,
            "from-modal": ""
          },
          on: {
            "close": function close($event) {
              return _vm.resetModal();
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openSubscriptionModal,
      callback: function callback($$v) {
        _vm.openSubscriptionModal = $$v;
      },
      expression: "openSubscriptionModal"
    }
  }), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.expressLimitModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "bodyFont"
        }, [_c('v-col', {
          staticClass: "bold-text mt-3",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Max app size limit reached ")]), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "mb-2"
        }, [_vm._v(" This release exceeded the limit which is "), _c('b', [_vm._v(_vm._s(_vm.allowedSize))])]), _c('div', [_vm._v(" You have uploaded "), _c('b', [_vm._v(_vm._s(_vm.uploadedSize))])]), _c('div', {
          staticClass: "bold-text text-center mt-4"
        }, [_vm._v(" Signup to increase the limit for free 💪 ")])]), _c('v-col', {
          staticClass: "text-center"
        }, [_c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push({
                name: 'signup'
              });
            }
          }
        }, [_vm._v(" Let's do it ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.expressLimitModal,
      callback: function callback($$v) {
        _vm.expressLimitModal = $$v;
      },
      expression: "expressLimitModal"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.mdAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 880 : '',
      "no-py": ""
    },
    on: {
      "close": function close($event) {
        _vm.showStartTrialModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm.showStartTrialModal ? _c('start-trial-modal', {
          attrs: {
            "allowed-size": _vm.allowedSize,
            "file-size": _vm.fileSize,
            "message": _vm.message,
            "tracker-event": _vm.trackerEvent,
            "trigger-from": 'appUploadSize',
            "uploaded-size": _vm.uploadedSize,
            "from-create-release": ""
          },
          on: {
            "close": function close($event) {
              _vm.showStartTrialModal = false, _vm.resetForm();
            },
            "open-subscribe": function openSubscribe($event) {
              _vm.openSubscriptionModal = true;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showStartTrialModal,
      callback: function callback($$v) {
        _vm.showStartTrialModal = $$v;
      },
      expression: "showStartTrialModal"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "width": "600"
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.showAppInformationModal ? _c('app-information', {
          attrs: {
            "app-detail": _vm.app,
            "from-create-release": ""
          },
          on: {
            "close": function close($event) {
              _vm.showAppInformationModal = false;
            },
            "success": _vm.afterInformationUpdated
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showAppInformationModal,
      callback: function callback($$v) {
        _vm.showAppInformationModal = $$v;
      },
      expression: "showAppInformationModal"
    }
  }), _c('side-panel', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 960 : ''
    },
    model: {
      value: _vm.showPreReleaseInfo,
      callback: function callback($$v) {
        _vm.showPreReleaseInfo = $$v;
      },
      expression: "showPreReleaseInfo"
    }
  }, [_c('pre-release-info-panel', {
    attrs: {
      "app": _vm.app,
      "info": _vm.releaseInfo
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }