var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": !_vm.fromStartTrial
    }
  }, [!_vm.fromStartTrial && !_vm.showNoTitle ? _c('v-card-title', [_c('div', [_c('span', {
    staticClass: "bold-text titleFont"
  }, [_vm._v(_vm._s(_vm.title))]), _vm.$vuetify.breakpoint.smAndDown ? _c('v-btn', {
    staticClass: "float-right",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-close')
    }
  })], 1) : _vm._e(), _c('v-divider', {
    staticClass: "my-2"
  }), _c('div', {
    staticClass: "font14"
  }, [_vm._v(" " + _vm._s(_vm.firstMessage) + " ")]), _c('div', {
    staticClass: "font14"
  }, [_vm._v(" " + _vm._s(_vm.secondMessage) + " ")])], 1)]) : _vm._e(), _c('v-card-text', {
    class: {
      'pa-0': _vm.showNoTitle
    }
  }, [_c('div', {
    staticClass: "wistia_responsive_padding",
    staticStyle: {
      "padding": "56.25% 0 0 0",
      "position": "relative"
    }
  }, [_c('div', {
    staticClass: "wistia_responsive_wrapper",
    staticStyle: {
      "height": "100%",
      "left": "0",
      "position": "absolute",
      "top": "0",
      "width": "100%"
    }
  }, [_c('span', {
    staticClass: "popover=true popoverAnimateThumbnail=true videoFoam=true",
    class: _vm.videoClass,
    staticStyle: {
      "display": "inline-block",
      "height": "100%",
      "position": "relative",
      "width": "100%"
    }
  }, [_vm._v(" ")])])])]), _vm.$vuetify.breakpoint.mdAndUp && !_vm.fromOnboarding && !_vm.fromStartTrial ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "id": "videoModalCloseButton",
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }