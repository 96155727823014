var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    class: this.$style.widget,
    attrs: {
      "outlined": ""
    }
  }, [!_vm.canBeManaged ? _vm._t("widget-no-permission") : [_vm.loading ? _vm._t("widget-loading") : [!_vm.isEmpty ? _vm._t("widget-content") : _vm._t("widget-no-data")]]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }