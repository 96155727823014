var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "margin-top": "40px"
    }
  }, [_vm.errorMessages ? _c('v-row', [_vm.errorMessages ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('no-data', {
    attrs: {
      "firstText": _vm.errorMessages,
      "with-button": ""
    }
  })], 1) : _vm._e()], 1) : _c('div', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "card, list"
    }
  })], 1), _vm._l(4, function (col) {
    return _c('v-col', {
      key: col,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "table"
      }
    })], 1);
  })], 2) : _vm._e(), !_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('app-release-stats')], 1)], 1) : _vm._e(), !_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('recent-releases-widget', {
    attrs: {
      "releases": _vm.dashboardList.releases,
      "dashboard-loader": _vm.loading
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('recent-comments-widget', {
    attrs: {
      "comments": _vm.dashboardList.release_comments,
      "dashboard-loader": _vm.loading
    }
  })], 1), _vm.canManageApp ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('recent-sessions-widget', {
    attrs: {
      "sessions": _vm.dashboardList.sessions,
      "sessions-loading": _vm.loading
    }
  })], 1) : _vm._e(), _vm.canManageFeedback ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('recent-feedback-widget', {
    attrs: {
      "feedbacks": _vm.dashboardList.feedback,
      "feedback-loading": _vm.loading
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.isNotGuestRole ? _c('v-row', [_vm.canViewTimeline ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('app-timeline', {
    attrs: {
      "app_id": _vm.app_id,
      "info": _vm.appInfo
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1), _c('SidePanel', {
    attrs: {
      "width": "720"
    },
    on: {
      "close": function close($event) {
        _vm.releaseDrawer = false;
      }
    },
    model: {
      value: _vm.releaseDrawer,
      callback: function callback($$v) {
        _vm.releaseDrawer = $$v;
      },
      expression: "releaseDrawer"
    }
  }, [_vm.releaseDrawer ? _c('create-release-step', {
    attrs: {
      "app-info": _vm.appInfo
    },
    on: {
      "close": function close($event) {
        _vm.releaseDrawer = false, _vm.$emit('close');
      },
      "released": function released($event) {
        return _vm.$router.push({
          name: 'releases',
          params: {
            app_id: _vm.appInfo.id
          }
        });
      }
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }