<template>
  <div>
    <v-row v-if="!fromMenu && message" class="mt-2">
      <v-col cols="12" md="8">
        <vue-inline-alert
          :message="message"
          message-type="error"
          outlined
          no-close-btn
        >
        </vue-inline-alert>
      </v-col>
    </v-row>
    <v-row class="mt-7 mb-0">
      <v-col cols="12" md="8">
        <div
          v-if="isEmpty(getTeamTrial) && !showContactUsMessage"
          class="bold-text font11 primary--text"
        >
          {{ trialDay }}-days free trial is ready to be activated 🎉
        </div>
        <div v-if="!showContactUsMessage" class="font22 my-5 bold-text">
          Upgrade team plan
        </div>
        <v-row>
          <v-col cols="12">
            <div
              class="font14 mb-6"
              v-html="featureInfo[selectedFeature].description"
            ></div>
          </v-col>
          <v-col class="text-center" cols="12">
            <img
              v-if="selectedFeature !== 'feedbackSessions'"
              height="auto"
              width="100%"
              :src="featureInfo[selectedFeature].src"
            />
            <video-modal
              v-else
              from-start-trial
              :video-class="`wistia_embed wistia_async_jbh2mr3oel email=${user?.id}`"
            ></video-modal>
          </v-col>
        </v-row>
        <v-row class="mt-11 mb-0" align="center">
          <v-col class="px-0 pt-0" cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col class="text-left" cols="12">
            <v-btn
              class="text-transform-none"
              color="primary"
              depressed
              :outlined="$vuetify.theme.dark"
              @click="subscribeNow"
            >
              <div class="font14">Upgrade now</div>
            </v-btn>
            <v-btn
              v-if="isEmpty(getTeamTrial)"
              class="text-transform-none mx-2"
              color="primary"
              id="startTrialConfirmBtn"
              outlined
              @click="startTrial"
            >
              <div class="font14">Start trial</div>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        :class="$vuetify.breakpoint.smAndDown ? '' : 'borderLeft'"
        cols="12"
        md="4"
      >
        <div class="subListFont mb-3">Features include:</div>
        <v-list class="subListFont">
          <v-list-item
            v-for="(item, findex) in featureList"
            :key="findex"
            :class="selectedFeature === item.value ? 'primary--text' : ''"
            class="mb-2"
            @click="selectedFeature = item.value"
          >
            <v-icon
              class="pt-1"
              :color="selectedFeature === item.value ? 'primary' : ''"
              size="18"
              v-text="'mdi-flash'"
              left
            ></v-icon>
            {{ item.title }}
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <div>
          <div class="font12 pt-4">CURRENT PLAN</div>
          <div class="subListFont">
            {{ getCurrentPlan.name }}
          </div>
          <div class="mt-1">
            <a class="font14" @click="redirectToPricing"
              >Learn more and compare plans</a
            >
          </div>
        </div>
      </v-col>
      <v-col v-if="showContactUsMessage" class="pt-0 bodyFont">
        Ops! This apps size is higher than our allowed size in our plans.
        <br /><br />
        We can still create a custom exception for your app, please
        <span @click="onContactus" class="primary--text cursor-pointer"
          >contact us</span
        >
        for more info.
      </v-col>
      <Modal
        :width="$vuetify.breakpoint.mdAndUp ? 1100 : ''"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        @close="openSubscriptionModal = false"
        v-model="openSubscriptionModal"
      >
        <template #message>
          <plan-pricing
            from-modal
            from-upgrade-component
            from-plan-page
            :subscribe="subscribe"
            :upgrade-plan-text="getUpgradePlanText()"
            @success="$emit('close'), (openSubscriptionModal = false)"
            @close="openSubscriptionModal = false"
          ></plan-pricing>
        </template>
      </Modal>
    </v-row>
  </div>
</template>

<script>
import { GET_PLANS, START_FREE_TRIAL } from "@/store/team/team.module";
import { GET_USER_ROLES } from "@/store/users/auth.module";
import PlanPricing from "@/view/components/Team/PlanPricing";
import { mapGetters } from "vuex";
import { isEmpty } from "@/core/services/helper.service";
import { segmentEvents } from "@/consts/segmentEventConst";
import VideoModal from "@/view/components/Common/VideoModal";

export default {
  components: { VideoModal, PlanPricing },
  props: {
    triggerFrom: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: "",
    },
    fromMenu: {
      type: Boolean,
      default: false,
    },
    fromCreateRelease: {
      type: Boolean,
      default: false,
    },
    fromAppsPage: {
      type: Boolean,
      default: false,
    },
    fromMembersPage: {
      type: Boolean,
      default: false,
    },
    uploadedSize: {
      type: [Number, String],
      default: "",
    },
    allowedSize: {
      type: [Number, String],
      default: "",
    },
    fileSize: {
      type: [Number, String],
      default: 0,
    },
    trackerEvent: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      isEmpty,
      openSubscriptionModal: false,
      subscribe: false,
      featureList: [
        {
          title: "Full analytics",
          value: "fullAnalytics",
        },
        {
          title: "More team members",
          value: "moreTeam",
        },
        {
          title: "More apps",
          value: "appProject",
        },
        {
          title: "Bigger app size",
          value: "appUploadSize",
        },
        {
          title: "Bigger storage",
          value: "internalStorage",
        },
        {
          title: "Export installs",
          value: "exportInstalls",
        },
        {
          title: "Public install page status",
          value: "publicPageStatus",
        },
        // {
        //   title: "Public install link limit",
        //   value: "shareLinkLimit",
        // },
        {
          title: "Auto archive releases",
          value: "autoArchiveRelease",
        },
        {
          title: "In-app sessions",
          value: "feedbackSessions",
        },
        {
          title: "Integrations",
          value: "integartions",
        },
        {
          title: "All future features",
          value: "allFutureFeatures",
        },
      ],
      featureInfo: {
        fullAnalytics: {
          src: "/media/instruction/share_your_app.svg",
          description:
            "Get holistic insights into device, location, and user interactions for every release. Monitor who engages with your app and its <b>public installation page</b>. <b>Upgrade</b> to elevate your data-driven strategies.",
        },
        moreTeam: {
          src: "/media/instruction/share_your_app.svg",
          description:
            "Empower your <b>team</b> with TestApp.io. Manage app releases, set distinct <b>roles</b>, and foster collaboration. Note: <b>Guests</b> don't affect your team quota, ensuring scalability.",
        },
        appProject: {
          src: "/media/instruction/share_your_app.svg",
          description:
            "Manage diverse <b>apps</b> seamlessly. Differentiate between Android and iOS releases, assign tasks, and create environments such as <b>DEV, STAGING, and PRODUCTION</b> for streamlined operations.",
        },
        appUploadSize: {
          src: "/media/instruction/share_your_app.svg",
          description:
            "Opt for the advanced <b>1 GB app size limit</b>. As your app evolves, ensure it thrives without constraints. Need more? Reach out to our <b>customer support</b> anytime.",
        },
        internalStorage: {
          src: "/media/instruction/share_your_app.svg",
          description:
            "Store app releases without storage concerns. Upgrade for up to <b>1 TB</b> space. Remember, only active releases count towards the limit.",
        },
        exportInstalls: {
          src: "/media/instruction/share_your_app.svg",
          description:
            "Export app install details effortlessly. Utilize the <b>CSV format</b> to better understand your testers and refine targeting strategies.",
        },
        publicPageStatus: {
          src: "/media/instruction/share_your_app.svg",
          description:
            "Toggle between <b>modes</b> for your app page. Inform users during updates or maintenance, ensuring transparency and trust.",
        },
        autoArchiveRelease: {
          src: "/media/instruction/share_your_app.svg",
          description:
            "Schedule automatic app archiving. Keep your projects tidy and optimize storage utilization without manual interventions.",
        },
        feedbackSessions: {
          src: "https://testappio.wistia.com/medias/jbh2mr3oel?wvideo=jbh2mr3oel",
          description:
            "Maximize app potential with advanced <b>feedback sessions</b>. Integrate our <a href='https://help.testapp.io/about-sdk/' target='_blank'>SDK</a> and access in-depth performance metrics. Proactively address issues pre-release.",
        },
        externalStorage: {
          src: "/media/instruction/share_your_app.svg",
          description:
            "Experience limitless storage. Integrate personal cloud services like <b>AWS S3, Azure, and more</b>, and gain greater control over your app's releases.",
        },
        integartions: {
          src: "/media/instruction/share_your_app.svg",
          description:
            "Synchronize TestApp.io with your favorite tools. Get real-time updates and insights. For more, explore the <a href='/team/integrations' target='_blank'><b>integrations page</b></a>.",
        },
        allFutureFeatures: {
          src: "/media/instruction/share_your_app.svg",
          description:
            "Our journey doesn't end! We continually enhance the app distribution experience. Await new <b>features</b> like in-app feedback via SDK, cloud simulators, and more. Got suggestions? Use our <a href='https://roadmap.testapp.io' target='_blank'><b>feature request form</b></a>.",
        },
      },
      selectedFeature: "fullAnalytics",
      showContactUsMessage: false,
    };
  },
  computed: {
    ...mapGetters({
      getTeamTrial: "getTeamTrial",
      user: "currentUser",
      getCurrentPlan: "getCurrentPlan",
    }),
  },
  created() {
    if (this.triggerFrom) {
      this.selectedFeature = this.triggerFrom;
    }
    this.logEvent(segmentEvents.TRIAL_POPUP, this.trackerEvent);
    if (
      this.fromCreateRelease &&
      this.fileSize &&
      parseInt(this.fileSize) > 1000000
    ) {
      this.showContactUsMessage = true;
      this.logEvent(segmentEvents.MAX_SIZE_UPLOAD);
    }
  },
  methods: {
    startTrial() {
      if (this.loading) return;
      this.loading = true;
      let planId = "V3GoPO2xXy";
      if (this.fromAppsPage || this.fromMembersPage) {
        planId = "V3GoPO2xXy";
      } else if (this.fromCreateRelease) {
        planId =
          parseInt(this.fileSize) <= 300000 ? "V3GoPO2xXy" : "V3GoPO2xXy";
      }
      this.$store
        .dispatch(START_FREE_TRIAL, { plan_id: planId })
        .then((response) => {
          this.notifyUserMessage({ message: response.message });
          this.$store.dispatch(GET_USER_ROLES).catch((err) => {
            this.notifyErrorMessage(err.message);
          });
          this.$store
            .dispatch(GET_PLANS)
            .then(() => {
              this.$emit("close");
              this.showStartTrialModal = false;
            })
            .catch((err) => {
              this.notifyErrorMessage(err.message);
            });
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
    redirectToPricing() {
      this.$emit("close");
      this.$router.push({ name: "team-plan" });
    },
    subscribeNow() {
      if (this.fromCreateRelease) {
        this.$emit("open-subscribe");
      } else {
        this.subscribe = true;
        this.openSubscriptionModal = true;
      }
    },
    getUpgradePlanText() {
      return this.getTeamTrial && this.getTeamTrial.status == "ended"
        ? "Your team's trial has ended, please subscribe to a plan."
        : "You can start a trial or directly subscribe to a plan.";
    },
  },
};
</script>

<style scoped>
.borderLeft {
  border-left: 1px solid #dadae1;
}
.borderTop {
  border-top: 1px solid #dadae1;
}
.currentPlanAlignment {
  position: absolute;
  bottom: 15px;
}
</style>
