<template>
  <v-row>
    <v-col
      v-if="!fromReleasePage"
      :class="$vuetify.theme.dark ? '' : 'lightBackground'"
      cols="12"
    >
      App include the following permissions
    </v-col>
    <template v-if="permissions?.length">
      <template v-for="(category, index) in permissionLists">
        <v-col
          v-if="category.permissions && category.permissions.length"
          :key="index"
          :class="
            $vuetify.theme.dark || fromReleasePage ? '' : 'lightBackground'
          "
          cols="12"
        >
          <div>
            <v-icon
              color="darkGrey"
              left
              size="20"
              v-text="category.icon"
            ></v-icon>
            <span>
              {{ category.title }}
            </span>
          </div>
          <ul
            v-for="(item, pindex) in category.permissions"
            :key="pindex"
            class="ml-4"
          >
            <li>
              {{ item }}
            </li>
          </ul>
        </v-col>
      </template>
    </template>
    <v-col v-else cols="12">
      <no-data first-text="No permissions for this release"></no-data>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    permissions: {
      type: Array,
      required: true,
    },
    fromReleasePage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PERMISSION_GROUP: [
        {
          title: "ACTIVITY_RECOGNITION",
          icon: "mdi-activity",
          permissions: [],
        },
        {
          title: "CALENDAR",
          icon: "mdi-calendar",
          permissions: [],
        },
        {
          title: "CALL_LOG",
          icon: "mdi-call",
          permissions: [],
        },
        {
          title: "CAMERA",
          icon: "mdi-camera",
          permissions: [],
        },
        {
          title: "CONTACTS",
          icon: "mdi-account-group",
          permissions: [],
        },
        {
          title: "LOCATION",
          icon: "mdi-place",
          permissions: [],
        },
        {
          title: "MICROPHONE",
          icon: "mdi-microphone",
          permissions: [],
        },
        {
          title: "BLUETOOTH",
          icon: "mdi-bluetooth",
          permissions: [],
        },
        {
          title: "STORAGE",
          icon: "mdi-database",
          permissions: [],
        },
        {
          title: "PHONE",
          icon: "mdi-phone",
          permissions: [],
        },
        {
          title: "SENSORS ",
          icon: "mdi-sensor",
          permissions: [],
        },
        {
          title: "SMS ",
          icon: "mdi-message-processing",
          permissions: [],
        },
        {
          title: "WIFI",
          icon: "mdi-wifi",
          permissions: [],
        },
        {
          title: "AUDIO",
          icon: "mdi-volume-high",
          permissions: [],
        },
        {
          title: "GOOGLE",
          icon: "mdi-google",
          permissions: [],
        },
        {
          title: "OTHERS",
          icon: "mdi-help-circle",
          permissions: [],
        },
      ],
    };
  },

  computed: {
    permissionLists() {
      let categoriesPermission = [];
      this.PERMISSION_GROUP.forEach((item) => {
        this.permissions.forEach((permission) => {
          if (
            permission.name.toLowerCase().includes(item.title.toLowerCase())
          ) {
            categoriesPermission.push(permission.name);
            item.permissions.push(permission.name);
          } else if (item.title === "OTHERS") {
            if (!categoriesPermission.includes(permission.name)) {
              item.permissions.push(permission.name);
            }
          }
        });
      });
      return this.PERMISSION_GROUP;
    },
  },
};
</script>
