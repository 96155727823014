var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', {
    attrs: {
      "can-be-managed": _vm.canManage,
      "is-empty": _vm.sessions.length === 0,
      "loading": _vm.sessionsLoading
    },
    scopedSlots: _vm._u([{
      key: "widget-loading",
      fn: function fn() {
        return [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('v-skeleton-loader', {
          attrs: {
            "type": "list-item-two-line"
          }
        })], 1), _c('v-col', {
          staticStyle: {
            "display": "flex",
            "justify-content": "flex-end"
          },
          attrs: {
            "cols": "6"
          }
        }, [_c('v-skeleton-loader', {
          staticStyle: {
            "margin-right": "16px"
          },
          attrs: {
            "type": "button"
          }
        })], 1)], 1), _c('v-row', [_c('v-col', _vm._l(5, function (i) {
          return _c('v-skeleton-loader', {
            key: i,
            staticClass: "transparent",
            attrs: {
              "loading": true,
              "type": "list-item-avatar-two-line"
            }
          });
        }), 1)], 1)];
      },
      proxy: true
    }, {
      key: "widget-content",
      fn: function fn() {
        return [_c('v-card-title', [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('div', {
          staticClass: "card-label bold-text text-dark"
        }, [_vm._v(" " + _vm._s(_vm.text.widgetName) + " ")])]), _vm.sessions.length > 0 ? _c('v-col', {
          staticStyle: {
            "text-align": "end"
          },
          attrs: {
            "cols": "6"
          }
        }, [_c('v-btn', {
          attrs: {
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary"
          },
          on: {
            "click": _vm.onViewAll
          }
        }, [_vm._v(_vm._s(_vm.text.viewAll) + " ")])], 1) : _vm._e()], 1)], 1), _c('v-data-table', {
          attrs: {
            "headers": _vm.headers,
            "items": _vm.sessions,
            "disable-sort": "",
            "hide-default-footer": "",
            "mobile-breakpoint": "0"
          },
          on: {
            "click:row": _vm.handleNavigation
          },
          scopedSlots: _vm._u([{
            key: "item.device.model",
            fn: function fn(_ref) {
              var item = _ref.item;
              return [_c('v-icon', {
                staticClass: "mr-1",
                attrs: {
                  "size": "24"
                },
                domProps: {
                  "textContent": _vm._s(item.device.name === 'Android' ? 'mdi-android' : 'mdi-apple')
                }
              }), _c('span', [_vm._v(" " + _vm._s(item.device.model))])];
            }
          }, {
            key: "item.application",
            fn: function fn(_ref2) {
              var _item$application, _item$application2, _item$application3;
              var item = _ref2.item;
              return [_c('div', {
                staticClass: "text-left py-2"
              }, [_c('div', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$application = item.application) === null || _item$application === void 0 ? void 0 : _item$application.name))]), _c('MaxText', {
                attrs: {
                  "max": "25",
                  "text": "".concat(item === null || item === void 0 ? void 0 : (_item$application2 = item.application) === null || _item$application2 === void 0 ? void 0 : _item$application2.version, " (").concat(item === null || item === void 0 ? void 0 : (_item$application3 = item.application) === null || _item$application3 === void 0 ? void 0 : _item$application3.version_code, ")")
                }
              })], 1)];
            }
          }, {
            key: "item.stats.errors",
            fn: function fn(_ref3) {
              var _item$stats$errors, _item$stats;
              var item = _ref3.item;
              return [_c('div', {
                staticStyle: {
                  "display": "flex",
                  "justify-content": "center",
                  "align-items": "center"
                }
              }, [_c('v-icon', {
                staticClass: "mr-1",
                class: item.stats.errors > 0 ? 'error--text' : '',
                attrs: {
                  "size": "24"
                }
              }, [_vm._v("mdi mdi-alert-circle ")]), _c('span', {
                class: item.stats.errors > 0 ? 'error--text' : ''
              }, [_vm._v(" " + _vm._s(_vm._f("formatNumber")((_item$stats$errors = item === null || item === void 0 ? void 0 : (_item$stats = item.stats) === null || _item$stats === void 0 ? void 0 : _item$stats.errors) !== null && _item$stats$errors !== void 0 ? _item$stats$errors : 0)) + " ")])], 1)];
            }
          }, {
            key: "item.feedback",
            fn: function fn(_ref4) {
              var _item$stats$feedback, _item$stats2;
              var item = _ref4.item;
              return [_c('div', {
                staticStyle: {
                  "display": "flex",
                  "justify-content": "center",
                  "align-items": "center"
                }
              }, [_vm._v(" " + _vm._s((_item$stats$feedback = item === null || item === void 0 ? void 0 : (_item$stats2 = item.stats) === null || _item$stats2 === void 0 ? void 0 : _item$stats2.feedback) !== null && _item$stats$feedback !== void 0 ? _item$stats$feedback : 0) + " ")])];
            }
          }, {
            key: "item.stats",
            fn: function fn(_ref5) {
              var _item$stats$screens, _item$stats4, _item$stats$events;
              var item = _ref5.item;
              return [_c('v-tooltip', {
                attrs: {
                  "bottom": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref6) {
                    var _item$stats3;
                    var on = _ref6.on,
                      attrs = _ref6.attrs;
                    return [_c('v-btn', _vm._g(_vm._b({
                      staticClass: "primary--text",
                      attrs: {
                        "icon": ""
                      },
                      on: {
                        "click": function click($event) {
                          $event.stopPropagation();
                          return _vm.handleNavigation.apply(null, arguments);
                        }
                      }
                    }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm._f("formatNumber")(_vm._f("sum")([item === null || item === void 0 ? void 0 : (_item$stats3 = item.stats) === null || _item$stats3 === void 0 ? void 0 : _item$stats3.screens, item === null || item === void 0 ? void 0 : item.stats.events]))) + " ")])];
                  }
                }], null, true)
              }, [_c('ul', [_c('li', [_vm._v("Screens: " + _vm._s(_vm._f("formatNumber")((_item$stats$screens = item === null || item === void 0 ? void 0 : (_item$stats4 = item.stats) === null || _item$stats4 === void 0 ? void 0 : _item$stats4.screens) !== null && _item$stats$screens !== void 0 ? _item$stats$screens : 0)))]), _c('li', [_vm._v("Events: " + _vm._s(_vm._f("formatNumber")((_item$stats$events = item === null || item === void 0 ? void 0 : item.stats.events) !== null && _item$stats$events !== void 0 ? _item$stats$events : 0)))])])])];
            }
          }, {
            key: "item.start_at",
            fn: function fn(_ref7) {
              var item = _ref7.item;
              return [_c('span', {
                class: item.status == 1 ? 'success--text' : null
              }, [_vm._v(_vm._s(_vm.getDuration(item.start_at, item.end_at)))])];
            }
          }, {
            key: "item.status",
            fn: function fn(_ref8) {
              var item = _ref8.item;
              return [_c('v-tooltip', {
                attrs: {
                  "bottom": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref9) {
                    var on = _ref9.on;
                    return [_c('v-icon', _vm._g({
                      staticClass: "mr-1",
                      class: item.status == 1 ? 'success--text' : null,
                      attrs: {
                        "size": "24"
                      }
                    }, on), [_vm._v("mdi mdi-timer ")])];
                  }
                }], null, true)
              }, [_c('span', [_vm._v(" " + _vm._s(item.status == 1 ? "Active" : "Expired") + " ")])])];
            }
          }, {
            key: "item.navigate",
            fn: function fn(_ref10) {
              var item = _ref10.item;
              return [_c('div', {
                staticClass: "flex-row jc-c"
              }, [_c('v-btn', {
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.handleNavigation(item);
                  }
                }
              }, [_c('i', {
                staticClass: "arrow-right"
              })])], 1)];
            }
          }])
        })];
      },
      proxy: true
    }, {
      key: "widget-no-permission",
      fn: function fn() {
        return [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.text.widgetName) + " ")]), _c('no-data', {
          attrs: {
            "first-text": _vm.text.noData,
            "second-text": _vm.text.noPermission
          }
        })];
      },
      proxy: true
    }, {
      key: "widget-no-data",
      fn: function fn() {
        return [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.text.widgetName) + " ")]), _c('no-data', {
          attrs: {
            "first-text": _vm.text.noData,
            "second-text": _vm.text.noDataSecond
          }
        })];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }