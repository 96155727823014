var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('img', {
    class: _vm.className,
    attrs: {
      "src": _vm.image && _vm.appId ? _vm.getAppImage(_vm.image, _vm.appId) : _vm.defaultImg || _vm.$vuetify.theme.dark ? 'app_default_light' : 'app_default',
      "height": _vm.size,
      "alt": _vm.alt || 'app'
    },
    on: {
      "error": _vm.replaceDefaultAppImage
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }