<template>
  <v-card outlined class="" flat>
    <v-card-title v-if="fromCreateRelease">
      <div>
        <div>App Information</div>
        <div class="smallFont">
          Please provide the following information about your app.
          <span v-if="showAllInfo">
            <br />
            This information is important to be correct for future updates.
            <br /><br />
            You can edit this information at any time from the app settings
            page.
          </span>
          <span
            class="cursor-pointer primary--text"
            @click="showAllInfo = !showAllInfo"
            >{{ !showAllInfo ? "Read more..." : "" }}</span
          >
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')">
        <v-icon v-text="'mdi-close'"></v-icon>
      </v-btn>
    </v-card-title>
    <v-divider v-if="fromCreateRelease" class="mb-3"></v-divider>

    <v-card-text>
      <v-row>
        <v-col
          v-if="
            !fromCreateApp &&
            ['started-releases', 'app-settings-information'].includes(
              $route.name
            )
          "
          cols="12"
          md="12"
        >
          <v-text-field
            id="editAppName"
            v-model="form.name"
            v-validate="'required|min:3|max:27'"
            counter="27"
            filled
            label="Name"
            placeholder="App name"
            v-bind="veeValidate('Name', 'Name')"
          />
        </v-col>
        <v-col v-if="!fromCreateRelease" cols="12" md="12">
          <template>
            <v-textarea
              id="team-desc"
              v-model="form.description"
              v-validate="'max:600'"
              auto-grow
              counter="600"
              filled
              label="Description"
              placeholder="Describe your awesome app... (Optional)"
              v-bind="veeValidate('Description', 'Description')"
            ></v-textarea>
          </template>
        </v-col>
        <v-col cols="12" md="12">
          <v-autocomplete
            v-model="form.categories"
            v-validate="'categories_validation'"
            :hint="appCategoriesHint"
            :items="getCategoriesList"
            :label="notRequired ? 'App categories' : 'App categories*'"
            chips
            counter="3"
            filled
            flat
            item-text="name"
            item-value="id"
            multiple
            persistent-hint
            placeholder="Search..."
            v-bind="veeValidate('category', '')"
          >
            <template v-slot:message="{ message, key }">
              <span v-html="message"></span>
            </template>
            <template v-slot:selection="data">
              <v-chip
                :input-value="data.selected"
                close
                v-bind="data.attrs"
                @click="data.select"
                @click:close="remove(data.item)"
              >
                {{ data.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-checkbox v-model="showAndroid" label="Android"></v-checkbox>
          <v-autocomplete
            v-show="showAndroid"
            v-model="form.androidLanguage"
            v-validate="''"
            :items="getAndroidLanguage"
            :search-input.sync="androidSearch"
            chips
            filled
            flat
            item-text="text"
            item-value="value"
            label="Android language"
            placeholder="Search..."
            v-bind="veeValidate('android', '')"
          >
            <template slot="append-item">
              <div>
                <v-divider></v-divider>
                <v-list-item style="border: none">
                  <span class="font-weight-regular mr-1">
                    <v-text-field
                      v-validate="'max:60'"
                      :value="newAndroidLanguage"
                      class="my-3"
                      counter="60"
                      dense
                      filled
                      hide-details
                      placeholder="Type here..."
                      v-bind="veeValidate('android option', '')"
                      @change="newAndroidLanguage = $event"
                      @keypress.enter="addAndroidLanguage"
                    ></v-text-field>
                  </span>
                  <v-btn
                    class="text-transform-none"
                    color="primary"
                    text
                    @click="addAndroidLanguage"
                  >
                    <v-icon left v-text="'mdi-plus'"></v-icon>
                    <span> Add </span>
                  </v-btn>
                </v-list-item>
              </div>
            </template>
            <template slot="no-data">
              <div>
                <v-list-item style="border: none">
                  <span class="font-weight-regular mr-1">
                    <b>{{ androidSearch }} </b> is not available
                  </span>
                  <v-btn
                    class="text-transform-none"
                    color="primary"
                    text
                    @click="addAndroidLanguage"
                  >
                    <v-icon left v-text="'mdi-plus'"></v-icon>
                    <span> Add now </span>
                  </v-btn>
                </v-list-item>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="6">
          <v-checkbox v-model="showIOS" label="iOS"></v-checkbox>
          <v-autocomplete
            v-show="showIOS"
            v-model="form.iosLanguage"
            v-validate="''"
            :items="getiOSLanguage"
            :search-input.sync="iOSSearch"
            chips
            filled
            flat
            item-text="text"
            item-value="value"
            label="iOS Language"
            placeholder="Search..."
            v-bind="veeValidate('iOS', '')"
          >
            <template slot="append-item">
              <div>
                <v-divider></v-divider>
                <v-list-item style="border: none">
                  <span class="font-weight-regular mr-1">
                    <v-text-field
                      v-validate="'max:60'"
                      :value="newIosLanguage"
                      class="my-3"
                      counter="60"
                      dense
                      filled
                      hide-details
                      placeholder="Type here..."
                      v-bind="veeValidate('iOS option', '')"
                      @change="newIosLanguage = $event"
                      @keypress.enter="addIosLanguage"
                    ></v-text-field>
                  </span>
                  <v-btn
                    class="text-transform-none"
                    color="primary"
                    text
                    @click="addIosLanguage"
                  >
                    <v-icon left v-text="'mdi-plus'"></v-icon>
                    <span> Add </span>
                  </v-btn>
                </v-list-item>
              </div>
            </template>
            <template slot="no-data">
              <div>
                <v-list-item style="border: none">
                  <span class="font-weight-regular mr-1">
                    <b>{{ iOSSearch }} </b> is not available
                  </span>
                  <v-btn
                    class="text-transform-none"
                    color="primary"
                    text
                    @click="addIosLanguage"
                  >
                    <v-icon left v-text="'mdi-plus'"></v-icon>
                    <span> Add now </span>
                  </v-btn>
                </v-list-item>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col v-if="errorMessage" class="error--text py-0" cols="12" md="12">
          {{ errorMessage }}
        </v-col>
        <v-col cols="12" md="12">
          <ownership-autocomplete
            v-if="!appInfo?.info?.ownership"
            :default-value="form.ownership"
            :not-required="notRequired"
            @selected="form.ownership = $event"
          ></ownership-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="!fromCreateApp">
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!form.ownership || !form.categories.length || busy"
        class="text-transform-none"
        color="primary"
        :outlined="$vuetify.theme.dark"
        large
        @click="updateInformation"
      >
        Update
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import { GET_APP_CATEGORIES, UPDATE } from "@/store/apps/app.module";
import { isEmpty } from "@/core/services/helper.service";
import { mapGetters } from "vuex";
import { Validator } from "vee-validate";
import OwnershipAutocomplete from "@/view/components/Common/OwnershipAutocomplete";

Validator.extend("categories_validation", {
  getMessage: (field) => {
    return `App ${field} should be less or equal to 3 `;
  },
  // Returns a boolean value
  validate: (value) => {
    return value.length <= 3;
  },
});
export default {
  components: { OwnershipAutocomplete },
  mixins: [veeValidate],
  props: {
    fromCreateRelease: {
      type: Boolean,
      default: false,
    },
    fromStarted: {
      type: Boolean,
      default: false,
    },
    notRequired: {
      type: Boolean,
      default: false,
    },
    fromCreateApp: {
      type: Boolean,
      default: false,
    },
    appDetail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        categories: [],
        androidLanguage: "",
        iosLanguage: "",
        ownership: "",
        description: "",
        name: "",
        package: "",
      },
      showAllInfo: false,
      errorMessage: "",
      newAndroidLanguage: "",
      newIosLanguage: "",
      androidSearch: "",
      busy: false,
      iOSSearch: "",
      ownershipSearch: "",
      newOwnership: "",
      appCategoriesHint: "",
      getAndroidLanguage: [
        {
          category: "Language",
          text: "Java",
          value: "Java",
        },
        {
          category: "Language",
          text: "Kotlin",
          value: "Kotlin",
        },
        {
          category: "Language",
          text: "Dart",
          value: "Dart",
        },
        {
          category: "Games",
          text: "Unity",
          value: "Unity",
        },
        {
          category: "Games",
          text: "Unreal Engine",
          value: "Unreal Engine",
        },
        {
          category: "Games",
          text: "Godot Engine",
          value: "Godot Engine",
        },
        {
          category: "Games",
          text: "Corona SDK",
          value: "Corona SDK",
        },
        {
          category: "Games",
          text: "Construct 3",
          value: "Construct 3",
        },
        {
          category: "Framework",
          text: "Flutter",
          value: "Flutter",
        },
        {
          category: "Framework",
          text: "React Native",
          value: "React Native",
        },
        {
          category: "Framework",
          text: "Nativescript",
          value: "Nativescript",
        },
        {
          category: "Framework",
          text: "Xamarin",
          value: "Xamarin",
        },
        {
          category: "Framework",
          text: "Jetpack",
          value: "Jetpack",
        },
        {
          category: "Framework",
          text: "PhoneGap ",
          value: "PhoneGap ",
        },
        {
          category: "Framework",
          text: "Framework7 ",
          value: "Framework7 ",
        },
        {
          category: "Framework",
          text: "JQuery Mobile ",
          value: "JQuery Mobile ",
        },
        {
          category: "Framework",
          text: "Sencha Touch ",
          value: "Sencha Touch ",
        },
        {
          category: "Framework",
          text: "Appcelerator Titanium",
          value: "Appcelerator Titanium",
        },
      ],
      getiOSLanguage: [
        {
          category: "Language",
          text: "Swift",
          value: "Swift",
        },
        {
          category: "Games",
          text: "Unity",
          value: "Unity",
        },
        {
          category: "Games",
          text: "Unreal Engine",
          value: "Unreal Engine",
        },
        {
          category: "Games",
          text: "Godot Engine",
          value: "Godot Engine",
        },
        {
          category: "Games",
          text: "Corona SDK",
          value: "Corona SDK",
        },
        {
          category: "Games",
          text: "Construct 3",
          value: "Construct 3",
        },
        {
          category: "Framework",
          text: "Flutter",
          value: "Flutter",
        },
        {
          category: "Framework",
          text: "React Native",
          value: "React Native",
        },
        {
          category: "Framework",
          text: "Nativescript",
          value: "Nativescript",
        },
        {
          category: "Framework",
          text: "Xamarin",
          value: "Xamarin",
        },
        {
          category: "Framework",
          text: "Jetpack",
          value: "Jetpack",
        },
        {
          category: "Framework",
          text: "PhoneGap ",
          value: "PhoneGap ",
        },
        {
          category: "Framework",
          text: "Framework7 ",
          value: "Framework7 ",
        },
        {
          category: "Framework",
          text: "JQuery Mobile ",
          value: "JQuery Mobile ",
        },
        {
          category: "Framework",
          text: "Sencha Touch ",
          value: "Sencha Touch ",
        },
        {
          category: "Framework",
          text: "Appcelerator Titanium",
          value: "Appcelerator Titanium",
        },
      ],

      showIOS: false,
      showAndroid: false,
    };
  },
  computed: {
    ...mapGetters({
      appInfo: "getAppInfo",
      user: "currentUser",
      getCategoriesList: "getCategoriesList",
    }),
  },
  watch: {
    form: {
      handler: function (val) {
        this.$emit("inputValue", val);
      },
      deep: true,
      immediate: true,
    },
    showAndroid(val) {
      if (!val) {
        this.form.androidLanguage = "";
      }
    },
    showIOS(val) {
      if (!val) {
        this.form.iosLanguage = "";
      }
    },
    appInfo(val) {
      if (val && !this.fromCreateApp) {
        this.initiateDefaultValues();
      }
    },
  },
  created() {
    if (isEmpty(this.getCategoriesList)) {
      this.getAppCategories();
    }
    if (!this.fromCreateApp) {
      this.initiateDefaultValues();
    }
  },
  methods: {
    getAppCategories() {
      if (this.busy) return;
      this.busy = false;
      this.$store.dispatch(GET_APP_CATEGORIES).then(() => {
        this.busy = false;
      });
    },
    initiateDefaultValues() {
      this.form.name = this.appDetail?.name || this.appInfo?.name;
      this.form.description =
        this.appInfo?.description || this.appDetail?.description || "";
      this.form.categories =
        this.appInfo?.categories?.map((x) => {
          if (x.id) {
            return x.id;
          } else {
            return x;
          }
        }) || [];
      this.form.androidLanguage = this.appInfo?.info?.android || "";
      this.form.iosLanguage = this.appInfo?.info?.ios || "";
      this.form.ownership = this.appInfo?.info?.ownership || "";
      this.form.package =
        this.appInfo?.package || this.appDetail?.package || "";
      if (this.form.androidLanguage) {
        this.showAndroid = true;
        if (
          !this.getAndroidLanguage.find(
            (x) => x.value === this.form.androidLanguage
          )
        ) {
          this.getAndroidLanguage.push({
            category: "",
            text: this.form.androidLanguage,
            value: this.form.androidLanguage,
          });
        }
      }
      if (this.form.iosLanguage) {
        this.showIOS = true;
        if (
          !this.getiOSLanguage.find((x) => x.value === this.form.iosLanguage)
        ) {
          this.getiOSLanguage.push({
            category: "",
            text: this.form.iosLanguage,
            value: this.form.iosLanguage,
          });
        }
      }
    },
    async addIosLanguage() {
      if (await this.validateAllFields()) {
        if (!this.newIosLanguage && !this.iOSSearch) {
          return;
        }
        this.getiOSLanguage.push({
          text: this.iOSSearch || this.newIosLanguage,
          value: this.iOSSearch || this.newIosLanguage,
        });
        this.form.iosLanguage = this.iOSSearch || this.newIosLanguage;
        this.newIosLanguage = "";
      }
    },
    async addAndroidLanguage() {
      if (await this.validateAllFields()) {
        if (!this.androidSearch && !this.newAndroidLanguage) {
          return;
        }
        this.getAndroidLanguage.push({
          text: this.androidSearch || this.newAndroidLanguage,
          value: this.androidSearch || this.newAndroidLanguage,
        });
        this.form.androidLanguage =
          this.androidSearch || this.newAndroidLanguage;
        this.newAndroidLanguage = "";
      }
    },
    updateInformation() {
      this.errorMessage = "";
      if (!this.form.androidLanguage && !this.form.iosLanguage) {
        this.errorMessage =
          "Please select the options for Android or iOS languages";
        return;
      }
      if (this.errors.any() || this.busy) {
        this.busy = false;
        return false;
      }

      this.busy = true;

      // const name = this.form.name;
      const dataToPost = {
        name: this.form.name,
        categories: this.form.categories,
        description: this.form.description,
        info: {
          android: this.form.androidLanguage,
          ios: this.form.iosLanguage,
          ownership: this.form.ownership,
        },
      };
      this.$store
        .dispatch(UPDATE, {
          id: this.appDetail?.id || this.appInfo?.id,
          ...dataToPost,
        })
        .then((response) => {
          this.busy = false;
          this.$emit("success", { info: dataToPost.info });
          this.busy = false;
          this.notifyUserMessage({
            message: response.message || "App details updated successfully",
            timeout: true,
          });
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message || "Update failed");
        });
    },
    remove(item) {
      const index = this.form.categories.indexOf(item.id);
      if (index >= 0) this.form.categories.splice(index, 1);
      if (this.form.categories.length <= 3) {
        this.$validator.errors.clear();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
