<template>
  <div class="mx-auto">
    <v-card flat :disabled="busy">
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" md="4" class="mb-3 text-center">
            <app-image-edit
              @selectedFile="selectedImage = $event"
              from-create
              :app-id="appId"
            ></app-image-edit>
          </v-col>
        </v-row>
        <storage-list
          v-if="fromCreatePanel"
          @fetch="storageLoad"
          id="storageList"
          v-model="formValues.storage_id"
        ></storage-list>

        <v-text-field
          id="appName"
          v-model="formValues.name"
          label="App name"
          v-validate="'required|max:27|min:3|name_field_validation'"
          v-bind="veeValidate('Name', '')"
          counter="27"
          filled
          placeholder="Your app name"
          messages="Can be renamed later"
        ></v-text-field>

        <!-- the filled and auto-grow prop is important -->
        <member-select
          v-if="fromCreatePanel"
          v-model="selectedMembers"
          @isInvite="inviteMember = $event"
          :app="null"
          @selected="selectedOption = $event"
        />
        <v-checkbox
          v-if="inviteMember"
          v-model="notify"
          label="Notify members by email"
          :value="false"
        ></v-checkbox>
        <v-divider class="mt-6 mb-3"></v-divider>
        <div
          @click="showAppInformation = !showAppInformation"
          class="bodyFont cursor-pointer py-3"
        >
          Additional Information
          <!--          <v-icon>{{ showAppInformation ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>-->
          <v-icon color="primary" right>{{
            showAppInformation ? "mdi-minus-circle" : "mdi-plus-circle"
          }}</v-icon>
        </div>
        <app-information
          v-if="showAppInformation"
          not-required
          @inputValue="additionalInformation = $event"
          from-create-app
        ></app-information>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="fromGetStarted"
          :outlined="$vuetify.theme.dark"
          class="text-transform-none"
          @click="$emit('skip')"
        >
          Skip to the end
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="!fromCreatePanel"
          :loading="busy"
          :disabled="errors.any() || busy || !formValues.name || busyStorage"
          id="appSubmitButton"
          color="primary"
          :outlined="$vuetify.theme.dark"
          class="text-transform-none py-7 px-15"
          depressed
          x-large
          @click="onSubmit"
        >
          {{ "Create" }}
        </v-btn>
        <v-btn
          v-else
          :disabled="errors.any() || busy || !formValues.name || busyStorage"
          :loading="busy"
          id="appSubmitButton"
          :outlined="$vuetify.theme.dark"
          class="py-7 px-15 text-transform-none"
          color="primary"
          x-large
          @click="onSubmit"
        >
          <span class="font14"> Create </span>
        </v-btn>
        <v-spacer v-if="!fromGetStarted" />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import { CREATE } from "@/store/apps/createApp.module";
import StorageList from "@/view/components/App/StorageList.vue";
import { mapGetters, mapMutations } from "vuex";
import MemberSelect from "@/view/components/Common/MemberSelectSection";
import { currentTimestamp } from "@/core/services/helper.service";
import AppImageEdit from "@/view/components/App/AppImageEdit";
import { ADD_INVITE, UPDATE_IMAGE } from "@/store/apps/app.module";
import { Validator } from "vee-validate";
import AppInformation from "@/view/components/App/AppInformation";

Validator.extend("name_field_validation", {
  getMessage: (field) => {
    return `${field} should not include any special characters `;
  },
  // Returns a boolean value
  validate: (value) => {
    const regex = new RegExp("^[0-9A-Za-z(-_.)ds ]+$");
    return regex.test(value);
  },
});

export default {
  components: { AppInformation, StorageList, MemberSelect, AppImageEdit },
  mixins: [veeValidate],
  props: {
    fromCreatePanel: {
      type: Boolean,
      default: false,
    },
    newAppName: {
      type: String,
      default: null,
    },
    fromGetStarted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAppInformation: false,
      busyStorage: true && this.fromCreatePanel,
      formValues: {
        storage_id: "",
        name: "",
      },
      additionalInformation: {},
      notify: false,
      selectedMembers: [],
      appId: "",
      err: false,
      inviteMember: false,
      selectedImage: null,
      selectedOption: "Current",
      sharePublic: false,
      busy: false,
    };
  },
  computed: {
    ...mapGetters({
      getTeamPlanUsage: "getTeamPlanUsage",
    }),
    publicApp() {
      return this.getTeamPlanUsage && this.getTeamPlanUsage.public_app;
    },
  },
  watch: {
    newAppName(val) {
      this.formValues.name = val;
    },
    showAppInformation(val) {
      if (!val) {
        this.additionalInformation = {};
      }
    },
  },
  mounted() {
    this.formValues.name = this.newAppName || "";
  },
  methods: {
    ...mapMutations({
      appendAppStats: "appendAppStats",
      addAppToAutocomplete: "addAppToAutocomplete",
    }),
    storageLoad(e) {
      this.busyStorage = e;
    },
    async onSubmit() {
      if (await this.validateAllFields()) {
        if (this.busy) return;

        this.busy = true;
        const name = this.formValues.name;
        this.$store
          .dispatch(CREATE, {
            name,
            storage_id: this.formValues.storage_id,
            description: this.additionalInformation.description,
            categories: this.additionalInformation.categories,
            info: {
              android: this.additionalInformation.androidLanguage,
              ios: this.additionalInformation.iosLanguage,
              ownership: this.additionalInformation.ownership,
            },
          })
          .then(async (response) => {
            this.appId = response.id;
            if (this.selectedImage) {
              await this.onFileUpload();
            }
            this.busy = false;
            this.$emit("close");
            this.notifyUserMessage({
              message: response.message || "Successfully created the app",
              timeout: true,
            });
            if (this.inviteMember) {
              this.addMember(response.id);
            } else if (this.fromCreatePanel && !this.inviteMember) {
              if (this.$route.name !== "app-release-index") {
                this.$router
                  .push({
                    name: "app-release-index",
                    params: {
                      app_id: response.id,
                    },
                  })
                  .catch((err) => {
                    this.err = err;
                  });
              }
            }
            this.appendAppStats({ appCount: 1 });
            const appDetail = {
              name: name,
              image: "",
              id: response.id,
              created_at: currentTimestamp(),
              platforms: [],
            };
            this.$emit("goNext", {
              id: response.id,
              name: name,
              image: "",
              platforms: [],
            });
          })
          .catch((err) => {
            this.busy = false;
            this.notifyErrorMessage(
              err.message || "Add app failed, please try again"
            );
          });
      }
    },
    onFileUpload() {
      return new Promise((resolve, reject) => {
        const form = new FormData();
        form.append("image", this.selectedImage);
        this.$store
          .dispatch(UPDATE_IMAGE, {
            id: this.appId || this.app.id,
            image: form,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    addMember(id) {
      const dataToPost =
        this.selectedOption === "Specific"
          ? {
              app_id: id,
              details: {
                add_all_team_members: false,
                notify: this.notify,
                ids: this.selectedMembers.map((x) => x.id),
              },
            }
          : {
              app_id: id,
              details: {
                notify: this.notify,
                add_all_team_members: true,
              },
            };
      this.$store
        .dispatch(ADD_INVITE, dataToPost)
        .then((response) => {
          this.notifyUserMessage({
            message: response.message || "Invited Successfully",
          });
          this.drawer = false;
          if (this.$route.name !== "app-release-index") {
            this.$router
              .push({
                name: "app-release-index",
                params: {
                  app_id: id,
                },
              })
              .catch((err) => {
                this.err = err;
              });
          }
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(
            err.message || "Invite failed, please try again later"
          );
          if (this.$route.name !== "app-release-index") {
            this.$router
              .push({
                name: "app-release-index",
                params: {
                  app_id: id,
                },
              })
              .catch((err) => {
                this.err = err;
                this.busy = false;
              });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
