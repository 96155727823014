<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-icon :color="value?.color" v-on="on">{{ value?.icon }}</v-icon>
    </template>
    <span>{{ status === 1 ? "Not Resolved" : "Resolved" }}</span>
  </v-tooltip>
</template>

<script>
import { feedbackStatus } from "@/consts/statuses";

export default {
  name: "FeedbackStatus",
  props: {
    status: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      statuses: feedbackStatus,
    };
  },
  computed: {
    value() {
      const status = this.statuses[this.status];
      return {
        icon: status?.icon,
        color: status?.color,
      };
    },
  },
};
</script>
