var render = function render(){
  var _vm$formValues$info;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "radius-10 pb-14",
    attrs: {
      "disabled": _vm.busy,
      "flat": ""
    },
    on: {
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.preventDefault();
        return _vm.createTeam.apply(null, arguments);
      }
    }
  }, [_c('v-form', [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|min:3|max:27',
      expression: "'required|min:3|max:27'"
    }],
    attrs: {
      "id": "team-name",
      "counter": "27",
      "filled": "",
      "label": "Team name",
      "messages": "Can be renamed later",
      "placeholder": "Your team name"
    },
    model: {
      value: _vm.formValues.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "name", $$v);
      },
      expression: "formValues.name"
    }
  }, 'v-text-field', _vm.veeValidate('Name', ''), false)), [_c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:600',
      expression: "'max:600'"
    }],
    staticClass: "mt-5",
    attrs: {
      "id": "team-desc",
      "auto-grow": "",
      "counter": "600",
      "filled": "",
      "label": "Description",
      "placeholder": "Describe your awesome team... (Optional)"
    },
    model: {
      value: _vm.formValues.description,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "description", $$v);
      },
      expression: "formValues.description"
    }
  }, 'v-textarea', _vm.veeValidate('Description', 'Description'), false))], [_c('ownership-autocomplete', {
    attrs: {
      "default-value": _vm.formValues.info.ownership
    },
    on: {
      "selected": function selected($event) {
        _vm.formValues.info.ownership = $event;
      }
    }
  })], _vm.fromSwitchTeam ? [_c('v-select', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: '',
      expression: "''"
    }],
    staticClass: "mt-5",
    attrs: {
      "items": _vm.teamPlanOptions,
      "filled": "",
      "item-text": "name",
      "item-value": "value"
    },
    model: {
      value: _vm.formValues.plan,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "plan", $$v);
      },
      expression: "formValues.plan"
    }
  }, 'v-select', _vm.veeValidate('plan', 'Select plan'), false))] : _vm._e(), _c('div', {
    staticClass: "text-center mt-7"
  }, [_c('v-btn', {
    staticClass: "text-transform-none py-7 px-10",
    attrs: {
      "id": "stepOneNext",
      "disabled": _vm.errors.any() || !_vm.formValues.name || !((_vm$formValues$info = _vm.formValues.info) !== null && _vm$formValues$info !== void 0 && _vm$formValues$info.ownership),
      "loading": _vm.busy,
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "x-large": ""
    },
    on: {
      "click": _vm.createTeam
    }
  }, [_c('span', {
    staticClass: "font14 semi-bold"
  }, [_vm._v(" " + _vm._s(_vm.fromSwitchTeam ? "Create" : "Next") + " ")]), !_vm.fromSwitchTeam ? _c('img', {
    staticClass: "ml-3",
    attrs: {
      "alt": "",
      "height": "20",
      "src": "/media/svg/icon-arrow-right-light.svg"
    }
  }) : _vm._e()])], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }