<template>
  <v-card color="background">
    <v-app-bar
      :flat="!$vuetify.theme.dark"
      color="secondary"
      dark
      height="80"
      max-height="80"
    >
      <v-img
        :src="'/media/logos/logo.svg'"
        class="text-left"
        contain
        height="50"
      ></v-img>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-card-text class="mt-2">
      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-row class="darkGrey--text" justify="center">
            <v-col cols="12">
              <v-row class="pt-5">
                <v-col class="subTitle darkGrey--text" cols="6">
                  {{ getTeamName }}
                  <v-menu :close-on-content-click="false" nudge-bottom="20">
                    <template v-slot:activator="{ on }">
                      <span class="mr-4 pointer font12 primary--text" v-on="on">
                        <v-icon color="primary" small>
                          mdi-swap-horizontal
                        </v-icon>
                        Switch teams
                      </span>
                    </template>
                    <team-list from-menu></team-list>
                  </v-menu>
                </v-col>
                <v-col class="text-right">
                  <v-btn icon @click="$emit('close')">
                    <v-icon v-text="'mdi-close'"></v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="background">
              <div class="titleFont darkGrey--text">
                Upgrade your team to {{ selectedPlan.name }} plan
              </div>
            </v-col>
            <v-col class="background" cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col v-if="loading" cols="12">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
            <v-col v-show="!loading" cols="12">
              <v-row class="background">
                <v-col cols="12" md="6">
                  <v-timeline align-top clipped dense>
                    <v-timeline-item
                      color="background"
                      icon="mdi-clock"
                      icon-color="darkGrey"
                      small
                    >
                      <v-row>
                        <v-col class="py-4" cols="12">
                          <div class="bodyFont darkGrey--text mb-3">
                            How often you want to be billed?
                          </div>
                          <div class="text-muted">
                            <span v-if="billingType === 'annually'">
                              Thank you for choosing yearly! We love you too
                              ❤️</span
                            >
                            <span v-else>
                              Show us some love and go yearly!
                            </span>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <span
                            :color="
                              billingType === 'annually'
                                ? 'primary'
                                : 'darkGrey'
                            "
                            :disabled="loading"
                            class="text-transform-none mr-1"
                            outlined
                            small
                            value="annually"
                            @click="billingType = 'annually'"
                          >
                            <v-icon
                              :color="
                                billingType === 'annually' ? 'primary' : 'grey'
                              "
                              left
                              v-text="
                                billingType === 'annually'
                                  ? 'mdi-radiobox-marked'
                                  : 'mdi-radiobox-blank'
                              "
                            >
                            </v-icon>
                            <span
                              :class="
                                billingType === 'annually' ? 'semi-bold' : ''
                              "
                              class="smallFont darkGrey--text"
                            >
                              Pay yearly (save 30% or nearly
                              <b>4 months</b> free)
                            </span>
                          </span>
                          <span
                            :color="
                              billingType === 'monthly' ? 'primary' : 'darkGrey'
                            "
                            :disabled="loading"
                            class="text-transform-none"
                            outlined
                            small
                            value="monthly"
                            @click="billingType = 'monthly'"
                          >
                            <v-icon
                              :color="
                                billingType === 'monthly' ? 'primary' : 'grey'
                              "
                              left
                              v-text="
                                billingType === 'monthly'
                                  ? 'mdi-radiobox-marked'
                                  : 'mdi-radiobox-blank'
                              "
                            >
                            </v-icon>
                            <span
                              :class="
                                billingType === 'monthly' ? 'semi-bold' : ''
                              "
                              class="smallFont darkGrey--text"
                            >
                              Pay monthly
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                    <!-- <v-timeline-item
                      color="white"
                      small
                      icon-color="darkGrey"
                      icon="mdi-account-group"
                    >
                      <v-row>
                        <v-col cols="12">
                          <div class="bodyFont darkGrey--text semi-bold mb-3">
                            How many members do you want in your team?
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <div>
                            <v-icon
                              v-text="'mdi-minus'"
                              left
                              right
                              @click="decreaseMembers"
                            ></v-icon>
                            <b>
                              {{ members || "..." }}
                            </b>
                            <v-icon
                              v-text="'mdi-plus'"
                              left
                              right
                              @click="increaseMembers"
                            ></v-icon>
                            <span class="bodyFont"
                              >member{{ members > 1 ? "s" : "" }}</span
                            >
                          </div>
                          <div class="bodyFont mt-4">
                            On TestApp.io, each membership cost
                            <b>${{ getCurrentPrice }}</b> per
                            {{ billingType === "annually" ? "year" : "month" }}.
                            Your team currently using
                            <a href="/team/members" target="_blank">
                              {{ upgradeSummary.total_members }} member{{
                                upgradeSummary.total_members > 1 ? "s" : ""
                              }}.
                            </a>
                          </div>
                        </v-col>
                      </v-row>
                    </v-timeline-item> -->
                    <v-timeline-item
                      color="background"
                      icon="mdi-credit-card"
                      icon-color="darkGrey"
                      small
                    >
                      <v-row>
                        <v-col cols="12">
                          <div class="bodyFont darkGrey--text mb-3">
                            Proceed with your payment
                          </div>
                          <div class="text-muted">
                            <v-icon color="green" left small
                              >mdi-lock-outline
                            </v-icon>
                            We never store your card information. We use
                            Paddle.com to process your payment and they use
                            bank-grade technology trusted by thousands of
                            companies.
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <div v-if="paddleLoading" class="bodyFont">
                            Loading payment information, please wait...
                            <v-skeleton-loader
                              type="article"
                            ></v-skeleton-loader>
                          </div>
                          <div class="paddleContainer"></div>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row class="mt-4">
                    <v-col cols="12">
                      <v-row>
                        <v-col class="bodyFont">
                          {{ selectedPlan.name }} plan
                        </v-col>
                        <v-col class="bodyFont">
                          ${{ getCurrentPrice }} /
                          {{
                            billingType === "annually" ? "yearly" : "monthly"
                          }}
                        </v-col>
                      </v-row>
                      <v-row v-if="!isEmpty(couponAppliedPrice)">
                        <v-col class="semi-bold"> Coupon (Discount) </v-col>
                        <v-col class="bodyFont"> ${{ couponDiscount }} </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="bodyFont"> Tax</v-col>
                        <v-col class="bodyFont">
                          <div v-if="paddleLoading">-</div>
                          <div v-else>${{ totalTax.toFixed(2) }}</div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12">
                      <v-row class="darkGrey--text">
                        <v-col class="titleFont bold-text"> Due amount</v-col>
                        <v-col class="bold-text titleFont">
                          <div v-if="paddleLoading">-</div>
                          <div v-else>
                            ${{
                              paddleLoading
                                ? "-"
                                : (
                                    getCurrentPrice +
                                    totalTax -
                                    couponDiscount
                                  ).toFixed(2)
                            }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <!--                    <v-col cols="12">-->
                    <!--                      <plans-questions from-modal></plans-questions>-->
                    <!--                    </v-col>-->
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <Modal v-model="successModal">
      <template #message>
        <v-row class="py-4">
          <v-col class="text-center text-h5" cols="12">
            Congratulations 🎉
          </v-col>
          <v-col class="text-center text-h6" cols="12">
            We are processing the payment <br />
            and upgrading your plan.
          </v-col>
          <v-col class="text-center" cols="12">
            <v-progress-circular
              color="success"
              indeterminate
            ></v-progress-circular>
          </v-col>
          <v-col class="text-center bodyFont" cols="12">
            It should be done within few moments
          </v-col>
        </v-row>
      </template>
    </Modal>
  </v-card>
</template>

<script>
import { get, isEmpty } from "@/core/services/helper.service";
import { UPGRADE_SUMMARY } from "@/store/team/team.module";
import { mapGetters, mapMutations } from "vuex";
import TeamList from "@/view/components/Team/List";

export default {
  components: { TeamList },
  props: {
    plans: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: "monthly",
    },
  },
  data() {
    return {
      get,
      isEmpty,
      billingType: "annually",
      loading: false,
      upgradeSummary: {},
      members: 0,
      successModal: false,
      planPrices: {
        net: "$0",
      },
      annuallyPrices: {},
      monthlyPrices: {},
      annuallySummary: {},
      monthlySummary: {},
      paddleLoading: false,
      tempThemeStatus: false,
    };
  },
  computed: {
    ...mapGetters({
      getTeamName: "getTeamName",
      getTeamId: "getTeamId",
      user: "currentUser",
      getTaxInformation: "getTaxInformation",
      getUserCountry: "getUserCountry",
      getPostCode: "getPostCode",
      couponAppliedPrice: "getCouponAppliedPrice",
      getPaymentProcessing: "getPaymentProcessing",
    }),
    selectedPlan() {
      return this.plans.find((plan) => plan.recurring === this.billingType);
    },
    totalTax() {
      if (this.billingType === "monthly") {
        return parseFloat(get(this.getTaxInformation, "vendor.unit_tax", "0"));
      } else {
        return parseFloat(get(this.getTaxInformation, "vendor.unit_tax", "0"));
      }
    },
    couponDiscount() {
      if (!isEmpty(this.couponAppliedPrice)) {
        return (
          parseInt(this.couponAppliedPrice.gross_discount) -
          parseInt(this.couponAppliedPrice.tax)
        );
      } else {
        return 0;
      }
    },
    getCurrentPrice() {
      return parseFloat(this.planPrices.net.split("$")[1]);
    },
  },
  watch: {
    billingType(val) {
      this.logEvent(`Selected ${val} plan from upgrade summary`, {
        plan_id: this.selectedPlan.id,
        plan: this.selectedPlan.name,
      });
      if (this.getUserCountry) {
        this.initCheckoutForm(this.getUserCountry);
      } else {
        this.initCheckoutForm(null);
      }
      this.setCouponPrice({});
      this.getPlanPrice();
    },
    getUserCountry(val) {
      if (val) {
        this.initCheckoutForm(val, this.getPostCode);
      }
    },
    members() {
      if (this.getUserCountry) {
        this.initCheckoutForm(this.getUserCountry, this.getPostCode);
      }
    },
  },
  created() {
    // if (this.type) {
    //   this.billingType = this.type;
    // }
    this.tempThemeStatus = this.$vuetify.theme.dark ? true : false;

    if (this.$vuetify.theme.dark) {
      this.$vuetify.theme.dark = false;
    }

    this.logEvent("initiated the payment popup", {
      plan_id: this.selectedPlan.id,
      plan_name: this.selectedPlan.name,
    });
    this.getUpgradeSummary();
  },
  mounted() {},
  beforeDestroy() {
    this.$vuetify.theme.dark = this.tempThemeStatus;
    this.setTaxInformation({ tax: {}, country: null });
    this.setCouponPrice({});
  },
  methods: {
    ...mapMutations({
      setTaxInformation: "setTaxInformation",
      setCouponPrice: "setCouponPrice",
    }),
    // increaseMembers() {
    //   if (this.members < 100) {
    //     this.members = this.members + 1;
    //   }
    // },
    // decreaseMembers() {
    //   if (this.upgradeSummary.total_members < this.members) {
    //     this.members = this.members - 1;
    //   }
    // },
    getPlanPrice() {
      if (!isEmpty(this.monthlyPrices) && this.billingType === "monthly") {
        this.planPrices = { ...this.monthlyPrices };
        return;
      }
      if (!isEmpty(this.annuallyPrices) && this.billingType === "annually") {
        this.planPrices = { ...this.annuallyPrices };
        return;
      }
      const self = this;
      window.Paddle.Product.Prices(
        this.selectedPlan.gateway_id,
        function (prices) {
          if (self.billingType === "annually") {
            self.annuallyPrices = prices.price;
          } else {
            self.monthlyPrices = prices.price;
          }
          self.planPrices = prices.price;
        }
      );
    },
    initCheckoutForm(country, postCode) {
      this.paddleLoading = true;
      const self = this;
      window.Paddle.Checkout.open({
        // override: this.upgradeSummary.url,
        product: this.selectedPlan.gateway_id,
        method: "inline",
        email: this.user?.email,
        country: country || null,
        postcode: postCode || this.getPostCode || null,
        passthrough: JSON.stringify({
          user_id: this.user.id,
          team_id: this.getTeamId,
        }),
        coupon: this.upgradeSummary.coupon || "",
        // allowQuantity: true,
        // quantity: this.members,
        // displayModeTheme: self.$vuetify.theme.dark ? "dark" : "light",
        disableLogout: true,
        frameTarget: "paddleContainer",
        frameInitialHeight: 416,
        frameStyle:
          "width:100%; min-width:312px; background-color: transparent; border: none;",
        loadCallback: function () {
          self.paddleLoading = false;
          self.loading = false;
        },
        successCallback: function () {
          self.paddleLoading = false;
          self.logEvent("subscribe", {
            plan_id: self.selectedPlan.id,
            plan_name: self.selectedPlan.name,
          });
          self.$router.push({ name: "team-usage" });
          self.successModal = true;
          setTimeout(() => {
            self.successModal = false;
            window.location.reload();
          }, 5000);
        },
      });
    },
    getUpgradeSummary() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(UPGRADE_SUMMARY, {
          plan_id:
            this.billingType === "annually" ? "Vg7oW5YxKv" : "V3GoPO2xXy",
        })
        .then((response) => {
          this.upgradeSummary = response.info;
          this.getPlanPrice();
          this.$nextTick(() => {
            this.initCheckoutForm(this.upgradeSummary);
          });
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
  },
};
</script>