var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var _vm$value, _vm$value2;
        var on = _ref.on;
        return [_c('v-icon', _vm._g({
          attrs: {
            "color": (_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.color
          }
        }, on), [_vm._v(_vm._s((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2.icon))])];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.status === 1 ? "Not Resolved" : "Resolved"))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }